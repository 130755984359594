/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-jquery */
import $ from 'jquery';
import { Promise as EmberPromise } from 'rsvp';
import Route from '@ember/routing/route';
let MapBoxLoadingRoute = Route.extend({
  beforeModel() {
    let promise = new EmberPromise(
      function (resolve) {
        if (typeof window.L === 'undefined') {
          return $.getScript('https://api.tiles.mapbox.com/mapbox.js/v1.6.2/mapbox.js')
            .done(this.loadMapStyles)
            .done(function () {
              $.getScript(
                'https://api.tiles.mapbox.com/mapbox.js/plugins/leaflet-markercluster/v0.4.0/leaflet.markercluster.js',
              ).always(function () {
                resolve();
              });
            })
            .fail(function () {
              resolve();
            });
        } else {
          resolve();
        }
      }.bind(this),
    );
    return promise;
  },
  loadMapStyles() {
    // @pat - It is okay to skip sanitization here as we are just injecting CSS.
    /* eslint-disable jquery-unsafe/no-append */
    $('head').append(
      $('<link rel="stylesheet" type="text/css" />').attr(
        'href',
        'https://api.tiles.mapbox.com/mapbox.js/v1.6.2/mapbox.css',
      ),
    );
    $('head').append(
      $('<link rel="stylesheet" type="text/css" />').attr(
        'href',
        'https://api.tiles.mapbox.com/mapbox.js/plugins/leaflet-markercluster/v0.4.0/MarkerCluster.css',
      ),
    );
    $('head').append(
      $('<link rel="stylesheet" type="text/css" />').attr(
        'href',
        'https://api.tiles.mapbox.com/mapbox.js/plugins/leaflet-markercluster/v0.4.0/MarkerCluster.Default.css',
      ),
    );
  },
});
export default MapBoxLoadingRoute;
