/* RESPONSIBLE TEAM: team-workflows */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

export default class OperatorBasicsRoute extends IntercomRoute {
  @service declare router: RouterService;

  redirect(): any {
    return this.router.replaceWith('apps.app.automation.basics');
  }
}
