/* RESPONSIBLE TEAM: team-customer-data-platform */
import Route from '@ember/routing/route';
import type Company from 'embercom/objects/inbox/company';

export default class ConversationsRoute extends Route {
  async model() {
    let company = this.modelFor('apps.app.companies.company') as Company;

    return { companyId: company.id };
  }
}
