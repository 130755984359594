/* RESPONSIBLE TEAM: team-ai-agent */
import FinAIAgentCustomAnswersAnswerEditRoute from 'embercom/routes/apps/app/fin-ai-agent/custom-answers/answer/edit';

export default class AutomationFinAIAgentCustomAnswersAnswerEditRoute extends FinAIAgentCustomAnswersAnswerEditRoute {
  analytics = {
    section: 'automation',
    place: 'fin-ai-agent.custom-answers.answer.edit',
  };

  controllerName = 'apps.app.fin-ai-agent.custom-answers.answer.edit';
  templateName = 'apps.app.fin-ai-agent.custom-answers.answer.edit';
}
