/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default Route.extend({
  router: service(),
  notificationsService: service('notificationsService'),
  redirect() {
    let url = this.router.location.formatURL('/not-found');
    if (window.location.pathname !== url) {
      later(() => {
        this.notificationsService.notifyError("We couldn't find the page you're looking for", -1);
      }, 3000);
      this.transitionTo('apps');
    }
  },
});
