/* RESPONSIBLE TEAM: team-workflows */
import FinAIAgentAICategoriesRoute from 'embercom/routes/apps/app/fin-ai-agent/ai-categories';

export default class AutomationFinAIAgentAICategoriesRoute extends FinAIAgentAICategoriesRoute {
  analytics = {
    section: 'automation',
    place: 'fin-ai-agent.ai-categories',
  };

  templateName = 'apps.app.fin-ai-agent.ai-categories';
}
