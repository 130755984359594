/* RESPONSIBLE TEAM: team-workflows */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type Router from '@ember/routing/router-service';

const OPERATOR_CUSTOM_BOTS_OVERVIEW_ROUTE = 'apps.app.automation.workflows-overview';
const OPERATOR_BASICS_ROUTE = 'apps.app.automation.basics';

export default class AutomationIndexRoute extends Route {
  @service declare appService: any;
  @service declare router: Router;

  get app() {
    return this.appService.app;
  }

  beforeModel() {
    if (this.app.canUseWorkflowsNetNewExperience && !this.app.canUseFeature('bot_workflows')) {
      this.router.replaceWith(OPERATOR_BASICS_ROUTE);
    } else {
      this.router.replaceWith(OPERATOR_CUSTOM_BOTS_OVERVIEW_ROUTE);
    }
  }
}
