/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-new-mixins */
import Mixin from '@ember/object/mixin';
import ENV from 'embercom/config/environment';
import redirect from 'embercom/lib/redirect';
import { inject as service } from '@ember/service';
import Metrics from 'embercom/models/metrics';

let AppRefresher = Mixin.create({
  router: service(),
  appService: service(),
  session: service(),
  tracing: service(),

  autoRefreshOptOuts: [
    'apps.app.inbox.inbox.conversations.conversation',
    'inbox.workspace.inbox.conversation',
    'apps.app.outbound.series.series',
  ],

  doReload(transition) {
    redirect(this._targetUrl(transition), transition);
  },

  setLastRefreshedAt() {
    this.set('lastRefreshedAt', new Date().getTime());
  },

  _lastRefreshGreaterThanTimeout() {
    let now = new Date().getTime();
    let lastRefreshedAt = this.lastRefreshedAt;
    if (!lastRefreshedAt) {
      return false;
    }
    let timeSinceLastRefresh = now - lastRefreshedAt;
    return timeSinceLastRefresh > ENV.APP.refresh_app_after_minutes * 1000 * 60;
  },

  refreshAppIfAppropriate(transition) {
    if (transition.isAborted || transition.isIntermediate || !transition.intent) {
      return;
    }

    let lastRefreshGreaterThanTimeout = this._lastRefreshGreaterThanTimeout();
    let shouldReloadForHighMemoryUsage = this._shouldReloadForHighMemoryUsage();

    if (
      (lastRefreshGreaterThanTimeout || shouldReloadForHighMemoryUsage) &&
      !this._isSensitiveRoute(transition)
    ) {
      if (shouldReloadForHighMemoryUsage) {
        this.tracing.tagRootSpan({
          app_refreshed_on_high_memory_usage: true,
        });

        Metrics.capture({ increment: ['app_refreshed_on_high_memory_usage'] });
      }

      this.doReload(transition);
    }
  },

  _isSensitiveRoute(transition) {
    for (let optOut of this.autoRefreshOptOuts) {
      if (transition.to.name.startsWith(optOut)) {
        return true;
      }
    }
    return false;
  },

  _shouldReloadForHighMemoryUsage() {
    return this._isHighMemoryUsage() && this._isActiveForMoreThanOneHour();
  },

  _targetUrl(transition) {
    let url = transition.intent.url;
    if (url) {
      if (url.startsWith(ENV.rootURL)) {
        return url;
      } else {
        let urlWithoutLeadingSlash = url.replace(/^\//, '');
        return `${ENV.rootURL}${urlWithoutLeadingSlash}`;
      }
    }

    return this.router.urlFor(transition.to.name, ...(transition.intent.contexts || []), {
      queryParams: transition.intent.queryParams,
    });
  },

  _isHighMemoryUsage() {
    if (!('performance' in window)) {
      return false;
    }

    let memory = window.performance.memory;
    if (!memory) {
      return false;
    }

    // Higher than 66% of the limit
    return memory.usedJSHeapSize > memory.jsHeapSizeLimit / 1.5;
  },

  _isActiveForMoreThanOneHour() {
    return performance.now() > 3600000;
  },
});

export default AppRefresher;
