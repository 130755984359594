/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AppsAppArticleContentRoute extends Route {
  @service store;
  @service permissionsService;

  model(params) {
    return this.store.findRecord('articles/article-content-for-editing', params.content_id);
  }

  afterModel(model, transition) {
    this.permissionsService.ensurePermissionWhenTransitioning(
      model.requiredEditPermission,
      transition,
    );
  }
}
