/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import type Store from '@ember-data/store';

export default class BillingCancelRoute extends Route {
  @service declare store: Store;
  model() {
    return this.store.findAll('cancellation-question');
  }
}
