/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
import { get } from '@ember/object';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { captureException } from 'embercom/lib/sentry';

export default Route.extend({
  notificationsService: service(),
  displayError(message) {
    this.notificationsService.notifyError(message);
  },
  displayErrorAndRedirect(message, replacementRoute) {
    this.notificationsService.notifyError(message);
    this.replaceWith(replacementRoute);
  },
  actions: {
    error(response, transition) {
      if (isPresent(get(response, 'jqXHR.responseJSON.errors'))) {
        let error = get(response, 'jqXHR.responseJSON.errors.firstObject');
        this.handleError(error, transition);
      } else if (isPresent(get(response, 'jqXHR.status'))) {
        let error = { status: get(response, 'jqXHR.status') };
        this.handleError(error, transition);
      } else if (get(response, 'errors.firstObject')) {
        let error = get(response, 'errors.firstObject');
        this.handleError(error, transition);
      } else {
        captureException(response, {
          fingerprint: ['unhandled-route-exception'],
          extra: {
            routeName: this.routeName,
            queryParams: this.queryParams,
          },
        });

        throw response;
      }
    },
  },
});
