/* RESPONSIBLE TEAM: team-product-guidance */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
export default Route.extend({
  intercomEventService: service(),
  redirect(model, transition) {
    let previousAppId = transition.to.queryParams.previousAppId;
    let analyticsParams = {
      action: 'created',
      context: 'apps_new',
      place: 'apps_new',
      object: 'app',
    };

    if (previousAppId) {
      analyticsParams['previous_app_id_code'] = previousAppId;
    }
    this.intercomEventService.trackAnalyticsEvent(analyticsParams);
    this.transitionTo('apps.app');
  },
});
