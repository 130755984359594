/* RESPONSIBLE TEAM: team-channels */
import ProductSettingsRoute from 'embercom/routes/apps/app/settings/base/product-settings-route';
import { inject as service } from '@ember/service';

export default class EmailConvertInboundEmailsToTicketsSettings extends ProductSettingsRoute {
  @service intl;
  @service store;

  analytics = {
    section: 'settings',
    place: 'email_convert_inbound_emails_to_tickets_settings',
  };
  get titleToken() {
    return this.intl.t('channels.email.settings.convert_inbound_emails_to_tickets.title');
  }

  get app() {
    return this.modelFor('apps.app');
  }

  model() {
    return this.store.findRecord('email-convert-inbound-emails-to-tickets-settings', this.app.id);
  }

  activate() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'visited',
      section: 'settings',
      place: 'email_convert_inbound_emails_to_tickets_settings',
      object: 'email_convert_inbound_emails_to_tickets_settings',
    });
  }
}
