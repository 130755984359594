/* RESPONSIBLE TEAM: team-purchase-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

export default class IntershopAddOnsAddOnRoute extends Route {
  @service declare router: RouterService;
  @service declare appService: any;
  @service declare notificationsService: any;

  get app() {
    return this.appService.app;
  }

  async model(params: any) {
    let product = this.app.products.findBy('slug', params.product_slug);
    return {
      product,
      plan: product?.plans.firstObject,
    };
  }

  afterModel(model: any) {
    if (!model.product) {
      this.notificationsService.notifyError('Sorry that addon cannot be found');
      this.router.transitionTo('apps.app.intershop');
    }
  }

  serialize(model: any) {
    return { product_slug: model.product.slug };
  }
}
