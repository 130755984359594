/* RESPONSIBLE TEAM: team-proactive-support */
import {
  objectTypes,
  matchBehaviors,
} from 'embercom/models/data/matching-system/matching-constants';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import OutboundBaseListRoute from 'embercom/lib/outbound/base-list-route';
import type IntlService from 'ember-intl/addon/services/intl';

export default class NewsfeedsIndexRoute extends OutboundBaseListRoute {
  @service declare outboundHomeService: $TSFixMe;
  @service declare intl: IntlService;

  @tracked selectedTab = 'targeted-newsfeeds';

  titleToken() {
    return this.intl.t('new-settings.submenu.proactive-support.newsfeeds');
  }

  beforeModel() {
    this.outboundHomeService.setActiveList(objectTypes.newsfeed);
  }

  objectTypes = [objectTypes.newsfeed];
  matchBehavior = matchBehaviors.transient;
}
