/* RESPONSIBLE TEAM: team-messenger */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import type MessengerSettingsService from 'embercom/services/messenger-settings-service';
import type MessengerSettingsTargetUserProvider from 'embercom/services/messenger-settings-target-user-provider';
import type Transition from '@ember/routing/transition';
import { DEFAULT_SECTIONS_FOR_TABS, type Tab } from 'embercom/services/messenger-settings-service';
import type RouterService from '@ember/routing/router-service';

export default class MessengerConversationalGreetingRoute extends IntercomRoute {
  @service declare messengerSettingsTargetUserProvider: MessengerSettingsTargetUserProvider;
  @service declare MessengerSettingsService: MessengerSettingsService;
  @service declare appService: $TSFixMe;
  @service declare router: RouterService;

  queryParams = {
    tab: {
      refreshModel: false,
      replace: true,
    },
    section: {
      refreshModel: false,
      replace: true,
    },
  };

  buildRouteInfoMetadata() {
    return {
      responsibleTeam: 'team-messenger',
    };
  }

  beforeModel() {
    if (
      !this.messengerSettingsTargetUserProvider.isUserType &&
      !this.messengerSettingsTargetUserProvider.isVisitor
    ) {
      this.messengerSettingsTargetUserProvider.setVisitorTarget();
    }
    this.MessengerSettingsService.preview.onPlatformChange('web');
  }

  afterModel(model: any, transition: Transition) {
    if (model.settings.lookAndFeel.activeBrand === model.settings.lookAndFeel.mobileBrand) {
      model.settings.lookAndFeel.activeBrand = model.settings.lookAndFeel.defaultBrand;
    }

    let { tab, section } = transition.to.queryParams;
    tab ||= 'greeting';
    section ||= DEFAULT_SECTIONS_FOR_TABS[tab as Tab];
    this.MessengerSettingsService.navigate(tab as Tab, section, { changeUrl: false });
  }
}
