/* RESPONSIBLE TEAM: team-growth-opportunities */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import { task } from 'ember-concurrency-decorators';
import {
  PRICING_5_EARLY_STAGE_BUNDLE,
  INCLUDED_TIERS_FROM_PRICING_ENDPOINT,
  PRICING_5_X_CORE_ESSENTIAL_ID,
  PRICING_5_X_CORE_ADVANCED_ID,
  PRICING_5_X_CORE_EXPERT_ID,
  PROACTIVE_SUPPORT_PLUS_BASE_ID,
} from 'embercom/lib/billing';
import { perform } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';
import ajax from 'embercom/lib/ajax';

export default class BillingUpdateSubsription extends Route {
  @service intercomEventService: $TSFixMe;
  @service router: $TSFixMe;
  @service customerService: $TSFixMe;
  @service appService: $TSFixMe;

  get app() {
    return this.appService.app;
  }

  get customer() {
    return this.customerService.customer;
  }

  get earlyStageGraduation() {
    return this.customerService.earlyStageGraduation;
  }

  beforeModel() {
    // Only accessible for Early Stage customers graduating to Pricing 5
    if (
      !this.app.canUseNewProgressionExperience ||
      this.customerService.customer.cancellationRequestedOutsideMigration ||
      !this.earlyStageGraduation?.pricing5_X_Graduation
    ) {
      return this.router.transitionTo('apps.app.billing.index');
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'billing_update_subscription_page',
    });
  }

  async model() {
    return hash({
      customer: this.customer,
      earlyStageGraduation: this.earlyStageGraduation,
      allFeatures: this.fetchAllFeatures(),
      availableEssentialFeatures: this.fetchAvailableEssentialFeatures(),
      availableAdvancedFeatures: this.fetchAvailableAdvancedFeatures(),
      availableExpertFeatures: this.fetchAvailableExpertFeatures(),
      availablePSPFeatures: this.fetchAvailablePSPFeatures(),
    });
  }

  afterModel() {
    return perform(this.requestGraduationPrices);
  }

  @task
  *requestGraduationPrices(): TaskGenerator<void> {
    let planIds = PRICING_5_EARLY_STAGE_BUNDLE.map(Number);
    let pricingModel = this.earlyStageGraduation.pricingModelForEarlyStagePlan;

    if (this.customerService.isPriceLoaded(planIds, pricingModel) === false) {
      yield this.customerService.bulkLoadPrices([
        {
          planIds,
          pricingModel,
          includeTiers: INCLUDED_TIERS_FROM_PRICING_ENDPOINT,
          source: 'update-subscription-page',
        },
      ]);
    }
  }

  fetchAllFeatures(): Promise<Array<any>> {
    return ajax({
      url: `/ember/billing_features`,
      type: 'GET',
    });
  }

  fetchAvailableFeaturesForPlan(plan_id: string): Promise<Array<any>> {
    return ajax({
      url: `/ember/billing_features/features_on_plan/${plan_id}`,
      type: 'GET',
      data: {
        plan_id,
      },
    });
  }

  fetchAvailableEssentialFeatures() {
    return this.fetchAvailableFeaturesForPlan(PRICING_5_X_CORE_ESSENTIAL_ID);
  }

  fetchAvailableAdvancedFeatures() {
    return this.fetchAvailableFeaturesForPlan(PRICING_5_X_CORE_ADVANCED_ID);
  }

  fetchAvailableExpertFeatures() {
    if (this.customer.currentlyOnEarlyStage) {
      return [];
    }
    return this.fetchAvailableFeaturesForPlan(PRICING_5_X_CORE_EXPERT_ID);
  }

  fetchAvailablePSPFeatures() {
    return this.fetchAvailableFeaturesForPlan(PROACTIVE_SUPPORT_PLUS_BASE_ID);
  }
}
