/* RESPONSIBLE TEAM: team-workflows */

/* eslint-disable @intercom/intercom/no-bare-strings */
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { hash } from 'rsvp';
import {
  OPERATOR_CUSTOM_BOTS_COMPLETED,
  OPERATOR_CUSTOM_BOTS_ENGAGED,
  OPERATOR_CUSTOM_BOTS_SENT,
} from 'embercom/lib/reporting/queries';
import moment from 'moment-timezone';
import { on } from '@ember/object/evented';

let fetchAppWithoutException = (store, appPackageId) => {
  return store.findRecord('appstore/app-package', appPackageId).catch(() => null);
};

let fetchApps = (store) =>
  hash({
    salesforce: fetchAppWithoutException(store, 'salesforce-by-intercom'),
    googleCalendar: fetchAppWithoutException(store, 'google-calendar-meetings'),
  });

let fetchStatsSignals = ({ service, range, filters }) =>
  hash({
    conversationsSent: service.fetchComparisonSignal(
      OPERATOR_CUSTOM_BOTS_SENT,
      'custom bot',
      range,
      Object.assign({ 'event.type': 'stats_receipt' }, filters),
    ),
    conversationsEngaged: service.fetchComparisonSignal(
      OPERATOR_CUSTOM_BOTS_ENGAGED,
      'custom bot',
      range,
      Object.assign({ 'event.type': 'stats_reply' }, filters),
    ),
    conversationsCompleted: service.fetchComparisonSignal(
      OPERATOR_CUSTOM_BOTS_COMPLETED,
      'custom bot',
      range,
      Object.assign({ 'event.type': 'stats_completion' }, filters),
    ),
  });

export default IntercomRoute.extend({
  store: service(),
  appService: service(),
  reportingService: service(),
  intercomEventService: service(),
  app: readOnly('appService.app'),
  titleToken: 'Custom Bots | Operator',
  range: readOnly('reportingService.range'),

  analytics: {
    place: 'custom_bots',
  },
  instrumentationDelay: 3000,

  queryParams: {
    customBot: { refreshModel: true },
    workflowTarget: { refreshModel: true },
  },

  model(params) {
    let signals;
    let { customBot, workflowTarget } = params;
    let range = this.range;
    let filters = {
      exists_fields: ['custom_bot_id'],
      by_customer_facing_workflow: customBot ? null : true,
    };
    let statsSystemFilters = {
      by_root_custom_bot: true,
      by_customer_facing_workflow: customBot ? null : true,
      should: {},
    };

    if (customBot) {
      filters.custom_bot_id = [customBot];
      statsSystemFilters.should['custom_bot.id'] = [customBot];
      statsSystemFilters.should['inbound_custom_bot.id'] = [customBot];
      statsSystemFilters.should['button_custom_bot.id'] = [customBot];
      statsSystemFilters.should['triggerable_custom_bot.id'] = [customBot];
    }

    if (workflowTarget) {
      filters.workflow_target = [workflowTarget];
      statsSystemFilters.workflow_target = [workflowTarget];
    }

    signals = fetchStatsSignals({
      service: this.reportingService,
      range,
      filters: statsSystemFilters,
    });

    let apps = fetchApps(this.store);
    let bots = this.store.findAll('operator/custom-bot-reportable-bot');

    return hash({ range, bots, signals, apps, filters, statsSystemFilters });
  },

  beforeModel() {
    this.replaceWith('apps.app.reports.lead-generation.custom-bots.sent');
  },

  setupController(controller, { range, bots, signals, apps, filters, statsSystemFilters }) {
    controller.setProperties({
      range,
      bots,
      signals,
      apps,
      filters,
      statsSystemFilters,
      rangeStart: range.start,
      rangeEnd: range.end,
    });
  },

  trackPageview: on('activate', function () {
    this.intercomEventService.trackEvent('viewed_custom_bots_report');
    this.instrumentationEvent = setTimeout(
      () =>
        this.intercomEventService.trackAnalyticsEvent({
          action: 'viewed',
          object: 'custom_bots_report',
          context: 'operator',
          start_date: moment(this.rangeStart).format('YYYY/MM/DD'),
          end_date: moment(this.rangeEnd).format('YYYY/MM/DD'),
          bot_id: this.customBot,
        }),
      this.instrumentationDelay || 0,
    );
  }),

  cleanupDelayedTrackPageview: on('deactivate', function () {
    return this.instrumentationEvent && clearTimeout(this.instrumentationEvent);
  }),
});
