/* RESPONSIBLE TEAM: team-workflows */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class SelfSolveOnboardingRoute extends IntercomRoute {
  @service intercomEventService;

  @action
  didTransition() {
    this.intercomEventService.trackEvent('has_visited_solve_page');
  }
}
