/* RESPONSIBLE TEAM: team-reporting */
import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import { tracked } from '@glimmer/tracking';
import { isEqual } from 'underscore';
import { copy } from 'ember-copy';
import ReportMode from 'embercom/lib/reporting/report-mode';
import { captureException } from 'embercom/lib/sentry';

export default class ShowReportRoute extends Route {
  @service store;
  @service appService;
  @service intercomEventService;
  @service router;
  @service notificationsService;
  @service paywallService;
  @service permissionsService;
  @service navbarCollapsingService;
  @service intl;
  @service customReportsService;
  @service reportAccessService;

  @tracked editMode;
  reportMode;
  reportSnapShot;
  originalReport;
  lastReportId;

  analytics = {
    place: 'custom_reports',
  };

  referrer = 'apps.app.reports.overview';

  get isPaywallActive() {
    if (this.appService.app.canSeeR2Beta) {
      return false;
    }

    return this.paywallService.isPaywallActive({
      featureName: 'custom_reports',
    });
  }

  getReferrer(transition) {
    if (
      transition.from === null ||
      transition.from.name === 'apps.app.reports.custom-reports.report.new.index' ||
      transition.from.name === 'apps.app.reports.custom-reports.report.new.chart.new' ||
      transition.from.name === 'apps.app.reports.custom-reports.report.show.chart.new' ||
      transition.from.name === 'apps.app.reports.custom-reports.report.show.chart.edit'
    ) {
      return this.referrer;
    } else {
      return this.router.currentURL;
    }
  }

  titleToken(model) {
    return model?.report?.title;
  }

  async model({ report_id }) {
    let localReport = this.store.peekRecord('reporting/custom/report', report_id);
    let report = localReport;
    if (
      this.lastReportId !== report_id &&
      report?.currentState.stateName !== 'root.loaded.created.uncommitted'
    ) {
      // with the condition above we load this anytime we transition into a report newly
      // https://github.com/intercom/intercom/issues/361648#issuecomment-2426616341
      this.lastReportId = report_id;
      try {
        if (this.appService.app.canShareReportsInternally) {
          // the order and await here is intentional
          // loadingReportAccess might slow down reporting so we skip the await and set this task on first
          this.loadReportAccess(report_id);
        }
        report = await this.store.findRecord('reporting/custom/report', report_id, {
          reload: true,
          backgroundReload: false,
        });
        this.originalReport = copy(
          report.serialize({ adapterOptions: { canSeeR2Beta: true } }),
          true,
        );
      } catch (error) {
        if (error.jqXHR.status === 404 && !this.appService.app.canSeeR2Beta) {
          // if report not found transition to all reports page
          this.notificationsService.notifyError(
            this.intl.t('reporting.reports-2-navigation.no-reports-found'),
          );
          this.transitionTo('apps.app.reports.views.view', 'all-reports');
          this.lastReportId = undefined;
        } else if (error.jqXHR.status === 404 && this.appService.app.canSeeR2Beta) {
          // if report not found transition to overview page
          this.notificationsService.notifyError(
            this.intl.t('reporting.reports-2-navigation.no-reports-found'),
          );
          this.router.transitionTo('apps.app.reports.overview');
          this.lastReportId = undefined;
        } else if (error.jqXHR.status === 403 && this.appService.app.canShareReportsInternally) {
          this.router.transitionTo('apps.app.reports.overview');
          this.reportAccessService.loadReportAccessModal(report_id, () => {});
          this.lastReportId = undefined;
        } else {
          captureException(error);
          console.error(error);
        }
      }
    }

    // reports can only be partially loaded for the nav bar
    // We only want to reload the record if thats the case
    // If the report has no charts, that indicates it was partially loaded
    this.reportMode = this.reportMode ?? new ReportMode(report.isNew, report.isNew);
    return hash({
      report,
      editMode: this.inEditMode(report),
      enableEditMode: this.enableEditMode,
      settings:
        this.store.peekAll('reporting/settings')?.firstObject ||
        this.store.queryRecord('reporting/settings', {}),
      referrer: this.referrer,
      reportMode: this.reportMode,
      resetState: this.resetState,
    });
  }

  beforeModel(transition) {
    this.referrer = this.getReferrer(transition);
  }

  redirect({ report }) {
    if (this.isPaywallActive && report.createdById) {
      this.transitionTo('apps.app.reports.views.view', 'all-reports');
    }
  }

  @action
  async enableEditMode(enable) {
    this.editMode = enable;
    if (enable) {
      let { report } = this.modelFor(this.routeName);
      if (report.hasDirtyAttributes) {
        // rolling back changes done while view mode, if any and use report loaded from db when transitioning
        report.rollbackAttributes();
        this.reportSnapShot = this.originalReport;
      } else {
        this.reportSnapShot = copy(
          report.serialize({ adapterOptions: { canSeeR2Beta: true } }),
          true,
        );
      }
    }
    return this.refresh();
  }

  async loadReportAccess(report_id) {
    try {
      return await this.reportAccessService.fetchReportAccess.perform(report_id);
    } catch (e) {
      if (e.name === 'TaskCancelation') {
        return [];
      } else {
        throw e;
      }
    }
  }

  @action
  didTransition() {
    let { report } = this.modelFor(this.routeName);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'custom_report',
      custom_report_id: report.id,
      custom_report_name: report.title || 'untitled',
      number_of_charts: report.charts.length,
      number_of_custom_charts: report.customCharts.length,
      has_custom_charts: report.hasCustomCharts,
      is_on_reports_1: !this.appService.app.canSeeR2Beta,
    });
    this.intercomEventService.trackEvent('viewed-custom-report');
  }

  get canChangeCustomReports() {
    return this.customReportsService.canChangeCustomReports;
  }

  @action
  resetState() {
    // setting editMode to undefined not false because we want user's transition into new report
    // to become true this.editMode ?? report.isNew
    this.editMode = undefined;
    this.reportSnapShot = undefined;
    this.reportMode = undefined;
    this.lastReportId = undefined;
    this.refresh();
  }

  afterModel(model, transition) {
    if (
      this.inEditMode(model.report) &&
      this.notAddingCR2Chart(transition) &&
      // !transition.queryParamsOnly gets set to true every time except when we transition between editMode.
      !transition.queryParamsOnly
    ) {
      this.resetState();
    } else if (this.fromReportCreation(transition) && this.editMode !== undefined) {
      // if you just created a new report, we want to reset panel and editMode
      this.resetState();
    }

    if (this.appService.app.canSeeR2Beta) {
      if (!this.navbarCollapsingService.collapsed && this.inEditMode(model.report)) {
        this.navbarCollapsingService.toggleNavbarCollapsed();
      } else if (this.navbarCollapsingService.collapsed && !this.inEditMode(model.report)) {
        this.navbarCollapsingService.toggleNavbarCollapsed();
      }
    }
  }

  deactivate() {
    // for issue: https://github.com/intercom/intercom/issues/340252
    let report = this.router.currentRoute?.attributes?.report;
    let inEditMode = report ? this.inEditMode(report) : this.editMode;
    if (this.appService.app.canSeeR2Beta) {
      if (this.navbarCollapsingService.collapsed && !inEditMode) {
        this.navbarCollapsingService.toggleNavbarCollapsed();
      }
    }
  }

  inEditMode(report) {
    if (!this.appService.app.canSeeR2Beta) {
      return true;
    } else {
      return this.editMode ?? report.isNew;
    }
  }

  @action
  revert() {
    let { report } = this.modelFor(this.routeName);
    report.charts.compact().forEach((chart) => {
      chart.rollbackAttributes();
    });
    // we'll load the report from db, we rollback any changes made in edit mode
    report.rollbackAttributes();
  }

  @action
  hasChanges() {
    // compare changes from when user moved into editMode (no changes before then considered)
    let { report } = this.modelFor(this.routeName);
    if (!this.appService.app.canSeeR2Beta) {
      return report.hasDirtyAttributes;
    } else if (report.isNew) {
      // new reports don't have snapshots
      return report.hasCharts || report.hasTitle || report.hasDescription;
    } else {
      return !isEqual(
        report.serialize({ adapterOptions: { canSeeR2Beta: true } }),
        this.reportSnapShot,
      );
    }
  }

  @action
  async willTransition(transition) {
    let { report } = this.modelFor(this.routeName);
    if (this.isPaywallActive) {
      report.rollbackAttributes();
    } else if (
      !transition.data.skipConfirmation &&
      this.inEditMode(report) &&
      this.hasChanges() &&
      this.notAddingCR2Chart(transition) &&
      // This guard prevents an issue with ember where an infinite loop is triggered if you abort a transition
      // and have a query param with `refreshModel` set to true: https://github.com/emberjs/ember.js/issues/12473
      // For more context see: https://github.com/intercom/embercom/pull/47042
      !transition.queryParamsOnly &&
      this.canChangeCustomReports &&
      this.reportAccessService.canEditReportOrReportIsNew(report)
    ) {
      transition.abort();
      let isNewReport = report.isNew;
      let confirmed = await this.customReportsService.confirmSave(
        isNewReport && this.appService.app.canShareReportsInternally,
      );
      if (confirmed === true) {
        await report.save({
          adapterOptions: {
            canSeeR2Beta: this.appService.app.canSeeR2Beta,
          },
        });
        if (isNewReport && this.appService.app.canShareReportsInternally) {
          await this.customReportsService.saveDefaultReportAccess(report);
        }
        this.resetState();
        transition.data.skipConfirmation = true;
        this.notificationsService.notifyConfirmation(
          this.intl.t('reporting.custom-reports.report.report-saved'),
        );
        if (report.charts.length > 0) {
          this.intercomEventService.trackEvent('saved-custom-report');
        }
        transition.retry();
      } else if (confirmed.canceled) {
        if (this.appService.app.canSeeR2Beta) {
          this.revert();
          this.resetState();
        } else {
          report.charts.compact().forEach((chart) => {
            chart.rollbackAttributes();
          });
          report.rollbackAttributes();
        }
        transition.data.skipConfirmation = true;
        transition.retry();
      } else if (confirmed.closed) {
        // Workaround for https://github.com/emberjs/ember.js/issues/5210#issuecomment-623967508
        this.router.location.setURL(this.router.currentURL);
      }
    }
  }

  notAddingCR2Chart(transition) {
    return (
      !transition.to.queryParams.cr2AddingChartToReport ||
      transition.to.queryParams.cr2AddingChartToReport === 'false'
    );
  }

  fromReportCreation(transition) {
    if (!this.appService.app.canSeeR2Beta) {
      return false;
    }
    return transition.to?.queryParams?.fromReportCreation === 'true';
  }
}
