/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
import { inject as service } from '@ember/service';
import SegmentSetupModelAndController from 'embercom/routes/mixins/users-and-companies/segment-setup-model-and-controller';
import ModalNavigationWarningRoute from 'embercom/routes/base/modal-navigation-warning';

let SegmentRoute = ModalNavigationWarningRoute.extend(SegmentSetupModelAndController, {
  pubsub: service(),
  titleToken: 'Companies',
  companiesOrUsersRoute: 'apps.app.companies',

  actions: {
    willTransition(transition) {
      let path = this.get('currentModel.identifier');
      return this.conditionallyTransition(transition, path);
    },
    showSegment(segment) {
      this.send('addSegmentToList', segment);
      this.transitionTo('apps.app.companies.segments.segment', segment.identifier);
    },
    addFiltersToUrl(segment) {
      this.addFiltersToUrl(segment);
    },
  },
});

export default SegmentRoute;
