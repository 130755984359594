/* RESPONSIBLE TEAM: team-workflows */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class CustomBotsGraphDemoRoute extends Route {
  @service router;

  beforeModel() {
    this.router.replaceWith('apps.app.automation.workflows.graph-demo');
  }
}
