/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controller-access-in-routes */
import { hash } from 'rsvp';
import WorkspaceSettingsRoute from 'embercom/routes/apps/app/settings/base/workspace-settings-route';
import { inject as service } from '@ember/service';
import { all } from 'rsvp';

export default WorkspaceSettingsRoute.extend({
  store: service(),
  intl: service(),
  analytics: {
    section: 'settings',
    place: 'security',
  },
  get titleToken() {
    return this.intl.t('apps.app.settings.security.title');
  },
  customerService: service(),
  permissionsHierarchyService: service(),
  appService: service(),
  activate() {
    window.onbeforeunload = function () {
      if (this.controller && this.controller.get('isDirty')) {
        return this.intl.t('apps.app.settings.security.warning');
      }
    }.bind(this);
  },
  hasAlreadyCancelled: false,
  deactivate() {
    this.set('hasAlreadyCancelled', false);
    window.onbeforeunload = undefined;
  },
  async beforeModel(_transition) {
    return all([
      this._super(...arguments),
      this.customerService.ensureDataIsLoaded.perform({ fetchPrices: false }),
      await this.permissionsHierarchyService.ensureDataIsLoaded.perform(),
    ]);
  },
  model() {
    let { id } = this.modelFor('apps/app');

    return hash({
      pingDomains: this.store.query('ping-domain', { ignore_anonymous: true }),
      settings: this.store.findRecord('security-app-settings', id),
    });
  },
  setupController(controller, _model) {
    this._super(...arguments);

    controller.set('initialProvisioningMethod', controller._initializeCurrentProvisioningMethod());
    controller.set('selectedProvisioningMethod', controller._initializeCurrentProvisioningMethod());
  },
  resetController(controller, _isExiting, _transition) {
    controller.samlAccount?.rollbackAttributes();
    controller.scimSettings?.rollbackAttributes();
    controller.model.settings.rollbackAttributes();
  },
  actions: {
    willTransition(transition) {
      if (this.hasAlreadyCancelled) {
        transition.abort();
        this.set('hasAlreadyCancelled', false);
      } else if (
        this.controller.get('isDirty') &&
        !window.confirm(this.intl.t('apps.app.settings.security.warning-leave-page'))
      ) {
        this.set('hasAlreadyCancelled', true);
        transition.abort();
      } else {
        return true;
      }
    },
  },
});
