/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import ReportBase from 'embercom/routes/apps/app/reports/base/report';

export default class EmailDeliverabilityRoute extends ReportBase {
  reportName = 'email_deliverability_report';
  titleToken = 'Email deliverability';
  analytics = {
    place: 'email_deliverability',
  };
  shouldRedirectFromParent = true;

  fetchSignals() {
    return {};
  }

  beforeModel() {
    this.replaceWith('apps.app.reports.outbound-engagement.email-deliverability.opened');
  }
}
