/* RESPONSIBLE TEAM: team-proactive-support */
import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';

export default class AppsAppOutboundChecklists extends Route {
  @service declare appService: any;
  @service declare store: Store;

  get app() {
    return this.appService.app;
  }

  async model() {
    let model = await hash({
      messengerSettings: this.store.findRecord('messenger-settings/all', this.app.id),
    });
    return model;
  }
}
