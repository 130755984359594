/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controller-access-in-routes */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import ENV from 'embercom/config/environment';
import { inject as service } from '@ember/service';

let ModalNavigationWarningRoute = IntercomRoute.extend({
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  intl: service(),
  router: service(),

  activate() {
    let self = this;
    if (ENV.environment !== 'test') {
      window.onbeforeunload = function () {
        let applicationController = self.controllerFor('application');
        if (applicationController.get('shouldWarnOnNavigation')) {
          return window.confirm(this.intl.t('components.modal.message.warning-message'));
        }
      };
    }
  },
  deactivate() {
    if (ENV.environment !== 'test') {
      window.onbeforeunload = undefined;
    }
  },

  conditionallyTransition(transition, path) {
    let shouldCloseModal = true;
    let applicationController = this.controllerFor('application');
    if (applicationController.get('shouldWarnOnNavigation')) {
      shouldCloseModal = window.confirm(this.intl.t('components.modal.message.warning-message'));

      if (!shouldCloseModal) {
        let previousRouteName = transition.from.name;
        let url = this.router.urlFor(previousRouteName, path);
        transition.abort();
        //use history.pushState to get over the fact that the url has already
        //changed by the time we get here
        history.pushState({}, '', url);
      } else {
        this.modalService.closeModal();
      }
    } else {
      this.modalService.closeModal();
      return true;
    }
  },
});

export default ModalNavigationWarningRoute;
