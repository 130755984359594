/* RESPONSIBLE TEAM: team-reporting */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class ReportsConversationalInsightsRoute extends Route {
  @service appService;

  buildRouteInfoMetadata() {
    return {
      responsibleTeam: 'team-reporting',
    };
  }

  analytics = {
    place: 'topics',
  };

  beforeModel(transition) {
    // Allow customers without the feature to see the paywall on
    // the index page. Otherwise, transition away.
    if (
      !this.appService.app.hasConversationalInsightsBillingFeature &&
      'apps.app.reports.conversational-insights.index' !== transition.to.name
    ) {
      return this.transitionTo('apps.app.reports.conversational-insights.index');
    }
  }
}
