/* RESPONSIBLE TEAM: team-help-desk-experience */

import Route from '@ember/routing/route';

export default class Inbox2SearchRoute extends Route {
  queryParams = {
    query: {
      refreshModel: true,
    },
    filters: {
      refreshModel: true,
    },
    sort: {
      refreshModel: true,
    },
  };

  model() {
    return this.paramsFor(this.routeName);
  }
}
