/* RESPONSIBLE TEAM: team-proactive-support */
import OutboundBaseListRoute from 'embercom/lib/outbound/base-list-route';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { inject as service } from '@ember/service';

export default class AppsAppOutboundCustomBotIndexRoute extends OutboundBaseListRoute {
  @service appService;
  @service outboundHomeService;

  beforeModel() {
    return this.redirectToAllOutboundSearch();
  }

  titleToken = 'Custom Bots';
  analytics = {
    section: 'outbound',
    place: 'custom-bot',
  };

  perPage = 100;
  objectTypes = [objectTypes.customBot];
}
