/* RESPONSIBLE TEAM: team-frontend-tech */
import Route from '@ember/routing/route';
import ajax from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class ImpersonateRoute extends Route {
  @service appService;
  @service notificationsService;
  @service modalService;

  async beforeModel(transition) {
    if (!this.app.isIntercom) {
      this.notificationsService.notifyErrorWithButton(
        'Impersonation request failed, current App is not Intercom.',
        { label: 'Ok', action: () => {} },
        0,
      );
    } else {
      await this.admin.reload();
      this.triggerImpersonation(transition.to.queryParams);
    }
  }

  get app() {
    return this.appService.app;
  }

  get admin() {
    return this.app.currentAdmin;
  }

  get adminImpersonationPermissionNotRequested() {
    return this.adminImpersonationPermissionStatus === 'not_requested';
  }

  get adminImpersonationPermissionStatus() {
    return this.admin.impersonation_permission_status;
  }

  @action
  async _submitImpersonationRequestForAdmin(admin) {
    try {
      await ajax({
        url: '/admin/impersonation_permissions',
        type: 'POST',
        dataType: 'text',
      });
      this.notificationsService.notifyErrorWithButton(
        'Impersonation request submitted. You will be notified via Slack once it has been approved. Once approved, refresh the page and try again.',
        { label: 'Ok', action: () => {} },
        0,
      );
      admin.set('impersonation_permission_status', 'requested');
    } catch (e) {
      console.error(e);
      this.notificationsService.notifyErrorWithButton(
        'There was a problem submitting a request for impersonation permission. Please reach out to #ask-security.',
        { label: 'Ok', action: () => {} },
        0,
      );
    }
  }

  @action
  async _checkImpersonationConsent(impersonatedAdminId, impersonatedAdminEmail) {
    if (!impersonatedAdminId && !impersonatedAdminEmail) {
      this.notificationsService.notifyErrorWithButton(
        'User is not an Admin of any App',
        { label: 'Ok', action: () => {} },
        0,
      );
      return {
        impersonation_consent: false,
      };
    }

    let searchParams = new URLSearchParams({
      impersonated_admin_email: impersonatedAdminEmail,
    });
    return ajax({
      url: `/admin/impersonations/check_consent/${impersonatedAdminId}?${searchParams.toString()}`,
    });
  }

  async triggerImpersonation(queryParams) {
    if (this.adminImpersonationPermissionNotRequested) {
      this._submitImpersonationRequestForAdmin(this.admin);
    } else if (this.adminImpersonationPermissionStatus === 'approved') {
      try {
        let impersonateConsentStatus = await this._checkImpersonationConsent(
          queryParams.impersonatedAdminId,
          queryParams.impersonatedAdminEmail,
        );
        if (impersonateConsentStatus?.impersonation_consent) {
          this.modalService.openModal(
            'users/user/modals/impersonate',
            {
              user_id: queryParams.impersonatedAdminId,
              impersonatedAdminEmail: queryParams.impersonatedAdminEmail,
            },
            {
              defaultReason: this.admin.impersonation_permission_default_reason,
              impersonationConsentExists:
                impersonateConsentStatus.enforce_restrict_data_access === false,
            },
          );
        } else {
          this.notificationsService.notifyErrorWithButton(
            'User has not given impersonation consent',
            { label: 'Ok', action: () => {} },
            0,
          );
        }
      } catch (e) {
        console.error(e);
        let errorMessage =
          e.jqXHR.status === 404
            ? e.jqXHR.responseJSON.errors
            : 'There was a problem checking for impersonation consent. Please reach out to #ask-security.';
        this.notificationsService.notifyErrorWithButton(
          errorMessage,
          { label: 'Ok', action: () => {} },
          0,
        );
        return;
      }
    } else {
      this.notificationsService.notifyErrorWithButton(
        'Impersonation request already submitted. You will be notified via Slack once it has been approved. Once approved, refresh the page and try again.',
        { label: 'Ok', action: () => {} },
        0,
      );
    }
  }
}
