/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import { TEAMMATE_USAGE } from 'embercom/lib/settings/seats/constants';
import calculateCommonSeats, {
  calculateCommonSeatTypes,
} from 'embercom/lib/teammates/calculate-common-seats';
import ManageTeammatesRoute from 'embercom/routes/apps/app/settings/base/manage-teammates-route';
import { isEqual } from 'underscore';
import ModelWithPermissions from 'embercom/models/model-with-permissions';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import type Transition from '@ember/routing/transition';
import { action } from '@ember/object';

export default class PermissionsRoute extends ManageTeammatesRoute {
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare permissionsMutatorService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare permissionsHierarchyService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare store: Store;
  @service declare intercomConfirmService: IntercomConfirmService;

  seatsSelections = {};
  analytics = {
    section: 'settings',
    place: 'bulk-teammate-permissions',
  };

  async beforeModel(transition: Transition) {
    await super.beforeModel(...arguments);

    if (!transition.data.teammates) {
      this.transitionTo('apps.app.settings.workspace.teammates');
    }

    await this.permissionsHierarchyService.ensureDataIsLoaded.perform();
  }

  model(_params: $TSFixMe, transition: Transition) {
    let teammates = transition.data.teammates;
    let commonRoleId = this._getCommonRoleId(teammates);
    let permissionsObject = this.store.createRecord('permission', { role_id: commonRoleId });
    if (this.appService.app.hasMultipleSeatTypes) {
      if (commonRoleId) {
        let role = this.store.peekRecord('role', commonRoleId);
        permissionsObject.setPermissions(role);
        permissionsObject.accessType = role.accessType;
        permissionsObject.includeUnassigned = role.includeUnassigned;
        permissionsObject.assigneeBlockedList = role.assigneeBlockedList;
      }
      this.seatsSelections = calculateCommonSeats(teammates);
      this._initializePermissionFields(teammates, permissionsObject);
      this._initializeAccessType(teammates, permissionsObject);
    } else {
      permissionsObject.setAllPermissions(undefined);
      permissionsObject.accessType = undefined;
    }
    return {
      app: this.appService.app,
      teammates,
      permissionsObject,
      permissionsObjectChanged: false,
      reassignments: undefined,
      roles: this.store.findAll('role'),
      seatTypes: calculateCommonSeatTypes(this.seatsSelections),
      seatsSelections: this.seatsSelections,
      seatsSelectionsChanged: false,
      usageType: TEAMMATE_USAGE,
      closeWithoutSaving: false,
    };
  }

  get getCurrentModel(): $TSFixMe {
    return this.modelFor(this.routeName);
  }

  _initializePermissionFields(teammates: $TSFixMe, permissionsObject: $TSFixMe) {
    ModelWithPermissions.editablePermissionKeys.forEach((permissionKey) => {
      let firstTeammatePermission = teammates.firstObject.currentAppPermissions[permissionKey];
      let allTeammatesHaveSamePermission = teammates.every(
        (teammate: $TSFixMe) =>
          teammate.currentAppPermissions[permissionKey] === firstTeammatePermission,
      );
      permissionsObject[permissionKey] = allTeammatesHaveSamePermission
        ? firstTeammatePermission
        : undefined;
    });
  }

  _initializeAccessType(teammates: $TSFixMe, permissionsObject: $TSFixMe) {
    let firstTeammatePermissions = teammates.firstObject.currentAppPermissions;
    let firstTeammateAccessType = firstTeammatePermissions.accessType;
    let firstTeammateIncludeUnassigned = firstTeammatePermissions.includeUnassigned;
    let firstTeammateAssigneeBlockedList = firstTeammatePermissions.assigneeBlockedList;

    let allTeammatesHaveSameAccessType = teammates.every((teammate: $TSFixMe) => {
      return (
        teammate.currentAppPermissions.accessType === firstTeammateAccessType &&
        teammate.currentAppPermissions.includeUnassigned === firstTeammateIncludeUnassigned &&
        isEqual(
          teammate.currentAppPermissions.assigneeBlockedList,
          firstTeammateAssigneeBlockedList,
        )
      );
    });

    if (allTeammatesHaveSameAccessType) {
      permissionsObject.accessType = firstTeammateAccessType;
      permissionsObject.includeUnassigned = firstTeammateIncludeUnassigned;
      permissionsObject.assigneeBlockedList = firstTeammateAssigneeBlockedList;
    } else {
      permissionsObject.accessType = undefined;
      permissionsObject.includeUnassigned = undefined;
      permissionsObject.assigneeBlockedList = undefined;
    }
  }

  _getCommonRoleId(teammates: $TSFixMe) {
    let commonRoleId = teammates[0].currentAppPermissions.role_id;
    let allRolesMatch = teammates.every(
      (teammate: $TSFixMe) => teammate.currentAppPermissions.role_id === commonRoleId,
    );
    return allRolesMatch ? commonRoleId : null;
  }

  @action
  async willTransition(transition: Transition) {
    if (this.getCurrentModel.closeWithoutSaving) {
      return;
    }

    // Only show modal if there were any changes
    if (this.getCurrentModel.permissionsObjectChanged) {
      transition.abort();

      let confirmOptions = {
        title: this.intl.t('settings.teammates.permissions.close-without-saving-modal.title'),
        primaryButtonType: 'primary-destructive',
        confirmButtonText: this.intl.t(
          'settings.teammates.permissions.close-without-saving-modal.close-without-saving',
        ),
        cancelButtonText: this.intl.t(
          'settings.teammates.permissions.close-without-saving-modal.keep-editing',
        ),
        body: this.intl.t('settings.teammates.permissions.close-without-saving-modal.message-body'),
      };

      if (await this.intercomConfirmService.confirm(confirmOptions)) {
        this.getCurrentModel.closeWithoutSaving = true;
        await transition.retry();
      }
    }
  }
}
