/* RESPONSIBLE TEAM: team-help-desk-experience */
import SettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';
import { inject as service } from '@ember/service';

export default class ManageTeammatesRoute extends SettingsRoute {
  @service permissionsService;

  beforeModel(transition) {
    super.beforeModel(...arguments);
    return this.permissionsService.ensurePermissionWhenTransitioning(
      'can_manage_teammates',
      transition,
    );
  }
}
