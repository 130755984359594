/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import EducateArticleListBase from 'embercom/routes/apps/app/articles/articles/list/educate-article-list-base';
import { inject as service } from '@ember/service';

export default class PublishedRoute extends EducateArticleListBase {
  @service intl;

  get titleToken() {
    return this.intl.t('articles.shared.left-side-nav.published');
  }

  get title() {
    return this.intl.t('articles.list.published_header_text');
  }

  get contentStatusDefault() {
    return 'published';
  }

  model() {
    let params = this.paramsFor('apps.app.articles');
    let isPresetViewChanged = this.checkRequestFilterDefaults(params, this.contentStatusDefault);
    let request = JSON.parse(params.request);

    return {
      selectedTagValues: params.selectedTagValues ? params.selectedTagValues.split(',') : [],
      selectedContentStatus: this.selectedContentStatus,
      locale: params.selectedLocaleId || null,
      title: this.title,
      articles: [],
      request: JSON.stringify({
        conditions: request.conditions,
        columns: request.columns,
        order: request.order ?? { key: 'updated_at', direction: 'desc' },
      }),
      view: params.view,
      isPresetViewChanged,
    };
  }
}
