/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
import Route from '@ember/routing/route';
import Admin from 'embercom/models/admin';

export default class VideoRoute extends Route {
  activate() {
    document.body.classList.add('responsive');
  }

  deactivate() {
    document.body.classList.remove('responsive');
  }

  beforeModel() {
    return Admin.meWithoutRedirect()
      .then((admin) => {
        this.transitionTo('apps.app.video', admin.get('current_app_id'));
      })
      .catch(() => {
        //show them the video without the Messenger on this route as they are not authenticated
      });
  }
}
