/* RESPONSIBLE TEAM: team-knowledge-interop */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import {
  CAN_MANAGE_KNOWLEDGE_BASE_CONTENT,
  KNOWLEDGE_HUB_ALLOWED_URL_PARAM_CONTENT_TYPES,
  KNOWLEDGE_HUB_CONTENT_TYPES_TO_DATA_STORES_FOR_CREATION,
} from 'embercom/lib/knowledge-hub/constants';
import { type Router } from '@ember/routing';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';
import type KnowledgeHubEditorService from 'embercom/services/knowledge-hub-editor-service';
import { type KnowledgeHubItem } from 'embercom/models/content-service/knowledge-hub-item';
import type Folder from 'embercom/models/content-service/folder';
import type Model from '@ember-data/model';
import type Transition from '@ember/routing/transition';
import { CAN_CREATE_AND_EDIT_DRAFT_ARTICLES_PERMISSION } from 'embercom/lib/articles/constants';

type ModelParams = {
  content_type: string;
  folder_id: string;
};

export default class New extends Route {
  @service declare notificationsService: any;
  @service declare appService: any;
  @service declare router: Router;
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare knowledgeHubEditorService: KnowledgeHubEditorService;
  @service declare permissionsService: any;

  private folderId?: string;

  async beforeModel(transition: Transition) {
    super.beforeModel(transition);
    this.folderId = transition.to.queryParams.folder_id;
  }

  async model(params: ModelParams, transition: Transition) {
    if (KNOWLEDGE_HUB_ALLOWED_URL_PARAM_CONTENT_TYPES.includes(params.content_type)) {
      let requiredPermission = '';
      if (params.content_type === 'article') {
        requiredPermission = CAN_CREATE_AND_EDIT_DRAFT_ARTICLES_PERMISSION;
      } else {
        requiredPermission = CAN_MANAGE_KNOWLEDGE_BASE_CONTENT;
      }

      this.permissionsService.ensurePermissionWhenTransitioning(
        requiredPermission,
        transition,
        'apps.app.knowledge-hub.all-content',
      );

      let record = this.store.createRecord(
        KNOWLEDGE_HUB_CONTENT_TYPES_TO_DATA_STORES_FOR_CREATION[params.content_type],
      );
      record.folderId = this.folderId;
      record.title = record.defaultTitle;
      await record.save();

      if (params.content_type === 'article') {
        let queryParams = transition.to.queryParams;
        if (queryParams.collection_id) {
          let articleIsInserted = await record.addToCollection(queryParams.collection_id);
          if (articleIsInserted) {
            await this.knowledgeHubEditorService.updateHelpCenterAndCollection(record);
          }
        }

        let articleContent;
        if (queryParams.locale) {
          articleContent = await record.findOrCreateContentByLocale(queryParams.locale);
        } else {
          articleContent = record.defaultLocalizedContent;
        }

        record = articleContent;
      }

      this.knowledgeHubEditorService.registerActiveContent(record);
      return record;
    } else {
      return null;
    }
  }

  async afterModel(model: any) {
    if (model !== null) {
      await this.updateFolder(model);
      this.knowledgeHubEditorService.trackAnalyticsEvent('created');
      this.router.transitionTo('apps.app.knowledge-hub.edit', model.entityName, model.id);
    } else {
      this.notificationsService.notifyError(
        this.intl.t('knowledge-hub.errors.create.unknown-content-type'),
      );
      this.router.transitionTo('apps.app.knowledge-hub.all-content');
    }
  }

  async updateFolder(model: KnowledgeHubItem & Model) {
    if (this.folderId) {
      let folder: Folder | undefined = this.store.peekRecord(
        'content-service/folder',
        this.folderId,
      );
      folder ??= await this.store.findRecord('content-service/folder', this.folderId);
      if (folder) {
        await model.moveToFolderAndSave({ folder, disableConfirmation: true });
      }
    }
  }
}
