/* RESPONSIBLE TEAM: team-channels */
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import type IntlService from 'embercom/services/intl';
import { hash } from 'rsvp';

export default class BrandsRoute extends Route {
  @service declare intl: IntlService;
  @service declare appService: any;

  get titleToken() {
    return this.intl.t(`new-settings.submenu.workspace.brands`);
  }

  async model() {
    return hash({
      emailDomains: this.appService.app.loadEmailDomains(),
    });
  }
}
