/* RESPONSIBLE TEAM: team-ml */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controller-access-in-routes */
/* eslint-disable ember/no-classic-classes */
import Route from '@ember/routing/route';
export default Route.extend({
  analytics: {
    place: 'authorize',
  },

  activate() {
    this.controllerFor('apps/app/import/zendesk-chat').set('currentStep', 2);
  },
});
