/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import EventTracking from 'embercom/components/mixins/articles/event-tracking';
import { readOnly } from '@ember/object/computed';

export default Route.extend(EventTracking, {
  intercomEventService: service(),
  queryParams: { displaying: { refreshModel: true } },
  appService: service(),
  app: readOnly('appService.app'),

  resetController(controller) {
    controller.set('displaying', null);
  },

  titleToken(model) {
    return model.get('titleForDisplay');
  },
  activate() {
    this.trackEducateArticleEvent(this.modelFor(this.routeName), {
      action: 'visited',
      place: 'article',
    });
  },
  actions: {
    didTransition() {
      this.intercomEventService.trackEvent('educate-article-stats', {
        action: 'visited',
        object: 'article_stats',
        place: 'article_stats',
        owner: 'educate',
        article_id: this.get('controller.model.id'),
      });
    },
  },
});
