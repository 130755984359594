/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import ReportBase from 'embercom/routes/apps/app/reports/base/report';
import { inject as service } from '@ember/service';
import Admin from 'embercom/models/admin';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';

export default ReportBase.extend({
  reportContext: 'performance',
  titleToken: 'Performance',
  store: service(),

  beforeModel(transition) {
    this._super(...arguments);

    //When transitioning from responsiveness and happiness we may have a teamParticipated query param
    let teamOrTeammateSelected =
      transition.to.queryParams.teamsParticipated || transition.to.queryParams.teammateId;
    if (teamOrTeammateSelected && teamOrTeammateSelected !== REPORTING_FILTER_SELECT_ALL) {
      this.set('teamOrTeammate', Admin.peekAndMaybeLoad(this.store, teamOrTeammateSelected));
    } else {
      this.set('teamOrTeammate', this.get('app.appTeam'));
    }
    this.set('teammateIds', this._teammateIds(this.teamOrTeammate));
  },

  filters() {
    let filters = {};

    if (this.teammateIds && !this.get('teamOrTeammate.isAppTeam')) {
      filters['teammate_ids'] = this.teammateIds;
    }

    if (this.scopingTagIds) {
      filters['scoping_tag_ids'] = this.scopingTagIds;
    }

    return filters;
  },

  setupController(controller, model) {
    this._super(...arguments);
    let teamOrTeammate = this.teamOrTeammate;
    controller.set('teamOrTeammate', teamOrTeammate);
    controller.set('teammateIds', this.teammateIds);
    controller.set('filters', this.filters());
    if (this.botInboxTime) {
      controller.set('botInboxTime', this.botInboxTime);
    }
    let {
      interval,
      comparisonStartMoment,
      comparisonEndMoment,
      startMoment,
      endMoment,
      inDays,
      timezone,
    } = this.range;

    controller.set('rangeParams', {
      interval,
      comparisonStartMoment,
      comparisonEndMoment,
      startMoment,
      endMoment,
      inDays,
      timezone,
    });
  },

  _teammateIds(teamOrTeammate) {
    if (!teamOrTeammate) {
      return;
    } else if (teamOrTeammate.get('isTeam')) {
      return teamOrTeammate.get('member_ids');
    } else {
      return [parseInt(teamOrTeammate.get('id'), 10)];
    }
  },
});
