/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* RESPONSIBLE TEAM: team-proactive-support */
import OutboundBaseListRoute from 'embercom/lib/outbound/base-list-route';
import { matchBehaviors } from 'embercom/models/data/matching-system/matching-constants';
import { inject as service } from '@ember/service';
import { statisticKeys } from 'embercom/models/data/outbound/constants';

export default class AllOutboundListRoute extends OutboundBaseListRoute {
  @service appService;
  @service navbarCollapsingService;
  @service outboundHomeService;
  @service store;

  titleToken = 'All Messages';
  analytics = {
    section: 'outbound',
    place: 'all',
  };
  matchBehavior = matchBehaviors.single;

  deactivate() {
    this.controllerFor('apps.app.outbound.all').setActiveFilter(null);
  }

  get app() {
    return this.appService.app;
  }

  get objectTypes() {
    return this.outboundHomeService.allObjectTypes;
  }

  get statistics() {
    return [
      statisticKeys.receipts,
      statisticKeys.goals,
      statisticKeys.replies,
      statisticKeys.clicks,
      statisticKeys.opens,
      statisticKeys.failures,
      statisticKeys.tourStepFailures,
      statisticKeys.completions,
      statisticKeys.reactions,
      statisticKeys.answers,
      statisticKeys.keywordReplies,
    ];
  }

  beforeModel() {
    super.beforeModel();
    this.outboundHomeService.setActiveList(null);
    this.outboundHomeService.setSearchParams({
      selectedMatchBehaviorValue: matchBehaviors.single,
    });
  }

  async model(params) {
    let [model, senderDomains] = await Promise.all([
      super.model(params),
      this.store.findAll('settings/sender-domain'),
    ]);
    model.senderDomains = senderDomains;
    return model;
  }

  activate() {
    if (this.get('navbarCollapsingService.collapsed')) {
      this.navbarCollapsingService.toggleNavbarCollapsed();
    }
  }
}
