/* RESPONSIBLE TEAM: team-customer-data-platform */
import type Transition from '@ember/routing/transition';
import { inject as service } from '@ember/service';
import ProductSettingsRoute from 'embercom/routes/apps/app/settings/base/product-settings-route';
import { type ImportZendeskContext } from 'embercom/lib/apps/app/new-settings/data/imports-exports/import-zendesk/zendesk-context-loader';
import type Session from 'embercom/services/session';
import type IntlService from 'ember-intl/services/intl';

const CAN_ACCESS_WORKSPACE_SETTINGS = 'can_access_workspace_settings';

export type Model = {
  importZendesk: {
    enabled: boolean;
    context?: ImportZendeskContext;
  };
};

export default class ImportsExportsRoute extends ProductSettingsRoute {
  @service declare session: Session;
  @service declare appService: $TSFixMe;
  @service declare permissionsService: $TSFixMe;
  @service declare intl: IntlService;

  get titleToken() {
    return this.intl.t('new-settings.submenu.data.imports-exports');
  }

  analytics = {
    section: 'settings',
    place: 'imports',
  };

  async beforeModel(transition: Transition) {
    super.beforeModel(transition);
    this.permissionsService.ensurePermissionWhenTransitioning(
      'can_bulk_import_contacts',
      transition,
    );
    this.session.switchWorkspace(this.appService.app.id);
  }

  async model(): Promise<Model> {
    return {
      importZendesk: {
        enabled: this.canMigrateFromZendesk,
        context: undefined,
      },
    };
  }

  get canMigrateFromZendesk(): boolean {
    return (
      this.appService.app.canMigrateFromZendesk &&
      this.permissionsService.currentAdminCan(CAN_ACCESS_WORKSPACE_SETTINGS)
    );
  }
}
