/* RESPONSIBLE TEAM: team-help-desk-experience */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import type InboxApi from 'embercom/services/inbox-api';
import type InboxState from 'embercom/services/inbox-state';

export default class extends Route {
  @service declare inboxApi: InboxApi;
  @service declare inboxState: InboxState;

  async model(params: { conversation_id: number }) {
    return await this.inboxApi.fetchConversation(params.conversation_id);
  }

  afterModel(conversation: ConversationTableEntry) {
    this.inboxState.activeConversation = conversation;
  }

  deactivate() {
    this.inboxState.activeConversation = undefined;
  }
}
