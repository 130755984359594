/* RESPONSIBLE TEAM: team-data-interop */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { hash } from 'rsvp';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class Index extends IntercomRoute {
  @service store;
  @service developerHub;
  @tracked appPackage = this.developerHub.appPackage;

  model() {
    return hash({
      appPackage: this.appPackage,
      allApiVersions: this.store.query('developer-hub/api-version', {
        app_package_id: this.get('appPackage.id'),
      }),
    });
  }

  @action
  willTransition(transition) {
    this.get('developerHub.appPackage').rollbackAttributes();
  }
}
