/* RESPONSIBLE TEAM: team-workflows */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';

export default class SelfSolveOnboardingRoute extends IntercomRoute {
  @service router;

  beforeModel() {
    this.router.replaceWith('apps.app.automation.self-solve-onboarding');
  }
}
