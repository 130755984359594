/* RESPONSIBLE TEAM: team-messenger */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';

export default class MessengerWebRoute extends IntercomRoute {
  @service messengerSettingsTargetUserProvider;
  @service MessengerSettingsService;

  buildRouteInfoMetadata() {
    return {
      responsibleTeam: 'team-messenger',
    };
  }

  beforeModel() {
    if (
      !this.messengerSettingsTargetUserProvider.isUserType &&
      !this.messengerSettingsTargetUserProvider.isVisitor
    ) {
      this.messengerSettingsTargetUserProvider.setVisitorTarget();
    }
    this.MessengerSettingsService.preview.onPlatformChange('web');
  }

  afterModel(model) {
    if (model.settings.lookAndFeel.activeBrand === model.settings.lookAndFeel.mobileBrand) {
      model.settings.lookAndFeel.activeBrand = model.settings.lookAndFeel.defaultBrand;
    }
  }
}
