/* RESPONSIBLE TEAM: team-ai-agent */
import FinAiAgentCustomAnswersAnswerRoute from 'embercom/routes/apps/app/fin-ai-agent/custom-answers/answer';

export default class ResolutionBotAnswersAnswerRoute extends FinAiAgentCustomAnswersAnswerRoute {
  analytics = {
    section: 'resolution-bot',
    place: 'answer',
  };

  controllerName = 'apps.app.fin-ai-agent.custom-answers.answer';
  templateName = 'apps.app.fin-ai-agent.custom-answers.answer';
}
