/* RESPONSIBLE TEAM: team-messenger */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import type Router from '@ember/routing/router-service';

export default class MessengerConversationalIndexRoute extends IntercomRoute {
  @service declare router: Router;

  buildRouteInfoMetadata() {
    return {
      responsibleTeam: 'team-messenger',
    };
  }

  async beforeModel(transition: any) {
    let { tab, section } = transition;
    this.router.replaceWith('apps.app.settings.channels.messenger.conversational.web', {
      queryParams: {
        tab: tab || 'web',
        section: section || 'brand',
      },
    });
  }
}
