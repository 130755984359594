/* RESPONSIBLE TEAM: team-customer-data-platform */
import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class SegmentsRoute extends Route {
  @service permissionsService;

  analytics = {
    place: 'segment',
  };

  beforeModel(transition) {
    this.permissionsService.ensurePermissionWhenTransitioning('can_access_contacts', transition);
  }

  @action
  addSegmentToList(segment) {
    this.modelFor('apps.app.users').pushObject(segment);
  }
}
