/* RESPONSIBLE TEAM: team-channels */
import ProductSettingsRoute from 'embercom/routes/apps/app/settings/base/product-settings-route';
import { inject as service } from '@ember/service';

export default class ProductMessagesSettingsRoute extends ProductSettingsRoute {
  @service permissionsService;
  @service intl;
  getTranslation(key) {
    return this.intl.t(key);
  }
  beforeModel(transition) {
    super.beforeModel(...arguments);
    return this.permissionsService.ensurePermissionWhenTransitioning(
      'can_manage_messages_settings',
      transition,
    );
  }
}
