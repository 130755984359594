/* RESPONSIBLE TEAM: team-standalone */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';

export default class FinStandaloneSalesforceCasesRoute extends Route {
  @service declare appService: $TSFixMe;
  @service declare finStandaloneService: FinStandaloneService;

  async beforeModel() {
    return await Promise.all([
      this.finStandaloneService.initialize(),
      this.appService.app.loadEmailDomains(),
    ]);
  }
}
