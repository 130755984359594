/* RESPONSIBLE TEAM: team-reporting */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import DetectsIntercomradesDomain from 'embercom/lib/detects-intercomrades-domain';
import { inject as service } from '@ember/service';
import { hideIntercomWidgetWhenLoaded } from 'embercom/lib/intercom-widget-helper';
import ENV from 'embercom/config/environment';

export default IntercomRoute.extend(DetectsIntercomradesDomain, {
  appService: service(),
  activate() {
    this._super(...arguments);
    this.hidePrimaryNav();
    hideIntercomWidgetWhenLoaded();
  },

  beforeModel() {
    if (this.isIntercomradesDomain || ENV.environment !== 'production') {
      if (window.opener) {
        window.opener.postMessage(
          {
            features: this.appService.app.features.mapBy('id'),
          },
          'http://intercom.test',
        );
        window.close();
      }
    } else {
      this.transitionTo('apps.app');
    }
  },
});
