/* RESPONSIBLE TEAM: team-product-guidance */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type Router from '@ember/routing/router-service';

export default class GuideLibraryRoute extends Route {
  @service declare appService: $TSFixMe;
  @service declare router: Router;

  async beforeModel() {
    this.router.transitionTo('apps.app.getting-started', this.appService.app.id);
  }
}
