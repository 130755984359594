/* RESPONSIBLE TEAM: team-proactive-support */
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type Transition from '@ember/routing/transition';

export default class SubscriptionsRoute extends Route {
  @service declare store: Store;
  @service declare intercomEventService: $TSFixMe;
  @service declare permissionsService: $TSFixMe;
  @service declare intl: IntlService;

  get app(): any {
    return this.modelFor('apps.app');
  }

  async beforeModel(transition: Transition) {
    return this.permissionsService.ensurePermissionWhenTransitioning(
      'can_manage_messages_settings',
      transition,
    );
  }

  titleToken() {
    return this.intl.t('new-settings.submenu.proactive-support.subscriptions');
  }

  model() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'subscription_types',
      place: 'settings',
      section: 'outbound',
      admin_id: this.app.currentAdmin.id,
    });

    return RSVP.hash({
      subscriptionTypes: this.store.findAll('outbound-subscriptions/subscription-type'),
      translationSettings: this.store.findAll('outbound-subscriptions/translation-settings'),
    });
  }
}
