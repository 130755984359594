/* RESPONSIBLE TEAM: team-growth-opportunities */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controller-access-in-routes */
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { MARKETO_FORM_IDS } from 'embercom/lib/gtm';
import { setPricingParamsIfEmpty } from 'embercom/lib/purchase/setup';
import IntercomRoute from 'embercom/routes/base/intercom-route';

export default IntercomRoute.extend({
  purchaseAnalyticsService: service(),
  gtmService: service(),
  stripeLoaderService: service(),
  router: service(),
  appService: service(),
  app: readOnly('appService.app'),
  cardlessTrialService: service(),
  earlyStageService: service(),
  checkoutService: service(),

  activate() {
    document.body.classList.add('responsive');
  },

  deactivate() {
    document.body.classList.remove('responsive');
  },

  beforeModel(transition) {
    this._super();
    if (!this.app.debuggingPurchaseFlow && this.app.forceEmailVerification) {
      if (this.cardlessTrialService.convertingFromCardlessTrial(transition)) {
        window.location.assign(this.cardlessTrialService.cardlessVerifyPageUrl);
      }
      return this.transitionTo('apps.app.teams-checkout.verify');
    }
    return this.stripeLoaderService.load();
  },

  afterModel(model, transition) {
    this.gtmService.setupMarketo(MARKETO_FORM_IDS.trialSignupEnd);
  },

  setupController(controller, model, transition) {
    this._super(...arguments);
    setPricingParamsIfEmpty(controller, transition);
    this._setCouponCodeFromSessionStorage(controller);
    this.teamsController().set('navStep', 3);
  },

  _setCouponCodeFromSessionStorage(controller) {
    let couponCode = window.sessionStorage && window.sessionStorage['intercom-coupon-code'];
    if (couponCode) {
      controller.set('couponCode', couponCode);
    }
  },

  redirect(model, transition) {
    if (
      this.app.debuggingPurchaseFlow ||
      transition.to.queryParams.cardless_trial === 'true' ||
      this.cardlessTrialService.customer?.validEarlyStageApplicant
    ) {
      return;
    }

    if (this.app.hasActiveSubscription) {
      this.showPrimaryNav();
      this.teamsController().redirectToOnboardingHome();
    }
  },

  teamsController() {
    return this.controllerFor('apps.app.teams-checkout');
  },

  get confirmController() {
    return this.controllerFor('apps.app.teams-checkout.confirm');
  },

  actions: {
    loading() {
      return false;
    },
    didTransition() {
      let controller = this.confirmController;
      this.purchaseAnalyticsService.trackPageView({
        context: 'usecase_signup_flow',
        object: controller.object,
        place: controller.place,
        planIds: controller.addOnPlanIds,
        solutionId: controller.solutionId,
        free: controller.free,
        cartAmount: controller.totalAmount,
        gclid: controller.gclid,
        emailSubmissionId: controller.emailSubmissionId,
        mobile: controller.mobileDevice,
        addOn: controller.addOn,
        locale: this.app.currentAdmin.locale,
      });
    },
  },
});
