/* RESPONSIBLE TEAM: team-messenger */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import type MessengerSettingsService from 'embercom/services/messenger-settings-service';
import type RouterService from '@ember/routing/router-service';

export default class MessengerConversationsRoute extends IntercomRoute {
  @service declare MessengerSettingsService: MessengerSettingsService;
  @service declare appService: $TSFixMe;
  @service declare router: RouterService;

  queryParams = {
    tab: {
      refreshModel: false,
      replace: true,
    },
    section: {
      refreshModel: false,
      replace: true,
    },
  };

  buildRouteInfoMetadata() {
    return {
      responsibleTeam: 'team-messenger',
    };
  }

  redirect(): any {
    if (!this.appService.app.canOptInToNewMessengerBeta) {
      this.router.replaceWith('apps.app.settings.channels.messenger.web', {
        queryParams: { tab: 'content', section: 'layout-and-spaces' },
      });
    }
  }
}
