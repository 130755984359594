/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-new-mixins */
/* eslint-disable ember/no-jquery */
import Mixin from '@ember/object/mixin';
import $ from 'jquery';
import { on } from '@ember/object/evented';
export default Mixin.create({
  scrollToTopOnActivate: on('activate', function () {
    this._scrollToTop();
  }),

  _scrollToTop: () => {
    $('.overflow-auto').scrollTop(0);
  },

  actions: {
    scrollToTop() {
      this._scrollToTop();
    },
  },
});
