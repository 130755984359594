/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import BillingSettingsRoute from 'embercom/routes/apps/app/settings/base/billing-settings-route';
import loadCDNScript from 'embercom/lib/load-cdn-script';
import { post } from 'embercom/lib/ajax';
import moment from 'moment-timezone';
import type IntlService from 'embercom/services/intl';
import type Store from '@ember-data/store';

export default class InvoicesRoute extends BillingSettingsRoute {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare store: Store;

  analytics = {
    place: 'invoices',
  };

  queryParams = {
    unpaidInvoice: {
      refreshModel: false,
      replace: true,
    },
    nextAttempt: {
      refreshModel: false,
      replace: true,
    },
    finalAttempt: {
      refreshModel: false,
      replace: true,
    },
  };

  get titleToken() {
    return this.intl.t(`billing.invoices.index.header`);
  }

  beforeModel() {
    return loadCDNScript('https://static.zuora.com/Resources/libs/hosted/1.3.1/zuora-min.js');
  }

  /**
   * Looks back 6 months prior from the subscriptions invoice day - 1 to check if a report exists.
   *
   * E.g. If subscription invoice day of month is: 2022-07-02
   * It will look back 2022-06-02, 2022-05-02, etc and return whether a file/report exists
   */
  async fetchAvailableReports(invoiceDayOfMonth: number, app_id: string) {
    let prevInvoiceDates = [...Array(6)].map((_, i) =>
      moment()
        .set('date', invoiceDayOfMonth)
        .subtract(1, 'day')
        .subtract(i, 'months')
        .format('YYYY-MM-DD'),
    );

    let { files } = await post('/ember/billing/people_reached_reporting', {
      dates: prevInvoiceDates,
      app_id,
    });

    // Format usage period without helper since it seems unncessary
    return Object.entries(files).map(([date, hasFile]) => {
      let initialDate = moment(date, 'YYYY-MM-DD').subtract(1, 'month');
      let formattedInitialDate = this.intl.formatDate(initialDate, {
        month: 'short',
        day: 'numeric',
      });

      let formattedEndDate = this.intl.formatDate(date, {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });

      let usagePeriod = `${formattedInitialDate} - ${formattedEndDate}`;

      return {
        hasFile,
        date,
        usagePeriod,
      };
    });
  }

  async model() {
    let [customer, app, invoices]: [customer: $TSFixMe, app: $TSFixMe, invoices: any] =
      await Promise.all([
        this.modelFor('apps.app.settings.workspace.billing'),
        this.modelFor('apps.app'),
        this.store.findAll('invoice'),
      ]);

    let peopleReachedReports;
    if (customer.hasActiveSubscription && app.usesPeopleReached && !app.impersonation_session) {
      peopleReachedReports = await this.fetchAvailableReports(
        customer.subscription.invoiceDayOfMonth,
        app.id,
      );
    }

    return hash({
      invoices,
      customer,
      app,
      peopleReachedReports,
    });
  }

  setupController(controller: $TSFixMe, model: $TSFixMe) {
    super.setupController(...arguments);
    let sortedInvoices = model.invoices
      .toArray()
      .sort((a: $TSFixMe, b: $TSFixMe) => b.parsedDueDate.unix() - a.parsedDueDate.unix());
    controller.setProperties({
      customer: model.customer,
      invoices: sortedInvoices,
      peopleReachedReports: model.peopleReachedReports,
    });
  }
}
