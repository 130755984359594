/* RESPONSIBLE TEAM: team-product-guidance */
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import IntercomRoute from 'embercom/routes/base/intercom-route';

export default class HomeRoute extends IntercomRoute {
  @service declare appService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare router: RouterService;

  get app() {
    return this.appService.app;
  }

  async beforeModel() {
    this.router.transitionTo('apps.app.getting-started', this.app.id);
    this.intercomEventService.trackEvent('nco_visited_home');
  }
}
