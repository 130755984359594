/* RESPONSIBLE TEAM: team-proactive-support */
import OutboundBaseListRoute from 'embercom/lib/outbound/base-list-route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class NewsBaseListRoute extends OutboundBaseListRoute {
  @service outboundHomeService;

  @action
  willTransition(transition) {
    if (transition.to.name.startsWith('apps.app.outbound.news')) {
      this.outboundHomeService.additionalSearchableData = null;
      this.outboundHomeService.selectedSenderValue = null;
    } else {
      this.outboundHomeService.resetFilters();
    }
    this.outboundHomeService.selectedMatchBehaviorValues = null;
  }
}
