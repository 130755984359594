/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controller-access-in-routes */
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import IntercomRoute from 'embercom/routes/base/intercom-route';
import Range from 'embercom/models/reporting/range';
import { hash } from 'rsvp';
import setupLineChartSignal from 'embercom/lib/inbox/reporting/setup-line-chart-signal';
import moment from 'moment-timezone';
import ajax from 'embercom/lib/ajax';
import {
  ALL_CONVERSATIONS_RB,
  MEDIAN_TIME_TO_CLOSE,
  RESOLUTION_BOT_CONTENT_SENT,
  RESOLUTION_BOT_CONVERSATION_COVERAGE,
  RESOLUTION_BOT_CONTENT_BREAKDOWN,
} from 'embercom/lib/reporting/queries';

const CONVERSATION_SOURCE_ALL = 'all';
const CONVERSATION_SOURCE_OUTBOUND = 'outbound';
const CONVERSATION_SOURCE_INBOUND = 'inbound';

function fetchSignals({
  reportingService,
  range,
  timezone,
  reportingSettings,
  conversationSource,
  resolutionBotSetting,
}) {
  let signalsToFetch = {
    conversationsWithAnswers: reportingService.fetchAnswerBotEffectivenessSignal(
      RESOLUTION_BOT_CONTENT_SENT,
      'conversations',
      range,
      filters(conversationSource, resolutionBotSetting),
    ),
    inboundConversationsWithAnswers: reportingService.fetchAnswerBotEffectivenessSignal(
      RESOLUTION_BOT_CONTENT_SENT,
      'conversations',
      range,
      filters(CONVERSATION_SOURCE_INBOUND, resolutionBotSetting),
    ),
    outboundConversationsWithAnswers: reportingService.fetchAnswerBotEffectivenessSignal(
      RESOLUTION_BOT_CONTENT_SENT,
      'conversations',
      range,
      filters(CONVERSATION_SOURCE_OUTBOUND, resolutionBotSetting),
    ),
    conversationsWithAnswersByDay: reportingService.fetchComparisonSignal(
      RESOLUTION_BOT_CONTENT_SENT,
      'answers',
      range,
      filters(conversationSource, resolutionBotSetting),
    ),
    conversationsSent: reportingService.fetchSignal(
      ALL_CONVERSATIONS_RB,
      'conversations',
      range,
      filters(conversationSource, resolutionBotSetting),
    ),
    inboundMedianClosedTimeLastWeek: medianClosedTimeLastWeek(
      reportingService,
      filters(CONVERSATION_SOURCE_INBOUND, resolutionBotSetting),
      timezone,
      reportingSettings,
    ),
    outboundMedianClosedTimeLastWeek: medianClosedTimeLastWeek(
      reportingService,
      filters(CONVERSATION_SOURCE_OUTBOUND, resolutionBotSetting),
      timezone,
      reportingSettings,
    ),
  };

  Object.assign(signalsToFetch, {
    conversationCoverage: reportingService.fetchStackedSignal({
      debugName: RESOLUTION_BOT_CONVERSATION_COVERAGE.debug_name,
      signalConfig: {
        ...RESOLUTION_BOT_CONVERSATION_COVERAGE,
        keys_are_dates: true,
        nested_keys_are_dates: false,
      },
      valueUnit: 'conversations',
      range,
      filters: filters(conversationSource, resolutionBotSetting),
      aggregations: [
        ...range.baseAggregations,
        ...RESOLUTION_BOT_CONVERSATION_COVERAGE.aggregations,
      ],
    }),
    contentBreakdown: reportingService.fetchStackedSignal({
      debugName: RESOLUTION_BOT_CONTENT_BREAKDOWN.debug_name,
      signalConfig: {
        ...RESOLUTION_BOT_CONTENT_BREAKDOWN,
        keys_are_dates: true,
        nested_keys_are_dates: false,
      },
      valueUnit: 'content',
      range,
      filters: filters(conversationSource, resolutionBotSetting),
      aggregations: [...range.baseAggregations, ...RESOLUTION_BOT_CONTENT_BREAKDOWN.aggregations],
    }),
  });

  return hash(signalsToFetch);
}

function medianClosedTimeLastWeek(reportingService, filters, timezone, reportingSettings) {
  let weekAgo = moment.tz(timezone).subtract(6, 'days').startOf('day').format();
  let today = moment.tz(timezone).endOf('day').format();
  let weekRange = Range.createFromParams(weekAgo, today, timezone);
  return reportingService.fetchSignal(
    MEDIAN_TIME_TO_CLOSE({ withinOfficeHours: reportingSettings.reportingExcludeOooHours }),
    'second',
    weekRange,
    filters,
  );
}

function filters(source, loopingSetting) {
  return Object.assign(
    {},
    filtersForConversationSource(source),
    filtersForResolutionBotSetting(loopingSetting),
  );
}

function filtersForConversationSource(source) {
  if (source === CONVERSATION_SOURCE_ALL) {
    return {};
  }

  let isConversationStartedByUser = source === CONVERSATION_SOURCE_INBOUND;
  let conversationSourceFilterKey = 'conversation_started_by_user';

  return { [conversationSourceFilterKey]: [isConversationStartedByUser] };
}

function filtersForResolutionBotSetting(setting) {
  if (setting === 'all') {
    return {};
  } else if (setting === 'one-time') {
    return { resolution_bot_looping_enabled: [false] };
  }
  return { resolution_bot_looping_enabled: [true] };
}

export default IntercomRoute.extend({
  store: service(),
  intercomEventService: service(),
  intl: service(),
  get titleToken() {
    return this.intl.t('apps.app.reports.customer-support.resolution-bot.title-token');
  },

  appService: service(),
  reportingService: service(),
  app: readOnly('appService.app'),
  range: readOnly('reportingService.range'),

  queryParams: {
    conversationSource: {
      refreshModel: true,
    },
    resolutionBotSetting: {
      refreshModel: true,
    },
  },

  analytics: {
    place: 'answer_bot',
  },

  async model(params) {
    let timezone = this.get('app.timezone');
    let range = this.range;
    let reportingService = this.reportingService;
    let appId = this.get('app.id');

    let answerCounts = ajax({
      url: '/ember/custom_answers/counts',
      type: 'GET',
      data: { app_id: appId },
    });

    let reportingAllSettings = await this.store.queryRecord('reporting/settings', {});
    let conversationSource = params.conversationSource;
    let resolutionBotSetting = params.resolutionBotSetting;
    let reportingSettings = {
      reportingExcludeOooHours: reportingAllSettings.get('excludeOooHours'),
    };
    let signals = fetchSignals({
      reportingService,
      range,
      timezone,
      reportingSettings,
      conversationSource,
      resolutionBotSetting,
    });
    return hash({ range, signals, reportingSettings, answerCounts });
  },

  setupController(controller, { range, signals, answerCounts }) {
    let { conversationSource, resolutionBotSetting } = controller;

    signals.conversationsWithAnswersByDayLines = setupLineChartSignal([
      signals.conversationsWithAnswersByDay,
    ]);

    controller.setProperties({
      signals,
      range,
      answerCounts,
      rangeStart: range.start,
      rangeEnd: range.end,
      filters: filters(conversationSource, resolutionBotSetting),
    });
  },

  actions: {
    didTransition() {
      this._super();
      this.intercomEventService.trackAnalyticsEvent({
        action: 'viewed',
        object: 'answer_bot_report',
        context: 'operator',
        start_date: moment(this.controller.rangeStart).format('YYYY/MM/DD'),
        end_date: moment(this.controller.rangeEnd).format('YYYY/MM/DD'),
        conversation_source: this.controller.conversationSource,
        resolution_bot_setting: this.controller.resolutionBotSetting,
      });
    },
  },
});
