/* RESPONSIBLE TEAM: team-ai-agent */
import FinAIAgentProfilesEditRoute from 'embercom/routes/apps/app/fin-ai-agent/profiles/edit';

export default class AutomationFinAIAgentProfilesEditRoute extends FinAIAgentProfilesEditRoute {
  analytics = {
    section: 'automation',
    place: 'fin-ai-agent.profiles.edit',
  };

  controllerName = 'apps.app.fin-ai-agent.profiles.edit';
}
