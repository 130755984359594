/* RESPONSIBLE TEAM: team-tickets-1 */
import { inject as service } from '@ember/service';
import SettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';
import type IntlService from 'embercom/services/intl';

export default class TicketsOnboardingRoute extends SettingsRoute {
  @service declare intl: IntlService;

  get titleToken() {
    return this.intl.t('settings.ticket-data.tickets-onboarding.title');
  }
}
