/* RESPONSIBLE TEAM: team-proactive-support */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';

export default class OutboundMatchCheckRulesetRoute extends Route {
  @service declare store: Store;

  async model(params: { id: string }) {
    return this.store.findRecord('matching-system/ruleset', params.id);
  }
}
