/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controller-access-in-routes */
import UsersOrCompaniesRoute from 'embercom/routes/base/users-or-companies-route';
import { inject as service } from '@ember/service';

export default UsersOrCompaniesRoute.extend({
  store: service(),
  buildRouteInfoMetadata() {
    return {
      responsibleTeam: 'team-data-interop',
    };
  },
  intercomEventService: service(),
  userCacheService: service(),
  analytics: {
    section: 'platform',
    place: 'users',
  },

  activate() {
    this._super(...arguments);
    this.intercomEventService.trackEvent('user-list-visited');
  },
  deactivate() {
    this._super(...arguments);
    this.userCacheService.clear();
    this.store.unloadAll('user');
    this.store.unloadAll('company');
  },
  isCompany: false,
  model() {
    let app = this.modelFor('apps/app');
    return app.get('userSegments');
  },
  actions: {
    showAllSegment() {
      let segment = this.modelFor('apps/app/users').get('firstObject');
      segment.revert();
      this.transitionTo('apps.app.users.segments.segment', segment);
    },
    setSearch(text) {
      this.controller.set('search.rawText', text);
    },
  },
});
