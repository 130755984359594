/* RESPONSIBLE TEAM: team-help-desk-experience */
import ProductSettingsRoute from 'embercom/routes/apps/app/settings/base/product-settings-route';
import SdkApp from 'embercom/models/sdk-app';
import { inject as service } from '@ember/service';

export default ProductSettingsRoute.extend({
  permissionsService: service(),
  appService: service(),

  setupController(controller, model) {
    this._super(...arguments);
    controller.set('titleToken', this.titleToken);
    controller.set('apiKey', model.api_key);
    controller.set('gcmKey', model.gcm_key);
  },
  beforeModel(transition) {
    this._super(...arguments);
    this.permissionsService.ensurePermissionWhenTransitioning(
      'can_access_workspace_settings',
      transition,
    );
  },
  activate() {
    this._super(...arguments);
    this.replaceWith('apps.app.settings.channels.messenger.install', {
      queryParams: { tab: this.titleToken?.toLowerCase() },
    });
  },
  model() {
    return SdkApp.findOrCreateForPlatform(this.titleToken);
  },
});
