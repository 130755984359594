/* RESPONSIBLE TEAM: team-growth-opportunities */
import Route from '@ember/routing/route';

export default class AppsAppTeamsCheckoutVerifyRoute extends Route {
  activate() {
    document.body.classList.add('responsive');
  }

  deactivate() {
    document.body.classList.remove('responsive');
  }
}
