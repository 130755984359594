/* RESPONSIBLE TEAM: team-ai-agent */
import ContentEditorRoute from 'embercom/routes/apps/app/content/edit';

export default class ResolutionBotBehaviorsEditRoute extends ContentEditorRoute {
  analytics = {
    section: 'resolution-bot',
    place: 'behaviors-edit',
  };

  controllerName = 'apps.app.fin-ai-agent.profiles.edit';
}
