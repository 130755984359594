/* RESPONSIBLE TEAM: team-growth-opportunities */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controller-access-in-routes */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-jquery */
import { readOnly } from '@ember/object/computed';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { MARKETO_FORM_IDS } from 'embercom/lib/gtm';
import $ from 'jquery';

export default Route.extend({
  purchaseAnalyticsService: service(),
  gtmService: service(),
  appService: service(),
  app: readOnly('appService.app'),
  earlyStageService: service(),

  activate() {
    $('body').addClass('responsive');
  },

  deactivate() {
    $('body').removeClass('responsive');
  },

  setupController(controller) {
    this._super(...arguments);
    controller.hideWidgetIfAppropriate();
    this.controllerFor('apps.app.teams-checkout').set('navStep', 2);
  },

  model() {
    return this.earlyStageService.fetchEarlyStagePartners();
  },

  beforeModel(transition) {
    if (this.app.debuggingPurchaseFlow) {
      return;
    }
    if (this.app.validEarlyStageApplicant) {
      if (this.app.hasNoActiveSubscription) {
        this.transitionTo('apps.app.teams-checkout.confirm', {
          queryParams: transition.to.queryParams,
        });
      } else {
        this.transitionTo('apps.app');
      }
    }
  },
  actions: {
    didTransition() {
      let controller = this.controllerFor('apps.app.teams-checkout.early-stage-application');
      let event = {
        action: 'viewed',
        context: 'usecase_signup_flow',
        place: 'early_stage_application',
        object: 'early_stage_application',
        solutionId: controller.solutionId,
        gclid: controller.gclid,
        emailSubmissionId: controller.emailSubmissionId,
        mobile: controller.mobileDevice,
        locale: this.app.currentAdmin.locale,
      };

      this.purchaseAnalyticsService.trackPageView(event);
    },
  },

  afterModel() {
    this.gtmService.setupMarketo(MARKETO_FORM_IDS.earlyStage);
  },
});
