/* RESPONSIBLE TEAM: team-ml */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controller-access-in-routes */
/* eslint-disable ember/no-classic-classes */
import Route from '@ember/routing/route';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Route.extend({
  analytics: {
    section: 'import_zendesk_chat',
  },

  appService: service(),
  reportingService: service(),
  app: readOnly('appService.app'),

  activate() {
    this.controllerFor('apps.app').set('showPrimaryNav', false);
  },
  deactivate() {
    this.controllerFor('apps.app').set('showPrimaryNav', true);
  },
});
