/* RESPONSIBLE TEAM: team-proactive-support */
import ContentEditorRoute from 'embercom/routes/apps/app/content/edit';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
export default ContentEditorRoute.extend({
  analytics: {
    section: 'messages',
    place: 'post_editor',
  },

  afterModel() {
    this.outboundHomeService.setActiveList(objectTypes.post);
  },
});
