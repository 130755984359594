/* RESPONSIBLE TEAM: team-messenger */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import { SETTINGS_REDIRECT_MAPPING } from 'embercom/lib/settings/settings-redirect-map';

export default class MessengerRoute extends IntercomRoute {
  @service intercomEventService;
  @service appService;
  @service router;
  @service permissionsService;
  @service messengerSettingsTargetUserProvider;
  @service intl;
  @service helpCenterService;
  @service MessengerSettingsService;
  @service store;
  analytics = {
    section: 'messenger_settings',
    messenger_version: '5',
  };

  queryParams = {
    tab: {
      refreshModel: true,
    },
    section: {
      refreshModel: true,
    },
    product_tour_id: {
      refreshModel: false,
      replace: true,
    },
  };

  buildRouteInfoMetadata() {
    return {
      responsibleTeam: 'team-messenger',
    };
  }

  beforeModel(transition) {
    this.permissionsService.ensurePermissionWhenTransitioning(
      'can_manage_messenger_settings',
      transition,
    );
    this.intl.maybeLoadMessengerTranslations();

    if (this.appService.app.canSeeNewIASettingsHub) {
      let routeName = 'apps.app.settings.channels.messenger.web';
      let queryParams = { tab: 'content', section: 'layout-and-spaces' };

      Object.keys(SETTINGS_REDIRECT_MAPPING).forEach((key) => {
        if (transition.to.name.startsWith(key)) {
          let redirectObj = SETTINGS_REDIRECT_MAPPING[key];
          if (redirectObj.resolveTransition) {
            let resolvedTransition = redirectObj.resolveTransition(transition);
            routeName = resolvedTransition.route;
            queryParams = resolvedTransition.queryParams;
          } else {
            routeName = redirectObj.route;
            queryParams = redirectObj.queryParams;
          }
        }
      });

      this.router.transitionTo(routeName, { queryParams: queryParams || {} });
    }
  }

  activate() {
    this.router.on('routeWillChange', this, 'warnIfHasUnsavedChanges');

    this.intercomEventService.trackAnalyticsEvent({
      action: 'visited',
      object: 'new_messenger_settings',
      place: 'new_messenger_settings',
    });

    this.intercomEventService.trackEvent('has_visited_messenger_settings');
  }

  deactivate() {
    this.router.off('routeWillChange', this, 'warnIfHasUnsavedChanges');
  }

  async model(transition = {}) {
    if (transition && transition.tab === 'teammates' && transition.section === 'require-email') {
      /*
        Previously there was another section, "require email", which was moved into automation.basics module
        For those who bookmarked the old URL we're mantaining permanent redirect
        */
      this.router.transitionTo('apps.app.automation.basics', {
        queryParams: {
          section: 'upfront-email-collection',
          for: 'leads',
        },
      });
      return null;
    }

    await this.helpCenterService.maybeFetchSite();
    let app = this.appService.app;
    let settings = this.store.peekRecord('messenger-settings/all', app.id);
    if (!settings) {
      settings = await this.store.findRecord('messenger-settings/all', app.id);
    }

    let model = await hash({
      settings,
      identity: this.store.findRecord('operator-identity', '1'),
    });

    if (transition && transition.tab) {
      let { tab, section } = transition;
      this.MessengerSettingsService.settings = model.settings;
      this.MessengerSettingsService.navigate(tab, section, { changeUrl: false });

      if (section === 'add-apps') {
        let messengerApps = this.store.peekAll('messenger-app');
        if (!messengerApps || !messengerApps.length) {
          await this.store.query('messenger-app', {
            restrict_to: 'messenger_home',
          });
        }
      }
    }

    this.messengerSettingsTargetUserProvider.settings = model.settings;

    return model;
  }

  redirect(model, transition) {
    if (this.appService.app.isOptedIntoConversationalMessengerBeta) {
      this.router.transitionTo('apps.app.new-messenger.opt-in');
    } else if (
      this.appService.app.isOptedIntoConversationalMessengerBeta &&
      !transition.to.name.startsWith('apps.app.messenger.conversational')
    ) {
      this.router.transitionTo('apps.app.messenger.conversational');
    }
  }

  warnIfHasUnsavedChanges(transition) {
    if (!transition.isAborted && transition.from.name !== transition.to.name) {
      if (
        this.router.currentRouteName !== transition.to.name &&
        this.controller?.model?.settings?.hasUnsavedChanges
      ) {
        if (window.confirm(this.intl.t('messenger.settings.unsaved-changes-warning'))) {
          this.controller.model.settings.refresh();
        } else {
          transition.abort();
        }
      }
    }
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      this.MessengerSettingsService.refresh();
      controller.set('tab', this.MessengerSettingsService.selectedTab);
      controller.set('section', this.MessengerSettingsService.openSectionId);
      controller.set('product_tour_id', null);
    }
  }
}
