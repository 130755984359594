/* RESPONSIBLE TEAM: team-growth-opportunities */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import ENV from 'embercom/config/environment';
import { MARKETO_FORM_IDS } from 'embercom/lib/gtm';
import AppInstall from 'embercom/models/app-install';

export default class SignupRoute extends Route {
  @service store;
  @service intl;
  @service iamService;
  @service gtmService;
  @service appService;
  @service redirectService;
  @service notificationsService;

  get app() {
    return this.appService.app;
  }

  activate() {
    document.body.classList.add('responsive');
  }

  deactivate() {
    document.body.classList.remove('responsive', 'signup__teams__body');
  }

  beforeModel(_transition) {
    this.iamService.boot();

    document.body.classList.add('signup__teams__body');
  }

  model(params) {
    return AppInstall.create({
      email: params.email,
      name: params.name,
      emailSubmissionId: params.emailSubmissionId,
      securityResearcher: params.securityResearcher,
      locale: params.locale,
      operatorPage: params.operatorPage,
      existingAdmin: params.existingAdmin,
      purchaseExperience: 'v1',
    });
  }

  async afterModel(model, transition) {
    this.gtmService.setupMarketo(MARKETO_FORM_IDS.developerSignup);
    let signup = await this.store.createRecord('signup').state();

    model.set('viaSingleSignOn', signup.get('viaSingleSignOn'));
    model.set('authenticatedAdmin', signup.get('authenticatedAdmin'));
    if (signup.get('authenticatedAdmin')) {
      if (transition.to.queryParams.developer) {
        this.redirectService.redirect(
          `${window.location.origin}/a/apps/_/developer-hub`,
          transition,
        );
      } else if (this.app) {
        this.redirectToPricingPage();
      }
    }
  }

  redirectToPricingPage() {
    window.location.replace(`${ENV.APP.marketingSiteUrl}/pricing`);
    return;
  }

  buildRouteInfoMetadata() {
    return {
      responsibleTeam: 'team-growth-opportunities',
    };
  }
}
