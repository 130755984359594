/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-new-mixins */
import { on } from '@ember/object/evented';
import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import ControlGroupStats from 'embercom/models/messages/control-group-stats';
import VariationStats from 'embercom/models/messages/variation-stats';

export default Mixin.create({
  store: service(),
  realTimeEventService: service(),
  subscribeToMessageStatsUpdate: on('activate', function () {
    this.realTimeEventService.subscribeTopics(['messages']);
    this.realTimeEventService.on('MessageStatsUpdate', this, '_updateMessageStats');
  }),
  unsubscribeFromMessageStatsUpdate: on('deactivate', function () {
    this.realTimeEventService.off('MessageStatsUpdate', this, '_updateMessageStats');
    this.realTimeEventService.unsubscribeTopics(['messages']);
  }),
  _updateMessageStats(realtimeStats) {
    let messageId = Object.keys(realtimeStats)[0];
    let message = this.store.peekRecord('message', messageId);
    if (isPresent(message)) {
      let statsForMessage = realtimeStats[messageId];
      message.get('variations').forEach((variation) => {
        statsForMessage[variation.get('id')].reply_type = variation.get('replyType');
      });
      let variationStats = VariationStats.translateFromRealTimeNotification(statsForMessage);
      variationStats.forEach((variationStat) => {
        this.store.push(this.store.normalize('messages/variation-stats', variationStat));
      });
      if (isPresent(statsForMessage.control_group_stats)) {
        let controlGroupStats = ControlGroupStats.translateFromRealTimeNotification(
          statsForMessage.control_group_stats,
        );
        this.store.push(this.store.normalize('messages/control-group-stats', controlGroupStats));
      }
    }
  },
});
