/* RESPONSIBLE TEAM: team-phone */
import ProductSettingsRoute from 'embercom/routes/apps/app/settings/base/product-settings-route';

export default ProductSettingsRoute.extend({
  beforeModel() {
    this._super(...arguments);

    this.replaceWith('apps.app.settings.calling');
  },
});
