/* RESPONSIBLE TEAM: team-growth-opportunities */
import { action } from '@ember/object';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import ENV from 'embercom/config/environment';
import { setPricingParamsIfEmpty } from 'embercom/lib/purchase/setup';
import IntercomRoute from 'embercom/routes/base/intercom-route';

export default class UsageRoute extends IntercomRoute {
  @service purchaseAnalyticsService;
  @service appService;
  @service media;
  @service cardlessTrialService;
  @service store;

  setupController(controller, _model, transition) {
    super.setupController(...arguments);
    setPricingParamsIfEmpty(controller, transition);
    this.teamsController().setSeatValueToMinimumSeatsValue();
    this.initializeBillingPeriodSelected();
    this.teamsController().setProperties({
      navStep: 2,
      editingAddons: false,
    });
  }

  activate() {
    document.body.classList.add('responsive');
  }

  deactivate() {
    document.body.classList.remove('responsive');
  }

  beforeModel(transition) {
    this.redirectCustomersRequiringEmailVerification(transition);
  }

  model() {
    return new Promise((resolve) => this.fetchCheckout.perform(resolve));
  }

  afterModel(model, transition) {
    this.redirectDeniedCustomersToPlanPage(model);
    this.redirectCustomersWithActiveSubscriptionsToBillingPage(model, transition);
  }

  @action
  didTransition() {
    let controller = this.controllerFor('apps.app.teams-checkout');
    this.purchaseAnalyticsService.trackPageView({
      context: 'usecase_signup_flow',
      object: 'usage_step',
      place: 'usage',
      planIds: controller.addOnPlanIds,
      solutionId: controller.solutionId,
      cartAmount: controller.solutionPlusAddOnPrice.getMonthlyTotalAfterTrial(
        controller.billingPeriodDurationInMonths,
      ),
      gclid: controller.gclid,
      emailSubmissionId: controller.emailSubmissionId,
      mobile: this.media.isMobile,
      addOn: controller.addOn,
      seats: controller.seats,
      locale: this.appService.app.currentAdmin.locale,
    });
  }

  @task
  *fetchCheckout(resolve) {
    let checkout = yield this.store.findRecord('checkout', this.app.id, { reload: true });

    if (checkout.isNotReady) {
      // Timeout is managed server-side. It will return status = authorized after some time waiting
      later(this, () => this.fetchCheckout.perform(resolve), ENV.APP._2000MS);
    } else {
      resolve(checkout);
    }
  }

  redirectCustomersRequiringEmailVerification(transition) {
    if (!this.app.debuggingPurchaseFlow && this.app.forceEmailVerification) {
      if (this.cardlessTrialService.convertingFromCardlessTrial(transition)) {
        window.location.assign(this.cardlessTrialService.cardlessVerifyPageUrl);
      }
      this.transitionTo('apps.app.teams-checkout.verify');
    }
  }

  redirectDeniedCustomersToPlanPage(model) {
    if (model.isDenied) {
      this.transitionTo('apps.app.teams-checkout.plan');
    }
  }

  redirectCustomersWithActiveSubscriptionsToBillingPage(model, transition) {
    if (
      this.app.hasActiveSubscription &&
      !this.app.debuggingPurchaseFlow &&
      !model.isDenied &&
      transition.to.queryParams.cardless_trial !== 'true'
    ) {
      this.transitionTo('apps.app.settings.workspace.billing.details');
    }
  }

  get app() {
    return this.appService.app;
  }

  initializeBillingPeriodSelected() {
    this.teamsController().setProperties({ isBillingPeriodSelected: true });
  }

  teamsController() {
    return this.controllerFor('apps.app.teams-checkout');
  }
}
