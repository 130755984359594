/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import type Store from '@ember-data/store';
import { action } from '@ember/object';
import { type Router } from '@ember/routing';
import type Transition from '@ember/routing/transition';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { INVITE_USAGE as INVITE } from 'embercom/lib/settings/seats/constants';
import ManageTeammatesRoute from 'embercom/routes/apps/app/settings/base/manage-teammates-route';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import { hash } from 'rsvp';

export default class EditRoute extends ManageTeammatesRoute {
  @service declare appService: $TSFixMe;
  @service declare store: Store;
  @service declare router: Router;
  @service declare intl: IntlService;
  @service declare permissionsHierarchyService: $TSFixMe;
  @service declare intercomConfirmService: IntercomConfirmService;

  analytics = {
    section: 'settings',
    place: 'edit-single-invite-permissions',
  };

  get getCurrentModel(): $TSFixMe {
    return this.modelFor(this.routeName);
  }

  async beforeModel() {
    await super.beforeModel(...arguments);
    await this.permissionsHierarchyService.ensureDataIsLoaded.perform();
  }

  async model({ id }: { id: number }) {
    let index: any = this.modelFor('apps.app.settings.workspace.teammates.index');
    // We need to fetch all invites because there is no endpoint for fetching a single invite
    await this.store.findAll('invited-admin');
    return hash({
      app: this.appService.app,
      invite: this.store.peekRecord('invited-admin', id),
      roles: this.store.findAll('role'),
      usageType: INVITE,
      closeWithoutSaving: false,
      copilotData: index?.copilotData,
    });
  }

  async afterModel(model: $TSFixMe) {
    if (this.appService.app.onPricing5 && model.invite.seatTypes.length === 0) {
      model.set('invite.seatTypes', ['core']);
    }
  }

  @action
  async willTransition(transition: Transition) {
    if (this.getCurrentModel.closeWithoutSaving) {
      return;
    }

    let permissionsObject = this.getCurrentModel.invite;
    if (permissionsObject && permissionsObject.hasDirtyAttributes) {
      transition.abort();

      let confirmOptions = {
        title: this.intl.t('settings.teammates.invite.cancel-modal.close-without-saving'),
        primaryButtonType: 'primary-destructive',
        confirmButtonText: this.intl.t(
          'settings.teammates.invite.cancel-modal.close-without-saving-btn',
        ),
        cancelButtonText: this.intl.t('settings.teammates.invite.cancel-modal.keep-editing'),
        body: this.intl.t('settings.teammates.invite.cancel-modal.lose-your-changes'),
      };

      if (await this.intercomConfirmService.confirm(confirmOptions)) {
        // I wish I didn't have to do this. Normally we would rollback then retry
        // which would run this action again and exit the route. However, doing that
        // in this route looks bad. The IcOnOffToggle components which are bound to
        // the model are animated with CSS transitions - so you'll see them animate
        // back to their original positions before we exit the route.
        this.getCurrentModel.closeWithoutSaving = true;
        await transition.retry();
        permissionsObject.rollbackAttributes();
      }
    }
  }
}
