/* RESPONSIBLE TEAM: team-proactive-support */
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default class AppsAppSmsDemoRoute extends Route {
  @service store;
  model() {
    let sms = this.store.createRecord('sms/sms');
    sms.localizedSmsMessageContents.pushObject(
      this.store.createRecord('sms/localized-sms-message-content', {
        sms,
      }),
    );
    return sms;
  }
}
