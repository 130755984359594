/* RESPONSIBLE TEAM: team-tickets-1 */
import Route from '@ember/routing/route';

export default class InboxRoute extends Route {
  analytics = {
    place: 'inbox',
  };

  beforeModel() {}
  model() {}
}
