/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Route from '@ember/routing/route';
import EventTracking from 'embercom/components/mixins/articles/event-tracking';

export default Route.extend(EventTracking, {
  beforeModel(transition) {
    let originalTarget = transition.targetName;
    let newTarget = originalTarget.replace(
      'apps.app.articles.insights',
      'apps.app.reports.customer-support.articles',
    );
    return this.replaceWith(newTarget);
  },
});
