/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/require-snake-case-analytics */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
  iamService: service(),
  intercomEventService: service(),
  transitionQueryParams: null,

  beforeModel(transition) {
    this.transitionQueryParams = transition.to.queryParams;
    this.iamService.boot();
  },

  actions: {
    didTransition() {
      let referal = document.referrer;
      this.intercomEventService.trackAnalyticsEvent({
        action: 'visited',
        section: 'index',
        object: 'canvas-kit-builder',
        queryParams: this.transitionQueryParams,
        in_snippet: false,
        referal,
      });
    },
  },
});
