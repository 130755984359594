/* RESPONSIBLE TEAM: team-workflows */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import type RouterService from '@ember/routing/router-service';
import type IntlService from 'embercom/services/intl';
import type Transition from '@ember/routing/-private/transition';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import type Store from '@ember-data/store';

export default class AutomationBasicsRoute extends IntercomRoute {
  @service declare router: RouterService;
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare intercomEventService: any;
  @service declare store: Store;

  get titleToken(): string {
    return this.intl.t('operator.sub-menu.task-bots.title');
  }

  get app(): any {
    return this.appService.app;
  }

  async model() {
    let [descriptors, settings] = await Promise.all([
      this.store.findAll('conversation-attributes/descriptor'),
      this.store.findRecord('operator-settings/task-bots', this.app.id),
    ]);

    return hash({
      descriptors,
      settings,
    });
  }

  activate() {
    this.router.on('routeWillChange', this._warnIfUnsavedChangesCallback);

    this.intercomEventService.trackAnalyticsEvent({
      action: 'visited',
      object: 'workflow_basics',
      place: 'operator',
    });

    this.intercomEventService.trackEvent('has_visited_workflow_basics');
  }

  deactivate() {
    this.router.off('routeWillChange', this._warnIfUnsavedChangesCallback);
  }

  _warnIfUnsavedChangesCallback = (transition: Transition) => {
    if (transition.from?.name !== transition.to.name) {
      if (
        this.router.currentRouteName !== transition.to.name &&
        // @ts-expect-error @types/ember__controller coming from multiple places
        this.controller?.model?.settings?.hasUnsavedChanges
      ) {
        if (window.confirm(this.intl.t('operator.task-bots.notifications.unsaved-changed'))) {
          // @ts-expect-error @types/ember__controller coming from multiple places
          this.controller.model.settings.refresh();
        } else {
          transition.abort();
        }
      }
    }
  };
}
