/* RESPONSIBLE TEAM: team-channels */
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import type Store from '@ember-data/store';
import type Transition from '@ember/routing/-private/transition';
import { isPresent } from '@ember/utils';
import type IntlService from 'embercom/services/intl';
import ENV from 'embercom/config/environment';
import { hash } from 'rsvp';
import { get } from 'embercom/lib/ajax';
import type EmailController from 'embercom/controllers/apps/app/settings/channels/email';
import type RouterService from '@ember/routing/router-service';

const CAN_MANAGE_MESSAGES_SETTINGS = 'can_manage_messages_settings';

export default class EmailRoute extends Route {
  @service declare appService: any;
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare notificationsService: $TSFixMe;
  @service declare router: RouterService;
  @service declare permissionsService: $TSFixMe;

  get titleToken() {
    return this.intl.t(`new-settings.submenu.channels.email`);
  }

  get app() {
    return this.appService.app;
  }

  async model() {
    let canAccessProactiveSettings = await this.permissionsService.currentAdminCan(
      CAN_MANAGE_MESSAGES_SETTINGS,
    );
    let canAccessWorkspaceSettings = await this.permissionsService.currentAdminCan(
      'can_access_workspace_settings',
    );
    let canAccessProductSettings = await this.permissionsService.currentAdminCan(
      'can_access_product_settings',
    );
    return hash({
      ...(canAccessProactiveSettings && { spambotContact: this.store.findAll('spambot-contact') }),
      ...(canAccessProductSettings && {
        conversationHistorySettings: this.store.findRecord(
          'email-conversation-history-settings',
          this.app.id,
        ),
      }),
      ...(canAccessProductSettings && {
        convertInboundEmailsToTicketsSettings: this.store.findRecord(
          'email-convert-inbound-emails-to-tickets-settings',
          this.app.id,
        ),
      }),
      hasLiveConvertToTicketWorkflows: get(
        '/ember/operator_workflows/has_live_convert_to_ticket_workflows',
        {
          app_id: this.app.id,
        },
      ),
      ...(canAccessProductSettings && {
        stripInboundEmailLinksSettings: this.store.findRecord(
          'strip-inbound-email-links-settings',
          this.app.id,
        ),
      }),
      ...(canAccessProductSettings && {
        spamSettings: this.store.findRecord('email-spam-settings', this.app.id),
      }),
      ...(canAccessProductSettings && {
        continuedConversationsSettings: this.store.findRecord(
          'email-continued-conversations-settings',
          this.app.id,
        ),
      }),
      ...(canAccessProductSettings && {
        emailAutoReplySettings: this.store.findRecord('email-auto-reply-settings', this.app.id),
      }),
      ...(canAccessProactiveSettings && {
        messagingSettings: this.store.findRecord('messaging-settings', this.app.id),
      }),
      ...(canAccessWorkspaceSettings && {
        attachmentSettings: this.store.findRecord('attachment-settings', this.app.id),
      }),
      emailDomains: this.app.loadEmailDomains(),
      ...(canAccessProductSettings && {
        detectForwardedSettings: this.store.findRecord(
          'email-detect-forwarded-settings',
          this.app.id,
        ),
      }),
      ...(canAccessProactiveSettings && {
        emailTemplatesSettings: this.store.findRecord('email-templates-settings', this.app.id),
      }),
      emailTemplateTitles: this.store.findAll('email-template-titles'),
      linkBrandingSettings: this.store.query('settings/custom-email-assets-domain', {}),
      ...(canAccessProactiveSettings && {
        dedicatedIp: this.store.query('settings/dedicated-ip', {}),
      }),
      canAccessProactiveSettings,
      canAccessWorkspaceSettings,
      canAccessProductSettings,
    });
  }

  resetController(controller: EmailController, isExiting: any) {
    if (isExiting) {
      controller.set('highlight', null);
      controller.set('step', null);
    }
  }

  async beforeModel(transition: Transition) {
    super.beforeModel(transition);
    this._notifyConfirmationForQueryParams(transition.to.queryParams);
  }

  _notifyConfirmationForQueryParams(queryParams: $TSFixMe) {
    if (isPresent(queryParams) && isPresent(queryParams['verifiedCustomEmail'])) {
      let email = `${queryParams['verifiedCustomEmail']}`;
      let confirmationText = this.intl.t('settings.senders.outbound-sender-settings.verified', {
        email,
      });
      this.notificationsService.notifyConfirmation(confirmationText, ENV.APP._15000MS);
      delete queryParams['verifiedCustomEmail'];
      this.router.replaceWith('apps.app.settings.channels.email', this.appService.app, {
        queryParams,
      });
    }
  }
}
