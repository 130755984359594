/* RESPONSIBLE TEAM: team-ml */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controller-access-in-routes */
/* eslint-disable ember/no-classic-classes */
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default Route.extend({
  intercomEventService: service(),
  analytics: {
    place: 'index',
  },

  activate() {
    this.controllerFor('apps/app/import/zendesk-chat').set('currentStep', 1);
  },

  actions: {
    didTransition() {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'viewed',
        object: 'zendesk_chat_importer_index',
        context: 'zendesk_chat_import',
      });
    },
  },
});
