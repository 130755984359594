/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Route from '@ember/routing/route';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';
import type Transition from '@ember/routing/transition';

export default class BillingSummaryIndexRoute extends Route {
  @service declare customerService: $TSFixMe;

  queryParams = {
    graduation: {
      refreshModel: false,
    },
    selectedTab: {
      refreshModel: true,
    },
  };

  beforeModel(transition: Transition) {
    if (transition.to.queryParams.graduation === 'true') {
      this.replaceWith(this.routeName, {
        queryParams: {
          selectedTab: 'graduationPrice',
          graduation: null,
        },
      });
    }
  }

  redirect(_model: $TSFixMe, transition: Transition) {
    if (
      transition.to.queryParams.selectedTab === 'graduationPrice' &&
      (isEmpty(this.customerService.earlyStageGraduation) ||
        !this.customerService.earlyStageGraduation?.inProgress)
    ) {
      this.replaceWith(this.routeName, {
        queryParams: {
          selectedTab: 'currentPrice',
          graduation: null,
        },
      });
    }
  }

  setupController(controller: $TSFixMe, model: $TSFixMe, transition: Transition) {
    super.setupController(controller, model, transition);
    if (controller.oepm) {
      controller._openEditPlanModal();
    }
  }

  resetController(controller: $TSFixMe, isExiting: boolean, _: Transition) {
    if (isExiting) {
      controller.setProperties({
        esChoiceConfirmation: false,
      });
    }
  }
}
