/* RESPONSIBLE TEAM: team-proactive-support */
import Route from '@ember/routing/route';

export default class MessagesRoute extends Route {
  redirect(model, transition) {
    if (transition.to.name.startsWith('apps.app.messages.')) {
      let route = transition.to.name.replace('apps.app.messages', 'apps.app.outbound');
      if (transition.to.params.id) {
        this.replaceWith(route, transition.to.params.id);
      } else {
        this.replaceWith(route);
      }
    }
  }
}
