/* RESPONSIBLE TEAM: team-standalone */
import ShowReportRoute from 'embercom/routes/apps/app/reports/custom-reports/report/show';

import { inject as service } from '@ember/service';
import type ReportingViews from 'embercom/services/reporting-views';
import ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';
import type Store from '@ember-data/store';

type ModelParams = {
  report_id: string;
};

export default class FinStandaloneReportsShowRoute extends ShowReportRoute {
  @service declare reportingViews: ReportingViews;
  @service declare store: Store;

  async model(params: ModelParams) {
    await ConversationAttributeDescriptor.peekAllAndMaybeLoad();

    let report = await this.getReport(params.report_id);
    return await super.model({ report_id: report.id });
  }

  async getReport(reportId: string) {
    return (
      (await this.store.peekRecord('reporting/custom/report', reportId)) ||
      this.store.findRecord('reporting/custom/report', reportId)
    );
  }
}
