/* RESPONSIBLE TEAM: team-proactive-support */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import {
  hideIntercomWidgetWhenLoaded,
  showIntercomWidget,
} from 'embercom/lib/intercom-widget-helper';

export default IntercomRoute.extend({
  activate() {
    this._super(...arguments);
    this.hidePrimaryNav();
    hideIntercomWidgetWhenLoaded();
  },
  deactivate() {
    this._super(...arguments);
    this.showPrimaryNav();
    showIntercomWidget();
  },
});
