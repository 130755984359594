/* RESPONSIBLE TEAM: team-product-guidance */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-private-routing-service */
import { inject as service } from '@ember/service';
import ErrorHandlingRoute from 'embercom/routes/base/error-handling-route';
import { getOwner } from '@ember/application';

export default ErrorHandlingRoute.extend({
  store: service(),
  appstoreService: service(),
  router: service(),
  beforeModel() {
    let routerMicrolib = getOwner(this).lookup('router:main')._routerMicrolib;
    let previousRoutes = routerMicrolib.currentHandlerInfos;
    let previousRoute = previousRoutes && previousRoutes.pop();
    if (previousRoute && previousRoute.name === this.get('appstoreService.baseRoute')) {
      let queryParams = routerMicrolib.state.queryParams;
      this.set('previousQueryParams', queryParams);
    }
  },
  model({ id }) {
    return this.store.findRecord('appstore/app-package', id);
  },
  afterModel(model, transition) {
    let title = `${model.get('name')} | App Store`;
    transition.trigger(false, 'setDocumentTitle', title);
  },
  handleError(error, transition) {
    switch (error.status) {
      case 404:
        return this.displayErrorAndRedirect(
          'Sorry but that app does not exist',
          this.get('appstoreService.baseRoute'),
        );
    }
  },
  setupController(controller, model) {
    controller.set('previousQueryParams', this.previousQueryParams);
    this._super(...arguments);
  },
});
