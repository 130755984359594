/* RESPONSIBLE TEAM: team-channels */
import AccountRouteBase from 'embercom/routes/apps/app/account/base/account-route-base';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default AccountRouteBase.extend({
  analytics: {
    section: 'your_account',
    place: 'visibility_tags',
  },
  appService: service(),
  app: readOnly('appService.app'),
});
