/* RESPONSIBLE TEAM: team-proactive-support */
import Route from '@ember/routing/route';
import { next } from '@ember/runloop';

export default class AppsAppOutboundSeriesRedirectToSeriesRoute extends Route {
  afterModel(series) {
    // Run next to ensure we've torn down and unregistered the old Series editor, if it existed.
    next(this, () => {
      this.replaceWith('apps.app.outbound.series.series', series.id, {
        queryParams: { mode: 'edit' },
      });
    });
  }
}
