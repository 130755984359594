/* RESPONSIBLE TEAM: team-app-security */

import type Store from '@ember-data/store';
import { action } from '@ember/object';
import type Transition from '@ember/routing/transition';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type SecurityController from 'embercom/controllers/apps/app/settings/workspace/security';
import SdkApp from 'embercom/models/sdk-app';
import WorkspaceSettingsRoute from 'embercom/routes/apps/app/settings/base/workspace-settings-route';
import type IntlService from 'embercom/services/intl';
import { all, hash } from 'rsvp';

interface SecurityModel {
  userSource: any;
  sdkApps: any;
  attachmentSettings: any;
  ios: any;
  android: any;
  pingDomains: any;
  settings: any;
  idvSettings: any;
  reminder: any;
}

export default class SecurityRoute extends WorkspaceSettingsRoute {
  @service declare appService: any;
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare customerService: any;
  @service declare permissionsHierarchyService: any;

  @tracked hasAlreadyCancelled = false;

  analytics = {
    section: 'new-settings',
    place: 'security',
  };

  get titleToken() {
    return this.intl.t(`new-settings.submenu.workspace.security`);
  }

  async beforeModel() {
    super.beforeModel(...arguments);
    if (!this.appService.app.api_secret) {
      this.appService.app.fetchAPISecret.perform();
    }
    return all([
      this.customerService.ensureDataIsLoaded.perform({ fetchPrices: false }),
      await this.permissionsHierarchyService.ensureDataIsLoaded.perform(),
    ]);
  }

  async model(): Promise<SecurityModel | undefined> {
    return hash({
      userSource: this.store.findAll('user-source'),
      sdkApps: this.store.findAll('sdk-app'),
      attachmentSettings: this.store.findRecord('attachment-settings', this.appService.app.id),
      ios: SdkApp.findOrCreateForPlatform('iOS'),
      android: SdkApp.findOrCreateForPlatform('Android'),
      pingDomains: this.store.query('ping-domain', { ignore_anonymous: true }),
      settings: this.store.findRecord('security-app-settings', this.appService.app.id),
      idvSettings: this.store.queryRecord('identity-verification-settings', {}),
      reminder: this.store.queryRecord('onboarding/home/identity_verification_reminder', {}),
    });
  }

  activate() {
    window.onbeforeunload = () => {
      let controller = this.controllerFor(
        'apps.app.settings.workspace.security',
      ) as SecurityController;
      if (controller && controller.isDirty) {
        return this.intl.t('apps.app.settings.security.warning');
      }
      return;
    };
  }

  deactivate() {
    this.set('hasAlreadyCancelled', false);
    window.onbeforeunload = null;
  }

  afterModel(model: SecurityModel): void {
    this.appService.app.set('sdkApps', model.sdkApps);
  }

  setupController(controller: SecurityController, model: SecurityModel) {
    super.setupController(controller, model);
    if (this.appService.app.canUseFeature('use-trusted-domains-for-url-verification-settings')) {
      controller.loadURLVerificationSettings();
    }
    controller.set('iosSecret', model.ios.api_secret);
    controller.set('androidSecret', model.android.api_secret);
    controller.set('sdkApps', model.sdkApps);
    controller.set('userSources', model.userSource);
    controller.set('pingDomains', model.pingDomains);
    controller.set('reminder', model.reminder);
    controller.set('idvSettings', model.idvSettings);
    controller.set('attachmentSettings', model.attachmentSettings);
    controller.set('initialProvisioningMethod', controller._initializeCurrentProvisioningMethod());
    controller.set('selectedProvisioningMethod', controller._initializeCurrentProvisioningMethod());
  }

  resetController(controller: SecurityController, isExiting: boolean) {
    if (isExiting) {
      controller.samlAccount?.rollbackAttributes();
      controller.scimSettings?.rollbackAttributes();
      controller.settings.rollbackAttributes();
    }
  }

  @action
  async willTransition(transition: Transition) {
    let controller = this.controllerFor(
      'apps.app.settings.workspace.security',
    ) as SecurityController;
    if (this.hasAlreadyCancelled) {
      transition.abort();
      this.hasAlreadyCancelled = false;
    } else if (
      controller.isDirty &&
      !window.confirm(this.intl.t('apps.app.settings.security.warning-leave-page'))
    ) {
      this.hasAlreadyCancelled = true;
      transition.abort();
    }
    return true;
  }
}
