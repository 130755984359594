/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Route from '@ember/routing/route';
let AppsIndexRoute = Route.extend({
  beforeModel() {
    let apps = this.modelFor('apps').get('apps');
    let defaultApp = apps.findBy('is_default_app');
    let appIdToUse = (defaultApp && defaultApp.id) || apps.get('firstObject.id');
    this.transitionTo('apps.app', appIdToUse);
  },
});

export default AppsIndexRoute;
