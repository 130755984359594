/* RESPONSIBLE TEAM: team-help-desk-experience */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
export default class InboxRoute extends Route {
  @service declare session: Session;

  beforeModel() {
    if (this.session.teammate.permissions.hasInboxAccess) {
      this.replaceWith('inbox.workspace.inbox');
    }
  }
}
