/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import ManageTeammatesRoute from 'embercom/routes/apps/app/settings/base/manage-teammates-route';
import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';
import type Transition from '@ember/routing/transition';
import type IntlService from 'ember-intl/services/intl';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';

export default class InviteRoute extends ManageTeammatesRoute {
  @service declare permissionsMutatorService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare store: Store;
  @service declare router: RouterService;
  @service declare intl: IntlService;
  @service declare intercomConfirmService: IntercomConfirmService;

  queryParams = {
    emails: {
      refreshModel: true,
    },
  };

  analytics = {
    section: 'settings',
    place: 'teammate-invite',
  };

  get getCurrentModel(): $TSFixMe {
    return this.modelFor(this.routeName);
  }

  async beforeModel() {
    await super.beforeModel(...arguments);

    if (this.appService.app.isTestApp) {
      this.router.transitionTo('apps.app.settings.workspace.teammates');
    }
  }

  model(
    params: {
      emails: string;
    },
    transition: Transition,
  ) {
    let emails: string[] = (transition.data.emails as string[]) ?? [];
    if (params.emails) {
      emails.push(...params.emails.split(','));
    }
    return {
      app: this.appService.app,
      emails,
      roles: this.store.findAll('role'),
      hasUnsavedChanges: false,
    };
  }

  @action
  onUnsavedChanges() {
    // @ts-ignore
    this.set('currentModel.hasUnsavedChanges', true);
  }

  @action
  onEmailsChange(emails: $TSFixMe) {
    // @ts-ignore
    this.set('currentModel.hasUnsavedChanges', false);
    // @ts-ignore
    this.set('currentModel.emails', emails);
  }

  @action
  async willTransition(transition: Transition) {
    if (this.getCurrentModel.hasUnsavedChanges) {
      transition.abort();

      let confirmOptions = {
        title: this.intl.t('settings.teammates.invite.cancel-modal.close-without-saving'),
        primaryButtonType: 'primary-destructive',
        confirmButtonText: this.intl.t(
          'settings.teammates.invite.cancel-modal.close-without-saving-btn',
        ),
        cancelButtonText: this.intl.t('settings.teammates.invite.cancel-modal.keep-editing'),
        body: this.intl.t('settings.teammates.invite.cancel-modal.lose-your-changes'),
      };

      if (await this.intercomConfirmService.confirm(confirmOptions)) {
        // @ts-ignore
        this.set('currentModel.hasUnsavedChanges', false);
        await transition.retry();
      }
    }
  }
}
