/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controller-access-in-routes */
import { inject as service } from '@ember/service';
import IntercomRoute from 'embercom/routes/base/intercom-route';

const routesWhereWeShouldRetainTheSearchTerm = new Set([
  'apps.app.users.segments.segment.index',
  'apps.app.companies.segments.segment',
]);

export default IntercomRoute.extend({
  bootService: service(),
  store: service(),

  activate() {
    this.bootService.deferSecondaryPhase();
  },

  actions: {
    willTransition(transition) {
      if (!routesWhereWeShouldRetainTheSearchTerm.has(transition.to.name)) {
        // we are not doing... `this.controller.send('exitSearch')` because
        // this would modify this.controller.searchTerm, which will cause an implicit
        // query param transition, which will result in the URL for `apps.app.index` (e.g. a/apps/tx2p130c)
        // to end up in the browser history, even though we transition away from it with a subsequent .replaceWith
        transition.to.queryParams.searchTerm = '';
        this.controller.set('showSearch', false);
      }
    },
  },
});
