/* RESPONSIBLE TEAM: team-growth-opportunities */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controller-access-in-routes */
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';

export default class SignupTeams extends Route {
  @service store;
  @service redirectService;
  @service stripeLoaderService;
  @service earlyStageService;
  @service iamService;
  @service gtmService;
  @service intl;
  @service tracing;
  @service windowLocationService;

  async beforeModel(transition) {
    let queryParams = transition.to.queryParams;
    let { locale, developer } = queryParams;

    if (!(developer && developer === 'true')) {
      let currentParams = new URLSearchParams(queryParams);
      let signupParams = currentParams.toString();

      this.windowLocationService.replace(`admins/sign_up?${signupParams}`);
      return;
    }

    this.iamService.boot();

    if (locale) {
      await this.intl.switchLocale(locale);
    }
  }

  model() {
    return hash({
      cookieConsentMode: this.gtmService.getCookieConsentMode(),
      authenticatedAdmin: this.modelFor('signup').authenticatedAdmin,
    });
  }

  setupController(controller, model, transition) {
    super.setupController(...arguments);

    this.controllerFor('signup').set('purchaseExperience', 'v3');
    if (transition.to.queryParams.after_signup_redirect_path) {
      this.redirectService.setRedirectPath(transition.to.queryParams.after_signup_redirect_path);
    }
  }

  @action
  loading(transition) {
    transition.promise.finally(() => {
      this.tracing.haltTrace('stopped_manually');
    });
    return true;
  }
}
