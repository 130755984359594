/* RESPONSIBLE TEAM: team-phone */
import { action } from '@ember/object';

import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import WorkspaceSettingsRoute from 'embercom/routes/apps/app/settings/base/workspace-settings-route';
import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';
import type Transition from '@ember/routing/-private/transition';
import type IntercomCallService from 'embercom/services/intercom-call-service';
import { hash } from 'rsvp';

const SETTINGS_PERMISSION = 'can_access_product_settings';

export default class CallingRoute extends WorkspaceSettingsRoute {
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare router: RouterService;
  @service declare intercomEventService: any;
  @service declare store: Store;
  @service declare permissionsService: any;
  @service declare intercomCallService: IntercomCallService;

  analytics = {
    section: 'new-settings',
    place: 'phone',
  };

  get titleToken() {
    return this.intl.t('channels.video-call.settings.title');
  }

  async beforeModel(transition: Transition) {
    super.beforeModel(...arguments);

    if (!this.appService.app.canAccessCallingBeta) {
      this.router.transitionTo('apps.app.settings.index');
    }

    return this.permissionsService.ensurePermissionWhenTransitioning(
      SETTINGS_PERMISSION,
      transition,
    );
  }

  async model() {
    return await hash({
      settings: this.store.queryRecord('settings/calling', {}),
      isCallingSupported: this.intercomCallService.checkCallingSupport(),
    });
  }

  @action
  didTransition() {
    super.didTransition();
    this.intercomEventService.trackEvent('calling_settings_page_visits');
  }
}
