/* RESPONSIBLE TEAM: team-proactive-support */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import Ruleset from 'embercom/models/matching-system/ruleset';
import {
  objectTypes,
  matchBehaviors,
} from 'embercom/models/data/matching-system/matching-constants';
import { EVERYONE_PREDICATE } from 'embercom/lib/operator/custom-bots/constants';

export default class NewItemRoute extends Route {
  @service store;
  @service appService;

  async beforeModel() {
    let adminId = this.appService.app.currentAdmin.id;
    let params = {
      app_id: this.appService.app.id,
      admin_id: adminId,
      match_behavior: matchBehaviors.transient,
      role_predicate_group: { predicates: [EVERYONE_PREDICATE] },
      object_type: objectTypes.newsItem,
      object_data: {
        sender_id: adminId,
      },
    };
    let ruleset = await Ruleset.createForType(this.store, params);
    ruleset.set('isNewRuleset', true);
    this.replaceWith('apps.app.outbound.news.news-items.edit', ruleset, {
      queryParams: { mode: 'edit' },
    });
  }
}
