/* RESPONSIBLE TEAM: team-channels */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default IntercomRoute.extend({
  intercomEventService: service(),
  appService: service(),
  app: readOnly('appService.app'),

  model() {
    return this.modelFor('apps');
  },

  setupController(controller, model) {
    this._super(...arguments);
    controller.set('titleToken', this.titleToken);
  },

  didTransition: action(function () {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'account',
    });
  }),
});
