/* RESPONSIBLE TEAM: team-reporting */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class TopicRoute extends IntercomRoute {
  @service store;
  topic;

  async model() {
    this.topic = this.store.createRecord('conversational-insights/conversation-topic');
    return { topic: this.topic };
  }

  @action
  willTransition() {
    this.topic.rollbackAttributes();
  }
}
