/* RESPONSIBLE TEAM: team-proactive-support */
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type RouterService from '@ember/routing/router-service';

export default class EditSubscriptionRoute extends Route {
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare router: RouterService;

  activate() {
    this.router.on('routeWillChange', (transition: any) => {
      let model: any = this.modelFor('apps.app.settings.proactive-support.subscriptions.edit');
      if (model.subscriptionType.hasDirtyAttributes) {
        if (confirm(this.intl.t('outbound.settings.subscription-types.warn-unsaved-changes'))) {
          model.subscriptionType.rollbackAttributes();
        } else {
          transition.abort();
        }
      }
    });
  }

  model(params: any) {
    return RSVP.hash({
      subscriptionType: this.store.findRecord(
        'outbound-subscriptions/subscription-type',
        params.id,
      ),
      translationSettings: this.store.findAll('outbound-subscriptions/translation-settings'),
    });
  }
}
