/* RESPONSIBLE TEAM: team-data-interop */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class DeveloperHubAppPackageAppPartnerShowRoute extends IntercomRoute {
  @service developerHub;
  @service store;

  beforeModel() {
    let appPartnerPath = 'appPackage.appPartner';
    this.developerHub.fillEmptyModel(
      appPartnerPath,
      this.store.createFragment('developer-hub/app-partner'),
    );
  }

  @action willTransition(transition) {
    this.developerHub.appPackage.rollbackAttributes();
  }
}
