/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class CustomReportsRoute extends Route {
  @service permissionsService;
  @service appService;

  titleToken = 'Custom reports';

  beforeModel(transition) {
    super.beforeModel(...arguments);
    if (this.appService.app.canShareReportsInternally) {
      return this.permissionsService.ensurePermissionWhenTransitioning(
        'can_access_reporting',
        transition,
      );
    } else {
      return this.permissionsService.ensurePermissionWhenTransitioning(
        'can_reporting__custom_reports__read',
        transition,
      );
    }
  }
}
