/* RESPONSIBLE TEAM: team-help-desk-experience */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type Conversation from 'embercom/objects/inbox/conversation';
import type ConversationSummary from 'embercom/objects/inbox/conversation-summary';
import type InboxState from 'embercom/services/inbox-state';
import type InboxApi from 'embercom/services/inbox-api';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';
import { ResponseError } from 'embercom/lib/inbox/requests';
import type TracingService from 'embercom/services/tracing';
import type Transition from '@ember/routing/-private/transition';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import { type Router } from '@ember/routing';

interface ConversationAnalytics {
  action: string;
  object: string;
  section: string;
  conversation_id: number;
  source?: string;
}

export default class ConversationRoute extends Route {
  @service router!: Router;
  @service inboxState!: InboxState;
  @service inboxApi!: InboxApi;
  @service intercomEventService: any;
  @service declare notificationsService: any;
  @service declare intl: IntlService;
  @service declare tracing: TracingService;
  @service declare inboxSidebarService: InboxSidebarService;

  async model(params: { conversation_id: number }) {
    return await this.inboxApi.fetchConversation(params.conversation_id);
  }

  serialize(conversation: Conversation | ConversationSummary) {
    return {
      conversation_id: conversation.id,
    };
  }

  titleToken(model: Conversation) {
    if (model.isTicket) {
      return this.intl.t('inbox.page-title.ticket', { title: model.ticketTitle });
    } else {
      return this.intl.t('inbox.page-title.conversation', { userName: model.user.displayAs });
    }
  }

  async afterModel(conversation: Conversation | ConversationSummary, transition: Transition) {
    this.inboxSidebarService.resetDefaults();
    this.inboxState.activeConversation = conversation;

    try {
      window.sessionStorage.setItem('inbox2:last-viewed-conversation', conversation.id.toString());
    } catch (e) {} // eslint-disable-line no-empty

    this.tracing.tagRootSpan({ conversation_id: conversation.id });
    this.trackConversationAnalytics(conversation, transition);
    this.router.on('routeDidChange', () => {
      this.inboxSidebarService.stopPreviewingConversation();
    });
  }

  trackConversationAnalytics(
    conversation: Conversation | ConversationSummary,
    transition: Transition,
  ) {
    let analytics: ConversationAnalytics = {
      action: 'viewed',
      object: 'conversation',
      section: 'conversation_thread',
      conversation_id: conversation.id,
    };

    let source = transition.to.queryParams['source'];
    if (source) {
      analytics['source'] = source;
    }
    this.intercomEventService.trackAnalyticsEvent(analytics);
  }

  deactivate(transition?: Transition) {
    // single conversation view sets the active conversation id so we don't want to clear it
    if (transition?.to?.name === 'inbox.workspace.inbox.conversation.conversation') {
      return;
    } else {
      this.inboxState.activeConversation = undefined;
    }
  }

  @action
  error(error: Error) {
    if (error instanceof ResponseError && error.response.status === 404) {
      this.notificationsService.notifyError(
        this.intl.t('inbox.notifications.failed-loading-conversation'),
      );
      this.transitionTo('inbox.workspace.inbox.inbox.index');
      return;
    }

    return true;
  }
}
