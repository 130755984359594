/* RESPONSIBLE TEAM: team-growth-opportunities */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import ajax from 'embercom/lib/ajax';
import { CURRENT_EARLY_STAGE_SOLUTION_IDS } from 'embercom/lib/billing';

export default Route.extend({
  notificationsService: service(),

  beforeModel(transition) {
    let queryParams = transition.to.queryParams;
    let apps = this.modelFor('apps').get('apps');
    let { after_signup_redirect_path, free, solution_id } = queryParams;
    let defaultApp;
    if (after_signup_redirect_path) {
      let appId = this.parseAppId(after_signup_redirect_path);
      defaultApp = apps.find((app) => app.id === appId);
    }

    if (!defaultApp) {
      defaultApp = apps.findBy('is_default_app');
    }

    let appToUse = defaultApp && defaultApp.id ? defaultApp : apps.get('lastObject');
    if (!appToUse || !appToUse.id) {
      queryParams.noApp = true;
      return this.transitionTo('signup.teams.index', { queryParams });
    }
    let appIdToUse = appToUse.id;
    if (free) {
      this.setupFreeFeatureFlags(appToUse);
    }

    if (CURRENT_EARLY_STAGE_SOLUTION_IDS.includes(solution_id)) {
      return this.transitionTo('apps.app.teams-checkout.early-stage-application', appIdToUse);
    }

    if (queryParams.developer) {
      return this.transitionTo('apps.app.developer-hub', appIdToUse);
    }
    this.transitionTo('apps.app.teams-checkout.plan', appIdToUse);
  },

  setupFreeFeatureFlags(app) {
    let params = {
      url: `/ember/customers/${app.id}/update_subscription`,
      type: 'post',
      contentType: 'application/json',
      data: JSON.stringify({ free: true }),
    };
    this.app = app;
    return ajax(params)
      .then(this.redirectToAppAndSendSuccessMessage.bind(this))
      .catch(this.redirectToAppAndSendFailureMessage.bind(this));
  },

  async redirectToAppAndSendSuccessMessage() {
    this.notificationsService.notifyConfirmation(
      'You have successfully updated your subscription!',
      5000,
    );
    this.redirectToApp();
  },

  redirectToAppAndSendFailureMessage(err) {
    console.error(err);
    let errorMessage =
      (err && err.jqXHR && err.jqXHR.responseJSON && err.jqXHR.responseJSON.message) ||
      'An unknown error occured! Please contact us for help';

    this.notificationsService.notifyError(
      `Your free plan could not be started. ${errorMessage}`,
      5000,
    );
    this.redirectToApp();
  },

  redirectToApp() {
    this.replaceWith('apps.app.home');
  },

  parseAppId(url) {
    let match = url.match(/apps\/(.*?)\//);
    return match && match.length > 1 && match[1];
  },
});
