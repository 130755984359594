/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { readOnly } from '@ember/object/computed';
import EventTracking from 'embercom/components/mixins/articles/event-tracking';

export default Route.extend(EventTracking, {
  per_page: 10,
  showing: 'conversations',
  queryParams: {
    conversations: { refreshModel: true, replace: true },
    reaction: { refreshModel: true, replace: true },
  },
  store: service(),
  appService: service(),
  app: readOnly('appService.app'),

  resetController(controller) {
    controller.set('conversations', true);
    controller.set('reaction', null);
  },

  actions: {
    didTransition() {
      let stat = this.paramsFor('apps.app.articles.articles.article.show').displaying;
      this.trackEducateEvent({
        action: 'clicked',
        object: 'article_stat',
        article_stat: stat === 'newchat' ? 'conversations' : stat,
        place: 'article',
        article_id: this.modelFor('apps.app.articles.articles.article').get('id'),
      });
    },
  },
});
