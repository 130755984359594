/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Route from '@ember/routing/route';
import getTransitionParams from 'embercom/lib/router/get-transition-params';

export default Route.extend({
  model() {},

  redirect(_, transition) {
    let app = this.modelFor('apps.app');
    let params = getTransitionParams(transition);
    let conversationId = params['apps.app.conversation'].conversation_id;
    let redirectTo =
      app.get('inboxIsActive') && app.get('currentAdminHasInboxAccess')
        ? transition.to.queryParams['redirectTo']
        : 'feed';

    let conversationRoutes = {
      feed: app.get('feedConversationRoute'),
      inbox: app.get('inboxConversationRoute'),
    };

    let conversationRoute = conversationRoutes[redirectTo] || conversationRoutes['inbox'];

    this.replaceWith(conversationRoute, app, conversationId);
  },
});
