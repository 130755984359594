/* RESPONSIBLE TEAM: team-knowledge-interop */
import Route from '@ember/routing/route';
import type Router from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import type Folder from 'embercom/models/content-service/folder';
import type Store from '@ember-data/store';
import KnowledgeHubApi, {
  type KnowledgeHubApiQueryParams,
} from 'embercom/lib/knowledge-hub/list-api';
import { getOwner } from '@ember/application';
import type Transition from '@ember/routing/transition';
import { ContentWrapperType } from 'embercom/models/content-service/content-wrapper';
import { type SortDirection } from 'embercom/lib/knowledge-hub/constants';
import { EntityType } from 'embercom/models/data/entity-types';
import { type ContentSearchParams } from 'embercom/lib/content-service/search-api';

type ModelParams = {
  folder_id: string;
};

export default class FolderRoute extends Route {
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare store: Store;
  @service declare appService: $TSFixMe;
  @service declare router: Router;

  async model(params: ModelParams): Promise<Folder> {
    return await this.getFolder(params.folder_id);
  }

  async afterModel(folder: Folder): Promise<KnowledgeHubApi> {
    if (!folder?.id) {
      this.router.transitionTo('apps.app.knowledge-hub.all-content');
    }

    let defaultSearchParams: ContentSearchParams = {
      object_types: this.knowledgeHubService.getSearchObjectTypes(),
      content_wrapper_types: [ContentWrapperType.SUPPORT_CONTENT],
      app_id: this.appService.app.id as string,
      folder_ids: [folder.id],
      folder_entity_type: EntityType.ContentLibraryFolder,
      sort_by: 'title',
      sort_direction: 'asc' as SortDirection,
    };

    let folderQueryParams = this.paramsFor(this.routeName) as KnowledgeHubApiQueryParams;

    this.knowledgeHubService.api = new KnowledgeHubApi(
      getOwner(this),
      defaultSearchParams,
      folderQueryParams,
    );
    this.knowledgeHubService.api.loadPage();
    return this.knowledgeHubService.api;
  }

  async setupController(controller: any, folder: Folder, transition: Transition) {
    super.setupController(controller, folder, transition);
    controller.setProperties({ listApi: this.knowledgeHubService.api, folder });
  }

  async getFolder(folder_id: string): Promise<Folder> {
    await this.knowledgeHubService.fetchFoldersOnce();
    return this.store.peekRecord('content-service/folder', folder_id);
  }
}
