/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable promise/prefer-await-to-then */
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import ReportBase from 'embercom/routes/apps/app/reports/base/report';
import { hash } from 'rsvp';

export default ReportBase.extend({
  store: service(),
  analytics: {
    place: 'overview',
  },
  titleToken: 'Overview',

  reportName: 'overview_report',

  appService: service(),
  app: readOnly('appService.app'),
  instrumentationDelay: 3000,

  async model() {
    return hash({
      settings: this.store
        .queryRecord('reporting/settings', {})
        .then((settings) => ({ reportingExcludeOooHours: settings.excludeOooHours })),
    });
  },
});
