/* RESPONSIBLE TEAM: team-proactive-support */
import ContentEditorRoute from 'embercom/routes/apps/app/content/edit';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { inject as service } from '@ember/service';

export default ContentEditorRoute.extend({
  reportingMetrics: service(),
  appService: service(),

  analytics: {
    section: 'messages',
    place: 'survey_editor',
  },

  async beforeModel() {
    await this.reportingMetrics.setup.perform();
  },

  afterModel() {
    this.outboundHomeService.setActiveList(objectTypes.survey);
  },
});
