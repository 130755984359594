/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';

export default Route.extend({
  reportingService: service(),
  appService: service(),
  app: readOnly('appService.app'),
  titleToken: 'Inbox',

  beforeModel() {
    let app = this.modelFor('apps.app');
    if (app.get('inboxIsNotActive')) {
      return this.replaceWith('apps.app.inbox');
    }
  },
});
