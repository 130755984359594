/* RESPONSIBLE TEAM: team-app-security */
import WorkspaceSettingsRoute from 'embercom/routes/apps/app/settings/base/workspace-settings-route';
import { inject as service } from '@ember/service';

export default class ComplianceDocuments extends WorkspaceSettingsRoute {
  @service appService;
  @service intl;

  get titleToken() {
    return this.intl.t('settings.app-settings-submenu.compliance-documents');
  }
}
