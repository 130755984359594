/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-new-mixins */
import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';
import B64FilterParamEncoder from 'embercom/vendor/intercom/b64-filter-param-encoder';

let RailsSegmentParameters = Mixin.create({
  router: service(),
  parseRailsParameters(segmentIdentifier) {
    if (segmentIdentifier.indexOf(':') > -1) {
      let separated = segmentIdentifier.split(':');
      segmentIdentifier = separated[0];
      this.set('queryParameters', JSON.parse(B64FilterParamEncoder.b64Decode(separated[1])));
    }
    return segmentIdentifier;
  },
  applyRailsParameters(segment) {
    let queryParameters = this.queryParameters;
    if (queryParameters) {
      if (queryParameters.predicates) {
        segment.set('predicates', queryParameters.predicates);
      } else if (queryParameters.search) {
        this.send('setSearch', queryParameters.search);
      }
    }
    this.set('queryParameters', undefined);
  },
  // we need to pass the segment because sometimes the controller is using a copy, not the route's model
  addFiltersToUrl(segment) {
    if (!this.get('router.location').replaceState) {
      return;
    }
    // This isn't ideal, but at least it's more Ember friendly than messing with window.location directly.
    // Because the `onUpdate` action fires as you edit the segment filters, we cannot use `replaceWith`
    // because it does a transition which causes the editor to lose focus.
    let newUrl = this.router.urlFor(
      this.routeName,
      B64FilterParamEncoder.segmentIdentifierWithFilters(segment),
    );
    this.get('router.location').replaceState(newUrl);
  },
});

export default RailsSegmentParameters;
