/* RESPONSIBLE TEAM: team-channels */
import AccountRouteBase from 'embercom/routes/apps/app/account/base/account-route-base';
import { inject as service } from '@ember/service';

export default AccountRouteBase.extend({
  store: service(),
  intl: service(),
  analytics: {
    section: 'your_account',
    place: 'oauth-tokens',
  },
  get titleToken() {
    return this.intl.t('account.oauth-tokens.title');
  },

  model() {
    return this.store.findAll('oauth-tokens');
  },
});
