/* RESPONSIBLE TEAM: team-frontend-tech */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class extends Route {
  @service intercomEventService;

  setupController(controller, model) {
    super.setupController(...arguments);
    controller.set('titleToken', this.titleToken);
  }

  @action
  didTransition() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'settings',
    });
  }
}
