/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-new-mixins */
import Mixin from '@ember/object/mixin';
import { on } from '@ember/object/evented';
// Checks if model has unsaved changes and prompts user on leaving the page

export default Mixin.create({
  setupCheckUnsavedChanges: on('activate', function () {
    window.onbeforeunload = function () {
      if (this.controller && this.controller.get('model.hasDirtyAttributes')) {
        return this.unsavedChangesMessage;
      }
    }.bind(this);
  }),
  teardownCheckUnsavedChanges: on('deactivate', function () {
    window.onbeforeunload = undefined;
  }),
  actions: {
    willTransition(transition) {
      this._super(...arguments);

      if (
        this.controller.get('model.hasDirtyAttributes') &&
        !window.confirm(this.unsavedChangesMessage)
      ) {
        transition.abort();
      } else {
        if (this.controller.get('model.hasDirtyAttributes') && this.cleanUpOnNavigatingAway) {
          this.cleanUpOnNavigatingAway(this.controller);
        }
        return true;
      }
    },
  },
  unsavedChangesMessage: 'Warning: You have unsaved changes. Leave page?',
});
