/* RESPONSIBLE TEAM: team-product-guidance */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controller-access-in-routes */
import GuideRouteBase from 'embercom/routes/base/guide';
import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';

export default GuideRouteBase.extend({
  appService: service(),
  app: readOnly('appService.app'),

  analytics: {
    section: 'platform',
    place: 'start_guide',
  },

  beforeModel() {
    this.transitionTo(this.app.onboardingHomeRoute);
  },

  activate() {
    this.controllerFor('apps.app').set('showPrimaryNav', true);
  },
});
