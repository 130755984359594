/* RESPONSIBLE TEAM: team-workflows */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { type Router } from '@ember/routing';
import { hash } from 'rsvp';
import type Store from '@ember-data/store';

export default class FinAIAgentAICategoriesRoute extends Route {
  @service declare appService: any;
  @service declare router: Router;
  @service declare store: Store;

  async beforeModel() {
    if (!this.appService.app.canUseFinAiCategories) {
      return this.router.transitionTo('apps.app.fin-ai-agent.setup');
    }
    return hash({});
  }

  async model() {
    return hash({
      aiAgentRoutingSettings: this.store.findRecord(
        'ai-agent/routing-settings',
        this.appService.app.id,
      ),
    });
  }
}
