/* RESPONSIBLE TEAM: team-proactive-support */
import ContentEditorRoute from 'embercom/routes/apps/app/content/edit';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';

export default class NewsItemEditRoute extends ContentEditorRoute {
  analytics = {
    section: 'news_items',
    place: 'news_items_editor',
  };

  afterModel() {
    this.outboundHomeService.setActiveList(objectTypes.newsItem);
  }
}
