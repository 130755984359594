/* RESPONSIBLE TEAM: team-channels */
import Route from '@ember/routing/route';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';

export default class SpamRoute extends Route {
  @service declare session: Session;

  beforeModel() {
    if (!this.teammateCanAccessSpam) {
      this.replaceWith('inbox.workspace.inbox');
      return;
    }
  }

  get teammateCanAccessSpam() {
    let permissionToCheck = this.session.teammate.permissions.conversationAccess?.accessType;

    if (permissionToCheck) {
      return ['all', 'all_except', null, undefined].includes(permissionToCheck);
    } else {
      return true;
    }
  }
}
