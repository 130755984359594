/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import OutboundBaseListRoute from 'embercom/lib/outbound/base-list-route';
import {
  objectTypes,
  matchBehaviors,
} from 'embercom/models/data/matching-system/matching-constants';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default OutboundBaseListRoute.extend({
  store: service(),
  outboundHomeService: service(),
  appService: service(),
  app: readOnly('appService.app'),

  beforeModel() {
    return this.redirectToAllOutboundSearch();
  },

  async model(params) {
    let [model, senderDomains] = await Promise.all([
      this._super(params),
      this.store.findAll('settings/sender-domain'),
    ]);
    model.senderDomains = senderDomains;
    return model;
  },

  titleToken: 'Email',
  analytics: {
    section: 'outbound',
    place: 'emails',
  },
  objectTypes: [objectTypes.legacyMessageEmail, objectTypes.email],
  matchBehavior: matchBehaviors.single,
});
