/* RESPONSIBLE TEAM: team-customer-data-platform */
import ProductSettingsRoute from 'embercom/routes/apps/app/settings/base/product-settings-route';
import { inject as service } from '@ember/service';

export default ProductSettingsRoute.extend({
  store: service(),
  model() {
    let app = this.modelFor('apps/app');
    return this.store.findRecord('data-attribute-settings', app.id);
  },
});
