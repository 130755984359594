/* RESPONSIBLE TEAM: team-standalone */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { taskFor } from 'ember-concurrency-ts';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';

export default class DataRoute extends Route {
  @service declare finStandaloneService: FinStandaloneService;

  async beforeModel() {
    let promises = [];

    if (taskFor(this.finStandaloneService.loadZendeskConfig).isRunning) {
      promises.push(taskFor(this.finStandaloneService.loadZendeskConfig).lastRunning!);
    } else {
      promises.push(taskFor(this.finStandaloneService.loadZendeskConfig).perform());
    }

    if (taskFor(this.finStandaloneService.loadSalesforceConfig).isRunning) {
      promises.push(taskFor(this.finStandaloneService.loadSalesforceConfig).lastRunning!);
    } else {
      promises.push(taskFor(this.finStandaloneService.loadSalesforceConfig).perform());
    }

    await Promise.all(promises);
  }
}
