/* RESPONSIBLE TEAM: team-product-guidance */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class PreviewFinRoute extends Route {
  @service iamService;

  beforeModel(_transition) {
    this.iamService.boot();
  }

  queryParams = {
    token: '',
    source: '',
  };
}
