/* RESPONSIBLE TEAM: team-workflows */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import { action } from '@ember/object';

const OPERATOR_SETTINGS_PERMISSION = 'can_create_and_edit_bots';

export default class Automation extends IntercomRoute {
  @service intercomEventService;
  @service store;
  @service appService;
  @service permissionsService;
  @service intl;

  analytics = {
    place: 'settings',
    section: 'operator',
  };

  get titleToken() {
    return this.intl.t('new-settings.ai-automation.automation.title');
  }

  beforeModel(transition) {
    this.permissionsService.ensurePermissionWhenTransitioning(
      OPERATOR_SETTINGS_PERMISSION,
      transition,
      'apps.app.automation.basics',
    );
  }

  async model() {
    let appId = this.get('appService.app.id');

    return hash({
      identity: this.store.findRecord('operator-identity', '1'),
      messengerSettings: this.store.findRecord('messenger-settings/all', appId),
      conversationImports: this.store.findAll('conversation-import'),
      customBotsSettings: this.store.findRecord('operator/custom-bots-settings', appId),
      botInboxSettings: this.store.findRecord('operator-bot-inbox-settings', appId),
      aiAgentSettings: this.store.findRecord('ai-agent/settings', appId),
      aiAgentQuickReplySettings: this.store.findRecord(
        'operator-settings/ai-agent-quick-replies',
        appId,
      ),
    });
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    controller.set('previewConfiguration', model.messengerSettings.get('previewConfiguration'));
  }

  @action
  refreshRoute() {
    this.refresh();
  }

  @action
  didTransition() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'operator_settings',
    });
  }
}
