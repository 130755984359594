/* RESPONSIBLE TEAM: team-ai-agent */
import OutboundBaseListRoute from 'embercom/lib/outbound/base-list-route';
import {
  objectTypes,
  matchBehaviors,
} from 'embercom/models/data/matching-system/matching-constants';
import { FIN_LANDING_PAGE_PAGEVIEW_KEY } from 'embercom/lib/operator/resolution-bot/constants';
import { inject as service } from '@ember/service';
import { adminHasSeenFromPageviews, fetchAdminPageviews } from 'embercom/lib/admin-view-tracking';

import { statisticKeys } from 'embercom/models/data/outbound/constants';

export default class FinAIAgentProfilesIndexRoute extends OutboundBaseListRoute {
  @service declare appService: any;
  @service declare outboundHomeService: $TSFixMe;
  @service declare router: $TSFixMe;
  @service declare finOptInService: $TSFixMe;
  @service declare store: $TSFixMe;

  showAiAnswersBanner: boolean | undefined;
  showFinLandingPage: boolean | undefined;
  hasSeenFinLandingPageWithPreview: boolean | undefined;

  async beforeModel() {
    let adminPageviews = await fetchAdminPageviews(this.store);
    let hasSeenFinLandingPageWithPreview = adminHasSeenFromPageviews(
      adminPageviews,
      FIN_LANDING_PAGE_PAGEVIEW_KEY,
    );
    this.outboundHomeService.setActiveList(objectTypes.resolutionBotBehavior);
    this.set('hasSeenFinLandingPageWithPreview', hasSeenFinLandingPageWithPreview);
  }

  async afterModel(model?: $TSFixMe): Promise<void> {
    if (model.contentWrappers.length === 1) {
      // If only one behavior, we immediately transition to the show/edit page
      this.router.transitionTo(
        model.contentWrappers.firstObject.routeForShowPage,
        model.contentWrappers.firstObject.contentWrapperId,
      );
    }

    this.set('showAiAnswersBanner', this.hasAIAnswersBehaviorLive(model.contentWrappers));
  }

  setupController(controller: $TSFixMe) {
    super.setupController(...arguments);
    controller.set('showAiAnswersBanner', this.showAiAnswersBanner);
    controller.set('showFinLandingPage', this.showFinLandingPage);
    controller.set('hasSeenFinLandingPageWithPreview', this.hasSeenFinLandingPageWithPreview);
  }

  statistics = [statisticKeys.receipts];

  objectTypes = [objectTypes.resolutionBotBehavior];
  matchBehavior = matchBehaviors.transient;

  hasAIAnswersBehaviorLive(contentWrappers: $TSFixMe) {
    return contentWrappers.some((contentWrapper: $TSFixMe) => {
      let content = contentWrapper.contents.firstObject;
      return content?.contentData?.use_ai_answers === true && content?.isLive;
    });
  }

  hasNoBehaviors(model: $TSFixMe) {
    return model.contentWrappers.length === 0;
  }

  analytics = {
    section: 'ai-chatbot',
    place: 'profiles_index',
  };
}
