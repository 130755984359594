/* RESPONSIBLE TEAM: team-phone */
import { action } from '@ember/object';

import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import WorkspaceSettingsRoute from 'embercom/routes/apps/app/settings/base/workspace-settings-route';
import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';
import type IntercomCallService from 'embercom/services/intercom-call-service';
import { tracked } from '@glimmer/tracking';

export default class CallingRoute extends WorkspaceSettingsRoute {
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare router: RouterService;
  @service declare intercomEventService: any;
  @service declare store: Store;
  @service declare permissionsService: any;
  @service declare intercomCallService: IntercomCallService;

  @tracked isCallingSupported = false;

  analytics = {
    section: 'settings',
    place: 'calling',
  };

  get titleToken() {
    return this.intl.t('channels.video-call.settings.title');
  }

  async beforeModel() {
    super.beforeModel(...arguments);

    let hasPermission = this.permissionsService.currentAdminCan('can_access_product_settings');
    if (!hasPermission) {
      return this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(
        'can_access_product_settings',
      );
    }

    if (!this.appService.app.canAccessCallingBeta) {
      this.router.transitionTo('apps.app.settings');
    }
    this.isCallingSupported = await this.intercomCallService.checkCallingSupport();
  }

  async model() {
    return {
      settings: await this.store.queryRecord('settings/calling', {}),
      isCallingSupported: this.isCallingSupported,
    };
  }

  @action
  didTransition() {
    super.didTransition();
    this.intercomEventService.trackEvent('calling_settings_page_visits');
  }
}
