/* RESPONSIBLE TEAM: team-help-desk-experience */

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';
import View from 'embercom/objects/inbox/inboxes/view';
import type InboxApi from 'embercom/services/inbox-api';
import type Session from 'embercom/services/session';

export default class Inbox2FinViewRedirect extends Route {
  @service declare inboxApi: InboxApi;
  @service declare session: Session;
  @service declare router: RouterService;

  async redirect() {
    let result = await this.inboxApi.listInboxes({ types: [InboxType.View] });

    let finView = result.inboxes.find(
      (view) => view instanceof View && view.viewSummary.isSystemView,
    );

    if (finView) {
      this.router.transitionTo('inbox.workspace.inbox.inbox', InboxCategory.View, finView.id);
    } else {
      this.router.transitionTo('inbox.workspace.inbox');
    }
  }
}
