/* RESPONSIBLE TEAM: team-ai-agent */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';
import type Store from '@ember-data/store';
import type ReportingMetrics from 'embercom/services/reporting-metrics';
import { type Router } from '@ember/routing';
import { taskFor } from 'ember-concurrency-ts';
import type Transition from '@ember/routing/-private/transition';
import type CopilotUsageService from 'embercom/services/copilot-usage-service';

export default class CopilotRoute extends IntercomRoute {
  @service declare appService: any;
  @service declare store: Store;
  @service declare reportingMetrics: ReportingMetrics;
  @service declare router: Router;
  @service declare copilotUsageService: CopilotUsageService;
  @service declare intercomEventService: $TSFixMe;

  analytics = { place: 'copilot' };
  reportName = 'fin_copilot_report';
  reportContext = 'fin';

  activate() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: this.reportName,
      context: this.reportContext,
    });
  }

  beforeModel() {
    if (!this.appService.app.hasCopilotReporting) {
      this.router.transitionTo('apps.app.reports');
    }
  }

  async model(_params: any, _transition: Transition) {
    let settings = this._fetchReportingSetting();
    let conversationAttributeDescriptors = ConversationAttributeDescriptor.peekAllAndMaybeLoad();
    let metricsSetup = taskFor(this.reportingMetrics.setup).perform();

    if (this.appService.app.canUseFinAiCopilotAddon) {
      await this.copilotUsageService.refreshCopilotUsageData();
    }

    let showRoiCalculatorArticle = () => {
      window.Intercom('showArticle', 9447924);
    };

    return hash({
      settings,
      conversationAttributeDescriptors,
      metricsSetup,
      showRoiCalculatorArticle,
    });
  }

  _fetchReportingSetting() {
    return (
      this.store.peekAll('reporting/settings')?.firstObject ||
      this.store.queryRecord('reporting/settings', {})
    );
  }
}
