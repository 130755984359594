/* RESPONSIBLE TEAM: team-customer-data-platform */
import ProductSettingsRoute from 'embercom/routes/apps/app/settings/base/product-settings-route';
import { type Model as ParentModel } from 'embercom/routes/apps/app/settings/data/imports-exports';
import { inject as service } from '@ember/service';
import type ImportConfigurationService from 'embercom/services/data/import-configuration-service';
import type Store from '@ember-data/store';
import { ZendeskContextLoader } from 'embercom/lib/apps/app/new-settings/data/imports-exports/import-zendesk/zendesk-context-loader';
import { hash } from 'rsvp';
import type Session from 'embercom/services/session';

export default class ImportsExportsIndexRoute extends ProductSettingsRoute {
  @service declare session: Session;
  @service declare appService: $TSFixMe;
  @service declare store: Store;
  @service('data/import-configuration-service')
  declare importConfigurationService: ImportConfigurationService;

  analytics = {
    section: 'settings',
    place: 'import-zendesk',
  };

  async model(): Promise<ParentModel> {
    let parentModel = this.modelFor('apps.app.settings.data.imports-exports') as ParentModel;
    return await hash({
      importZendesk: this.importZendesk(parentModel.importZendesk.enabled),
    });
  }

  redirect(model: ParentModel) {
    if (!this.canRenderPage(model)) {
      this.transitionTo('apps.app.settings.data.imports-exports.index');
    }
  }

  private canRenderPage(model: ParentModel) {
    return this.appService.app.canImportFromZendeskNewUi && model.importZendesk.enabled;
  }

  async importZendesk(canMigrateFromZendesk: boolean) {
    if (!canMigrateFromZendesk) {
      return {
        enabled: false,
        context: undefined,
      };
    }
    let loader = new ZendeskContextLoader(this.session.workspace.id);
    await loader.load();
    await this.store.findAll('inbox/ticket-type');
    await this.importConfigurationService.loadConfiguration('migrate-from-zendesk');
    return {
      enabled: true,
      context: loader.result,
    };
  }
}
