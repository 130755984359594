/* RESPONSIBLE TEAM: team-workflows */
import WorkflowsBaseRoute from 'embercom/routes/apps/app/settings/inbox-rules/workflows-base';
import { inject as service } from '@ember/service';

export default class WorkflowsNewRoute extends WorkflowsBaseRoute {
  @service intercomEventService;
  @service appService;
  @service intl;
  @service store;

  beforeModel(transition) {
    super.beforeModel(...arguments);

    this.store.findAll('inbox/ticket-type');

    if (this.appService.app.canUseFeature('disable-inbox-rules')) {
      this.transitionTo('apps.app.automation.workflows-overview');
    }
  }

  model() {
    let createParams = {
      name: this.intl.t('settings.inbox-rules.new.new-rule-name'),
      triggerTypes: ['inbound_conversation_started'],
    };

    return this.store.createRecord('workflow', createParams).save();
  }

  afterModel(model, _transition) {
    this.intercomEventService.trackEvent('workflows', {
      action: 'created',
      object: 'inbox_rule',
      place: 'inbox_automation',
      section: 'respond',
      rule_id: model.id,
    });
    let queryParams = { mode: 'create' };
    this.replaceWith('apps.app.settings.inbox-rules.edit', model.id, { queryParams });
  }
}
