/* RESPONSIBLE TEAM: team-knowledge-interop */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';

export default class FinContentReviewsRoute extends IntercomRoute {
  @service declare router: RouterService;

  redirect() {
    this.router.replaceWith('apps.app.fin-ai-agent.content-suggestions');
  }
}
