/* RESPONSIBLE TEAM: team-proactive-support */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { hash } from 'rsvp';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';
import {
  FilterSet,
  convertFilterPredicatesToFilterSet,
} from 'embercom/lib/reporting/flexible/filter-helpers';

export default class ExportRoute extends IntercomRoute {
  @service appService;
  @service reportingService;
  @service intl;
  @service store;

  reportName = 'inbox_export_report';
  get titleToken() {
    return this.intl.t('apps.app.reports.customer-support.export.title-token');
  }
  analytics = {
    place: 'export',
  };
  queryParams = {
    scheduleId: {
      refreshModel: true,
    },
  };

  get app() {
    return this.appService.app;
  }

  async model(params) {
    let conversationAttributeDescriptors = [];
    if (this.app.canUseFeature('conversation_attributes')) {
      conversationAttributeDescriptors =
        await ConversationAttributeDescriptor.peekAllAndMaybeLoad();
    }

    let schedules = await this.store.findAll('reporting/message-data-export-schedule');

    let modelHash = {
      conversationAttributeDescriptors,
      schedules,
    };

    if (params.scheduleId) {
      let scheduleToEdit = await this.store.peekRecord(
        'reporting/message-data-export-schedule',
        params.scheduleId,
      );

      if (!scheduleToEdit) {
        return this.transitionTo('apps.app.reports.customer-support.export', {
          queryParams: { scheduleId: null },
        });
      }

      this.reportingService.range.setDefaultRange(scheduleToEdit.rangeSetting);
      modelHash.selectedColumns =
        scheduleToEdit.columnsToInclude instanceof Array
          ? scheduleToEdit.columnsToInclude.join(',')
          : scheduleToEdit.columnsToInclude.split(',');
      modelHash.filterSet = new FilterSet();
      let filterPredicates = JSON.parse(scheduleToEdit.filterPredicates);
      convertFilterPredicatesToFilterSet(filterPredicates, modelHash.filterSet);
      modelHash.scheduleToEdit = scheduleToEdit;
      modelHash.initialFiltersAddedDirty = true;
    } else {
      modelHash.scheduleToEdit = this.store.createRecord('reporting/message-data-export-schedule', {
        timeToExport: 0,
      });
    }

    return hash(modelHash);
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    controller.setProperties({
      reportName: this.reportName,
      range: this.reportingService.range,
      ...model,
    });
  }

  @action
  willTransition() {
    if (this.controller.scheduleToEdit) {
      this.controller.scheduleToEdit.rollbackAttributes();
    }
  }
}
