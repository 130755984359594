/* RESPONSIBLE TEAM: team-phone */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controller-access-in-routes */
/* eslint-disable ember/no-classic-classes */
import EmberObject from '@ember/object';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { task } from 'ember-concurrency';

export default Route.extend({
  conversationFeedService: service(),

  model() {
    this.set('admin', this.modelFor('apps.app.admins.admin'));
    this.set('app', this.modelFor('apps.app'));
    return this.conversationFeedService.getConversationsPage(this._searchOptions());
  },

  setupController(controller, model) {
    controller.set('model', model.conversations);
  },

  _searchOptions() {
    let appId = this.get('app.id');
    let adminId = this.get('admin.id');

    return EmberObject.create({ appId, adminId });
  },

  fetchPage: task(function* (searchOptions) {
    try {
      let result = yield this.conversationFeedService.paginate(searchOptions);

      this.controller.setProperties({
        conversations: result.conversations,
        hasMoreConversationsToLoad: result.hasMoreConversationsToLoad,
      });
    } catch (e) {
      // no handling
    } finally {
      this.controller.set('isPaginating', false);
    }
  }).drop(),

  actions: {
    paginate() {
      if (this.controller.get('hasMoreConversationsToLoad')) {
        this.controller.set('isPaginating', true);
        let searchOptions = this._searchOptions();
        searchOptions.set('pageFrom', this.controller.get('conversations.lastObject.updated_at'));
        searchOptions.set('currentConversations', this.controller.get('conversations'));

        return this.fetchPage.perform(searchOptions);
      }
    },

    loading(transition) {
      if (!this.controller) {
        return;
      }

      this.controller.set('currentlyLoading', true);
      transition.promise.finally(() => {
        this.controller.set('currentlyLoading', false);
      });
    },
  },
});
