/* RESPONSIBLE TEAM: team-data-interop */
import AppstoreAppPackageBaseRoute from 'embercom/routes/base/appstore/app-package';
import { inject as service } from '@ember/service';

export default AppstoreAppPackageBaseRoute.extend({
  appstoreMetaTagsService: service(),

  headTags() {
    let appPackage = this.modelFor(this.routeName);
    return this.appstoreMetaTagsService.getMetaTags({ appPackage });
  },
});
