/* RESPONSIBLE TEAM: team-proactive-support */
import ManageMessagesSettingsRoute from 'embercom/routes/apps/app/settings/base/manage-messages-settings-route';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import { resolve } from 'rsvp';
import type RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';

export default class EmailEditTemplateRoute extends ManageMessagesSettingsRoute {
  @service declare store: Store;
  @service declare navbarCollapsingService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intercomConfirmService: IntercomConfirmService;
  @service declare router: RouterService;
  @service declare intl: IntlService;

  get getCurrentModel(): any {
    return this.modelFor(this.routeName);
  }

  async model(params: any) {
    try {
      let emailTemplate = await this.store.findRecord('email-template', params.id);
      return resolve(emailTemplate);
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('new-settings.channels.email.edit-template.no-template-found-error'),
      );
      this.router.transitionTo('apps.app.settings.channels.email.index', {
        queryParams: {
          tab: 'customisation',
        },
      });
    }
  }

  async showDiscardChangeWarning(transition: any) {
    let confirmOptions = {
      title: this.intl.t('new-settings.channels.email.edit-template.discard-changes-confirm.title'),
      body: this.intl.t('new-settings.channels.email.edit-template.discard-changes-confirm.body'),
      confirmButtonText: this.intl.t(
        'new-settings.channels.email.edit-template.discard-changes-confirm.confirm-button',
      ),
    };
    let confirm = await this.intercomConfirmService.confirm(confirmOptions);
    if (confirm) {
      this.getCurrentModel.rollbackAttributes();
      transition.retry();
    }
  }

  @action
  willTransition(transition: any) {
    if (this.getCurrentModel.isDirtyIgnoringWhitespace) {
      this.showDiscardChangeWarning(transition);
      transition.abort();
    }
  }

  @action
  activate() {
    if (!this.navbarCollapsingService.collapsed) {
      this.navbarCollapsingService.toggleNavbarCollapsed();
    }
  }
}
