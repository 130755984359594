/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-jquery */
import { inject as service } from '@ember/service';
import $ from 'jquery';
import Route from '@ember/routing/route';
import { hash } from 'rsvp';

export default Route.extend({
  store: service(),
  activate() {
    $('body').addClass('responsive');
  },
  model(params) {
    let duration = params.duration ? this._getDurationFromString(params.duration) : '30';
    let calendar = this.store.findRecord('meeting-scheduler/calendar', params.id, {
      adapterOptions: { duration },
    });
    return hash({
      calendar,
      duration,
    });
  },
  afterModel(model) {
    this.set('title', `Meet with ${model.calendar.get('teammate.firstName')}`);
  },
  _getDurationFromString(durationString) {
    return durationString.replace('min', '');
  },
});
