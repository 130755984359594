/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import { isValidEmail } from 'embercom/lib/email';
import ManageTeammatesRoute from 'embercom/routes/apps/app/settings/base/manage-teammates-route';
import type Transition from '@ember/routing/transition';

export default class CsvImportRoute extends ManageTeammatesRoute {
  @service declare appService: $TSFixMe;

  analytics = {
    section: 'settings',
    place: 'teammate-invite-csv-import',
  };

  beforeModel(transition: Transition) {
    if (!transition.data.parsedCsv) {
      this.transitionTo('apps.app.settings.workspace.teammates');
    }
  }

  model(_params: $TSFixMe, transition: Transition) {
    return {
      columns: this._columnsFromParsedCsv(transition.data.parsedCsv),
      hasHeaderRow: this._guessHasHeaderRow(transition.data.parsedCsv),
      selectedIndex: this._guessEmailAddressColumnIndex(transition.data.parsedCsv),
    };
  }

  _columnsFromParsedCsv(parsedCsv: $TSFixMe) {
    // convert rows of columns to columns of rows
    let rows = parsedCsv.data;
    let columns = new Array(rows[0].length);
    for (let col = 0; col < columns.length; col++) {
      columns[col] = new Array(rows.length);
      for (let row = 0; row < columns[col].length; row++) {
        columns[col][row] = rows[row][col];
      }
    }
    return columns;
  }

  _guessHasHeaderRow(parsedCsv: $TSFixMe) {
    let firstRow = parsedCsv.data[0];
    if (firstRow && firstRow.length > 0) {
      return firstRow.find((item: $TSFixMe) => isValidEmail(item)) === undefined;
    } else {
      return false;
    }
  }

  _guessEmailAddressColumnIndex(parsedCsv: $TSFixMe) {
    let headerRow = parsedCsv.data[0];
    let columnNames = headerRow.map((n: string) => n.toLowerCase());
    let emailColumnName =
      columnNames.find((n: string) => n === 'email') ||
      columnNames.find((n: string) => n.startsWith('email')) ||
      columnNames.find((n: string) => n.includes('email'));
    if (emailColumnName) {
      return columnNames.indexOf(emailColumnName);
    }

    let sampleRow = parsedCsv.data.length > 1 ? parsedCsv.data[1] : parsedCsv.data[0];
    let firstPossibleEmail = sampleRow.findIndex((value: $TSFixMe) => isValidEmail(value));
    if (firstPossibleEmail !== -1) {
      return firstPossibleEmail;
    }
    return null;
  }
}
