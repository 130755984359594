/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-controller-access-in-routes */
import { readOnly } from '@ember/object/computed';
import { computed } from '@ember/object';
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { stats } from 'embercom/models/data/stats-system/stats-constants';
import { isBlank } from '@ember/utils';

export default IntercomRoute.extend({
  store: service(),
  appService: service(),
  intercomEventService: service(),
  onboardingHomeExternalStepService: service(),
  analytics: {
    section: 'tours',
    place: 'tours',
  },
  app: readOnly('appService.app'),
  queryParams: {
    state: {
      refreshModel: false,
      default: 'all',
    },
  },

  titleToken: computed('controller.state', function () {
    if (this.controller.state === 'published') {
      return 'Published Product Tours';
    } else if (this.controller.state === 'draft') {
      return 'Draft Product Tours';
    } else {
      return 'All Product Tours';
    }
  }),

  model(params) {
    let queryParams = {
      object_type: objectTypes.tour,
      stats: [stats.receipt, stats.completion, stats.goalSuccess, stats.tourStepFailure],
      include_unique_stat_count: true,
    };

    if (isBlank(params.state)) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'viewed',
        object: 'all_tours',
      });
    }

    let templates = this.store.findAll('tour-template');
    this.store.findAll('account');
    return this.store.query('matching-system/ruleset', queryParams).then((rulesets) => {
      return { rulesets, templates };
    });
  },
});
