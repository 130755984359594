/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import { on } from '@ember/object/evented';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
  loadingIndicatorService: service(),
  globalLoadingOn: on('activate', function () {
    this.loadingIndicatorService.on();
  }),
  globalLoadingOff: on('deactivate', function () {
    this.loadingIndicatorService.off();
  }),
});
