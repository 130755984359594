/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import OutboundBaseListRoute from 'embercom/lib/outbound/base-list-route';
import {
  objectTypes,
  matchBehaviors,
} from 'embercom/models/data/matching-system/matching-constants';
import { inject as service } from '@ember/service';

export default class AppsAppOutboundCarouselIndexRoute extends OutboundBaseListRoute {
  @service appService;
  @service outboundHomeService;

  beforeModel() {
    return this.redirectToAllOutboundSearch();
  }

  titleToken = 'Mobile Carousels';
  analytics = {
    section: 'outbound',
    place: 'carousel',
  };

  objectTypes = [objectTypes.carousel];
  matchBehavior = matchBehaviors.single;
}
