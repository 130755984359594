/* RESPONSIBLE TEAM: team-purchase-experience */
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { task } from 'ember-concurrency-decorators';
import { INCLUDED_TIERS_FROM_PRICING_ENDPOINT } from 'embercom/lib/billing';
import type RouterService from '@ember/routing/router-service';
import type IntlService from 'embercom/services/intl';
import { taskFor } from 'ember-concurrency-ts';

export default class IntershopRoute extends Route {
  @service declare appService: any;
  @service declare customerService: any;
  @service declare router: RouterService;
  @service declare intl: IntlService;

  analytics = {
    section: 'intershop',
  };

  get titleToken(): string {
    return this.intl.t('intershop.title');
  }

  get app(): any {
    return this.appService.app;
  }

  get products(): any {
    return this.app.products;
  }

  redirect(): any {
    if (!this.app.canAccessIntershop) {
      return this.router.transitionTo('apps.app');
    }
  }

  model() {
    return this.products;
  }

  afterModel() {
    return taskFor(this.loadData).perform();
  }

  @task *loadData() {
    yield this.customerService.ensureDataIsLoaded.perform({ fetchPrices: false });
    let planIds = this.customerService.billableCustomerPlanIds;

    if (planIds.length === 0) {
      return;
    }

    let pricesLoaded = this.customerService.isPriceLoaded(
      planIds,
      this.customerService?.currentPricingModelIdentifier,
    );

    if (pricesLoaded) {
      return;
    }

    yield this.customerService.bulkLoadPrices([
      {
        planIds,
        includeTiers: INCLUDED_TIERS_FROM_PRICING_ENDPOINT,
        includePlanCombinationValidation: true,
        source: 'intershop-route',
      },
    ]);
  }
}
