/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import ReportBase from 'embercom/routes/apps/app/reports/base/report';

export default ReportBase.extend({
  reportName: 'leads_report',
  titleToken: 'Leads',
  analytics: {
    place: 'leads',
  },
  shouldRedirectFromParent: true,

  fetchSignals() {
    return {};
  },

  setupController(controller) {
    this._super(...arguments);
    controller.loadSalesforceIntegrationTask.perform();
  },

  beforeModel() {
    this._super(...arguments);
  },
});
