/* RESPONSIBLE TEAM: team-knowledge-interop */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import {
  KNOWLEDGE_HUB_ALLOWED_URL_PARAM_CONTENT_TYPES,
  KNOWLEDGE_HUB_CONTENT_TYPES_TO_DATA_STORES,
} from 'embercom/lib/knowledge-hub/constants';
import { type Router } from '@ember/routing';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';
import { EntityType } from 'embercom/models/data/entity-types';
import type KnowledgeHubEditorService from 'embercom/services/knowledge-hub-editor-service';
import { action } from '@ember/object';
import type Transition from '@ember/routing/-private/transition';
import { ContentAction } from 'embercom/objects/knowledge-hub/localized-knowledge-content';

type ModelParams = {
  content_id: number;
  content_type: string;
};

export default class Edit extends Route {
  @service declare notificationsService: any;
  @service declare router: Router;
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare knowledgeHubEditorService: KnowledgeHubEditorService;
  @service declare permissionsService: any;
  @service declare appService: any;

  async model(params: ModelParams, transition: Transition) {
    if (KNOWLEDGE_HUB_ALLOWED_URL_PARAM_CONTENT_TYPES.includes(params.content_type)) {
      let record = await this.store.findRecord(
        KNOWLEDGE_HUB_CONTENT_TYPES_TO_DATA_STORES[params.content_type],
        params.content_id,
        { reload: true },
      );

      await record.loadRelatedContent(ContentAction.EDIT);

      this.permissionsService.ensurePermissionWhenTransitioning(
        record.requiredEditPermissionForKnowledgeHub,
        transition,
        'apps.app.knowledge-hub.all-content',
      );

      this.knowledgeHubEditorService.registerActiveContent(record);
      return record;
    } else {
      return null;
    }
  }

  afterModel(model: any) {
    if (!model) {
      this.notificationsService.notifyError(
        this.intl.t('knowledge-hub.errors.view.unknown-content-type'),
      );
      this.router.transitionTo('apps.app.knowledge-hub.all-content');
    } else if (this.modelIsSynced(model)) {
      let errorKey =
        model.entityType === EntityType.InternalArticle
          ? 'synced-internal-article'
          : 'synced-public-article';
      this.notificationsService.notifyError(this.intl.t(`knowledge-hub.errors.edit.${errorKey}`));
      this.router.transitionTo('apps.app.knowledge-hub.view', model.entityName, model.id);
    } else if (model.entityType === EntityType.ArticleContent) {
      model.rollbackAttributes(); // Article content has a weird behaviour with autosave where it ends up having dirty attributes due to the overriding of the `save` method
    }
  }

  private modelIsSynced(model: any): boolean {
    return (
      (model.entityType === EntityType.InternalArticle && model.isSynced) ||
      (model.entityType === EntityType.ArticleContent && !model.isEditable)
    );
  }

  @action
  willTransition(): void {
    this.knowledgeHubEditorService.registerActiveContent(undefined);
  }
}
