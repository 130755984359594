/* RESPONSIBLE TEAM: team-messenger */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { hash } from 'ember-concurrency';
import type MessengerSettingsService from 'embercom/services/messenger-settings-service';
import type MessengerSettingsTargetUserProvider from 'embercom/services/messenger-settings-target-user-provider';

export default class Messenger extends Route {
  @service declare intercomEventService: any;
  @service declare appService: any;
  @service declare router: any;
  @service declare permissionsService: any;
  @service declare messengerSettingsTargetUserProvider: MessengerSettingsTargetUserProvider;
  @service declare intl: any;
  @service declare helpCenterService: any;
  @service declare messengerSettingsService: MessengerSettingsService;
  @service declare store: any;

  analytics = {
    section: 'messenger_settings',
    messenger_version: '5',
  };

  queryParams = {
    product_tour_id: {
      refreshModel: false,
      replace: true,
    },
  };

  buildRouteInfoMetadata() {
    return {
      responsibleTeam: 'team-messenger',
    };
  }

  beforeModel(transition: any) {
    this.permissionsService.ensurePermissionWhenTransitioning(
      'can_manage_messenger_settings',
      transition,
    );
    this.intl.maybeLoadMessengerTranslations();
  }

  activate() {
    this.router.on('routeWillChange', this, 'warnIfHasUnsavedChanges');

    this.intercomEventService.trackAnalyticsEvent({
      action: 'visited',
      object: 'new_messenger_settings',
      place: 'new_messenger_settings',
    });

    this.intercomEventService.trackEvent('has_visited_messenger_settings');
  }

  deactivate() {
    this.router.off('routeWillChange', this, 'warnIfHasUnsavedChanges');
  }

  async model(transition: any = {}) {
    let app = this.appService.app;

    let messengerApps = this.store.peekAll('messenger-app');
    if (!messengerApps || !messengerApps.length) {
      messengerApps = this.store.query('messenger-app', {
        restrict_to: 'messenger_home',
      });
    }

    let model = await hash({
      helpCenterSite: this.helpCenterService.maybeFetchSite(),
      settings:
        this.store.peekRecord('messenger-settings/all', app.id) ||
        this.store.findRecord('messenger-settings/all', app.id),
      messengerApps,
      identity:
        this.store.peekRecord('operator-identity', '1') ||
        this.store.findRecord('operator-identity', '1'),
    });

    this.messengerSettingsService.settings = model.settings;
    this.messengerSettingsTargetUserProvider.settings = model.settings;

    if (transition && transition.tab) {
      let { tab, section } = transition;
      this.messengerSettingsService.navigate(tab, section, { changeUrl: false });
    }

    return model;
  }

  redirect(_model: any, transition: any) {
    if (!this.appService.app.canUseNewMessengerSettings) {
      if (transition && transition.tab) {
        let { tab, section } = transition;
        this.router.transitionTo('apps.app.messenger.web', {
          queryParams: {
            tab,
            section,
          },
        });
      } else {
        this.router.transitionTo('apps.app.messenger.web', {
          queryParams: { section: 'layout-and-spaces', tab: 'content' },
        });
      }
    }
  }

  warnIfHasUnsavedChanges(transition: any) {
    let settings = (this.modelFor(this.routeName) as any)?.settings;

    if (!transition.isAborted && transition.from.name !== transition.to.name) {
      if (this.router.currentRouteName !== transition.to.name && settings?.hasUnsavedChanges) {
        if (window.confirm(this.intl.t('messenger.settings.unsaved-changes-warning'))) {
          settings.refresh();
        } else {
          transition.abort();
        }
      }
    }
  }

  resetController(controller: any, isExiting: boolean) {
    if (isExiting) {
      this.messengerSettingsService.refresh();
      controller.set('tab', this.messengerSettingsService.selectedTab);
      controller.set('section', this.messengerSettingsService.openSectionId);
      controller.set('product_tour_id', null);
    }
  }
}
