/* RESPONSIBLE TEAM: team-ai-agent */
import { inject as service } from '@ember/service';
import IntercomRoute from 'embercom/routes/base/intercom-route';

export default class AnswerClustersRoute extends IntercomRoute {
  @service router;

  redirect() {
    this.router.replaceWith('apps.app.fin-ai-agent.custom-answers.unanswered-questions');
  }
}
