/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';

export default class CustomBotsRoute extends Route {
  @service store;
  @service intercomEventService;
  @service contentImportService;

  titleToken = 'Workflows';

  analytics = {
    place: 'custom-bots',
    section: 'operator',
  };

  beforeModel() {
    this.intercomEventService.trackEvent('visited-custom-bots', {
      onMatchingSystem: true,
    });
  }

  async model() {
    let [descriptors, customObjects, qualificationAttributes, messengerApps] = await Promise.all([
      this.store.findAll('conversation-attributes/descriptor'),
      this.store.findAll('custom-objects/object-type'),
      this.store.findAll('people/qualification-attribute'),
      this.store.findAll('messenger-app'),
      this.store.findAll('workflow-connector/insertable-action'),
      this.store.findAll('office-hours-schedule'),
      this.store.findAll('conversational-insights/conversation-topic'),
      this.store.findAll('inbox/ticket-type'),
      this.store.findAll('instagram/integration').catch(() => {
        this.notificationsService.notifyError('Error loading Instagram business accounts');
      }),
      this.store.query('whatsapp/integration', { no_external_updates: true }),
    ]);

    return hash({
      descriptors,
      customObjects,
      qualificationAttributes,
      messengerApps,
    });
  }
}
