/* RESPONSIBLE TEAM: team-help-desk-experience */
import { inject as service } from '@ember/service';
import SettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';

export default SettingsRoute.extend({
  permissionsService: service(),
  intercomEventService: service(),
  beforeModel(transition) {
    this._super(...arguments);
    this.permissionsService.ensurePermissionWhenTransitioning(
      'can_access_product_settings',
      transition,
    );
  },
});
