/* RESPONSIBLE TEAM: team-help-desk-experience */
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import type RealtimeUsersService from 'embercom/services/realtime-users';
import type Session from 'embercom/services/session';
import moment from 'moment-timezone';
import type RouteInfo from '@ember/routing/-private/route-info';
import storage from 'embercom/vendor/intercom/storage';
import type Transition from '@ember/routing/transition';
import type CopilotApi from 'embercom/services/copilot-api';

const INBOX_CONVERSATION_SHOW_ROUTE = 'inbox.workspace.inbox.inbox.conversation.conversation';
const CONVERSATION_SHOW_ROUTE = 'inbox.workspace.inbox.conversation.conversation';
const INBOX_LIST_ROUTE = 'inbox.workspace.inbox.inbox';
const FEED_CONVERSATION_ROUTE = 'apps.app.feed.conversation';

export default class InboxRoute extends Route {
  @service declare session: Session;
  @service declare realtimeUsers: RealtimeUsersService;
  @service declare inboxSidebarService: InboxSidebarService;
  @service declare copilotApi: CopilotApi;

  beforeModel(transition: any) {
    if (!this.session.teammate.permissions.hasInboxAccess) {
      if (transition.to.params.conversation_id) {
        let conversationId = transition.to.params.conversation_id;
        this.replaceWith(FEED_CONVERSATION_ROUTE, this.session.workspace.id, conversationId, {
          queryParams: { redirectTo: 'feed' },
        });
      } else {
        this.replaceWith('inbox.workspace.inbox-requires-seat');
      }
      return;
    }

    if (this.session.showLightInbox) {
      if (transition.to.name === INBOX_CONVERSATION_SHOW_ROUTE) {
        if (!this.isTargetedToMentionsInbox(transition)) {
          this.replaceWith(
            INBOX_CONVERSATION_SHOW_ROUTE,
            InboxCategory.Shared,
            InboxType.Mentions,
            transition.to.params.conversation_id,
          );
        }
      } else if (transition.to.name === CONVERSATION_SHOW_ROUTE) {
        // Do nothing, allow route
      } else {
        this.replaceWith('inbox.workspace.inbox.inbox', InboxCategory.Shared, InboxType.Mentions);
      }
    }
  }

  activate() {
    // When displaying relative time in seconds in the inbox, show the exact number of seconds if >= 10 seconds ago.
    // The default behaviour is to show this after 45 seconds, but this is not granular enough for showing remaining time
    // on Inbox SLAs.
    storage.set('lastProductUsed', 'inbox');
    moment.relativeTimeThreshold('ss', 10);
    this.realtimeUsers.beginTracking();
  }

  setupController(controller: any, model: unknown, transition: Transition) {
    super.setupController(controller, model, transition);

    if (controller.openCopilot === 'true') {
      this.inboxSidebarService.updateActiveTab('copilot');
      // Immediately reset this so that it's not sticky and persisted in the URL
      controller.openCopilot = undefined;
    }
  }

  @action
  queryParamsDidChange(changed: Record<string, any>) {
    // queryParamsDidChange can be called before the app is booted, and we don't
    // have the workspace or the sidebar service setup at that point, so we
    // cannot do anything.
    if (!this.session.isWorkspaceLoaded) {
      return;
    }

    if ('view' in changed) {
      this.inboxSidebarService.resetDefaults();
    }
  }

  private isTargetedToMentionsInbox(transition: any) {
    let inboxRoute = transition.to.find(
      (routeInfo: RouteInfo) => routeInfo.name === INBOX_LIST_ROUTE,
    );

    return (
      inboxRoute.params.inbox_category === InboxCategory.Shared &&
      inboxRoute.params.inbox_id === InboxType.Mentions
    );
  }
}
