/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';

export default IntercomRoute.extend({
  store: service(),
  developerHub: service(),
  intl: service(),
  intercomConfirmService: service(),
  beforeModel() {
    let webhooksPath = 'appPackage.webhookDefinition';
    this.developerHub.fillEmptyModel(
      webhooksPath,
      this.store.createFragment('developer-hub/webhook-definition'),
    );
  },

  model() {
    return hash({
      topics: this.modelFor('apps/app/developer-hub/app-packages/app-package/webhooks'),
    });
  },

  actions: {
    async willTransition(transition) {
      if (this.get('developerHub.appPackage').hasDirtyAttributes) {
        transition.abort();

        if (
          await this.intercomConfirmService.confirm({
            title: this.intl.t(
              'apps.app.developer-hub.app-packages.app-package.webhooks.edit.close-without-saving-modal.title',
            ),
            primaryButtonType: 'primary-destructive',
            confirmButtonText: this.intl.t(
              'apps.app.developer-hub.app-packages.app-package.webhooks.edit.close-without-saving-modal.close-without-saving',
            ),
            cancelButtonText: this.intl.t(
              'apps.app.developer-hub.app-packages.app-package.webhooks.edit.close-without-saving-modal.keep-editing',
            ),
            body: this.intl.t(
              'apps.app.developer-hub.app-packages.app-package.webhooks.edit.close-without-saving-modal.message-body',
            ),
          })
        ) {
          this.get('developerHub.appPackage').rollbackAttributes();
          await transition.retry();
        } else if (transition.intent.name !== transition.from.name) {
          this.replaceWith(transition.from.name);
        }
      }
    },
  },
});
