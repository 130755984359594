/* RESPONSIBLE TEAM: team-knowledge-interop */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import KNOWLEDGE_BLANK_QUERY_PARAMS from 'embercom/lib/knowledge-hub/list-api';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
const REDIRECTION_TO_KH_CONTENT_PAGE = 'redirectionToKHContentPage';

export default class KnowledgeHubIndexRoute extends Route {
  @service declare router: RouterService;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare appService: $TSFixMe;
  @service declare helpCenterService: $TSFixMe;

  private async canRedirectToAllContent() {
    if (localStorage.getItem(REDIRECTION_TO_KH_CONTENT_PAGE) === 'true') {
      return true;
    }
    if (!this.knowledgeHubService.overviewChecklistAttributes) {
      return false;
    }
    if (
      (this.knowledgeHubService.overviewChecklistAttributes.ai_agent_enabled &&
        this.knowledgeHubService.hasAddedMinimunRequiredSources('agent')) ||
      (this.knowledgeHubService.overviewChecklistAttributes.used_ai_copilot &&
        this.knowledgeHubService.hasAddedMinimunRequiredSources('copilot')) ||
      this.helpCenterService.allLiveSites.length > 0
    ) {
      localStorage.setItem(REDIRECTION_TO_KH_CONTENT_PAGE, 'true');
      return true;
    }
    return false;
  }

  async beforeModel() {
    if (
      !this.appService.app.canUseCustomRedirectionInKnowledgeHub ||
      !(await this.canRedirectToAllContent())
    ) {
      this.router.transitionTo('apps.app.knowledge-hub.overview');
    } else {
      this.router.transitionTo('apps.app.knowledge-hub.all-content', {
        queryParams: KNOWLEDGE_BLANK_QUERY_PARAMS,
      });
    }
  }
}
