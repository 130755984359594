/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-controller-access-in-routes */
/* eslint-disable ember/no-classic-classes */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { PAGE_SIZE, WILDCARD_TITLE_SEARCH } from 'embercom/models/data/outbound/constants';
import { hash } from 'rsvp';
import { statisticKeys } from 'embercom/models/data/outbound/constants';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';

export default Route.extend({
  titleToken: 'Messages',
  intercomEventService: service(),
  outboundHomeService: service(),
  appService: service(),
  app: readOnly('appService.app'),

  allObjectTypes: readOnly('outboundHomeService.allObjectTypes'),
  objectTypes: computed('allObjectTypes', function () {
    return this.allObjectTypes.concat([objectTypes.series]);
  }),
  statistics: [statisticKeys.receipts, statisticKeys.goals],

  activate() {
    this._super(...arguments);
    this.outboundHomeService.setActiveList(null);
    this.intercomEventService.trackEvent('outbound-list-view', {
      route: this.routeName,
    });
    this.controllerFor('apps/app/outbound').set('showSearch', true);
  },

  deactivate() {
    this._super(...arguments);
    this.controllerFor('apps/app/outbound').set('showSearch', false);
    let searchController = this.controllerFor('apps/app/outbound/search');
    if (!(searchController.isDestroying || searchController.isDestroyed)) {
      searchController.send('resetSearch');
    }
  },

  async model(params) {
    let response = {};

    if (params.searchString) {
      response = await this.outboundHomeService.contentSearch({
        app_id: this.app.id,
        object_types: this.objectTypes,
        per_page: PAGE_SIZE,
        page_from: 0,
        title: params.searchString,
        statistics: this.statistics,
        wildcard_title_search: WILDCARD_TITLE_SEARCH,
      });
    }

    return hash({
      response,
      searchString: params.searchString,
      type: params.type,
    });
  },

  setupController(controller, { response, searchString, type }) {
    controller.setProperties({
      searchResults: response.contentWrappers,
      pageFrom: response.pageFrom,
      perPage: response.perPage,
      totalCount: response.totalCount,
      totalPages: response.totalPages,
      lastPageHit: response.lastPageHit,
      searchString,
      type,
      objectTypes: this.objectTypes,
      statistics: this.statistics,
    });
  },
});
