/* RESPONSIBLE TEAM: team-proactive-support */
import OutboundBaseListRoute from 'embercom/lib/outbound/base-list-route';
import {
  objectTypes,
  matchBehaviors,
} from 'embercom/models/data/matching-system/matching-constants';
import { inject as service } from '@ember/service';

export default class AppsAppOutboundWhatsappIndexRoute extends OutboundBaseListRoute {
  @service appService;
  @service outboundHomeService;
  @service intercomEventService;

  beforeModel() {
    this.outboundHomeService.setActiveList(objectTypes.whatsapp);
  }

  activate() {
    this.intercomEventService.trackEvent('whatsapp-home-viewed');
  }

  titleToken = 'WhatsApp';
  analytics = {
    section: 'outbound',
    place: 'whatsapp',
  };

  objectTypes = [objectTypes.whatsapp];
  matchBehavior = matchBehaviors.single;
}
