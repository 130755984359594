/* RESPONSIBLE TEAM: team-proactive-support */
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/addon/services/intl';
import Route from '@ember/routing/route';

export default class NewslabelsIndexRoute extends Route {
  @service declare intl: IntlService;

  titleToken() {
    return this.intl.t('new-settings.submenu.proactive-support.news-labels');
  }
}
