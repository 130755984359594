/* RESPONSIBLE TEAM: team-customer-data-platform */
import type Store from '@ember-data/store';
import type Transition from '@ember/routing/transition';
import { inject as service } from '@ember/service';
import type IndexController from 'embercom/controllers/apps/app/settings/data/imports-exports/index';
import ProductSettingsRoute from 'embercom/routes/apps/app/settings/base/product-settings-route';
import { hash } from 'rsvp';
import { type Model as ParentModel } from 'embercom/routes/apps/app/settings/data/imports-exports';
import { ZendeskContextLoader } from 'embercom/lib/apps/app/new-settings/data/imports-exports/import-zendesk/zendesk-context-loader';
import type Session from 'embercom/services/session';

export default class ImportsExportsIndexRoute extends ProductSettingsRoute {
  @service declare session: Session;
  @service declare store: Store;

  analytics = {
    section: 'settings',
    place: 'imports',
  };

  async model() {
    let parentModel = this.modelFor('apps.app.settings.data.imports-exports') as ParentModel;
    return await hash({
      csv: this.store.query('csv-import', { page: 1 }),
      mailchimp: this.store.query('mailchimp-import', { page: 1 }),
      mixpanel: this.store.query('mixpanel-import', { page: 1 }),
      importZendesk: this.importZendesk(parentModel.importZendesk.enabled),
    });
  }

  setupController(controller: IndexController, model: any, transition: Transition): void {
    super.setupController(controller, model, transition);
    controller.set('hasMoreCsvImports', model.csv.meta.pages_left);
    controller.set('hasMoreMailchimpImports', model.mailchimp.meta.pages_left);
    controller.set('hasMoreMixpanelImports', model.mixpanel.meta.pages_left);
    controller.set('importZendesk', model.importZendesk);
  }

  async importZendesk(canMigrateFromZendesk: boolean) {
    if (!canMigrateFromZendesk) {
      return {
        enabled: false,
        context: undefined,
      };
    }
    let loader = new ZendeskContextLoader(this.session.workspace.id);
    await loader.load();
    return {
      enabled: true,
      context: loader.result,
    };
  }
}
