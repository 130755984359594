/* RESPONSIBLE TEAM: team-standalone */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class FinStandaloneSetupRoute extends Route {
  @service declare appService: any;
  @service declare finStandaloneService: any;
  @service declare store: any;

  async beforeModel() {
    await Promise.all([
      this.store.findRecord('messenger-settings/all', this.appService.app.id),
      this.finStandaloneService.initialize(),
    ]);
  }
}
