/* RESPONSIBLE TEAM: team-proactive-support */

import ManageMessagesSettingsRoute from 'embercom/routes/apps/app/settings/base/manage-messages-settings-route';
import { inject as service } from '@ember/service';

export default class EmailCustomAssetsDomains extends ManageMessagesSettingsRoute {
  @service intl;
  @service store;

  analytics = {
    section: 'settings',
    place: 'custom_email_assets_domains',
    object: 'custom_email_assets_domains',
  };

  get app() {
    return this.modelFor('apps.app');
  }

  model() {
    return this.store.query('settings/custom-email-assets-domain', {});
  }

  get titleToken() {
    return this.intl.t('settings.email.custom-assets-domain.title-token');
  }
}
