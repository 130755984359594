/* RESPONSIBLE TEAM: team-help-desk-experience */

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import PredicateGroup from 'embercom/objects/inbox/search/predicate-group';
import type InboxApi from 'embercom/services/inbox-api';
import type InboxState from 'embercom/services/inbox-state';
import type Session from 'embercom/services/session';
import type Nexus from 'embercom/services/nexus';

export default class Inbox2LinkedConversationsRoute extends Route {
  @service declare inboxApi: InboxApi;
  @service declare inboxState: InboxState;
  @service declare session: Session;
  @service declare router: RouterService;
  @service declare nexus: Nexus;

  queryParams = {
    show_bulk_edit_modal: {},
  };

  activate() {
    this.nexus.subscribeTopics([`conversation/${this.ticketId}`]);
  }

  async model() {
    let group = new PredicateGroup([
      {
        identifier: 'linked-to-ticket',
        type: 'integer',
        attribute: 'linked_to_ticket_ids',
        comparison: 'eq',
        value: this.ticketId,
      },
    ]);

    return {
      group,
      conversation: await this.inboxApi.fetchConversation(Number(this.ticketId)),
      showBulkEditModal: this.shouldShowBulkEditModal,
    };
  }

  get ticketId() {
    let params = this.paramsFor(this.routeName) as { ticket_id: string };
    return params.ticket_id;
  }

  get shouldShowBulkEditModal() {
    let params = this.paramsFor(this.routeName);

    return ('show_bulk_edit_modal' in params &&
      params.show_bulk_edit_modal &&
      params.show_bulk_edit_modal !== 'false') as boolean;
  }

  deactivate() {
    this.inboxState.hideBulkEditModal();
    this.nexus.unsubscribeTopics([`conversation/${this.ticketId}`]);
  }
}
