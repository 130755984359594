/* RESPONSIBLE TEAM: team-purchase-experience */
import { type Router } from '@ember/routing';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class IntershopProductsRoute extends Route {
  @service declare router: Router;

  redirect() {
    this.router.transitionTo('apps.app.intershop');
  }
}
