/* RESPONSIBLE TEAM: team-help-desk-experience */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type Conversation from 'embercom/objects/inbox/conversation';
import type ConversationSummary from 'embercom/objects/inbox/conversation-summary';
import type InboxState from 'embercom/services/inbox-state';
import type InboxApi from 'embercom/services/inbox-api';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';
import { ResponseError } from 'embercom/lib/inbox/requests';
import type Transition from '@ember/routing/-private/transition';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import type Session from 'embercom/services/session';

export default class ConversationRoute extends Route {
  @service declare inboxState: InboxState;
  @service declare inboxApi: InboxApi;
  @service declare notificationsService: any;
  @service declare intl: IntlService;
  @service declare session: Session;

  async model(params: { conversation_id: number }) {
    if (!this.session.workspace.isFeatureEnabled('inbox2-single-conversation-view')) {
      this.transitionTo(
        'inbox.workspace.inbox.inbox.conversation.conversation',
        InboxCategory.Shared,
        InboxType.All,
        params.conversation_id,
      );
      return;
    }
    return await this.inboxApi.fetchConversation(params.conversation_id);
  }

  titleToken(model: Conversation) {
    if (model.isTicket) {
      return this.intl.t('inbox.page-title.ticket', { title: model.ticketTitle });
    } else {
      return this.intl.t('inbox.page-title.conversation', { userName: model.user.displayAs });
    }
  }

  serialize(conversation: Conversation | ConversationSummary) {
    return {
      conversation_id: conversation.id,
    };
  }

  async afterModel(conversation: Conversation | ConversationSummary, _transition: Transition) {
    this.inboxState.activeConversation = conversation;
  }

  deactivate(transition?: Transition) {
    // regular conversation view sets the active conversation id so we don't want to clear it
    if (transition?.to?.name === 'inbox.workspace.inbox.inbox.conversation.conversation') {
      return;
    } else {
      this.inboxState.activeConversation = undefined;
    }
  }

  @action
  error(error: Error) {
    if (error instanceof ResponseError && error.response.status === 404) {
      this.notificationsService.notifyError(
        this.intl.t('inbox.notifications.failed-loading-conversation'),
      );
      this.transitionTo('inbox.workspace.inbox.inbox', InboxCategory.Shared, InboxType.All);
      return;
    }

    return true;
  }
}
