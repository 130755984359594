/* RESPONSIBLE TEAM: team-proactive-support */
import ManageMessagesSettingsRoute from 'embercom/routes/apps/app/settings/base/manage-messages-settings-route';
import { inject as service } from '@ember/service';

export default class EmailDedicatedIpsSettings extends ManageMessagesSettingsRoute {
  @service intl;
  @service store;

  analytics = {
    section: 'settings',
    place: 'email_dedicated_ips_settings',
    object: 'email_dedicated_ips_settings',
  };

  get app() {
    return this.modelFor('apps.app');
  }

  get titleToken() {
    return this.intl.t('settings.email.dedicated-ips.title-token');
  }

  model() {
    return this.store.query('settings/dedicated-ip', {});
  }
}
