/* RESPONSIBLE TEAM: team-ai-agent */
/* eslint-disable ember/no-controller-access-in-routes */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { RESOLUTION_BOT_REQUIRED_PERMISSION } from 'embercom/lib/operator/resolution-bot/constants';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';

export default class FinAIAgentCustomAnswersAnswerEditRoute extends Route {
  @service declare intercomEventService: any;
  @service declare router: any;
  @service declare appService: any;
  @service declare permissionsService: any;
  @service declare navbarCollapsingService: any;
  @service declare intercomConfirmService: any;
  @service declare intl: IntlService;

  get app() {
    return this.appService.app;
  }

  beforeModel(transition: any) {
    this.permissionsService.ensurePermissionWhenTransitioning(
      RESOLUTION_BOT_REQUIRED_PERMISSION,
      transition,
      `${this.app.answersRoute}.answer.show`,
    );
  }

  setupController(controller: any, model: any, transition: any) {
    super.setupController(controller, model, transition);

    controller.updateSuggestedAnswerClusters();

    let queryParams = transition.to.queryParams;

    controller.set('previousShowPageAnswerId', queryParams.previousShowPageAnswerId);
    controller.set('prepopulatedSearchTerm', queryParams.prepopulatedSearchTerm);
    controller.set('expandSelectedExamples', queryParams.expandSelectedExamples);
    controller.set('initiallyOpenSectionName', queryParams.initiallyOpenSectionName);
    controller.set('clusterId', queryParams.clusterId);
    controller.set(
      'transitionedFromAnswerShowPage',
      this.router.currentRouteName === `${this.app.answersRoute}.answer.show.index`,
    );
  }

  activate() {
    window.onbeforeunload = (e: any) => {
      let controller = this.controllerFor(this.controllerName);
      let answer = controller.model as { hasUnsavedChanges?: boolean };
      if (answer && answer.hasUnsavedChanges) {
        let text = this.intl.t('operator.resolution-bot-answers.notifications.warning-text');
        e.returnValue = text;
        return text;
      }
      return;
    };

    if (this.navbarCollapsingService.collapsed === false) {
      this.navbarCollapsingService.set('collapsed', true);
    }
  }

  deactivate() {
    window.onbeforeunload = null;

    if (this.navbarCollapsingService.collapsed !== false) {
      this.navbarCollapsingService.set('collapsed', false);
    }
  }

  @action
  async willTransition(transition: any) {
    let answer = this.controller.model as {
      isDeleted?: boolean;
      hasUnsavedChanges?: boolean;
      rollbackAttributes?: () => void;
    };

    if (answer && !answer.isDeleted && answer.hasUnsavedChanges) {
      transition.abort();

      let confirmOptions = {
        title: this.intl.t('operator.resolution-bot-answers.notifications.confirm-options.title'),
        primaryButtonType: 'primary-destructive',
        confirmButtonText: this.intl.t(
          'operator.resolution-bot-answers.notifications.confirm-options.confirm-button-label',
        ),
        cancelButtonText: this.intl.t(
          'operator.resolution-bot-answers.notifications.confirm-options.cancel-button-label',
        ),
        body: this.intl.t('operator.resolution-bot-answers.notifications.confirm-options.body'),
      };
      let isConfirmed = await this.intercomConfirmService.confirm(confirmOptions);
      if (!isConfirmed) {
        return;
      }
      this.intercomEventService.trackAnalyticsEvent({
        action: 'closed_without_saving',
        object: answer,
      });
      answer.rollbackAttributes?.();
      transition.retry();
    }
  }
  analytics = {
    section: 'ai-chatbot',
    place: 'custom-answers-answer-edit',
  };
}
