/* RESPONSIBLE TEAM: team-proactive-support */
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default class ShowSelectionSetRoute extends Route {
  @service store;
  analytics = {
    section: 'messages',
    place: 'selection_set_show',
  };

  async model(params, _transition) {
    return this.store.findRecord('matching-system/selection-set', params.id);
  }
}
