/* RESPONSIBLE TEAM: team-help-desk-experience */
import Route from '@ember/routing/route';
import type NewConversationController from 'embercom/controllers/inbox/workspace/inbox/new-conversation';

export default class extends Route {
  queryParams = { recipient: {}, ticketTypeId: {}, forwardedPartId: {} };

  resetController(controller: NewConversationController, isExiting: boolean) {
    if (isExiting) {
      controller.recipient = undefined;
      controller.ticketTypeId = undefined;
      controller.forwardedPartId = undefined;
    }
  }
}
