/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import EventTracking from 'embercom/components/mixins/articles/event-tracking';
import ArticleGroup from 'embercom/models/articles/article-group';
import { CAN_MANAGE_ARTICLES_PERMISSION } from 'embercom/lib/articles/constants';
import {
  PROACTIVE_REDIRECT_MAPPING,
  routeRedirector,
} from 'embercom/lib/settings/settings-redirect-map';

export default Route.extend(EventTracking, {
  permissionsService: service(),
  store: service(),
  appService: service(),
  router: service(),

  analytics: {
    place: 'site',
  },

  async beforeModel(transition) {
    this.permissionsService.ensurePermissionWhenTransitioning(
      CAN_MANAGE_ARTICLES_PERMISSION,
      transition,
    );
    let canAccess = await this.permissionsService.currentAdminCan(CAN_MANAGE_ARTICLES_PERMISSION);
    if (
      this.appService.app.canSeeNewIASettingsHub &&
      this.appService.app.canSeeIASettingsGA &&
      canAccess
    ) {
      routeRedirector(
        transition,
        this.router,
        PROACTIVE_REDIRECT_MAPPING,
        'apps.app.settings.helpcenter.all',
      );
    }
  },

  notificationsService: service('notificationsService'),

  _saveFolder(folder) {
    return folder
      .saveIfDirty()
      .then(() => {
        this.notificationsService.notifyConfirmation("You've created a new collection");
      })
      .catch(() => {
        this.notificationsService.notifyError(
          "Something went wrong and we couldn't save the folder",
        );
        folder.rollbackAttributes();
      });
  },

  _createCollection(creatingArticle) {
    let home = this.store.peekRecord('articles/article-group', 'home');
    let folder = ArticleGroup.createNewFolder(home);
    if (creatingArticle) {
      return this._saveFolder(folder);
    }
    return this._saveFolder(folder).then(() =>
      this.transitionTo(
        'apps.app.settings.helpcenter.workspace-helpcenter.collections',
        folder.get('id'),
      ),
    );
  },

  actions: {
    createArticle(folder) {
      folder =
        folder ||
        this.store.peekRecord('articles/article-group', 'home').get('sortedSections.firstObject');
      if (!folder) {
        this._createCollection(true).then(() => this.send('createArticle'));
      } else {
        this.transitionTo('apps.app.articles.articles.new', {
          queryParams: { folder_id: folder.get('id') },
        });
      }
    },
    createSection(parentFolder) {
      let section = ArticleGroup.createNewFolder(parentFolder, 'Section heading');
      section.save().then(() => {
        this.trackEducateCollectionOrSectionEvent(section, {
          action: 'created',
          place: 'collection',
        });
      });
    },
    createCollection() {
      this._createCollection();
    },
    // TODO: remove this and rename above post redesign
    saveFolder() {
      this._saveFolder(this.folder);
    },
    saveContainerRef(htmlRef) {
      this.set('containerReference', htmlRef);
    },
    getContainerRef() {
      return this.containerReference;
    },
  },
});
