/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import {
  parsedESFilters,
  filterQueryParams,
  setRouteFilterProperties,
} from 'embercom/lib/reporting/flexible/filter-helpers';
import ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';

const EVENTED_QUERY_PARAMS_ES_MAPPINGS = {
  audience: 'user.role',
  channel: 'article_stat_metadata.article_channel',
  source: 'article_stat_metadata.article_source',
  help_center_id: 'article_stat_metadata.help_center_id',
};
const COMBINED_QUERY_PARAMS_ES_MAPPINGS = {
  audience: 'user.role',
  channel: 'channel',
  source: 'source',
  help_center_id: 'help_center_id',
};

const FILTER_TYPES = Object.keys(EVENTED_QUERY_PARAMS_ES_MAPPINGS);

const ARTICLE_CHART_ID = 'article-views-columnchart';

function mapFilters(parsedFilters) {
  parsedFilters['user.role']?.forEach((value, index) => {
    if (value === 'lead') {
      parsedFilters['user.role'][index] = 'contact_role';
    } else {
      parsedFilters['user.role'][index] = value.concat('_role');
    }
  });
  return parsedFilters;
}

export default class ArticlesFlexibleRoute extends IntercomRoute {
  @service appService;
  @service store;
  @service reportingService;
  analytics = { place: 'articles_summary' };
  reportName = 'articles_summary';
  reportContext = 'articles';
  titleToken = 'Articles';
  mergedProperties = ['queryParams'];
  queryParams = filterQueryParams(FILTER_TYPES);

  dashboardIdentifier = 'articles-summary';

  shouldRedirectFromParent = true;

  get app() {
    return this.appService.app;
  }

  get range() {
    return this.reportingService.range;
  }

  eventedESFilters() {
    return parsedESFilters(this, EVENTED_QUERY_PARAMS_ES_MAPPINGS);
  }

  combinedESFilters() {
    return mapFilters(parsedESFilters(this, COMBINED_QUERY_PARAMS_ES_MAPPINGS));
  }

  beforeModel(transition) {
    super.beforeModel(...arguments);
    setRouteFilterProperties(this, transition.to.queryParams, FILTER_TYPES);
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    let { range, reportContext, reportName, tag } = this;
    let controllerProperties = {
      rangeStart: range.start,
      rangeEnd: range.end,
      range,
      rangeParams: {
        interval: this.range.interval,
        comparisonStartMoment: this.range.comparisonStartMoment,
        comparisonEndMoment: this.range.comparisonEndMoment,
        startMoment: this.range.startMoment,
        endMoment: this.range.endMoment,
        inDays: this.range.inDays,
        timezone: this.range.timezone,
      },
      reportContext,
      reportName,
      tag,
      ...model,
    };
    controller.setProperties(controllerProperties);
    let filterProperties = {};
    FILTER_TYPES.forEach((filterType) => {
      filterProperties[filterType] = this.get(filterType);
    });
    controller.setProperties(filterProperties);
    controller.setProperties({
      eventedESFilters: this.eventedESFilters(),
      combinedESFilters: this.combinedESFilters(),
    });
  }

  async model() {
    let reportingAllSettings = await this.store.queryRecord('reporting/settings', {});

    let fetchedDataConfigs = await this.store.query('reporting/chart', {
      dashboard_identifier: this.dashboardIdentifier,
    });

    let dataConfig = fetchedDataConfigs.findBy('chart_identifier', ARTICLE_CHART_ID);
    if (!dataConfig) {
      dataConfig = this.store.createRecord('reporting/chart', {
        dashboard_identifier: this.dashboardIdentifier,
        chart_identifier: ARTICLE_CHART_ID,
        app_id: this.app.id,
      });
    }

    let conversationAttributeDescriptors = [];

    let settings = {
      reportingExcludeOooHours: reportingAllSettings.get('excludeOooHours'),
    };

    if (this.app.canUseFeature('conversation_attributes')) {
      conversationAttributeDescriptors =
        await ConversationAttributeDescriptor.peekAllAndMaybeLoad();
    }

    return hash({
      settings,
      dataConfig,
      dashboardIdentifier: this.dashboardIdentifier,
      conversationAttributeDescriptors,
    });
  }
}
