/* RESPONSIBLE TEAM: team-messenger */
import SettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';
import { inject as service } from '@ember/service';

export default class ManageMessagesSettingsRoute extends SettingsRoute {
  @service permissionsService;
  @service intl;
  getTranslation(key) {
    return this.intl.t(key);
  }
  beforeModel(transition) {
    return this.permissionsService.ensurePermissionWhenTransitioning(
      'can_manage_messages_settings',
      transition,
    );
  }
}
