/* RESPONSIBLE TEAM: team-ai-agent */
import FinAIAgentContentSuggestionsRoute from 'embercom/routes/apps/app/fin-ai-agent/content-suggestions';

export default class AutomationFinAIAgentContentSuggestionsRoute extends FinAIAgentContentSuggestionsRoute {
  analytics = {
    section: 'automation',
    place: 'fin-ai-agent.content-suggestions',
  };

  controllerName = 'apps.app.fin-ai-agent.content-suggestions';
  templateName = 'apps.app.fin-ai-agent.content-suggestions';
}
