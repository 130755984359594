/* RESPONSIBLE TEAM: team-product-guidance */
/* eslint-disable ember/no-controller-access-in-routes */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { captureException } from 'embercom/lib/sentry';
import { tracked } from '@glimmer/tracking';
import type Session from 'embercom/services/session';
import { type Router } from '@ember/routing';
import { post } from 'embercom/lib/ajax';
import type IntlService from 'ember-intl/services/intl';

export default class WizardRoute extends Route {
  @service declare customerService: any;
  @service declare onboardingHomeService: any;
  @service declare router: Router;
  @service declare intercomEventService: any;
  @tracked stepId = '';
  @tracked wizardId = '';
  @service declare appService: any;
  @service session!: Session;
  @service intl!: IntlService;

  async model(queryParams: any) {
    if (!this.onboardingHomeService.guide) {
      if (this.onboardingHomeService.guideForCurrentAppFromStore()) {
        await this.onboardingHomeService.findOrLoadGuide();
      } else {
        try {
          await Promise.all([
            this.customerService.refreshCustomer(),
            this.onboardingHomeService.findOrLoadGuide(),
          ]);
        } catch (e) {
          console.error('Guide library model hook error\n', e);
          captureException(e, {
            fingerprint: ['route', 'guide-library', 'model', e.message],
          });
          throw e;
        }
      }
    }
    this.stepId = queryParams.stepId;
    this.wizardId = queryParams.wizardId;
    return this.onboardingHomeService.guide.levels.filter(
      (level: any) => level.identifier === queryParams.wizardId,
    )[0];
  }

  async activate(): Promise<void> {
    this._setPageTitle(this._getPageTitle());
    await this.session.getTeammate(this.appService.app.id);
    this.recordEvents(this.wizardId);
    await post('/ember/onboarding/guide_library_cda/record_first_selected_wizard', {
      app_id: this.appService.app.id,
      wizard_id: this.wizardId,
    });
  }

  _getPageTitle(): string {
    switch (this.wizardId) {
      case 'evaluation_wizard_helpdesk':
        return this.intl.t('onboarding.guide-library.page-title.wizards.helpdesk');
      case 'evaluation_wizard_chatbot':
        return this.intl.t('onboarding.guide-library.page-title.wizards.chatbot');
      case 'evaluation_wizard_proactive_support':
        return this.intl.t('onboarding.guide-library.page-title.wizards.proactive_support');
      default:
        return this.intl.t('onboarding.guide-library.page-title.wizards.home');
    }
  }

  _setPageTitle(title: string): void {
    document.title = `${title} | Wizard | Intercom`;
  }

  async deactivate(): Promise<void> {
    try {
      await this.session.getTeammate(this.appService.app.id);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  recordEvents(wizardId: string): void {
    this.intercomEventService.trackEvent(`${wizardId}-entered`);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'wizard_entered',
      object: 'entered',
      place: 'guide_library',
      wizard_identifier: wizardId,
      guide_identifier: this.onboardingHomeService.guide.identifier,
      section: 'wizard',
    });
  }
}
