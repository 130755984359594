/* RESPONSIBLE TEAM: team-ai-agent */
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import IntercomRoute from 'embercom/routes/base/intercom-route';
import type Store from '@ember-data/store';

export default class FinAIAgentProfilesRoute extends IntercomRoute {
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare usageLimitsService: any;
  @service declare store: Store;

  analytics = {
    section: 'ai-chatbot',
    place: 'profiles',
  };
  get titleToken(): string {
    return this.intl.t('ai-agent.sub-menu.profiles.title');
  }

  async model() {
    let storeRequests = [
      this.store.findAll('inbox/conversation-sla'),
      this.store.findAll('office-hours-schedule'),
      this.store.findAll('messenger-app'),
      this.store.findRecord('operator-settings/ai-agent-quick-replies', this.appService.app.id),
      this.store.findRecord('messenger_settings/all', this.appService.app.id),
      this.store.findAll('conversational-insights/conversation-topic'),
      this.usageLimitsService.fetchFinUsageLimits(),
    ];

    await Promise.all(storeRequests);
  }
}
