/* RESPONSIBLE TEAM: team-data-interop */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';

export default IntercomRoute.extend({
  developerHub: service(),

  model() {
    return hash({
      topics: this.modelFor('apps/app/developer-hub/app-packages/app-package/webhooks'),
    });
  },

  afterModel() {
    if (!this.get('developerHub.appPackage.webhookDefinition')) {
      this.transitionTo('apps.app.developer-hub.app-packages.app-package.webhooks.edit');
    }
  },
});
