/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { valueOrDefault } from '@intercom/pulse/lib/computed-properties';

export default Route.extend({
  appService: service(),
  app: readOnly('appService.app'),
  defaultRoutePath: null,
  product: null,
  productId: valueOrDefault('product.id', null),
  shouldRedirect: true,

  init() {
    this._super(...arguments);
    if (this.shouldRedirect && this.defaultRoutePath === null) {
      throw new TypeError('Must set defaultRoutePath on product index route.');
    }
  },

  afterModel() {
    if (this.shouldRedirect) {
      return this.replaceWith(this.defaultRoutePath);
    }
  },
});
