/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Route from '@ember/routing/route';
import type Router from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';

export default class ArticleReviews extends Route {
  @service declare store: Store;
  @service declare appService: $TSFixMe;
  @service declare router: Router;

  beforeModel() {
    if (!this.appService.app.canUseArticleReviews) {
      this.router.transitionTo('apps.app.knowledge-hub.all-content');
    }
  }

  async model() {
    return await this.store.query('articles/article-content-review-wrapper', {});
  }
}
