/* RESPONSIBLE TEAM: team-proactive-support */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AppsAppOutboundSeriesRoute extends Route {
  @service appService;

  beforeModel() {
    if (!this.appService.app.canUseSeries) {
      this.replaceWith('apps.app.outbound.all');
    }
  }
}
