/* RESPONSIBLE TEAM: team-workflows */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import ChatMessage from 'embercom/models/operator/visual-builder/step/chat-message';

const DEFAULT_NUM_NODES = 3;
const NUM_CHAT_MESSAGES_PER_GROUP = [1, 2];
const NUM_REPLY_BUTTONS_PER_GROUP = [2, 5];

function randomInteger(min, max) {
  return !max ? Number(min) : Math.floor(Math.random() * (max - min + 1)) + Number(min);
}

export default class CustomBotsGraphDemoRoute extends Route {
  @service store;
  @service appService;
  @service router;

  beforeModel() {
    if (!this.appService.app.canUseFeature('support-content-custom-bot-visual-builder-demo')) {
      this.router.replaceWith('apps.app.automation.workflows-overview');
    }
  }

  /**
   * @queryParam nodes?    <number> - number of groups
   * @queryParam buttons?  <number>[,<number>] - number of reply buttons per group
   * @queryParam messages? <number>[,<number>] - number of messages per group
   **/

  model(_params, transition) {
    let { queryParams } = transition.to;
    this.numGroups = queryParams.nodes ?? DEFAULT_NUM_NODES;
    this.chatMessagesPerGroup = queryParams.messages?.split(',') ?? NUM_CHAT_MESSAGES_PER_GROUP;
    this.replyButtonsPerGroup = queryParams.buttons?.split(',') ?? NUM_REPLY_BUTTONS_PER_GROUP;

    return this.createWorkflow();
  }

  createWorkflow() {
    let workflow = this.store.createRecord('operator/visual-builder/workflow');

    let unvisitedGroups = [this.addNewGroup(workflow, { isStart: true })];

    while (workflow.groups.length < this.numGroups) {
      let currentGroup = unvisitedGroups.shift();
      this._createReplyButtonsStep(currentGroup);

      let outwardConnectionPoints = currentGroup.steps.reduce((points, step) => {
        return points.concat(step.outwardConnectionPoints.toArray());
      }, []);

      // create and connect to successor group for each outward connection point
      // unless we have already created the desired number of groups for workflow
      for (let i = 0; i < outwardConnectionPoints.length; i++) {
        if (workflow.groups.length < this.numGroups) {
          let toGroup = this.addNewGroup(workflow);
          let outwardConnectionPoint = outwardConnectionPoints.objectAt(i);
          outwardConnectionPoint.isTerminal = false;
          this.store.createRecord('operator/visual-builder/edge', {
            outwardConnectionPoint,
            toGroup,
          });
          unvisitedGroups.push(toGroup);
        }
      }
    }
    return workflow;
  }

  addNewGroup(workflow, params = {}) {
    let newGroup = this.store.createRecord('operator/visual-builder/group', params);
    this._createChatMessageSteps(newGroup);

    return workflow.groups.pushObject(newGroup);
  }

  _createChatMessageSteps(group) {
    let numChatMessages = randomInteger(...this.chatMessagesPerGroup);
    let start = group.steps.length;
    for (let i = 0; i < numChatMessages; i++) {
      group.steps.insertAt(start + i, ChatMessage.createNewStep(this.store));
    }
  }

  _createReplyButtonsStep(group) {
    let currentTotal = group.workflow.groups.length;
    let numConnectionPoints = Math.min(
      randomInteger(...this.replyButtonsPerGroup),
      currentTotal === 1 ? Infinity : this.numGroups - currentTotal,
    );

    let outwardConnectionPoints = [...Array(numConnectionPoints)].map(() =>
      this.store.createRecord('operator/visual-builder/connection-point'),
    );

    let step = this.store.createRecord('operator/visual-builder/step/reply-buttons', {
      outwardConnectionPoints,
    });

    group.steps.pushObject(step);
  }
}
