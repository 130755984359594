/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default Route.extend({
  intercomEventService: service(),
  beforeModel(transition) {
    let queryParams = Object.assign(transition.to.queryParams || {}, {
      developer: true,
    });
    this.replaceWith('signup.teams.index', {
      queryParams,
    });
  },
});
