/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controller-access-in-routes */
/* eslint-disable ember/no-classic-classes */
import Route from '@ember/routing/route';
import PALETTE from '@intercom/pulse/lib/palette';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Route.extend({
  appService: service(),
  app: readOnly('appService.app'),
  reportContext: 'sales',

  setupController(controller, model) {
    this._super(controller, model);

    let parentController = this.controllerFor(this.parentRoute);
    controller.set('range', parentController.get('range'));
    controller.set('palette', PALETTE);
    controller.set('app', this.app);
    controller.set('signals', parentController.get('signals'));
  },
});
