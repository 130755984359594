/* RESPONSIBLE TEAM: team-proactive-support */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class ToursRoute extends Route {
  @service store;
  @service intercomEventService;

  beforeModel(transition) {
    this.intercomEventService.trackEvent('product-tours-visited');

    let { queryParams, params } = transition.to;
    let newRoute = `apps.app.outbound.tour.${transition.to.localName}`;

    if (params.id !== undefined) {
      let tour = this.store.findRecord('matching-system/ruleset', params.id);
      this.replaceWith(newRoute, tour, { queryParams });
    } else {
      this.replaceWith(newRoute, { queryParams });
    }
  }
}
