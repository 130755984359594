/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { METRICS_SUPPORTING_USAGE_LIMITS } from 'embercom/helpers/billing/usage-helper';
import ajax from 'embercom/lib/ajax';
import {
  INCLUDED_TIERS_FROM_PRICING_ENDPOINT,
  PRICING_5_0_EARLY_STAGE_FREE_PRICING_MODEL,
  PRICING_5_0_EARLY_STAGE_PRICING_MODEL,
  PRICING_5_1_COPILOT_EARLY_STAGE_FREE_PRICING_MODEL,
  PRICING_5_1_COPILOT_EARLY_STAGE_PRICING_MODEL,
  PRICING_5_1_EARLY_STAGE_FREE_PRICING_MODEL,
  PRICING_5_1_EARLY_STAGE_PRICING_MODEL,
} from 'embercom/lib/billing';
import { hash } from 'rsvp';
import { type Router } from '@ember/routing';
import type Store from '@ember-data/store';
import type Transition from '@ember/routing/transition';
import type UsageReminderModel from 'embercom/models/usage-reminder';

export default class BillingUsageRoute extends Route {
  @service declare intercomEventService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare router: Router;
  @service declare customerService: $TSFixMe;
  @service declare store: Store;

  get app() {
    return this.appService.app;
  }

  get customer() {
    return this.customerService.customer;
  }

  get pricingModelIdentifier() {
    return this.customerService.currentPricingModelIdentifier;
  }

  beforeModel() {
    if (!this.app.onPricing5 || !this.app.hasActiveSubscription || this.customer.inCardlessTrial) {
      this.router.transitionTo('apps.app.settings.workspace.billing.index');
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'billing_usage_page',
    });
  }

  model() {
    return hash({
      contract: this.store.findRecord('billing/contract', this.app.id),
      pmdasData: this.getPmdasData(),
      usageReminders: this.store.findAll(
        'usage-reminder',
        this.app.id,
      ) as unknown as Array<UsageReminderModel>,
      usageLimits: this.getUsageLimits(),
      pricingFaqLink: this.pricingFaqLink,
    });
  }

  async getPmdasData() {
    return ajax({
      url: '/ember/pricing_model_daily_app_stats/fetch_for_current_billing_cycle',
      type: 'GET',
      data: {
        app_id: this.appService.app.id,
      },
    });
  }

  async getUsageLimits() {
    let usageLimitsArray: $TSFixMe[] = [];
    METRICS_SUPPORTING_USAGE_LIMITS.forEach((item) => {
      this.store.queryRecord(item.tableName, {});
      usageLimitsArray.push({
        metric: item.metric,
        tableName: item.tableName,
      });
    });

    return usageLimitsArray;
  }

  afterModel(_model: $TSFixMe, _transition: Transition) {
    if (!this.customerService.currentPrice) {
      return this.customerService.fetchCurrentPrice({
        includeTiers: INCLUDED_TIERS_FROM_PRICING_ENDPOINT,
      });
    }
  }

  get pricingFaqLink() {
    switch (this.pricingModelIdentifier) {
      case PRICING_5_0_EARLY_STAGE_PRICING_MODEL:
        return 'https://www.intercom.com/help/en/articles/8711333-early-stage-program';
      case PRICING_5_0_EARLY_STAGE_FREE_PRICING_MODEL:
        return 'https://www.intercom.com/help/en/articles/8806647-startup-partner-program';
      case PRICING_5_1_EARLY_STAGE_PRICING_MODEL:
        return 'https://www.intercom.com/help/en/articles/9096410-early-stage-program';
      case PRICING_5_1_EARLY_STAGE_FREE_PRICING_MODEL:
        return 'https://www.intercom.com/help/en/articles/9105521-startup-partner-program';
      case PRICING_5_1_COPILOT_EARLY_STAGE_PRICING_MODEL:
        return 'https://www.intercom.com/help/en/articles/9361928-early-stage-program-from-june-27-2024';
      case PRICING_5_1_COPILOT_EARLY_STAGE_FREE_PRICING_MODEL:
        return 'https://www.intercom.com/help/en/articles/9361933-startup-partner-program-from-june-27-2024';
      default:
        return 'https://www.intercom.com/help/en/articles/8344190-intercom-pricing-faqs';
    }
  }
}
