/* RESPONSIBLE TEAM: team-customer-data-platform */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';

export default IntercomRoute.extend({
  permissionsService: service(),

  userSegmentsRoute: 'apps.app.users.segments',

  beforeModel(transition) {
    this._super(...arguments);
    return this.permissionsService.ensurePermissionWhenTransitioning(
      'can_bulk_import_contacts',
      transition,
      this.userSegmentsRoute,
    );
  },

  activate() {
    this.hidePrimaryNav();
  },

  deactivate() {
    this.showPrimaryNav();
  },
});
