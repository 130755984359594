/* RESPONSIBLE TEAM: team-phone */
import ManageTeammatesRoute from 'embercom/routes/apps/app/settings/base/manage-teammates-route';
import { inject as service } from '@ember/service';

export default class RolesSettingsRoute extends ManageTeammatesRoute {
  @service appService;
  @service permissionsHierarchyService;

  async beforeModel(transition) {
    await super.beforeModel(...arguments);
    await this.permissionsHierarchyService.ensureDataIsLoaded.perform();
  }
}
