/* RESPONSIBLE TEAM: team-help-desk-experience */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import type Session from 'embercom/services/session';

export default class InboxRoute extends Route {
  @service declare session: Session;

  // In the case where we are just loading the root `inbox/index` route, we transition
  // to load the current admin's inbox - otherwise we have nothing useful to show in the
  // main section of the page.
  redirect() {
    this.transitionTo('inbox.workspace.inbox.inbox', InboxCategory.Admin, this.session.teammate.id);
  }
}
