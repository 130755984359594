/* RESPONSIBLE TEAM: team-standalone */
import EditChartCustomReportRoute from 'embercom/routes/apps/app/reports/custom-reports/report/show/chart/edit';

type ModelParams = {
  chart_id: number;
};

export default class FinStandaloneEditChartRoute extends EditChartCustomReportRoute {
  async model(params: ModelParams) {
    return await super.model(params);
  }
}
