/* RESPONSIBLE TEAM: team-product-guidance */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-actions-hash */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { captureException } from 'embercom/lib/sentry';

export default Route.extend({
  store: service(),
  analytics: {
    place: 'onboarding_home',
  },

  guideAssistantService: service(),
  onboardingHomeService: service(),
  appService: service(),

  queryParams: {
    step: {
      refreshModel: true,
    },
    app_package_code: null,
  },

  async model(queryParams) {
    let guide = this.modelFor('apps.app.home');
    if (queryParams.step) {
      let step = this.store.peekRecord('onboarding/home/step', queryParams.step);
      if (step) {
        if (step.stepData.loadModelDataOnDemand && step.modelData === null) {
          try {
            await step.loadModelData();
          } catch (e) {
            console.error('Home index model hook error', e);
            captureException(e, {
              fingerprint: ['route', 'home', 'index', 'model', e.message],
            });
            throw e;
          }
        }
      }
    }
    return guide;
  },

  setupController(controller, model, transition) {
    this._super(...arguments);
    let initiallyOpenStep = this._getInitiallyOpenStep(transition.to.queryParams);
    controller.set('initiallyOpenStep', initiallyOpenStep);
    this._setupSelectedAppPackage(controller, transition.to.queryParams);
    this.guideAssistantService.refreshScript();
  },

  _getInitiallyOpenStep(queryParams) {
    if (queryParams.step) {
      let step = this.store.peekRecord('onboarding/home/step', queryParams.step);
      if (step) {
        return step;
      }
    }
    let latestStep = this.get('onboardingHomeService.guide.latestAvailableOrPendingCompletedStep');
    if (!latestStep) {
      return;
    }

    if (queryParams.showLatestAvailableOrPendingCompletedStep) {
      return latestStep;
    }

    return this.get('onboardingHomeService.guide.latestPendingCompletedStep');
  },

  async _setupSelectedAppPackage(controller, queryParams) {
    if (queryParams.app_package_code) {
      try {
        let selectedAppPackage = await this.store.findRecord(
          'appstore/app-package',
          queryParams.app_package_code,
        );
        controller.set('selectedAppPackage', selectedAppPackage);
      } catch (e) {
        console.error('Home index _setupSelectedAppPackage error: ', e);
        captureException(e, {
          fingerprint: ['route', 'home', 'index', '_setupSelectedAppPackage', e.message],
        });
        throw e;
      }
    }
  },
});
