/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Route from '@ember/routing/route';

export default class InstallsAndUninstalls extends Route {
  titleToken = 'Installs and uninstalls';
  analytics = { section: 'installs_and_uninstalls' };
}
