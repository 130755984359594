/* RESPONSIBLE TEAM: team-customer-data-platform */
import BaseSettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import type CustomObjects from 'embercom/services/custom-objects-service';

export default class extends BaseSettingsRoute {
  @service declare intercomEventService;
  @service declare appService: $TSFixMe;
  @service declare customObjectsService: CustomObjects;

  model(params: $TSFixMe) {
    let customObjects = this.modelFor('apps.app.settings.data.custom-objects') as $TSFixMe;
    let customObject = customObjects.findBy('identifier', params.object_type_identifier);
    return hash({ customObjects, customObject });
  }
}
