/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
import WorkflowsBaseRoute from 'embercom/routes/apps/app/settings/inbox-rules/workflows-base';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';
import type IntlService from 'embercom/services/intl';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import type Store from '@ember-data/store';
import type Transition from '@ember/routing/transition';
import type EditRulesController from 'embercom/controllers/apps/app/settings/helpdesk/rules/edit';

export default class WorkflowsEditRoute extends WorkflowsBaseRoute {
  @service declare intl: IntlService;
  @service declare intercomConfirmService: IntercomConfirmService;
  @service declare store: Store;

  queryParams = {
    mode: { refreshModel: true },
  };

  beforeModel() {
    super.beforeModel(...arguments);

    this.store.findAll('inbox/ticket-type');

    if (this.appService.app.canUseFeature('disable-inbox-rules')) {
      this.transitionTo('apps.app.automation.workflows-overview');
    }
  }

  model(params: $TSFixMe) {
    return RSVP.hash({
      workflow: this.store.findRecord('workflow', params.id),
      ...this.contextModels(),
    });
  }

  activate() {
    window.onbeforeunload = () => {
      let controller = this.controller as EditRulesController;
      if (controller.workflow.hasChanges) {
        return this.intl.t('settings.inbox-rules.edit.warn-unsaved-changes');
      }
      return null;
    };
  }

  deactivate() {
    window.onbeforeunload = null;
  }

  @action
  async willTransition(transition: Transition) {
    let controller = this.controller as EditRulesController;
    let workflow = controller.workflow;
    if (workflow.hasChanges) {
      transition.abort();

      // This seems to cover an underlying ember bug https://github.com/emberjs/ember.js/issues/5210
      if (window.history) {
        window.history.forward();
      }

      let isConfirmed = await this.intercomConfirmService.confirm({
        title: this.intl.t('settings.inbox-rules.edit.close-without-saving-title'),
        primaryButtonType: 'primary-destructive',
        confirmButtonText: this.intl.t('settings.inbox-rules.edit.close-without-saving-button'),
        cancelButtonText: this.intl.t('settings.inbox-rules.edit.keep-editing-button'),
        body: this.intl.t('settings.inbox-rules.edit.lose-changes'),
      });
      if (!isConfirmed) {
        return;
      }
      workflow.rollbackAttributes();
      transition.retry();
    }
  }

  resetController(controller: $TSFixMe) {
    controller.set('mode', null);
  }
}
