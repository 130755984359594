/* RESPONSIBLE TEAM: team-proactive-support */
import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { inject as service } from '@ember/service';
import {
  PROACTIVE_REDIRECT_MAPPING,
  routeRedirector,
} from 'embercom/lib/settings/settings-redirect-map';
import { type Router } from '@ember/routing';

export default class SettingsRoute extends Route {
  @service declare appService: $TSFixMe;
  @service declare router: Router;

  beforeModel(transition: Transition) {
    if (this.appService.app.canSeeNewIASettingsHub && this.appService.app.canSeeIASettingsGA) {
      routeRedirector(
        transition,
        this.router,
        PROACTIVE_REDIRECT_MAPPING,
        'apps.app.settings.proactive-support.customization',
      );
    }
  }
}
