/* RESPONSIBLE TEAM: team-reporting */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';

export default class TeammatePerformanceReportRoute extends IntercomRoute {
  @service appService;
  @service store;
  @service reportingMetrics;
  @service router;

  analytics = {
    place: 'teammate_performance_report',
  };
  reportName = 'teammate-performance-report';
  reportContext = 'teammate_performance';

  activate() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: this.reportName,
      context: this.reportContext,
    });
  }

  get app() {
    return this.appService.app;
  }

  async beforeModel(transition) {
    if (this.appService.app.hasR2MigratedExperience) {
      this.router.transitionTo('apps.app.reports.customer-support.team-performance');
    }

    super.beforeModel(...arguments);
    await this.reportingMetrics.loadMetricsAndProperties();
  }

  async model(_, transition) {
    let settings = this._fetchReportingSetting();
    let conversationAttributeDescriptors =
      await ConversationAttributeDescriptor.peekAllAndMaybeLoad();

    return hash({
      settings,
      conversationAttributeDescriptors,
      launchPaywall: transition.to.queryParams.paywall,
      learnMoreItems: this.learnMoreItems,
    });
  }

  _fetchReportingSetting() {
    return (
      this.store.peekAll('reporting/settings')?.firstObject ||
      this.store.queryRecord('reporting/settings', {})
    );
  }
}
