/* RESPONSIBLE TEAM: team-knowledge-interop */
import type Store from '@ember-data/store';
import { action } from '@ember/object';
import { type Router } from '@ember/routing';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { KNOWLEDGE_HUB_ALLOWED_URL_PARAM_CONTENT_TYPES } from 'embercom/lib/knowledge-hub/constants';
import { ContentAction } from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import type IntlService from 'embercom/services/intl';
import type KnowledgeHubEditorService from 'embercom/services/knowledge-hub-editor-service';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';

type ModelParams = {
  content_id: number;
  content_type: string;
};

export default class View extends Route {
  @service declare notificationsService: any;
  @service declare router: Router;
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare knowledgeHubEditorService: KnowledgeHubEditorService;
  @service declare knowledgeHubService: KnowledgeHubService;

  async model(params: ModelParams) {
    let additionalParams = {};
    // we want to fetch the live article-content version for articles
    if (params.content_type === 'article') {
      additionalParams = {
        include: 'live_version',
      };
    }
    if (KNOWLEDGE_HUB_ALLOWED_URL_PARAM_CONTENT_TYPES.includes(params.content_type)) {
      let record = await this.knowledgeHubService.findContent({
        contentId: params.content_id,
        contentTypeName: params.content_type,
        findRecordParams: additionalParams,
      });
      await record.loadRelatedContent(ContentAction.VIEW);
      this.knowledgeHubEditorService.registerActiveContent(record);
      this.knowledgeHubEditorService.trackAnalyticsEvent('viewed');
      return record;
    } else {
      return null;
    }
  }

  afterModel(model: any) {
    if (model === null) {
      this.notificationsService.notifyError(
        this.intl.t('knowledge-hub.errors.view.unknown-content-type'),
      );
      this.router.transitionTo('apps.app.knowledge-hub.all-content');
    }
  }

  @action
  willTransition(): void {
    this.knowledgeHubEditorService.registerActiveContent(undefined);
  }
}
