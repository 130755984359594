/* RESPONSIBLE TEAM: team-ai-agent */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { inject as service } from '@ember/service';
import ErrorHandlingRoute from 'embercom/routes/base/error-handling-route';
import CustomAnswer from 'embercom/models/custom-answers/custom-answer';

export default ErrorHandlingRoute.extend({
  store: service(),
  titleToken: (answerModel) => answerModel.get('title') || 'New Answer',

  analytics: {
    section: 'operator',
    place: 'answer',
  },

  model({ answer_id }) {
    return CustomAnswer.loadFullAnswer(answer_id);
  },

  async afterModel(model) {
    let appId = this.modelFor('apps.app').get('id');
    let [lookAndFeel, conversationSlas, officeHoursSchedules] = await Promise.all([
      this.store.findRecord('messenger-settings/look-and-feel', appId),
      this.store.findAll('inbox/conversation-sla'),
      this.store.findAll('office-hours-schedule'),
      this.store.findAll('messenger-app'),
      this.store.findAll('workflow-connector/insertable-action'),
      this.store.findAll('conversational-insights/conversation-topic'),
    ]);

    this.set('messengerSettingsLookAndFeel', lookAndFeel);
    this.set('conversationSlas', conversationSlas);
    this.set('officeHoursSchedules', officeHoursSchedules);
  },

  handleError(error, transition) {
    switch (error.status) {
      case 404:
        return this.displayErrorAndRedirect(
          'Sorry but that answer does not exist',
          this.transitionTo('apps.app.automation.resolution-bot'),
        );
      default:
        return this.displayErrorAndRedirect(
          "We couldn't load the answer. Something went wrong on our side, please contact support.",
          this.transitionTo('apps.app.automation.resolution-bot'),
        );
    }
  },

  setupController(controller, model, transition) {
    this._super(...arguments);

    let queryParams = transition.to.queryParams;
    let lookAndFeel = this.messengerSettingsLookAndFeel;
    let previewConfiguration = this.store.createFragment(
      'messenger-preview/configuration',
      lookAndFeel.get('previewConfigurationParams'),
    );
    controller.set('previewConfiguration', previewConfiguration);
    controller.set('transitionBackTo', queryParams.transitionBackTo);
    controller.set('conversationSlas', this.conversationSlas);
    controller.set('officeHoursSchedules', this.officeHoursSchedules);
    controller.changeVisualBuilderQueryParam(model);
  },
});
