/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';

export default class DeveloperHubAppPackageAppPartnerRoute extends IntercomRoute {
  @service developerHub;
  titleToken = 'App Partner';
  analytics = { section: 'app-partner' };

  beforeModel() {
    let app = this.modelFor('apps/app');
    if (!app.get('canShowDevHubAppPartner')) {
      this.transitionTo(
        'apps.app.developer-hub.app-packages.app-package.basic-info',
        app,
        this.developerHub.appPackage,
      );
    }
  }
}
