/* RESPONSIBLE TEAM: team-frontend-tech */

import Route from '@ember/routing/route';

import { Tree, TreeItem } from 'embercom/objects/tree-list';

export default class TreeDemoRoute extends Route {
  model() {
    let tree = new Tree([], { canDragItem: (_) => true, canDropItem: () => true });

    let a = new TreeItem({
      tree,
      parent: tree,
      children: [],
      isExpanded: true,
      canHaveChildren: true,
      dataObject: { name: 'A' },
      component: 'common/tree-list/example',
    });

    let b = new TreeItem({
      tree,
      parent: tree,
      children: undefined,
      isExpanded: false,
      canHaveChildren: false,
      dataObject: { name: 'B' },
      component: 'common/tree-list/example',
    });

    let c = new TreeItem({
      tree,
      parent: tree,
      children: [],
      isExpanded: true,
      canHaveChildren: true,
      dataObject: { name: 'C' },
      component: 'common/tree-list/example',
    });

    let d = new TreeItem({
      tree,
      parent: tree,
      children: [],
      isExpanded: false,
      canHaveChildren: false,
      dataObject: { name: 'D' },
      component: 'common/tree-list/example',
    });

    let e = new TreeItem({
      tree,
      parent: tree,
      children: [],
      isExpanded: false,
      canHaveChildren: true,
      dataObject: { name: 'E' },
      component: 'common/tree-list/example',
    });

    let f = new TreeItem({
      tree,
      parent: tree,
      children: [],
      isExpanded: false,
      canHaveChildren: false,
      dataObject: { name: 'F' },
      component: 'common/tree-list/example',
    });

    let g = new TreeItem({
      tree,
      parent: tree,
      children: [],
      isExpanded: false,
      canHaveChildren: false,
      dataObject: { name: 'G' },
      component: 'common/tree-list/example',
    });

    a.addChildren([d, e]);
    e.addChildren([f]);
    c.addChildren([g]);

    tree.children = [a, b, c];

    return tree;
  }
}
