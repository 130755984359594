/* RESPONSIBLE TEAM: team-channels */
import AccountRouteBase from 'embercom/routes/apps/app/account/base/account-route-base';
import { inject as service } from '@ember/service';

export default AccountRouteBase.extend({
  intl: service(),
  analytics: {
    section: 'your_account',
    place: 'visibility_company_segments',
  },
  get titleToken() {
    return this.intl.t('account.visibility.company-segments.title-token');
  },

  beforeModel() {
    if (!this.get('app.companiesActive')) {
      this.transitionTo('apps.app.account.visibility.user-segments');
    }
  },
});
