/* RESPONSIBLE TEAM: team-help-desk-experience */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class OnboardingRoute extends Route {
  @service declare intercomEventService: any;

  activate() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'started',
      object: 'onboarding',
      place: 'onboarding',
      section: 'onboarding',
    });
  }
}
