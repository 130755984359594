/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controller-access-in-routes */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-jquery */
import $ from 'jquery';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default Route.extend({
  purchaseAnalyticsService: service(),
  onboardingHomeService: service(),
  activate() {
    $('body').addClass('responsive');
    this.controllerFor('apps.app').set('showPrimaryNav', false);

    this.purchaseAnalyticsService.trackPageView({
      action: 'viewed',
      context: 'mobile_welcome',
      place: 'mobile_welcome',
      object: 'mobile_welcome',
      mobile: true,
    });
  },
  async model() {
    await this.onboardingHomeService.loadGuide();
    return this.get('onboardingHomeService.guide');
  },
  deactivate() {
    $('body').removeClass('responsive');
    this.controllerFor('apps.app').set('showPrimaryNav', true);
  },
});
