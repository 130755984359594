/* RESPONSIBLE TEAM: team-standalone */
import FinReportsRoute from 'embercom/routes/apps/app/reports/customer-support/fin-ai-agent';
import { inject as service } from '@ember/service';
import Range from 'embercom/models/reporting/range';
import type Transition from '@ember/routing/transition';
import type ReportingService from 'embercom/services/reporting-service';
import type ReportingViews from 'embercom/services/reporting-views';
import type RouterService from '@ember/routing/router-service';

export default class FinStandaloneReportsOverviewRoute extends FinReportsRoute {
  controllerName = 'embercom/controllers/apps/app/reports';

  @service declare appService: $TSFixMe;
  @service declare reportingService: ReportingService;
  @service declare router: RouterService;
  @service declare reportingViews: ReportingViews;

  queryParams = {
    rangeEnd: { refreshModel: true },
    rangeStart: { refreshModel: true },
  };

  // @ts-ignore
  async beforeModel(transition: Transition) {
    let defaultReport = this.reportingViews.standaloneDefaultReport;
    if (this.appService.app.canEditStandaloneFinReport && defaultReport) {
      await this.router.transitionTo('apps.app.standalone.reports.report.show', defaultReport.id);
    } else {
      let { rangeStart, rangeEnd } = transition.to.queryParams;
      let range = Range.createFromParams(rangeStart, rangeEnd, this.appService.app.timezone);
      this.reportingService.set('range', range);

      await super.beforeModel(transition);
    }
  }
}
