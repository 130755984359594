/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-new-mixins */
import Mixin from '@ember/object/mixin';
import RailsSegmentParameters from 'embercom/routes/mixins/users-and-companies/rails-segment-parameters';

let SegmentSetupModelAndController = Mixin.create(RailsSegmentParameters, {
  model(params) {
    let segmentIdentifier = this.parseRailsParameters(params.segment_identifier);
    let segments = this.modelFor(this.companiesOrUsersRoute);
    let segment = segments.findBy('identifier', segmentIdentifier);
    if (segment === undefined) {
      segment = segments.findBy('identifier', 'all-users');
    }
    if (params.predicates) {
      segment.set('predicates', JSON.parse(params.predicates));
    }
    return segment;
  },
  afterModel(model) {
    if (model.hasDirtyAttributes) {
      model.rollbackAttributes();
    }

    // drop the search term if this is not one of the "all" segments
    if (
      this.paramsFor(this.companiesOrUsersRoute).searchTerm &&
      !model.isUsersSegment &&
      !model.isContactsSegment &&
      !model.isAllSegment
    ) {
      this.transitionTo(this.routeName, model.identifier, { queryParams: { searchTerm: '' } });
    }
  },
  setupController(controller, model) {
    let app = this.modelFor('apps.app');

    this.applyRailsParameters(model);

    controller.setProperties({
      model,
      app,
    });
  },

  serialize(model) {
    return { segment_identifier: model.get('identifier') };
  },
});

export default SegmentSetupModelAndController;
