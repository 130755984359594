/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import {
  CUSTOM_BOT_REQUIRED_PERMISSION,
  OUTBOUND_CUSTOM_BOT_REQUIRED_PERMISSION,
  OUTBOUND_TARGET,
} from 'embercom/lib/operator/custom-bots/constants';

export default Route.extend({
  store: service(),
  appService: service(),
  intercomEventService: service(),
  notificationsService: service(),
  permissionsService: service(),
  editRoute: 'apps.app.operator.custom-bots.custom-bot.edit',
  indexRoute: 'apps.app.automation.workflows-overview',
  reportingRoute: 'apps.app.operator.reporting',

  beforeModel(transition) {
    let target = transition.to.queryParams.target;
    let requiredPermission =
      target === OUTBOUND_TARGET
        ? OUTBOUND_CUSTOM_BOT_REQUIRED_PERMISSION
        : CUSTOM_BOT_REQUIRED_PERMISSION;

    let hasPermission = this.permissionsService.currentAdminCan(requiredPermission);

    if (hasPermission) {
      this.createBotAndTransitionToEdit(transition.to.queryParams);
    } else {
      this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(requiredPermission);
      this.transitionTo(this.indexRoute);
    }
  },

  createBotAndTransitionToEdit(queryParams) {
    let templateId = queryParams.templateId;
    let createdFrom = queryParams.createdFrom;
    let target = queryParams.target;
    this.trackNewBotEvent(templateId, createdFrom, target);
    let customBot = this.createCustomBot(templateId, target);
    customBot
      .save()
      .then((bot) => {
        if (customBot.get('hasNoTemplate')) {
          bot.set('title', undefined);
        }
        bot.set('isNewCustomBot', true);
        return bot.reload();
      })
      .then((bot) => this.replaceWith(this.editRoute, bot.get('id')))
      .catch((error) => {
        console.error(error);
        customBot.unloadRecord();
        this.displayError(error);
        return this.transitionAfterError(createdFrom);
      });
  },

  displayError(error) {
    this.notificationsService.notifyResponseError(error, {
      default:
        'Couldnʼt create a new Custom Bot. Send us a message and weʼll do our best to help out.',
      413: 'You have reached the maximum number of custom bots. Please delete some to build more.',
    });
  },

  transitionAfterError(createdFrom) {
    if (createdFrom === 'reporting') {
      return this.transitionTo(this.reportingRoute);
    }
    return this.transitionTo(this.indexRoute);
  },

  createCustomBot(templateId, target) {
    let customBot = this.store.createRecord('operator/custom-bot');
    if (templateId) {
      customBot.set('templateId', templateId);
    }

    return customBot;
  },

  trackNewBotEvent(templateId, context, target) {
    let analyticsData = {
      action: 'clicked',
      context,
      object: 'new-workflow',
      target,
    };
    if (templateId) {
      analyticsData.object = 'new-workflow-from-template';
      analyticsData.workflow_template_id = templateId;
    }
    this.intercomEventService.trackAnalyticsEvent(analyticsData);
  },
});
