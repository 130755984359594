/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
export default Route.extend({
  intercomEventService: service(),
  // This route and empty model bizarrely/apparently has to exist in order
  // for the redirect one level up (apps.app.companies.company.index) to fire
  model() {},

  setupController(controller) {
    this._super(...arguments);
    controller.send('reset');
  },

  actions: {
    didTransition() {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'visited',
        object: 'company_profile_page',
      });
    },
  },
});
