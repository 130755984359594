/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-controller-access-in-routes */
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';
import { permissionsMap } from 'embercom/models/data/matching-system/matching-constants';

export default class AppsAppContentEditRoute extends Route {
  @service store;
  templateName = 'apps/app/content/edit';
  @service outboundHomeService;
  @service seriesEditorService;
  @service permissionsService;
  @service notificationsService;
  @service intl;
  @service guideLibraryService;

  model(params) {
    return this.store.findRecord('matching-system/ruleset', params.id);
  }

  setupController(controller, model, transition) {
    super.setupController(...arguments);

    let maybeCustomBot = model.rulesetLinks.firstObject?.object;
    controller.changeVisualBuilderQueryParam(maybeCustomBot);

    if (!this.hasPermission && controller.mode === 'edit') {
      this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(this.permissionKey);
      controller.setProperties({
        mode: 'view',
      });
      transition.to.queryParams.mode = 'view';
      this.replaceWith(transition.to.name, model.id, {
        queryParams: transition.to.queryParams,
      });
    }
  }

  redirect(model, transition) {
    if (model.isMemberOfSeries) {
      this.seriesEditorService.set(
        'contentEditorView',
        transition.to.queryParams.contentEditorView || 'content',
      );
      this.transitionTo('apps.app.outbound.series.series', model.seriesRelation.series_id, {
        queryParams: { mode: 'view', nodeId: model.seriesRelation.node_id },
      });
    }
  }

  activate() {
    this.guideLibraryService.addOutboundMessageSetLiveListener();
    this.guideLibraryService.addWorkflowSetLiveListener();
  }

  get permissionKey() {
    return permissionsMap[this.outboundHomeService.activeList];
  }

  get hasPermission() {
    if (isBlank(this.permissionKey)) {
      return true;
    } else {
      return this.permissionsService.currentAdminCan(this.permissionKey);
    }
  }

  @action willTransition(transition) {
    if (
      this.controller.mode === 'edit' &&
      !this.currentModel.isDeleted &&
      this.currentModel.hasUnsavedChanges
    ) {
      if (confirm('Warning: You have unsaved changes. Do you want to leave the page?')) {
        this.currentModel.rollbackAttributes();
      } else {
        transition.abort();
      }
    }
  }

  @action error(response) {
    console.error('Error loading ruleset', response);

    this.notificationsService.notifyResponseError(response, {
      404: this.intl.t('outbound.find.errors.not-found'),
      403: this.intl.t('outbound.find.errors.dont-have-permission'),
      default: this.intl.t('outbound.find.errors.internal-error'),
    });
    this.transitionTo('apps.app.outbound.all');
  }
}
