/* RESPONSIBLE TEAM: team-messenger */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';

export default class MessengerMobileSDKRoute extends IntercomRoute {
  @service messengerSettingsTargetUserProvider;
  @service MessengerSettingsService;

  buildRouteInfoMetadata() {
    return {
      responsibleTeam: 'team-messenger',
    };
  }

  beforeModel() {
    this.messengerSettingsTargetUserProvider.setMobileTarget();
    this.MessengerSettingsService.preview.onPlatformChange('ios');
  }

  afterModel(model) {
    if (model.settings.lookAndFeel.activeBrand.platform === 'web') {
      model.settings.lookAndFeel.activeBrand = model.settings.lookAndFeel.mobileBrand;
    }
  }
}
