/* RESPONSIBLE TEAM: team-reporting */
import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';

export default class ConversationalInsightsSuggestedTopicsIndexRoute extends Route {
  @service store;
  @service appService;
  @service intercomEventService;

  async model() {
    let topics = await this.store.findAll('conversational-insights/suggested-conversation-topic');

    return hash({
      topics,
    });
  }

  @action
  didTransition() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'visited',
      object: 'suggestion_list',
      place: 'topics',
      suggestion_count: this.currentModel?.topics.length,
    });
  }
}
