/* RESPONSIBLE TEAM: team-workflows */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';

export default IntercomRoute.extend({
  router: service(),

  beforeModel() {
    this.router.replaceWith('apps.app.settings.ai-automation.automation');
  },
});
