/* RESPONSIBLE TEAM: team-ai-agent */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { type Router } from '@ember/routing';
import { inject as service } from '@ember/service';

export default class ResolutionBotAnswersIndexRoute extends IntercomRoute {
  @service declare router: Router;

  redirect() {
    this.router.transitionTo('apps.app.fin-ai-agent.custom-answers.index');
  }
}
