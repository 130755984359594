/* RESPONSIBLE TEAM: team-ai-agent */
import FinAIAgentPlaygroundRoute from 'embercom/routes/apps/app/fin-ai-agent/playground';

export default class AutomationFinAiAgentPlaygroundRoute extends FinAIAgentPlaygroundRoute {
  analytics = {
    section: 'automation',
    place: 'fin-ai-agent.playground',
  };

  controllerName = 'apps.app.fin-ai-agent.playground';
  templateName = 'apps.app.fin-ai-agent.playground';
}
