/* RESPONSIBLE TEAM: team-workflows */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import { get } from 'embercom/lib/ajax';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';

export default class AutomationRoute extends Route {
  @service store;
  @service appService;
  @service router;
  @service contentImportService;
  @service outboundHomeService;

  get app() {
    return this.appService.app;
  }

  buildRouteInfoMetadata() {
    return {
      responsibleTeam: 'team-workflows',
    };
  }

  beforeModel(transition) {
    this.maybeRedirectToFinAIAgent(transition);
  }

  maybeRedirectToFinAIAgent(transition) {
    if (!this.app.canUseFinExperience) {
      return;
    }

    let transitionToName = transition.to.name;

    if (transitionToName.includes('apps.app.automation.resolution-bot.behaviors')) {
      if (transitionToName === 'apps.app.automation.resolution-bot.behaviors.edit') {
        this.router.replaceWith(
          'apps.app.fin-ai-agent.profiles.edit',
          transition.to.params.id || transition.to.queryParams.id,
        );
      } else {
        this.router.replaceWith('apps.app.fin-ai-agent.setup');
      }
    }
  }

  async model() {
    this.contentImportService.fetchContentIngestionState();
    this.contentImportService.fetchAiContentLibrarySummary();
    this.contentImportService.fetchContentReviewSummary();
    await this.contentImportService.fetchFinConversationContentSettings();

    let counts = {};

    // We don't want to fail the page load in case this ever goes wrong
    try {
      let { custom_bots: customBots, answers } = await get(
        `/ember/operator_workflows/workflow_counts`,
        {
          app_id: this.app.id,
        },
      );

      let profiles = await this.outboundHomeService.contentSearch({
        object_types: [objectTypes.resolutionBotBehavior],
        app_id: this.app.id,
      });
      counts = {
        customBots,
        answers,
        profiles: profiles.totalCount,
      };
    } catch (e) {
      console.error(e);
    }

    let [lookAndFeel, availability, upfrontEmailCollection] = await Promise.all([
      this.store.findRecord('messenger-settings/look-and-feel', this.app.id),
      this.store.findRecord('messenger-settings/availability', this.app.id),
      this.store.findRecord('messenger-settings/upfront-email-collection', this.app.id),
    ]);

    return hash({
      lookAndFeel,
      availability,
      upfrontEmailCollection,
      counts,
    });
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    controller.initializeConfigurationModel();
  }
}
