/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import ENV from 'embercom/config/environment';
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { task } from 'ember-concurrency';
import md5 from 'blueimp-md5';
import { encodedVapidToken } from 'embercom/lib/vapid-token';
import { computed } from '@ember/object';
import UserAgentDetector from '@intercom/pulse/lib/user-agent-detector';
import { getOwner } from '@ember/application';
import Metrics from 'embercom/models/metrics';

export default IntercomRoute.extend({
  store: service(),
  pubsub: service(),

  conversationsService: service(),
  bootService: service(),
  router: service(),
  intl: service(),
  shortcuts: service(),
  modelDataCacheService: service(),
  encodedVapidToken: computed('ENV.environment', function () {
    return encodedVapidToken();
  }),

  beforeModel() {
    if (sessionStorage) {
      try {
        let tabIdentifier =
          ENV.environment === 'test'
            ? 'test_tab'
            : `${new Date().toISOString()}::${md5(Math.random())}`;
        sessionStorage.setItem('embercom_tab_identifier', tabIdentifier);
      } catch (e) {
        // Do nothing
      }
    }
  },

  adminModelId() {
    if (isPresent(window.__embercom_boot_data)) {
      return window.__embercom_boot_data.current_admin_id;
    }
  },

  async model() {
    let model = await this.modelDataCacheService.fetch('admin', this.adminModelId());

    await this.intl.switchLocale(model.locale);

    return model;
  },

  afterModel(model) {
    this.set('admin', model);
    this.conversationsService.set('admin', model);

    this.bootService.ifOrWhenSecondaryStageReached().then(() => {
      let admin = this.admin;
      let appRoute = getOwner(this).lookup('route:apps/app');
      let app = appRoute.app;

      this.bootShortcuts(admin);
      this.checkNotificationSettings(app, admin);
    });
  },

  checkNotificationSettings(app, admin) {
    let hasServiceWorkers = 'serviceWorker' in navigator;
    let isImpersonationSession = app && app.get('impersonation_session');

    if (isImpersonationSession || !hasServiceWorkers) {
      return;
    }

    this.promptForPushPermissionIfRequired.perform(app, admin).catch(() => {
      // Only catching so acceptance tests don't fail
    });
  },

  bootShortcuts(admin) {
    let state = {
      advancedEnabled: admin.get('advanced_shortcuts_enabled'),
      csEnabled: admin.get('hasEnabledCSComponent'),
    };
    let pubsub = this.pubsub;
    this.shortcuts.setProperties({ state, pubsub });
  },

  promptForPushPermissionIfRequired: task(function* (app, admin) {
    let serviceWorkerRegistration;
    let options = { userVisibleOnly: true };
    if (UserAgentDetector.isChrome()) {
      options.applicationServerKey = this.encodedVapidToken;
    }
    if (ENV.environment === 'test' && navigator.serviceWorker.fakeReady) {
      serviceWorkerRegistration = yield navigator.serviceWorker.fakeReady();
    } else {
      serviceWorkerRegistration = yield navigator.serviceWorker.ready;
    }
    let pushManager = serviceWorkerRegistration.pushManager;
    let permissionState = yield pushManager.permissionState(options);

    if (permissionState === 'denied') {
      return;
    }

    let existingPushSubscription = yield pushManager.getSubscription();
    if (existingPushSubscription) {
      if (existingPushSubscription.endpoint.startsWith('https://android.googleapis.com/gcm/send')) {
        Metrics.capture({ increment: ['gcm_unsubscribe_token'] });
        yield existingPushSubscription.unsubscribe();
      } else {
        return;
      }
    }

    let settings = yield this.store.findRecord('admin-notification-settings', admin.get('id'));
    if (settings.get('hasAnyWebPush')) {
      try {
        let pushSubscription = yield pushManager.subscribe(options);
        settings.savePushSubscription(app, admin, pushSubscription);
      } catch (e) {
        // User denied permission, it would be a bit rude to do anything now
      }
    }
  }),

  actions: {
    refreshAppsAdminModel() {
      this.refresh();
    },
  },
});
