/* RESPONSIBLE TEAM: team-phone */
import { inject as service } from '@ember/service';
import WorkspaceSettingsRoute from 'embercom/routes/apps/app/settings/base/workspace-settings-route';
import type RouterService from '@ember/routing/router-service';
import { PhoneNumberType, PhoneNumberTypeToTwilioString } from 'embercom/models/settings/calling';

export default class PhoneRegulatoryBundlesNewRoute extends WorkspaceSettingsRoute {
  @service declare appService: any;
  @service declare router: RouterService;
  @service declare intl: any;

  countryCode = 'DE';
  phoneNumberType = PhoneNumberType.Local;

  beforeModel(transition: any) {
    super.beforeModel(...arguments);

    if (!this.appService.app.canAccessCallingBeta) {
      this.router.transitionTo('apps.app.settings');
    }

    if (transition.to.queryParams.countryCode) {
      this.countryCode = transition.to.queryParams.countryCode;
    }
    if (transition.to.queryParams.phoneNumberType) {
      this.phoneNumberType = Number(transition.to.queryParams.phoneNumberType);
    }
  }

  model() {
    return {
      regulatoryBundle: {
        countryCode: this.countryCode,
        phoneNumberType: this.phoneNumberType,
        friendlyName: `${this.countryCode} ${PhoneNumberTypeToTwilioString.get(
          this.phoneNumberType,
        )} ${this.intl.t('calling.settings.phone-regulatory-bundle.regulatory-bundle')}`,
      },
      endUser: {},
      supportingDocuments: [],
    };
  }
}
