/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';

export default IntercomRoute.extend({
  developerHub: service(),
  appPackage: alias('developerHub.appPackage'),

  actions: {
    refreshRoute() {
      this.refresh();
    },
  },
});
