/* RESPONSIBLE TEAM: team-standalone */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';

export default class FinStandaloneSetupSalesforceRoute extends Route {
  @service declare appService: any;
  @service declare store: Store;
  @service declare finStandaloneService: any;

  async beforeModel() {
    return await Promise.all([
      this.finStandaloneService.initialize(),
      this.appService.app.loadEmailDomains(),
    ]);
  }
}
