/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { post } from 'embercom/lib/ajax';
import { action } from '@ember/object';

export default class AppsAppOutboundSeriesNewRoute extends Route {
  @service store;
  @service appService;
  @service notificationsService;

  async model(params) {
    let response = await post('/ember/series/series', {
      app_id: this.appService.app.id,
      template_id: params.template_id,
    });
    this.store.pushPayload('series/series', { 'series/series': [response] });
    let series = this.store.peekRecord('series/series', response.id);
    this.replaceWith('apps.app.outbound.series.series', series);
  }

  @action error(response) {
    this.notificationsService.notifyResponseError(response, {
      403: `You don't have permission to do that. Ask an admin on your team for help.`,
      default: `Something went wrong on our side, please try again and reach out to our support team if the problem persists.`,
    });
    this.transitionTo('apps.app.outbound.series.index');
  }
}
