/* RESPONSIBLE TEAM: team-reporting */
import { assert } from '@ember/debug';
import { readOnly } from '@ember/object/computed';
import { on } from '@ember/object/evented';
import { inject as service } from '@ember/service';
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { hash } from 'rsvp';

export default IntercomRoute.extend({
  store: service(),
  intercomEventService: service(),
  reportingService: service(),
  appService: service(),
  app: readOnly('appService.app'),
  range: readOnly('reportingService.range'),

  mergedProperties: ['queryParams'],
  queryParams: {
    teammateId: { refreshModel: true },
    scopingTagIds: { refreshModel: true, type: 'intarray' },
    slaId: { refreshModel: true },
    botInboxTime: { refreshModel: true },
  },
  cameFrom: null,

  trackPageview: on('activate', function () {
    let models = [];

    if (this.teammate) {
      models = [...models, this.teammate];
    }

    if (this.teamOrTeammate) {
      models = [...models, this.teamOrTeammate];
    }

    this.instrumentationEvent = setTimeout(
      () =>
        this.intercomEventService.trackAnalyticsEvent({
          action: 'viewed',
          object: this.reportName,
          context: this.reportContext,
          start_date: this.range.startMoment.format('YYYY/MM/DD'),
          end_date: this.range.endMoment.format('YYYY/MM/DD'),
          filtered_tag: this.get('tag.name'),
          models,
          cameFrom: this.cameFrom,
        }),
      this.instrumentationDelay || 0,
    );
  }),

  cleanupDelayedTrackPageview: on('deactivate', function () {
    return this.instrumentationEvent && clearTimeout(this.instrumentationEvent);
  }),

  beforeModel(transition) {
    let { scopingTagIds, botInboxTime } = transition.to.queryParams;
    let tagId = scopingTagIds ? scopingTagIds[0] : undefined;
    let tag = tagId ? this.store.peekRecord('tag', tagId) : undefined;

    if (!tag) {
      this.setProperties({
        scopingTagIds: null,
        tag: undefined,
        botInboxTime,
      });
    } else {
      this.setProperties({
        scopingTagIds,
        tag,
        botInboxTime,
      });
    }

    this.set('cameFrom', transition.to.queryParams.cameFrom);
  },

  async model() {
    let reportingAllSettings = await this.store.queryRecord('reporting/settings', {});
    let dataConfigs = {};

    if (this.dashboardIdentifier) {
      let fetchedDataConfigs = await this.store.query('reporting/chart', {
        dashboard_identifier: this.dashboardIdentifier,
      });

      dataConfigs = this.chartIdentifiers.reduce((result, id) => {
        result[id] = this.findDataConfig(fetchedDataConfigs, id);
        return result;
      }, {});
    }
    let botInboxSettings = await this.store.findRecord('operator-bot-inbox-settings', this.app.id);

    let settings = {
      reportingExcludeOooHours: reportingAllSettings.get('excludeOooHours'),
      allowToExcludeBotInbox: botInboxSettings.get('assignConversationsToBots'),
      excludeBotInboxStartDate: botInboxSettings.get('assignConversationsToBotsLastEnabledAt'),
    };

    return hash({
      settings,
      dataConfigs,
      dashboardIdentifier: this.dashboardIdentifier,
      signals: this.fetchSignals(settings),
    });
  },

  findDataConfig(dataConfigs, chartId) {
    let result = dataConfigs.findBy('chart_identifier', chartId);

    if (!result) {
      result = this.store.createRecord('reporting/chart', {
        dashboard_identifier: this.dashboardIdentifier,
        chart_identifier: chartId,
        aggregation_method: 'median',
        app_id: this.app.id,
      });
    }
    return result;
  },

  setupController(controller, model) {
    let { range, reportName, tag } = this;
    let controllerProperties = {
      rangeStart: range.start,
      rangeEnd: range.end,
      range,
      tag,
      reportName,
      rangeParams: {
        baseAggregations: range.baseAggregations,
        heatmapAggregations: range.heatmapAggregations,
        interval: range.interval,
        comparisonStartMoment: range.comparisonStartMoment,
        comparisonEndMoment: range.comparisonEndMoment,
        startMoment: range.startMoment,
        endMoment: range.endMoment,
        inDays: range.inDays,
        timezone: range.timezone,
      },
      ...model,
    };
    controller.setProperties(controllerProperties);
    this.intercomEventService.trackEvent(`viewed_${this.reportName}`);
  },

  fetchSignals() {
    assert('Must implement fetchSignals');
  },

  deserializeQueryParam(value, urlKey, defaultValueType) {
    if (value && defaultValueType === 'intarray') {
      let arr = [];
      for (let i = 0; i < value.length; i++) {
        arr.push(parseInt(value[i], 10));
      }
      return arr; // Original: return Ember.A(JSON.parse(value));
    }
    return this._super(...arguments);
  },

  serializeQueryParam(value, urlKey, defaultValueType) {
    if (defaultValueType === 'intarray') {
      return value; // Original: return JSON.stringify(value);
    }
    return this._super(...arguments);
  },
});
