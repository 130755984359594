/* RESPONSIBLE TEAM: team-channels */
import AccountRouteBase from 'embercom/routes/apps/app/account/base/account-route-base';
import { inject as service } from '@ember/service';

export default AccountRouteBase.extend({
  intl: service(),
  analytics: {
    section: 'your_account',
    place: 'password',
  },
  get titleToken() {
    return this.intl.t('apps.app.account.password.change-title');
  },
  model() {
    return this.modelFor('apps');
  },
});
