/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-controller-access-in-routes */
/* eslint-disable ember/no-classic-classes */
import EmberObject from '@ember/object';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { task } from 'ember-concurrency';

export default Route.extend({
  conversationFeedService: service(),
  notificationsService: service(),

  beforeModel() {
    this.setProperties({
      user: this.modelFor('apps.app.users.user'),
      app: this.modelFor('apps.app'),
      admin: this.controllerFor('apps.app').get('admin'),
    });
  },
  model() {
    return this.conversationFeedService.getConversationsPage(this._searchOptions());
  },
  setupController(controller, model) {
    this._super(controller, model);
    controller.set('model', model.conversations);
    controller.set('app', this.app);
    controller.set('admin', this.admin);
  },
  _scope() {
    if (this.controllerFor('apps.app.users.user').get('selectedOption') === 'conversations') {
      return 'sorted_conversations';
    } else {
      return this.controllerFor('apps.app.users.user').get('selectedOption');
    }
  },
  _before() {
    if (this._scope() === 'sorted_conversations') {
      return this.controller.get('conversations.lastObject.latest_admin_visible_comment_at');
    } else {
      return this.controller.get('conversations.lastObject.updated_at');
    }
  },
  _searchOptions() {
    return EmberObject.create({
      appId: this.get('app.id'),
      adminId: this.get('admin.id'),
      userId: this.get('user.id'),
      scope: this._scope(),
    });
  },
  searchConversations: task(function* () {
    this.controller.set('isPaginating', true);

    let searchOptions = this._searchOptions();
    searchOptions.set('before', this._before());
    searchOptions.set('currentConversations', this.controller.get('conversations'));

    try {
      let response = yield this.conversationFeedService.paginate(searchOptions);
      this.controller.setProperties({
        isPaginating: false,
        model: response.conversations,
        hasMoreConversationsToLoad: response.hasMoreConversationsToLoad,
      });
    } catch (error) {
      this.controller.set('isPaginating', false);
      this.notificationsService.notifyError(
        'There was an error loading conversations, please scroll down to try again.',
      );
    }
  }).drop(),
  actions: {
    paginate() {
      this.searchConversations.perform();
    },

    refresh() {
      this.refresh();
    },
  },
});
