/* RESPONSIBLE TEAM: team-help-desk-experience */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type InboxApi from 'embercom/services/inbox-api';
import type InboxState from 'embercom/services/inbox-state';
import { captureException } from 'embercom/lib/sentry';
import type Conversation from 'embercom/objects/inbox/conversation';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import type TracingService from 'embercom/services/tracing';

export default class InboxIndexRoute extends Route {
  @service inboxApi!: InboxApi;
  @service inboxState!: InboxState;
  @service router!: any;
  @service tracing!: TracingService;

  async beforeModel(transition: any) {
    if (transition.intent?.queryParams?.skipRedirection) {
      return;
    }

    this.router.on('routeDidChange', () => {
      this.inboxState.showConversationList();
    });

    let inbox = this.modelFor('inbox.workspace.inbox.inbox') as Inbox;
    this.tracing.tagRootSpan({
      inbox_id: inbox.id,
      inbox_total_conversation_count: inbox.count,
      inbox_category: inbox.category,
      inbox_type: inbox.type,
    });

    let conversation: Conversation | undefined;
    try {
      conversation = await this.inboxApi.fetchMostRecentConversation(
        inbox.category,
        inbox.id,
        this.inboxState.getDefaultState(inbox),
        this.inboxState.getDefaultSort(inbox),
        { reason: 'fetch_most_recent' },
      );
    } catch (err) {
      // Just capture the exception. We don't need to show any error message
      // here, just make sure we're not throwing an error in the route
      // hierarchy.
      captureException(err);
    }

    if (!transition.isAborted && conversation && this.inboxState.isListView) {
      this.router.transitionTo(
        'inbox.workspace.inbox.inbox.conversation.conversation',
        inbox,
        conversation,
      );
    }
  }
}
