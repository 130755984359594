/* RESPONSIBLE TEAM: team-ai-agent */
import IntercomRoute from 'embercom/routes/base/intercom-route';

export default class FinAIAgentCustomAnswersAnswerShowConversations extends IntercomRoute {
  analytics = {
    section: 'ai-chatbot',
    place: 'custom-answers-answer-show-conversations',
  };

  controllerName = 'apps.app.fin-ai-agent.custom-answers.answer.show';
  templateName = 'apps.app.fin-ai-agent.custom-answers.answer.show.conversations';
}
