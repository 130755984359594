/* RESPONSIBLE TEAM: team-data-interop */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class SamlRoute extends Route {
  @service iamService;

  beforeModel(_transition) {
    this.iamService.boot();
  }
}
