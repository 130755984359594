/* RESPONSIBLE TEAM: team-ml */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type RouterService from '@ember/routing/router';

export default class FinAIAgentPlaygroundRoute extends Route {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare router: RouterService;

  analytics = {
    section: 'ai-chatbot',
    place: 'playground',
  };

  get titleToken(): string {
    return this.intl.t('ai-agent.sub-menu.playground.title');
  }

  beforeModel() {
    if (!this.appService.app.canUseFinPlayground) {
      this.router.transitionTo('apps.app.fin-ai-agent.index');
    }
  }

  async model() {
    return {};
  }
}
