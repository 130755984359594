/* RESPONSIBLE TEAM: team-frontend-tech */
import CheckUnsavedChanges from 'embercom/routes/apps/app/account/base/check-unsaved-changes-route';
import { inject as service } from '@ember/service';

import { ReactRoute } from 'embercom/lib/react-route';

const AccountNotificationsRoute = CheckUnsavedChanges.extend({
  store: service(),
  appService: service(),
  intl: service(),
  analytics: {
    section: 'your_account',
    place: 'notifications',
  },
  get titleToken() {
    return this.intl.t('account.notifications.title-token');
  },

  reactEnabled() {
    return this.appService.app.enableReactAccountNotificationsRoute;
  },

  activate() {
    this.store
      .createRecord('admin-pageview', {
        pageKey: 'notifications_settings',
      })
      .save();
  },
  model() {
    let admin = this.modelFor('apps');
    return this.store.findRecord('admin-notification-settings', admin.get('id'));
  },
});

export default ReactRoute(AccountNotificationsRoute);
