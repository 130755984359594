/* RESPONSIBLE TEAM: team-channels */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import {
  senderTypes,
  emailTemplateTypes,
  premadeTemplates,
} from 'embercom/models/data/outbound/constants';

export default class AppsAppEmailEditorDemoRoute extends Route {
  @service appService;
  @service store;

  async beforeModel() {
    await Promise.all([
      this.appService.app.email_templates,
      this.store.findAll('email-template-data'),
      this.appService.app.customEmailAddresses,
    ]);
  }

  async model() {
    this.store.pushPayload({
      'emails/localized-email-content': {
        id: 1,
        app_id: this.appService.app.id,
        blocks: [{ type: 'paragraph', text: 'Hello, world!' }],
        subject: 'Hello!',
        email_template_id: premadeTemplates.personal,
        email_template_type: emailTemplateTypes.premade,
        assign_to_type: senderTypes.admin,
        assign_to_id: this.appService.app.currentAdmin.id,
        sender_type: senderTypes.admin,
        sender_id: this.appService.app.currentAdmin.id,
      },
    });
    return this.store.peekRecord('emails/localized-email-content', 1);
  }
}
