/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import ManageMessagesSettingsRoute from 'embercom/routes/apps/app/settings/base/manage-messages-settings-route';
import { inject as service } from '@ember/service';

export default class EditKeywordResponseRoute extends ManageMessagesSettingsRoute {
  @service store;
  titleToken = 'Edit keyword response';

  async model(params) {
    return await this.store.findRecord('sms/keyword-response', params.id);
  }
}
