/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import OutboundBaseListRoute from 'embercom/lib/outbound/base-list-route';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class AppsAppOutboundSeriesIndexRoute extends OutboundBaseListRoute {
  @service intercomEventService;
  @service appService;
  @service reportingMetrics;

  titleToken = 'Series';
  analytics = {
    section: 'outbound',
    place: 'series',
  };
  objectTypes = [objectTypes.series];

  activate() {
    this.intercomEventService.trackEvent('series-viewed-home');
  }

  async beforeModel() {
    this.outboundHomeService.setActiveList(objectTypes.series);
    await this.reportingMetrics.setup.perform();
  }

  @action willTransition() {
    this.outboundHomeService.resetFilters();
  }
}
