/* RESPONSIBLE TEAM: team-proactive-support */
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';
import Route from '@ember/routing/route';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';

export default class NewSubscriptionRoute extends Route {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare router: RouterService;

  get app() {
    return this.modelFor('apps.app');
  }

  setupController(controller: $TSFixMe, model: $TSFixMe, transition: Transition) {
    super.setupController(controller, model, transition);

    this.router.on('routeWillChange', () => {
      // Safely unload unsaved subscriptions
      let subscriptionTypes = this.store.peekAll('outbound-subscriptions/subscription-type');
      subscriptionTypes
        .filter((subscriptionType) => !subscriptionType.id)
        .forEach((subscriptionType) => this.store.unloadRecord(subscriptionType));
    });
  }

  model() {
    return RSVP.hash({
      translationSettings: this.store.findAll('outbound-subscriptions/translation-settings'),
    });
  }
}
