/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import {
  objectTypes,
  matchBehaviors,
} from 'embercom/models/data/matching-system/matching-constants';
import { inject as service } from '@ember/service';
import NewsBaseListRoute from '../index';
export default class AppsAppOutboundNewsItemsIndexRoute extends NewsBaseListRoute {
  @service store;
  @service appService;
  @service outboundHomeService;
  @service newsfeedsService;

  beforeModel() {
    return this.redirectToAllOutboundSearch();
  }

  titleToken = 'News Items';
  analytics = {
    section: 'outbound',
    place: 'news-items',
  };

  objectTypes = [objectTypes.newsItem];
  matchBehavior = matchBehaviors.transient;
}
