/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Route from '@ember/routing/route';
import getDefaultSegment from 'embercom/lib/routes/get-default-segment';

let SegmentsIndexRoute = Route.extend({
  beforeModel() {
    let app = this.modelFor('apps.app');
    let segments = this.modelFor('apps.app.users');
    let admin = this.modelFor('apps');
    let segment;
    if (app.importedUsersAndIsNotActivated) {
      segment = segments.firstObject;
    } else if (app.onlyArticlesIsActive) {
      segment = segments.findBy('identifier', 'all-leads');
    } else {
      segment = getDefaultSegment(segments, admin.visible_segment_ids, 'all-users');
    }

    this.replaceWith('apps.app.users.segments.segment', segment);
  },
});

export default SegmentsIndexRoute;
