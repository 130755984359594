/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import Route from '@ember/routing/route';
import { action } from '@ember/object';
import storage from 'embercom/vendor/intercom/storage';
import type Transition from '@ember/routing/-private/transition';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import { Scope } from 'embercom/objects/content-service/saved-views/constants/scopes';
import { DataFormat } from 'embercom/objects/content-service/saved-views/constants/data-formats';
import { EntityType } from 'embercom/models/data/entity-types';
import type Store from '@ember-data/store';
import type RouterService from '@ember/routing/router-service';
import type ArticleMultilingual from 'embercom/models/article-multilingual';
import {
  PROACTIVE_REDIRECT_MAPPING,
  routeRedirector,
} from 'embercom/lib/settings/settings-redirect-map';

type ModelParams = {
  selectedLocaleId?: string;
};

export default class ArticlesRoute extends Route {
  @service declare store: Store;
  @service declare permissionsService: $TSFixMe;
  @service declare helpCenterService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare onboardingHomeExternalStepService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare router: RouterService;

  helpCenterSite: $TSFixMe;

  get app() {
    return this.appService.app;
  }

  buildRouteInfoMetadata() {
    return {
      responsibleTeam: 'team-knowledge-and-data-setup',
    };
  }

  get analytics() {
    return {
      section: 'educate',
    };
  }

  async beforeModel(transition: Transition) {
    if (this.app.hasOptedInForProductIa) {
      if (
        transition.to.name.startsWith('apps.app.articles.reviews') &&
        this.app.canUseArticleReviews
      ) {
        // Article reviews is a FF live only for app 6 and not currently ported to knowledge hub
        return;
      }

      if (transition.to.name.startsWith('apps.app.articles.site')) {
        await this.redirectToHelpCenterSettings(transition);
      } else {
        await this.redirectToKnowledgeHub(transition);
      }
    }
  }

  async model(params: ModelParams) {
    await this.helpCenterService.maybeFetchSite();
    let site = this.helpCenterService.site;

    if (isPresent(params.selectedLocaleId) && !site.supportsLocale(params.selectedLocaleId)) {
      this.transitionTo({ queryParams: { selectedLocaleId: undefined } });
    }

    return site;
  }

  async afterModel(model: any, transition: Transition) {
    this.set('helpCenterSite', model);
    if (transition.to.queryParams.external_step) {
      this.onboardingHomeExternalStepService.start(transition.to.queryParams.external_step, {
        source: 'articles route afterModel', // eslint-disable-line @intercom/intercom/no-bare-strings
      });
    }
    await this.store.query('content-service/saved-view', {
      scope: Scope.ARTICLES,
      saved_view_data_format: DataFormat.ARTICLES,
    });
  }

  activate() {
    storage.set('lastProductUsed', 'articles');
  }

  async deactivate() {
    // TODO: Create a re-usable hook and use for all external step routes.
    // use willTransition and check if transition.to matches external step
    // tourInProgressRoute before unsetting. Explore other options too.
    this.controller.set('externalStep' as $TSFixMe, null); // eslint-disable-line ember/no-controller-access-in-routes
  }

  @action
  didTransition() {
    this.intercomEventService.trackEvent('articles-visited');
  }

  async redirectToHelpCenterSettings(transition: Transition) {
    routeRedirector(
      transition,
      this.router,
      PROACTIVE_REDIRECT_MAPPING,
      'apps.app.settings.helpcenter.all',
    );
  }

  async redirectToKnowledgeHub(transition: Transition) {
    switch (transition.to.name) {
      case 'apps.app.articles.articles.article-content.edit':
        this.redirectOldArticleContentEditRoute(transition);
        break;
      case 'apps.app.articles.articles.new':
        this.redirectOldArticleCreationRoute(transition);
        break;
      case 'apps.app.articles.articles.article.index':
      case 'apps.app.articles.articles.article.show.index':
        await this.redirectOldArticleRoute(transition, 'view');
        break;
      case 'apps.app.articles.articles.article.edit':
        await this.redirectOldArticleRoute(transition, 'edit');
        break;
      default:
        this.redirectArticleListRoute(transition);
        break;
    }
  }

  redirectOldArticleContentEditRoute(transition: Transition) {
    let currentTransition = transition.to.find(
      (curr) => curr.name === 'apps.app.articles.articles.article-content',
    );

    if (!currentTransition || !currentTransition.params?.content_id) {
      this.redirectArticleListRoute(transition);
      return;
    }

    this.router.transitionTo(
      'apps.app.knowledge-hub.edit',
      'article',
      currentTransition.params.content_id,
      { queryParams: currentTransition.queryParams },
    );
  }

  redirectOldArticleCreationRoute(transition: Transition) {
    this.router.transitionTo('apps.app.knowledge-hub.new', 'article', {
      queryParams: transition.to.queryParams,
    });
  }

  redirectArticleListRoute(transition: Transition) {
    let queryParams = transition.to.queryParams ?? {};

    if (queryParams.tag_ids) {
      queryParams = {
        ...queryParams,
        filtersApplied: '["tag"]',
        tagIds: queryParams.tag_ids,
      };
      delete queryParams.tag_ids;
    }

    this.router.transitionTo('apps.app.knowledge-hub.all-content', {
      queryParams: { types: EntityType.ArticleContent, ...queryParams },
    });
  }

  async redirectOldArticleRoute(transition: Transition, mode: 'edit' | 'view') {
    let currentTransition = transition.to.find(
      (curr) => curr.name === 'apps.app.articles.articles.article',
    );

    if (!currentTransition || !currentTransition.params.id) {
      this.redirectArticleListRoute(transition);
      return;
    }

    let articleId = currentTransition.params.id;
    let [_, article] = await Promise.all([
      this.helpCenterService.maybeFetchSite(),
      this.store.findRecord('article-multilingual', articleId, { reload: true }) as Promise<
        ArticleMultilingual | undefined
      >,
    ]);
    if (!article || article.articleContents.length === 0) {
      this.redirectArticleListRoute(transition);
      return;
    }

    let language = currentTransition.queryParams.language ?? article.defaultLocaleId;
    let articleContent =
      article.contentByLocale(language) ??
      article.defaultLocalizedContent ??
      article.articleContents.firstObject!;

    this.router.transitionTo(`apps.app.knowledge-hub.${mode}`, 'article', articleContent.id, {
      queryParams: currentTransition.queryParams,
    });
  }
}
