/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';

export default class AppsAppArticlesReview extends Route {
  @service declare appService: any;
  @service declare store: Store;

  beforeModel() {
    if (!this.appService.app.canUseArticleReviews) {
      this.transitionTo('apps.app.articles.index');
    }
  }

  async model() {
    return this.store.query('articles/article-content-review-wrapper', {});
  }
}
