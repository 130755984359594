/* RESPONSIBLE TEAM: team-reporting */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class ReportsConversationalInsightsSggestedTopicsRoute extends Route {
  @service appService;

  beforeModel() {
    if (!this.appService.app.hasConversationalInsightsBillingFeature) {
      return this.transitionTo('apps.app.reports.conversational-insights.index');
    }
  }
}
