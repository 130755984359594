/* RESPONSIBLE TEAM: team-proactive-support */
import AllOutboundListRoute from 'embercom/routes/apps/app/outbound/all';
import { inject as service } from '@ember/service';
import { statisticKeys } from 'embercom/models/data/outbound/constants';
import { hash } from 'rsvp';

export default class OutboundViewsRoute extends AllOutboundListRoute {
  @service intl;
  @service outboundHomeService;
  @service store;

  analytics = {
    section: 'outbound',
    place: 'views',
  };

  get titleToken() {
    return this.intl.t('outbound.views');
  }

  get statistics() {
    return [
      statisticKeys.receipts,
      statisticKeys.goals,
      statisticKeys.replies,
      statisticKeys.clicks,
      statisticKeys.opens,
      statisticKeys.failures,
      statisticKeys.tourStepFailures,
      statisticKeys.completions,
      statisticKeys.reactions,
      statisticKeys.answers,
      statisticKeys.keywordReplies,
    ];
  }

  beforeModel() {
    super.beforeModel();
  }

  afterModel() {
    // overriding this method to not set query params for views from the route
  }

  async model(params) {
    this.intercomEventService.trackEvent('outbound-list-view', {
      route: this.routeName,
    });
    let app = this.modelFor('apps.app');

    this.resetFilters();
    let view = this.store.peekRecord('content-service/saved-view', params.id);
    if (!view) {
      return this.redirectToAllOutboundSearch();
    }

    this.outboundHomeService.view = view;

    let searchResponsePromise = this.contentSearchWithParams(
      this.outboundHomeService.view.savedViewData.query,
    );

    let hashParams = {
      messagesTags: this.tagService.getMessagesTags(app),
      templates: this.store.findAll('template'),
      tourTemplates: this.store.findAll('tour-template'),
      seriesTemplates: this.store.findAll('series/template'),
      searchResponse: searchResponsePromise,
      senderDomains: this.store.findAll('settings/sender-domain'),
    };
    hashParams.subscriptionTypes = this.store.findAll('outbound-subscriptions/subscription-type');

    let results = await hash(hashParams);

    if (this.outboundHomeService.isFirstRequest) {
      this.set('outboundHomeService.isFirstRequest', false);
    }

    let model = {
      viewId: params.id,
      contentWrappers: results.searchResponse.contentWrappers,
      pageFrom: results.searchResponse.pageFrom,
      perPage: results.searchResponse.perPage,
      totalCount: results.searchResponse.totalCount,
      totalPages: results.searchResponse.totalPages,
      lastPageHit: results.searchResponse.lastPageHit,
      templates: results.templates,
      tourTemplates: results.tourTemplates,
      seriesTemplates: results.seriesTemplates,
      messagesTags: results.messagesTags,
      senderDomains: results.senderDomains,
    };
    model.subscriptionTypes = results.subscriptionTypes.filter((subscriptionType) =>
      this.objectTypes.any((objectType) =>
        subscriptionType.permittedContentTypes.includes(objectType),
      ),
    );
    return model;
  }

  _setOutboundHomeServiceFromParams(params) {
    super._setOutboundHomeServiceFromParams(params);

    let savedViewData = this.outboundHomeService.view.savedViewData;

    if (savedViewData.query.matchBehaviors) {
      this.outboundHomeService.selectedMatchBehaviorValues = savedViewData.query.matchBehaviors;
    }
    if (savedViewData.query.predicates) {
      this.outboundHomeService.predicates = savedViewData.query.predicates;
    }
    if (savedViewData.columns) {
      this.outboundHomeService.selectedTableColumns = savedViewData.columns;
    }
  }
}
