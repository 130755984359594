/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import ReportBase from 'embercom/routes/apps/app/reports/base/report';

export default ReportBase.extend({
  reportName: 'conversation_tags',
  analytics: {
    place: 'conversation-tags',
  },
  titleToken: 'Conversation tags',

  fetchSignals() {
    return Promise.resolve();
  },
});
