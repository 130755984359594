/* RESPONSIBLE TEAM: team-proactive-support */
import ReportBase from 'embercom/routes/apps/app/reports/base/report';
import { inject as service } from '@ember/service';

export default class OutboundEngagementOverviewRoute extends ReportBase {
  @service intl;
  reportName = 'customer_engagement_report';
  get titleToken() {
    return this.intl.t('apps.app.reports.outbound-engagement.overview.title');
  }
  analytics = {
    place: 'customer_engagement',
  };
  shouldRedirectFromParent = true;

  fetchSignals() {
    return {};
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('selectedContentTypeIds', []);
    }
  }
}
