/* RESPONSIBLE TEAM: team-product-exploration */
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';

export default class NewSettingsRoute extends Route {
  @service declare appService: any;
  @service declare router: RouterService;
  @service declare helpCenterService: any;
  @service declare store: any;

  analytics = {
    section: 'new-settings',
  };

  redirect(): any {
    if (!this.appService.app.canSeeNewIASettingsHub) {
      return this.router.transitionTo('apps.app.settings', this.appService.app.id);
    }
  }

  async model() {
    return hash({
      helpCenters: this.store.findAll('help-center-site'),
    });
  }

  @action
  willTransition(transition: Transition): void {
    if (transition.to.name.includes('new-settings')) {
      window.document
        .querySelector('.main__content-outlet')
        ?.scrollTo({ top: 0, behavior: 'instant' });
    }
  }
}
