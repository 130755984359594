/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
import Route from '@ember/routing/route';
import { isBlank } from '@ember/utils';
import Ruleset from 'embercom/models/matching-system/ruleset';
import {
  objectTypes,
  humanReadableObjectNames,
  permissionsMap,
  matchBehaviors,
} from 'embercom/models/data/matching-system/matching-constants';
import { inject as service } from '@ember/service';
import { OUTBOUND_BOT_TYPE } from 'embercom/lib/operator/custom-bots/constants';

const SUPPORTED_CONTENT_TYPES = new Set([
  objectTypes.push,
  objectTypes.sms,
  objectTypes.chat,
  objectTypes.post,
  objectTypes.email,
  objectTypes.carousel,
  objectTypes.customBot,
  objectTypes.inboundCustomBot,
  objectTypes.buttonCustomBot,
  objectTypes.banner,
  objectTypes.tour,
  objectTypes.resolutionBotBehavior,
  objectTypes.survey,
  objectTypes.tooltipGroup,
  objectTypes.newsItem,
  objectTypes.triggerableCustomBot,
  objectTypes.checklist,
  objectTypes.whatsapp,
]);

const ROUTE_MAP = {
  [objectTypes.push]: 'apps.app.outbound.push.edit',
  [objectTypes.sms]: 'apps.app.outbound.sms.edit',
  [objectTypes.chat]: 'apps.app.outbound.chat.edit',
  [objectTypes.post]: 'apps.app.outbound.post.edit',
  [objectTypes.email]: 'apps.app.outbound.email.edit',
  [objectTypes.carousel]: 'apps.app.outbound.carousel.edit',
  [objectTypes.customBot]: 'apps.app.outbound.custom-bot.edit',
  [objectTypes.inboundCustomBot]: 'apps.app.automation.workflows.edit',
  [objectTypes.buttonCustomBot]: 'apps.app.automation.workflows.edit',
  [objectTypes.banner]: 'apps.app.outbound.banners.edit',
  [objectTypes.tour]: 'apps.app.outbound.tour.edit',
  [objectTypes.resolutionBotBehavior]: 'apps.app.automation.resolution-bot.behaviors.edit',
  [objectTypes.survey]: 'apps.app.outbound.survey.edit',
  [objectTypes.tooltipGroup]: 'apps.app.outbound.tooltips.edit',
  [objectTypes.newsItem]: 'apps.app.outbound.news.news-items.edit',
  [objectTypes.triggerableCustomBot]: 'apps.app.automation.workflows.edit',
  [objectTypes.checklist]: 'apps.app.outbound.checklists.edit',
  [objectTypes.whatsapp]: 'apps.app.outbound.whatsapp.edit',
};

export default Route.extend({
  store: service(),
  notificationsService: service('notificationsService'),
  permissionsService: service('permissionsService'),
  appService: service('appService'),
  intercomEventService: service('intercomEventService'),

  model() {
    let error = `
      Transitioning to 'app.app.content.new' without creation params is unsupported.
      Please pass a hash to use for Ruleset creation:
      this.transitionToRoute('apps.app.content.new', {
        object_type: objectTypes.example,
        object_data: { ... },
        etc
      })
    `;
    console.error(error);
    throw new Error(error);
  },

  async afterModel(creationParams, transition) {
    let permissionKey = permissionsMap[creationParams.object_type];

    if (this.hasPermission(permissionKey)) {
      await this.createRecord(creationParams, transition);
    } else {
      this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(permissionKey);
      this.transitionTo(transition.from.name, {
        queryParams: transition.from.queryParams,
      });
    }
  },

  hasPermission(permissionKey) {
    if (isBlank(permissionKey)) {
      return true;
    } else {
      return this.permissionsService.currentAdminCan(permissionKey);
    }
  },

  rulesetQueryParams(objectType, ruleset) {
    return { mode: 'edit', id: ruleset.id };
  },

  trackEvent(object_type, ruleset_id, template_id) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'created',
      object: 'ruleset',
      context: 'template_modal',
      object_type,
      ruleset_id,
      template_id,
    });
  },

  async createRecord(creationParams, transition) {
    if (SUPPORTED_CONTENT_TYPES.has(creationParams.object_type)) {
      creationParams.app_id ||= this.appService.app.id;
      if (!creationParams.template_id) {
        creationParams.object_data ||= {};
        creationParams.match_behavior ||= matchBehaviors.static;
        if (creationParams.objectType === objectTypes.customBot) {
          creationParams.object_data.type ||= OUTBOUND_BOT_TYPE;
        }
      }

      try {
        let nextContentWrapper = null;

        if (creationParams.next_content_wrapper) {
          nextContentWrapper = creationParams.next_content_wrapper;
          delete creationParams.next_content_wrapper;
        }

        let ruleset = await Ruleset.createForType(this.store, creationParams);
        this.trackEvent(creationParams.object_type, ruleset.id, creationParams.template_id);

        if (nextContentWrapper) {
          await ruleset.reorder(undefined, nextContentWrapper);
        }

        if (this.appService.app.hasOptedInForProductIa) {
          ROUTE_MAP[objectTypes.resolutionBotBehavior] =
            'apps.app.automation.fin-ai-agent.profiles.edit';
        }
        return this.replaceWith(ROUTE_MAP[creationParams.object_type], ruleset, {
          queryParams: this.rulesetQueryParams(creationParams.object_type, ruleset),
        });
      } catch (error) {
        this.notificationsService.notifyResponseError(error, {
          default: `We couldn't create your ${
            humanReadableObjectNames[creationParams.object_type]
          }. Send us a message and we'll do our best to help out.`,
        });
        this.transitionTo(transition.from.name, {
          queryParams: transition.from.queryParams,
        });
      }
    } else {
      let error = `Content Editor – Unsupported content type ${creationParams.object_type}`;
      console.error(error);
      throw new Error(error);
    }
  },
});
