/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import EmberObject from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import {
  hideIntercomWidgetWhenLoaded,
  showIntercomWidget,
} from 'embercom/lib/intercom-widget-helper';
import IntercomRoute from 'embercom/routes/base/intercom-route';

const APP_ROUTE = 'apps.app';
const INDEX_ROUTE = 'apps.app.seatless-experience.index';
const WELCOME_ROUTE = 'apps.app.seatless-experience.welcome';
const SELECT_SEAT_ROUTE = 'apps.app.seatless-experience.select-seat';

export default class SeatlessExperienceRoute extends IntercomRoute {
  @service appService;
  @service modelDataCacheService;
  @tracked seatType;
  @tracked requestTo;

  activate() {
    this.hidePrimaryNav();
    hideIntercomWidgetWhenLoaded();
  }

  beforeModel(transition) {
    if (this.appService.app.currentAdmin.teammate_role_data_completed) {
      if ([INDEX_ROUTE, WELCOME_ROUTE].includes(transition.targetName)) {
        return this.replaceWith(SELECT_SEAT_ROUTE);
      }
    } else if (transition.targetName === INDEX_ROUTE) {
      return this.replaceWith(WELCOME_ROUTE);
    }

    return this.modelDataCacheService.awaitRefreshedModel(
      'admin',
      this.get('appService.app.currentAdmin.id'),
    );
  }

  model() {
    let app = this.appService.app;
    if (app.isTestApp || !app.hasMultipleSeatTypes || app.currentAdmin.seats.length > 0) {
      return this.replaceWith(APP_ROUTE, app.id);
    }
    return EmberObject.create({
      admin: app.currentAdmin,
      app,
      seat: this.seatType,
      requestTo: this.requestTo,
    });
  }

  deactivate() {
    this.showPrimaryNav();
    showIntercomWidget();
  }
}
