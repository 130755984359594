/* RESPONSIBLE TEAM: team-workflows */
import WorkflowsBaseRoute from 'embercom/routes/apps/app/settings/inbox-rules/workflows-base';
import RSVP from 'rsvp';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';

export default class WorkflowsListRoute extends WorkflowsBaseRoute {
  @service declare permissionsService: $TSFixMe;
  @service declare store: Store;
  @service declare modalService: $TSFixMe;
  @service declare router: RouterService;

  AUTOMATION_INDEX_ROUTE = 'apps.app.inbox.automation';
  INBOX2_WORKLOAD_MANAGEMENT_ROUTE = 'apps.app.settings.helpdesk.assignments';
  INBOX2_INBOX_RULES_ROUTE = 'apps.app.settings.helpdesk.rules';

  async model(_: $TSFixMe, transition: Transition) {
    return RSVP.hash({
      launchPaywall: !!transition.to.queryParams.paywall,
      workflows: this.store.query('workflow', {}),
      ...this.contextModels(),
    });
  }

  get titleToken() {
    return this.intl.t('new-settings.submenu.helpdesk.rules');
  }

  get app() {
    return this.appService.app;
  }

  beforeModel(transition: Transition) {
    if (this._isTransitionFromAnAutomationRoute(transition)) {
      // if transition is from an automation route(i.e. workload management)
      // we should show the permission wall to user, since she can already see the features under automation.
      this.permissionsService.ensurePermissionWhenTransitioning(
        'can_manage_inbox_rules',
        transition,
      );
      return;
    }
    super.beforeModel(...arguments);
  }

  async afterModel(model: $TSFixMe, transition: Transition) {
    let isInboxRulesDisabled = this.appService.app.canUseFeature('disable-inbox-rules');

    let isDeepLinkingInboxRulesToWorkflowsEnabled =
      isInboxRulesDisabled &&
      this.appService.app.canUseFeature('deep-link-inbox-rules-to-workflows');

    // We redirect user,
    // 1. If they have no rules and the 'disable-inbox-rules' feature flag is enabled.
    // 2. If they have rules, and were migrated as part of cohort5, but doesn't have any rules migrated to workflows.
    if (
      (isInboxRulesDisabled && model.workflows.content.length === 0) ||
      (isDeepLinkingInboxRulesToWorkflowsEnabled &&
        !model.workflows.filter((workflow: $TSFixMe) => workflow.migratedToRulesetIds.length > 0)
          .length)
    ) {
      this.transitionTo('apps.app.automation.workflows-overview');
      return;
    }

    if (
      !isDeepLinkingInboxRulesToWorkflowsEnabled &&
      isInboxRulesDisabled &&
      model.workflows.length > 0
    ) {
      try {
        await this._cancelTransition(transition);
      } finally {
        this.modalService.openModal('settings/modals/inbox-rules-disabled', {
          workflows: model.workflows,
        });
      }
    }
  }

  _isTransitionFromAnAutomationRoute(transition: Transition) {
    let inbox1_automation_route =
      transition.from && transition.from.name.includes(this.AUTOMATION_INDEX_ROUTE);
    let inbox2_automation_route =
      transition.from &&
      (transition.from.name.includes(this.INBOX2_WORKLOAD_MANAGEMENT_ROUTE) ||
        transition.from.name.includes(this.INBOX2_INBOX_RULES_ROUTE));
    return inbox1_automation_route || inbox2_automation_route;
  }

  async _cancelTransition(transition: Transition) {
    if (!transition.from || transition.from.name === 'application_loading') {
      await this.router.transitionTo('apps.app.settings.index').followRedirects();
    } else {
      await this.router.transitionTo(transition.from.name);
    }
  }
}
