/* RESPONSIBLE TEAM: team-proactive-support */
import ContentEditorRoute from 'embercom/routes/apps/app/content/edit';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { inject as service } from '@ember/service';

export default class TooltipsEditRoute extends ContentEditorRoute {
  @service intersectionService;
  @service appService;

  analytics = {
    section: 'messages',
    place: 'tooltips_editor',
  };

  beforeModel() {
    if (!this.appService.app.canUseTooltips) {
      return this.transitionTo('apps.app.outbound.all');
    }
  }

  afterModel() {
    this.outboundHomeService.setActiveList(objectTypes.tooltipGroup);
  }

  deactivate() {
    super.deactivate(...arguments);
    this.intersectionService.unregisterEventHandler('getTooltipGroupId');
    this.intersectionService.unregisterEventHandler('tooltips-updated');
  }
}
