/* RESPONSIBLE TEAM: team-proactive-support */
import OutboundBaseListRoute from 'embercom/lib/outbound/base-list-route';
import {
  objectTypes,
  matchBehaviors,
} from 'embercom/models/data/matching-system/matching-constants';
import { inject as service } from '@ember/service';

export default class AppsAppOutboundSmsIndexRoute extends OutboundBaseListRoute {
  @service appService;
  @service outboundHomeService;
  @service intercomEventService;

  beforeModel() {
    return this.redirectToAllOutboundSearch();
  }

  activate() {
    this.intercomEventService.trackEvent('sms-home-viewed');
  }

  titleToken = 'SMS';
  analytics = {
    section: 'outbound',
    place: 'sms',
  };

  objectTypes = [objectTypes.sms];
  matchBehavior = matchBehaviors.single;
}
