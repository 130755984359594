/* RESPONSIBLE TEAM: team-channels */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';
import type InboundEmail from 'embercom/models/inbound-email';

type ModelParams = { email_id: number };

export default class InboundEmailRoute extends Route {
  @service declare store: Store;
  @service declare intl: IntlService;

  get titleToken(): string {
    return this.intl.t('app.templates.apps.app.inbound-email.title');
  }

  async model(params: ModelParams): Promise<InboundEmail> {
    return this.store.findRecord('inbound-email', params.email_id);
  }
}
