/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { hash } from 'rsvp';

export default class ConversationalSupportFunnelRoute extends Route {
  @service store;
  titleToken = 'Conversational support funnel';

  @service appService;
  @service tagService;
  @service intercomEventService;
  @service reportingService;

  analytics = {
    place: 'csf_report',
  };

  async model() {
    let proactiveSupportMessageFilters;

    try {
      let response = await this.store.findAll('proactive-support-message-filters');
      proactiveSupportMessageFilters = response.firstObject;
    } catch (e) {
      proactiveSupportMessageFilters = this.store.createRecord('proactive-support-message-filters');
    }

    return hash({
      messageTags: this.tagService.getMessagesTags(this.appService.app),
      senderRoles: this.store.findAll('role'),
      proactiveSupportMessageFilters,
      settings:
        this.store.peekAll('reporting/settings')?.firstObject ||
        this.store.queryRecord('reporting/settings', {}),
    });
  }

  @action
  didTransition() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'csf_report',
    });
  }
}
