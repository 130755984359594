/* RESPONSIBLE TEAM: team-messenger */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import type MessengerSettingsTargetUserProvider from 'embercom/services/messenger-settings-target-user-provider';
import type MessengerSettingsService from 'embercom/services/messenger-settings-service';
import type Transition from '@ember/routing/transition';
import { DEFAULT_SECTIONS_FOR_TABS, type Tab } from 'embercom/services/messenger-settings-service';
import type Router from '@ember/routing/router-service';
import type IntlService from 'ember-intl/services/intl';

export default class MessengerMobileSDKRoute extends IntercomRoute {
  @service declare messengerSettingsTargetUserProvider: MessengerSettingsTargetUserProvider;
  @service declare MessengerSettingsService: MessengerSettingsService;
  @service declare router: Router;
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;

  queryParams = {
    tab: {
      refreshModel: false,
      replace: true,
    },
    section: {
      refreshModel: false,
      replace: true,
    },
  };

  get titleToken() {
    return this.intl.t(`new-settings.submenu.channels.messenger`);
  }

  buildRouteInfoMetadata() {
    return {
      responsibleTeam: 'team-messenger',
    };
  }

  beforeModel() {
    this.messengerSettingsTargetUserProvider.setMobileTarget();
    this.MessengerSettingsService.preview.onPlatformChange('ios');
  }

  redirect(): any {
    if (this.appService.app.isOptedIntoConversationalMessengerBeta) {
      this.router.replaceWith('apps.app.settings.channels.messenger.conversational.mobile-sdk', {
        queryParams: { tab: 'mobile', section: 'brand' },
      });
    }
  }

  afterModel(model: any, transition: Transition) {
    if (model.settings.lookAndFeel.activeBrand.platform === 'web') {
      model.settings.lookAndFeel.activeBrand = model.settings.lookAndFeel.mobileBrand;
    }

    let { tab, section } = transition.to.queryParams;
    tab ||= 'content';
    section ||= DEFAULT_SECTIONS_FOR_TABS[tab as Tab];
    this.MessengerSettingsService.settings = model.settings;
    this.MessengerSettingsService.navigate(tab as Tab, section, { changeUrl: false });
  }
}
