/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-controller-access-in-routes */
import { reads, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { startTour } from 'embercom/lib/intercom-widget-helper';
import ajax from 'embercom/lib/ajax';
import Article from 'embercom/models/article';
import RouteRegexes from 'embercom/lib/route-regexes';

export default IntercomRoute.extend({
  templateName: 'apps/app/articles/articles/list/list-base',
  appService: service(),
  intercomEventService: service(),
  onboardingHomeExternalStepService: service(),
  router: service(),
  analytics: {
    place: 'articles_list',
  },

  currentImport: null,
  currentImportUrl: null,
  queryParams: { search_term: { refreshModel: true }, url_partials: { refreshModel: true } },

  app: readOnly('appService.app'),
  titleToken: reads('title'),

  buildRouteInfoMetadata() {
    return {
      responsibleTeam: 'team-knowledge-and-data-setup',
    };
  },

  beforeModel() {
    let app = this.modelFor('apps.app');
    Article.updateArticleCountsForApp(app);
  },

  model() {
    let params = this.paramsFor('apps.app.articles');
    return {
      selectedTagValues: params.selectedTagValues ? params.selectedTagValues.split(',') : [],
      selectedContentStatus: this.selectedContentStatus,
      locale: params.selectedLocaleId || null,
      title: this.title,
      articles: [],
      request: params.request,
      view: params.view,
    };
  },

  activate() {
    let statusToOption = {
      '': 'all',
      draft: 'drafts',
      published: 'published',
      missing: 'missing',
    };

    this.intercomEventService.trackEvent('educate-event', {
      action: 'visited',
      object: 'article_list',
      place: 'article_list',
      owner: 'educate',
      option: statusToOption[this.selectedContentStatus],
    });
  },
  getApiImport() {
    let app = this.modelFor('apps.app');
    return ajax({
      url: '/ember/article_import_jobs/current_import',
      type: 'GET',
      data: { app_id: app.get('id') },
    });
  },

  setupController(controller, model, transition) {
    this._super(controller, model);
    let isEnteringArticleList =
      !transition?.from?.name.match(RouteRegexes.articleListAll) &&
      transition?.to?.name.match(RouteRegexes.articleListAll);
    if (isEnteringArticleList) {
      controller.setProperties({ showSearch: true });
    }

    controller.getSyncSettings.perform().then(() => {
      this.getApiImport().then((currentImport) => {
        if (currentImport && currentImport.mode === 'sync') {
          controller.set('activeSyncJob', currentImport);
        } else {
          controller.set('activeSyncJob', null);
        }
      });
      controller.loadNextPage.perform();
    });
  },

  resetController(controller, isExiting, transition) {
    if (isExiting) {
      let isExitingArticleListAll = !transition?.to?.name.match(RouteRegexes.articleListAll);
      controller.setProperties({
        search_term: null,
        inputSearchTerm: null,
        url_partials: [],
        ...(isExitingArticleListAll && { showSearch: false }),
      });
    }
  },

  actions: {
    createArticle() {
      this.transitionTo('apps.app.articles.articles.new');
    },

    reloadArticles() {
      this.set('currentModel.articles.meta', null);
      this.get('currentModel.articles').clear();
      this.controllerFor(this.routeName).get('loadNextPage').perform();
    },

    async openArticlesTour(route, tourId) {
      await this.onboardingHomeExternalStepService.generateExampleDataForStep(
        'create_short_article',
      );

      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'tour',
        tour_id: tourId,
      });

      await this.onboardingHomeExternalStepService.loadStep('create_short_article');

      if (this.onboardingHomeExternalStepService.externalStep) {
        this.onboardingHomeExternalStepService.startAfterTransition('create_short_article', {
          source: 'tutorial-container-component openArticlesTour',
          method: 'startAfterTransition',
        });
      } else {
        startTour(tourId);
      }

      this.router.transitionTo(route);
    },
    refreshModel() {
      this.refresh();
    },
  },

  checkRequestFilterDefaults(params, defaultStatus = '') {
    let request = JSON.parse(params.request);
    return (
      request.conditions.status !== defaultStatus ||
      request.conditions.languages?.length > 0 ||
      request.conditions.collection_ids?.length > 0 ||
      request.conditions.help_center_ids?.length > 0 ||
      request.conditions.author_ids?.length > 0 ||
      request.conditions.last_edited_by_ids?.length > 0 ||
      params.selectedTagValues?.length > 0
    );
  },
});
