/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controller-access-in-routes */
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import IntercomRoute from 'embercom/routes/base/intercom-route';

export default IntercomRoute.extend({
  intercomEventService: service(),
  appService: service(),
  permissionsService: service(),
  analytics: {
    place: 'user',
  },
  userCacheService: service('userCacheService'),
  titleToken(model) {
    return model.get('display_as');
  },
  userPresenceService: service('userPresenceService'),

  beforeModel(transition) {
    this.permissionsService.ensurePermissionWhenTransitioning(
      'can_access_user_profiles',
      transition,
    );
  },
  model(params) {
    return this.userCacheService.getUserAndRefreshCache(params.user_id);
  },
  afterModel(model) {
    this._resetSearch();
    this.userPresenceService.updateUserPresenceForUser(model);
    this._trackVisitedUserProfile(model);
  },
  _trackVisitedUserProfile(model) {
    let eventName = `visited-${model.get('typeLabel').toLowerCase()}-profile`;
    this.intercomEventService.trackEvent(eventName);
  },
  _resetSearch() {
    let search = this.controllerFor('apps.app.users').get('search');
    if (isPresent(search)) {
      search.reset();
    }
  },
  actions: {
    error() {
      this.transitionTo('apps.app.users.user-not-found');
    },
  },
});
