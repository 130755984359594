/* RESPONSIBLE TEAM: team-ai-agent */
import FinAIAgentProfilesIndex from 'embercom/routes/apps/app/fin-ai-agent/profiles/index';
export default class ResolutionBotBehaviorsIndexRoute extends FinAIAgentProfilesIndex {
  analytics = {
    section: 'resolution-bot',
    place: 'behaviors-index',
  };

  controllerName = 'apps.app.fin-ai-agent.profiles.index';
  templateName = 'apps.app.fin-ai-agent.profiles.index';
}
