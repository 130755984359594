/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import ManageTeammatesRoute from 'embercom/routes/apps/app/settings/base/manage-teammates-route';
import Admin from 'embercom/models/admin';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type RouterService from '@ember/routing/router-service';

export default class TeammateRoute extends ManageTeammatesRoute {
  @service declare store: Store;
  @service declare router: RouterService;

  async beforeModel(transition: any) {
    await super.beforeModel(...arguments);

    if (transition.targetName === 'apps.app.settings.workspace.teammates.teammate.index') {
      this.router.replaceWith('apps.app.settings.workspace.teammates.teammate.permissions');
    }
  }

  model({ id }: { id: string }) {
    return Admin.peekAndMaybeLoad(this.store, id);
  }
}
