/* RESPONSIBLE TEAM: team-proactive-support */
import Route from '@ember/routing/route';
import Graph from 'embercom/models/graph-editor/graph';

const CONNECTION_POINTS = [
  { x: 50, y: -10, alignment: 'top' },
  { x: -10, y: 50, alignment: 'left' },
  { x: 10, y: 50, alignment: 'right' },
  { x: 50, y: 10, alignment: 'bottom' },
];

export { CONNECTION_POINTS };

export default class GraphDemoRoute extends Route {
  model() {
    let graph = new Graph();
    for (let y = 100; y < 2000; y += 200) {
      for (let x = 100; x < 2000; x += 200) {
        let predecessor = graph.nodes.lastObject;
        let node = graph.addNode({
          position: { x, y },
          connectionPoints: CONNECTION_POINTS,
          dataObject: { color: Math.floor(Math.random() * 16777215).toString(16) },
        });

        if (x !== 100) {
          graph.addEdge({
            predecessor,
            successor: node,
          });
        }
      }
    }
    return graph;
  }
}
