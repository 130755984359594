/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Route from '@ember/routing/route';
import { captureException } from 'embercom/lib/sentry';
import { inject as service } from '@ember/service';

export default Route.extend({
  tracing: service(),
  setupController(controller, error) {
    // eslint-disable-next-line @intercom/intercom/no-bare-strings
    this.tracing.onError(error, 'Ember');
    captureException(error, {
      tags: { bootError: true },
    });
    console.error(error);
  },
});
