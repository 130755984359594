/* RESPONSIBLE TEAM: team-proactive-support */
import type Store from '@ember-data/store';
import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { inject as service } from '@ember/service';
import { hash } from 'ember-concurrency';
import type IntlService from 'ember-intl/services/intl';

export default class CustomizationRoute extends Route {
  @service declare store: Store;
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare permissionsService: $TSFixMe;

  get app() {
    return this.appService.app;
  }

  titleToken() {
    return this.intl.t('new-settings.submenu.proactive-support.customization');
  }

  async beforeModel(transition: Transition) {
    return this.permissionsService.ensurePermissionWhenTransitioning(
      'can_manage_messages_settings',
      transition,
    );
  }

  async model() {
    return hash({
      clickTrackingDomainOptOut: this.store.findAll('click-tracking-domain-opt-out', {
        reload: true,
      }),
      messagingSettings: this.store.findRecord('messaging-settings', this.app.id),
    });
  }
}
