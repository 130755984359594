/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controller-access-in-routes */
/* eslint-disable ember/no-classic-classes */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
  intercomEventService: service(),

  hidePrimaryNav() {
    this.controllerFor('apps/app').set('showPrimaryNav', false);
  },

  showPrimaryNav() {
    this.controllerFor('apps/app').set('showPrimaryNav', true);
  },

  _inInbox() {
    let match =
      this.routeName.match(/^apps.app.*.inbox.conversations.+$/) ||
      this.routeName.match(/^apps.app.*.inbox.search.conversations.+$/) ||
      this.routeName.match(/^apps.app.*.inbox.conversation$/) ||
      [];
    return match.length > 0;
  },

  activate() {
    if (this.controller) {
      this.set('controller.inInbox', this._inInbox());
    }
  },

  deactivate() {
    if (this.controller) {
      this.set('controller.inInbox', false);
    }
  },
});
