/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import IntercomRoute from 'embercom/routes/base/intercom-route';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';

type ModelParams = {
  invoice_id: number;
};

export default class InvoiceRoute extends IntercomRoute {
  @service declare store: Store;
  @service declare intl: IntlService;

  get titleToken() {
    return this.intl.t('new-settings.workspace.billing.invoice-header');
  }

  async model(params: ModelParams) {
    return this.store.findRecord('invoice', params.invoice_id, { reload: true });
  }
}
