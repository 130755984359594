/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import { action } from '@ember/object';
import { type Router } from '@ember/routing';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import { tracked } from '@glimmer/tracking';
import type Transition from '@ember/routing/transition';
import type Contract from 'embercom/models/billing/contract';

export const DEFAULT_RESOLUTIONS_REMINDER_THRESHOLD = 100;

export default class ManageUsageRoute extends Route {
  @service declare store: Store;
  @service declare appService: $TSFixMe;
  @service declare customerService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare router: Router;
  @service declare intl: IntlService;
  @service declare intercomConfirmService: IntercomConfirmService;

  @tracked usageReminderToggleChanged = false;

  analytics = {
    place: 'manage_usage',
  };

  get app() {
    return this.appService.app;
  }

  get getCurrentModel() {
    return this.modelFor(this.routeName) as $TSFixMe;
  }

  beforeModel() {
    this.usageReminderToggleChanged = false;

    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'billing_manage_usage_page',
    });
  }

  async model() {
    let contract = this.app.isSalesforceContracted
      ? await this.store.findRecord('billing/contract', this.app.id)
      : undefined;

    return hash({
      usageReminder: this._queryUsageReminder(contract),
      finUsageLimit: this._queryFinUsageLimit(),
      currentUsage: this.store.queryRecord('usage-reminders/current-usage', {}),
      contract,
    });
  }

  afterModel(_model: $TSFixMe, _transition: Transition) {
    if (!this.customerService.currentPrice) {
      return this.customerService.fetchCurrentPrice();
    }
  }

  setupController(controller: $TSFixMe, model: $TSFixMe, transition: Transition) {
    super.setupController(controller, model, transition);

    controller.usageReminder = model.usageReminder;
    controller.finUsageLimit = model.finUsageLimit;
    controller.onToggleOnNewUsageReminder = this.onToggleOnNewUsageReminder.bind(this);
  }

  @action
  async willTransition(transition: Transition) {
    if (
      (this.getCurrentModel.usageReminder.isNew &&
        this.usageReminderToggleChanged &&
        typeof this.getCurrentModel.usageReminder.threshold !== 'undefined') ||
      this._persistedRecordIsUpdatedOrDeleted ||
      this.getCurrentModel.finUsageLimit.hasDirtyAttributes
    ) {
      transition.abort();

      if (await this._confirmClosingWithoutSaving()) {
        if (this.getCurrentModel.usageReminder.isNew) {
          this.getCurrentModel.usageReminder.threshold = undefined;
        } else {
          this.getCurrentModel.usageReminder.rollbackAttributes();
        }
        this.getCurrentModel.finUsageLimit.rollbackAttributes();

        await transition.retry();
      }
    }
  }

  @action
  onToggleOnNewUsageReminder() {
    this.toggleProperty('usageReminderToggleChanged');
  }

  get _persistedRecordIsUpdatedOrDeleted() {
    return (
      this.getCurrentModel.usageReminder.dirtyType === 'updated' ||
      this.getCurrentModel.usageReminder.dirtyType === 'deleted'
    );
  }

  _confirmClosingWithoutSaving() {
    return this.intercomConfirmService.confirm({
      title: this.intl.t('billing.manage-usage.close-without-saving-modal.title'),
      primaryButtonType: 'primary-destructive',
      confirmButtonText: this.intl.t(
        'billing.manage-usage.close-without-saving-modal.close-without-saving',
      ),
      cancelButtonText: this.intl.t('billing.manage-usage.close-without-saving-modal.keep-editing'),
      body: this.intl.t('billing.manage-usage.close-without-saving-modal.message-body'),
    });
  }

  _queryUsageReminder(contract: Contract) {
    return this.store.queryRecord('usage-reminder', {}).catch((error) => {
      if (error.jqXHR.status === 404) {
        return this.store.createRecord('usage-reminder', {
          threshold: this._defaultThreshold(contract),
          usageType: 'resolutions',
        });
      }

      throw error;
    });
  }

  _queryFinUsageLimit() {
    return this.store.queryRecord('fin-usage-limit', {});
  }

  _defaultThreshold(contract: Contract) {
    if (this.app.isSalesforceContracted) {
      if (this.app.usesResolutionsWithCustomAnswers) {
        return contract.contractUsageLimits.resolutions_with_custom_answers;
      }

      return contract.contractUsageLimits.resolutions;
    } else {
      return DEFAULT_RESOLUTIONS_REMINDER_THRESHOLD;
    }
  }
}
