/* RESPONSIBLE TEAM: team-frontend-tech */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { get } from 'embercom/lib/ajax';

export default class BenchmarkRoute extends Route {
  @service declare appService: any;

  async model(): Promise<Array<any>> {
    return get(`/ember/admins`, {
      app_id: this.appService.app.id,
    });
  }
}
