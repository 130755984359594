/* RESPONSIBLE TEAM: team-reporting */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';
import { inject as service } from '@ember/service';
import type ReportingUnderlyingDataService from 'embercom/services/reporting-underlying-data-service';
import { taskFor } from 'ember-concurrency-ts';
import type IntlService from 'ember-intl/services/intl';
import type RouterService from '@ember/routing/router-service';
import type BannerDismissalService from 'embercom/services/banner-dismissal-service';

export default class NewOverviewRoute extends IntercomRoute {
  @service intl!: IntlService;
  @service appService: $TSFixMe;
  @service declare router: RouterService;
  @service declare reportingUnderlyingDataService: ReportingUnderlyingDataService;
  @service declare bannerDismissalService: BannerDismissalService;
  analytics = { place: 'new-overview' };
  titleToken = this.intl.t('reporting.overview.title');
  awarenessBannerKey = 'r2-awareness-banner';
  migrationBannerKey = 'r2-migration-banner';

  async beforeModel() {
    await taskFor(this.reportingUnderlyingDataService.loadAttributeMappings).perform(
      'conversation.workflow_conversation_rating.workflow_id',
      'conversation',
      true,
    );
  }

  async model() {
    let hasDismissedAwarenessBanner = await this.bannerDismissalService.hasDismissed(
      this.awarenessBannerKey,
    );
    let hasDismissedMigrationBanner = await this.bannerDismissalService.hasDismissed(
      this.migrationBannerKey,
    );
    return {
      conversationAttributeDescriptors: ConversationAttributeDescriptor.peekAllAndMaybeLoad(),
      hasDismissedAwarenessBanner,
      hasDismissedMigrationBanner,
      awarenessBannerKey: this.awarenessBannerKey,
      migrationBannerKey: this.migrationBannerKey,
    };
  }
}
