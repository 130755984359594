/* RESPONSIBLE TEAM: team-product-exploration */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class PersonalDetailsRoute extends Route {
  @service declare conversationFeedService: $TSFixMe;
  @service declare appService: $TSFixMe;

  get app() {
    return this.appService.app;
  }

  async model() {
    return this.conversationFeedService.getConversationsPage({
      appId: this.app.id,
      adminId: this.app.currentAdmin.id,
    });
  }

  setupController(controller: any, model: any) {
    controller.set('model', model.conversations);
    controller.set('currentlyLoading', false);
  }
}
