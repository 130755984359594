/* RESPONSIBLE TEAM: team-channels */

import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import AccountRouteBase from 'embercom/routes/apps/app/account/base/account-route-base';
import type Store from '@ember-data/store';

export default class AuthenticationRoute extends AccountRouteBase {
  @service declare intl: IntlService;
  @service declare store: Store;

  analytics = {
    section: 'settings',
    place: 'personal.authentication',
  };

  get titleToken() {
    return this.intl.t('new-settings.submenu.personal.authentication');
  }

  async model() {
    return this.store.findAll('oauth-tokens');
  }
}
