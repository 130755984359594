/* RESPONSIBLE TEAM: team-workflows */
import ContentEditorRoute from 'embercom/routes/apps/app/content/edit';
import { inject as service } from '@ember/service';

export default class AppsAppContentEditRoute extends ContentEditorRoute {
  @service router;

  redirect(model) {
    this.router.replaceWith('apps.app.automation.workflows.edit', model.id);
  }
}
