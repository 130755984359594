/* RESPONSIBLE TEAM: team-reporting */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class MetricsPlaygroundRoute extends Route {
  @service appService;
  @service router;
  @service detectIntercomradesDomainService;
  titleToken = 'Metrics playground';

  beforeModel(_) {
    if (
      !this.appService.app.canSeeMetricsPlayground &&
      !this.detectIntercomradesDomainService.isIntercomradesDomain
    ) {
      this.router.transitionTo('apps.app');
    }
  }
}
