/* RESPONSIBLE TEAM: team-proactive-support */
import { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';
import { camelize } from '@ember/string';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { OUTBOUND_EDITOR_ROUTES } from 'embercom/models/outbound/content-wrapper';

import Route from '@ember/routing/route';

export default class LegacyMessageRoute extends Route {
  @service store;
  @service outboundHomeService;

  async model(params) {
    let message = this.store.peekRecord('message', params.id);
    if (isNone(message)) {
      return this.store.findRecord('message', params.id);
    } else {
      return message.reload();
    }
  }

  transitionToContentObjectPage(model) {
    this.transitionTo(OUTBOUND_EDITOR_ROUTES[model.redirectContentType], model.redirectRulesetId);
  }

  async afterModel(model) {
    super.afterModel(...arguments);
    if (model.shouldRedirectToContentObject) {
      return this.transitionToContentObjectPage(model);
    }
    let objectType = camelize(model.type.toLowerCase());
    this.outboundHomeService.setActiveList(objectTypes[objectType]);
  }
}
