/* RESPONSIBLE TEAM: team-ai-agent */
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import IntercomRoute from 'embercom/routes/base/intercom-route';
import type RouterService from '@ember/routing/router';

export default class AutomationFinAIAgentGuidanceRoute extends IntercomRoute {
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare router: RouterService;

  analytics = {
    section: 'automation',
    place: 'fin-ai-agent.guidance',
  };

  get titleToken(): string {
    return this.intl.t('ai-agent.sub-menu.guidance.title');
  }

  beforeModel() {
    if (!this.appService.app.canUseFinGuidance) {
      this.router.transitionTo('apps.app.automation.fin-ai-agent.setup');
    }
  }
}
