/* RESPONSIBLE TEAM: team-growth-opportunities */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Route from '@ember/routing/route';
import { PRICING_5_X_EARLY_STAGE_FREE_SOLUTION_ID } from 'embercom/lib/billing';
import getTransitionParams from 'embercom/lib/router/get-transition-params';

export default Route.extend({
  beforeModel(transition) {
    let params = getTransitionParams(transition);
    let queryParams = Object.assign(transition.to.queryParams || {}, {
      partner: params['early-stage-partner'].partner,
      solution_id: PRICING_5_X_EARLY_STAGE_FREE_SOLUTION_ID,
    });
    this.replaceWith('signup.teams.index', {
      queryParams,
    });
  },
});
