/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import RolesSettingsRoute from 'embercom/routes/apps/app/settings/base/roles-settings-route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import { hash } from 'rsvp';

export default class extends RolesSettingsRoute {
  @service declare intl: IntlService;
  @service declare notificationsService: $TSFixMe;
  @service declare intercomConfirmService: $TSFixMe;
  @service declare store: Store;

  get titleToken() {
    return this.intl.t('apps.app.settings.roles.edit.edit-role');
  }

  model(params: $TSFixMe) {
    return hash({
      saveChanges: this.saveChanges,
      delete: this.delete,
      role: this.store.findRecord('role', params.role_id),
    });
  }

  get getCurrentModel(): any {
    let model = this.modelFor(this.routeName) as $TSFixMe;
    return model.role;
  }

  @action
  async saveChanges() {
    try {
      await this.getCurrentModel.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('apps.app.settings.roles.edit.role-edited'),
      );
      this.transitionTo('apps.app.settings.workspace.teammates', {
        queryParams: {
          tab: 'roles',
        },
      });
    } catch (error) {
      let message;
      if (
        this.appService.app.cannotAssignWiderAccess &&
        isPresent(error?.jqXHR?.responseJSON?.tokens)
      ) {
        message = this.intl.t('settings.error_message.cannot_modify_unowned_permissions_error');
      } else {
        message =
          error.jqXHR?.responseJSON?.errors ||
          this.intl.t('settings.error_message.cannot_save_role_error');
      }
      this.notificationsService.notifyError(message);
    }
  }

  @action
  async delete() {
    if (
      await this.intercomConfirmService.confirm({
        title: this.intl.t('apps.app.settings.roles.remove-role-modal.remove-role'),
        body: this.intl.t('apps.app.settings.roles.remove-role-modal.message-body'),
        primaryButtonType: 'primary-destructive',
        confirmButtonText: this.intl.t('apps.app.settings.roles.remove-role-modal.remove'),
      })
    ) {
      try {
        await this.getCurrentModel.destroyRecord();
        this.transitionTo('apps.app.settings.workspace.teammates', {
          queryParams: {
            tab: 'roles',
          },
        });
      } catch (err) {
        let serverMessage =
          err?.jqXHR?.responseJSON?.message ||
          this.intl.t('apps.app.settings.roles.notification.please-try-again');
        let message = this.intl.t('apps.app.settings.roles.notification.role-cannot-be-deleted', {
          serverMessage,
        });
        this.notificationsService.notifyError(message);
      }
    }
  }

  @action
  async willTransition(transition: $TSFixMe) {
    if (this.getCurrentModel.hasDirtyAttributes) {
      transition.abort();

      if (
        await this.intercomConfirmService.confirm({
          title: this.intl.t('apps.app.settings.roles.close-without-saving-modal.title'),
          primaryButtonType: 'primary-destructive',
          confirmButtonText: this.intl.t(
            'apps.app.settings.roles.close-without-saving-modal.close-without-saving',
          ),
          cancelButtonText: this.intl.t(
            'apps.app.settings.roles.close-without-saving-modal.keep-editing',
          ),
          body: this.intl.t('apps.app.settings.roles.close-without-saving-modal.message-body'),
        })
      ) {
        this.getCurrentModel.rollbackAttributes();
        await transition.retry();
      }
    }
  }
}
