/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controller-access-in-routes */
import { hash } from 'rsvp';
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';

export default IntercomRoute.extend({
  store: service(),
  developerHub: service(),
  titleToken: 'Developer Hub',
  activate() {
    this._super(...arguments);
    this.hidePrimaryNav();
  },
  deactivate() {
    this._super(...arguments);
    this.showPrimaryNav();
  },
  fetchOauthScopes() {
    return this.store.findAll('developer-hub/oauth-scope');
  },
  model() {
    return hash({
      oauthScopes: this.fetchOauthScopes(),
    });
  },
  afterModel(model) {
    this.set('developerHub.oauthScopes', model.oauthScopes);
  },
  analytics: {
    section: 'developer_hub',
    place: 'developer_hub',
  },
  actions: {
    loading(transition) {
      if (!this.controller) {
        return;
      }

      this.controller.set('currentlyLoading', true);
      transition.promise.finally(() => {
        this.controller.set('currentlyLoading', false);
      });
    },
  },
});
