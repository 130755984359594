/* RESPONSIBLE TEAM: team-channels */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-controller-access-in-routes */
import AccountRouteBase from 'embercom/routes/apps/app/account/base/account-route-base';
import { inject as service } from '@ember/service';

import { ReactRoute } from 'embercom/lib/react-route';

const AccountRoute = AccountRouteBase.extend({
  appService: service(),
  store: service(),
  intl: service(),
  analytics: {
    section: 'your_account',
    place: 'account_access',
  },
  get titleToken() {
    return this.intl.t('account.account-access.title');
  },

  queryParams: {
    createFromMessengerConversation: { replace: true },
  },

  reactEnabled() {
    return this.appService.app.enableReactAccountAccessRoute;
  },

  // Do not prompt to save unsaved changes on this page
  activate() {},

  model(_, transition) {
    let sessions = this.store.findAll('session-device-detail');
    return this.store.findAll('admin-impersonation-consent').then((consents) => {
      let latestActiveConsent = consents
        .filter((consent) => consent.get('isActive'))
        .get('lastObject');

      if (
        !latestActiveConsent &&
        transition.to.queryParams.createFromMessengerConversation === 'true'
      ) {
        return this.store
          .createRecord('admin-impersonation-consent')
          .save()
          .then((consent) => {
            consents.toArray().pushObject(consent);
            return { latestActiveConsent: consent, consents };
          });
      }
      return { latestActiveConsent, consents: consents.toArray(), sessions };
    });
  },

  setupController(controller, model) {
    this._super(...arguments);
    let latestActiveConsent = model.latestActiveConsent;
    controller.set('impersonationConsents', model.consents);
    controller.set('sessions', model.sessions);

    if (!latestActiveConsent) {
      return controller.set('model', this.store.createRecord('admin-impersonation-consent'));
    }

    controller.set('model', latestActiveConsent);
  },

  actions: {
    willTransition(transition) {
      if (!this.reactEnabled() && !this.get('controller.model.isSaving')) {
        this.controller.revertUnsavedChanges();
      }
    },
  },
});

export default ReactRoute(AccountRoute);
