/* RESPONSIBLE TEAM: team-standalone */

import { default as OriginalRoute } from 'embercom/routes/apps/app/fin-ai-agent/content';

export default class Route extends OriginalRoute {
  beforeModel() {
    // noop – original route redirects to knowledge hub
  }

  templateName = 'apps/app/fin-ai-agent/content';
  controllerName = 'apps/app/fin-ai-agent/content';
}
