/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-controller-access-in-routes */
import UserConversationsRoute from 'embercom/routes/base/user-conversations-route';
import { inject as service } from '@ember/service';

export default UserConversationsRoute.extend({
  onboardingHomeExternalStepService: service(),

  externalStep: null,

  async beforeModel(transition) {
    this._super(...arguments);
    this.controllerFor('apps.app.users.user').set('selectedOption', 'all');

    if (transition.to.queryParams.external_step) {
      await this.onboardingHomeExternalStepService.start(transition.to.queryParams.external_step, {
        source: 'all-conversations route beforeModel',
      });
    }
  },
});
