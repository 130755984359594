/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
import Route from '@ember/routing/route';
import Admin from 'embercom/models/admin';
import { allSettled } from 'rsvp';
import { inject as service } from '@ember/service';

export default class InvalidRoute extends Route {
  @service iamService;
  @service intl;

  currentAdmin;

  async beforeModel() {
    this.intl.switchLocale(navigator.language);
    this.iamService.boot();
    return allSettled([
      Admin.me(false).then((admin) => {
        this.currentAdmin = admin;
      }),
    ]);
  }

  setupController(controller, model, transition) {
    super.setupController(...arguments);

    controller.set('currentAdmin', this.currentAdmin);
    if (this.currentAdmin) {
      controller.set('currentAdminEmail', this.currentAdmin.email);
    } else if (transition.to.queryParams.email) {
      controller.set('currentAdminEmail', transition.to.queryParams.email);
    }
  }
}
