/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import OutboundBaseListRoute from 'embercom/lib/outbound/base-list-route';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { inject as service } from '@ember/service';
import { statisticKeys } from 'embercom/models/data/outbound/constants';

export default OutboundBaseListRoute.extend({
  outboundHomeService: service(),

  beforeModel() {
    return this.redirectToAllOutboundSearch();
  },

  analytics: {
    section: 'outbound',
    place: 'banners',
  },
  titleToken: 'Banners',
  objectTypes: [objectTypes.banner],
  statistics: [statisticKeys.receipts, statisticKeys.goals, statisticKeys.clicks],
});
