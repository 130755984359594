/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { type Router } from '@ember/routing';
import type Store from '@ember-data/store';
import type Transition from '@ember/routing/transition';

export default class BillingSettingsRoute extends Route {
  @service declare store: Store;
  @service declare appService: $TSFixMe;
  @service declare customerService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare router: Router;

  get app() {
    return this.appService.app;
  }

  get customer() {
    return this.customerService.customer;
  }

  model() {
    return {
      canCancelSubscription:
        (this.customer.ongoingCancellableStripeMigration ||
          !this.customer.subscriptionWillBeCancelled) &&
        !this.app.isSalesforceContracted &&
        !this.customer.hasCustomPricing &&
        this.customer.hasActiveSubscription,
      emptyBillingAddresseesList: this.customer.billingAddressees?.length === 0,
    };
  }

  async beforeModel(_transition: Transition) {
    this._handleBillingSummaryTransition(await this._isSecondaryWorkspace());

    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'billing_settings_page',
    });
  }

  async _isSecondaryWorkspace() {
    if (this.app.isSalesforceContracted) {
      let contract = await this.store.findRecord('billing/contract', this.app.id);
      return contract?.isSecondarySubscription;
    }
  }

  _handleBillingSummaryTransition(isSecondaryWorkspace: boolean | undefined) {
    if (isSecondaryWorkspace) {
      return this.router.transitionTo('apps.app.settings.workspace.billing.summary');
    }
    return undefined;
  }
}
