/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-controller-access-in-routes */
/* eslint-disable ember/no-classic-classes */
import EmberObject, { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { task } from 'ember-concurrency';

export default Route.extend({
  intercomEventService: service(),
  maximumPendingUpdatesToPrepend: 10,
  titleToken: 'Conversations',

  realTimeEventService: service(),
  conversationFeedService: service(),
  onboardingHomeService: service(),
  store: service(),

  queryParams: {
    showing: {
      refreshModel: true,
    },
  },

  app: computed(function () {
    return this.modelFor('apps.app');
  }),

  model(params) {
    let status = params.showing || 'opened';
    return this.conversationFeedService.getConversationsPage(this._searchOptions(status));
  },

  setupController(controller, model) {
    if (!controller.showing) {
      controller.set('showing', 'opened');
    }
    controller.setProperties({
      hasMoreConversationsToLoad: true,
      conversations: model.conversations,
      conversationsTotalCount: model.totalCount,
      pendingConversations: [],
    });

    controller.contractConversations();
    controller.hideRepeatedDays();
  },

  activate() {
    this.realTimeEventService.on('ThreadCreated', this, '_prefetchPendingConversation');
    this.realTimeEventService.on('ThreadUpdated', this, '_prefetchPendingConversation');
    this.realTimeEventService.on('ThreadClosed', this, '_prefetchPendingConversation');
    this.realTimeEventService.on('ThreadReopened', this, '_prefetchPendingConversation');

    // Hooks
    this.realTimeEventService.on('ThreadCreated', this, '_newlyOpened');
    this.realTimeEventService.on('ThreadReopened', this, '_closedBecameOpen');
    this.realTimeEventService.on('ThreadClosed', this, '_openBecameClosed');
  },

  deactivate() {
    this.realTimeEventService.off('ThreadCreated', this, '_prefetchPendingConversation');
    this.realTimeEventService.off('ThreadUpdated', this, '_prefetchPendingConversation');
    this.realTimeEventService.off('ThreadClosed', this, '_prefetchPendingConversation');
    this.realTimeEventService.off('ThreadReopened', this, '_prefetchPendingConversation');

    // Hooks
    this.realTimeEventService.off('ThreadCreated', this, '_newlyOpened');
    this.realTimeEventService.off('ThreadReopened', this, '_closedBecameOpen');
    this.realTimeEventService.off('ThreadClosed', this, '_openBecameClosed');

    this.controller.contractConversations();
  },

  paginate: task(function* () {
    this.controller.set('isPaginating', true);
    let searchOptions = this._searchOptions(this.controller.get('showing'));
    searchOptions.set('pageFrom', this.controller.get('conversations.lastObject.updated_at'));
    searchOptions.set('currentConversations', this.controller.get('conversations'));

    try {
      let result = yield this.conversationFeedService.paginate(searchOptions);

      this.controller.setProperties({
        conversations: result.conversations,
        conversationsTotalCount: result.totalCount,
        hasMoreConversationsToLoad: result.hasMoreConversationsToLoad,
      });
      this.controller.hideRepeatedDays();
    } catch (e) {
      // no handling
    } finally {
      this.controller.set('isPaginating', false);
    }
  }).drop(),

  _newlyOpened() {
    this.controller.incrementProperty('openedCount');
  },

  _closedBecameOpen() {
    this.controller.incrementProperty('openedCount');
    this.controller.decrementProperty('closedCount');
  },

  _openBecameClosed() {
    this.controller.decrementProperty('openedCount');
    this.controller.incrementProperty('closedCount');
  },

  _prefetchPendingConversation(event) {
    let conversation = this.controller.get('conversations').findBy('id', event.conversationId);
    if (conversation) {
      conversation.handleRealtimeEventIfShould(event);
    } else {
      this.store.findRecord('conversation', event.conversationId).then(
        function (conversation) {
          conversation.handleRealtimeEventIfShould(event);

          if (conversation.get('isOpen') && this.controller.get('showing') === 'closed') {
            return;
          }

          if (conversation.get('isClosed') && this.controller.get('showing') === 'open') {
            return;
          }

          let pendingConversations = this.controller.get('pendingConversations');

          // remove the pending conversation if already present
          pendingConversations = pendingConversations.reject((pendingConversation) => {
            return pendingConversation.get('id') === conversation.get('id');
          });

          pendingConversations.unshiftObject(conversation);

          this.controller.set('pendingConversations', pendingConversations);

          if (this.controller.get('conversations.length') === 0) {
            this.send('prependUpdatedConversations');
          }
        }.bind(this),
      );
    }
  },

  _searchOptions(status) {
    return EmberObject.create({
      appId: this.get('app.id'),
      adminId: 'all',
      status,
    });
  },

  actions: {
    prependUpdatedConversations() {
      let pendingConversations = this.controller.get('pendingConversations');
      let pendingIds = pendingConversations.map((pendingConversation) =>
        pendingConversation.get('id'),
      );

      let displayedConversations = this.controller
        .get('conversations')
        .reject((conversation) => {
          // remove updated conversations that we’ll add back in a moment

          return pendingIds.includes(conversation.get('id'));
        })
        .reject((conversation) => {
          // only append conversations which match the open/closed feed state

          let feedState = this.controller.get('showing');
          if (!['open', 'closed'].includes(feedState)) {
            return false;
          }

          let conversationState = this.isOpen ? 'open' : 'closed';
          return conversationState !== feedState;
        });

      displayedConversations.unshiftObjects(pendingConversations);

      let trimPendingUpdates = pendingConversations.length > this.maximumPendingUpdatesToPrepend;
      // rendering a big load of new conversations is expensive and slow.
      // instead, just reset the feed to show the latest N, as we're already
      // scrolled to the top, and the user can paginate as required.

      if (trimPendingUpdates) {
        this.controller.setProperties({
          conversations: displayedConversations.slice(0, this.maximumPendingUpdatesToPrepend),
          hasMoreConversationsToLoad: true,
          pendingConversations: [],
        });
      } else {
        this.controller.setProperties({
          conversations: displayedConversations,
          pendingConversations: [],
        });
      }

      this.controller.hideRepeatedDays();
    },

    paginate() {
      if (this.controller.get('hasMoreConversationsToLoad')) {
        return this.paginate.perform();
      }
    },

    navigateToInstallMessenger() {
      this.intercomEventService.trackEvent('clicked-install-messenger-user-list-empty-state');
      return this.onboardingHomeService.openMessengerInstallationStep();
    },
  },
});
