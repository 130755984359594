/* RESPONSIBLE TEAM: team-standalone */
import NewChartCustomReportRoute from 'embercom/routes/apps/app/reports/custom-reports/report/show/chart/new';

type ModelParams = {
  chart_id: number;
};

export default class FinStandaloneNewChartRoute extends NewChartCustomReportRoute {
  model(params: ModelParams) {
    return super.model(params);
  }
}
