/* RESPONSIBLE TEAM: team-reporting */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class ConversationalInsightsIndexRoute extends Route {
  @service appService;

  beforeModel() {
    this.transitionTo('apps.app.reports.conversational-insights.topics');
  }
}
