/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default IntercomRoute.extend({
  titleToken: 'Webhooks',
  developerHub: service(),
  appPackage: alias('developerHub.appPackage'),
  analytics: { section: 'webhooks' },

  model() {
    return this.appPackage.apiVersion.get('webhookTopics');
  },
});
