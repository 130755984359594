/* RESPONSIBLE TEAM: team-help-desk-experience */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { hideIntercomWidgetWhenLoaded } from 'embercom/lib/intercom-widget-helper';
import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';

export default IntercomRoute.extend({
  analytics: {
    place: 'hosted_messenger',
  },

  onboardingHomeService: service(),
  appService: service(),
  guide: readOnly('onboardingHomeService.guide'),

  activate() {
    this.hidePrimaryNav();
    hideIntercomWidgetWhenLoaded();
  },

  model() {
    return this.onboardingHomeService.findOrLoadGuide();
  },

  setupController(controller) {
    controller.set('model', this.guide.findStepByIdentifier('send_test_message'));
  },

  deactivate() {
    this.showPrimaryNav();
  },
});
