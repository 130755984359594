/* RESPONSIBLE TEAM: team-reporting */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { hash } from 'rsvp';

export default class ConversationalInsightsTopicsIndexRoute extends Route {
  @service store;
  @service reportingService;
  @service conversationalInsights;
  @service intercomEventService;

  async model() {
    let topics = this.store.findAll('conversational-insights/conversation-topic');
    return hash({
      topics,
    });
  }

  @action
  didTransition() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'visited',
      object: 'topic_list',
      place: 'topics',
      topic_count: this.currentModel?.topics.length,
    });

    this.intercomEventService.trackEvent('visited-conversation-topics');
  }
}
