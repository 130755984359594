/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/require-snake-case-analytics */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
import { A } from '@ember/array';
import { computed } from '@ember/object';
import Route from '@ember/routing/route';
import EventTracking from 'embercom/components/mixins/articles/event-tracking';
import { inject as service } from '@ember/service';
import nonconcurrentAjax from 'embercom/lib/nonconcurrent-ajax';

export default Route.extend(EventTracking, {
  store: service(),
  intercomEventService: service(),
  queryParams: {
    interval: { refreshModel: true },
  },

  analytics: {
    place: 'articles',
    context: 'conversation_closed_by_operator',
  },

  app: computed(function () {
    return this.modelFor('apps.app');
  }),

  setupController(controller, model) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'visited',
      object: 'operator-closed-conversation',
      models: this.admin,
    });
    this._super(controller, model);
  },

  beforeModel(transition) {
    this.replaceWith('apps.app.reports.customer-support.articles.closed-by-operator', {
      queryParams: {
        interval: transition.to.queryParams.interval || 28,
      },
    });
  },

  model(params) {
    return nonconcurrentAjax(this, {
      url: '/ember/insights/closed_by_operator.json',
      type: 'GET',
      data: {
        app_id: this.modelFor('apps.app').get('id'),
        interval: params.interval,
      },
    }).then((response) => {
      return this._createConversationModelFromInsightsResponse(response);
    });
  },

  _createConversationModelFromInsightsResponse(response) {
    response.conversations = response.conversations || A();
    this.store.pushPayload({ conversations: response.conversations });
    return {
      total_count: response.conversations.length,
      conversations: A(
        response.conversations.map((c) => this.store.peekRecord('conversation', c.id)),
      ),
    };
  },
});
