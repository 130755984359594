/* RESPONSIBLE TEAM: team-ai-agent */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type Router from '@ember/routing/router-service';

export default class AiChatbotRoute extends Route {
  @service declare router: Router;
  @service declare appService: any;

  buildRouteInfoMetadata() {
    return {
      responsibleTeam: 'team-ai-agent',
    };
  }
  beforeModel(transition: any) {
    let target = transition.intent.url;
    this.router.transitionTo(target.replace('ai-chatbot', 'fin-ai-agent'));
  }
}
