/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import EmailTemplate from 'embercom/models/email-template';
import MessagesRealtimeStats from 'embercom/routes/mixins/messages/messages-realtime-stats';
import storage from 'embercom/vendor/intercom/storage';
import { hash, resolve } from 'rsvp';
import { fetchAdminPageviews } from 'embercom/lib/admin-view-tracking';
import { Scope } from 'embercom/objects/content-service/saved-views/constants/scopes';
import { DataFormat } from 'embercom/objects/content-service/saved-views/constants/data-formats';

export default Route.extend(MessagesRealtimeStats, {
  outboundHomeService: service(),
  intercomEventService: service(),
  appService: service(),
  store: service(),

  buildRouteInfoMetadata() {
    return {
      responsibleTeam: 'team-proactive-support',
    };
  },
  customerService: service(),
  analytics: {
    section: 'messages',
  },

  activate() {
    storage.set('lastProductUsed', 'outbound');
  },

  beforeModel() {
    this.outboundHomeService.setSearchParams({
      selectedMatchBehaviorValues: null,
    });
    this.intercomEventService.trackEvent('messages-visited');
    let app = this.modelFor('apps.app');
    let defaultEmailTemplates = app
      .get('translations')
      .then((translations) => EmailTemplate.populateDefaultTemplates(app, translations));
    return hash({
      accounts: this.store.findAll('account'),
      customer: resolve(app.get('canUseFree') ? this.customerService.refreshCustomer() : undefined),
      userSources: app.get('userSources'),
      defaultEmailTemplates,
      emailTemplateTitles: this.store.findAll('email-template-titles'),
      customEmailAddresses: app.get('customEmailAddresses'),
      emailTemplateData: this.store.findAll('email-template-data'),
      adminPageviews: fetchAdminPageviews(this.store),
      insertableContent: app.loadInsertableContent(),
    });
  },

  async afterModel() {
    await this.store.query('content-service/saved-view', {
      scope: Scope.OUTBOUND,
      saved_view_data_format: DataFormat.OUTBOUND,
    });
  },
});
