/* RESPONSIBLE TEAM: team-channels */
import { isPresent } from '@ember/utils';
import { copy } from 'ember-copy';
import ENV from 'embercom/config/environment';
import { inject as service } from '@ember/service';
import CheckUnsavedChanges from 'embercom/routes/apps/app/account/base/check-unsaved-changes-route';

export default CheckUnsavedChanges.extend({
  intl: service(),
  analytics: {
    section: 'your_account',
    place: 'general',
  },
  notificationsService: service(),
  get titleToken() {
    return this.intl.t('account.general.your-details');
  },
  beforeModel(transition) {
    let app = this.modelFor('apps.app');
    this._notifyConfirmationForQueryParams(transition.to.queryParams, app);
  },

  setupController(controller, model) {
    this._super(controller, model);
    controller.resetToCurrentAdminData();
  },

  _notifyConfirmationForQueryParams(queryParams, app) {
    let originalQueryParams = copy(queryParams);
    if (isPresent(queryParams) && queryParams !== {}) {
      if (queryParams['verifiedAdminEmail'] === 'true') {
        this.notificationsService.notifyConfirmation(
          this.intl.t('account.general.email-verified', { email: app.get('currentAdmin.email') }),
          ENV.APP._15000MS,
        );
        delete queryParams['verifiedAdminEmail'];
      } else if (queryParams['verifiedAdminEmail'] === 'false') {
        this.notificationsService.notifyError(
          this.intl.t('account.general.could-not-verified', {
            email: app.get('currentAdmin.email'),
          }),
          ENV.APP._15000MS,
        );
        delete queryParams['verifiedAdminEmail'];
      }
      if (originalQueryParams !== queryParams) {
        this.transitionTo('apps.app.account.general', app, { queryParams });
      }
    }
  },
});
