/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import {
  BILLING_REDIRECT_MAPPING,
  routeRedirector,
} from 'embercom/lib/settings/settings-redirect-map';

export default class BillingRoute extends Route {
  @service store;
  @service paywallService;
  @service permissionsService;
  @service customerService;
  @service appService;
  @service stripeLoaderService;
  @service notificationsService;
  @service router;

  titleToken = 'Subscription';

  analytics = {
    section: 'billing_settings',
  };

  beforeModel(transition) {
    if (this.appService.app.canSeeNewIASettingsHub && this.appService.app.canSeeIASettingsGA) {
      routeRedirector(
        transition,
        this.router,
        BILLING_REDIRECT_MAPPING,
        'apps.app.settings.workspace.billing.index',
      );
    }
    if (!transition.to.queryParams.from_billing_admin) {
      this.permissionsService.ensurePermissionWhenTransitioning(
        'can_access_billing_settings',
        transition,
      );
    }
  }

  model() {
    let app = this.modelFor('apps/app');
    return this.store.findRecord('billing/customer', app.get('customer_id'));
  }

  afterModel(model, transition) {
    let hasPermission = this.permissionsService.currentAdminCan('can_access_billing_settings');
    if (!hasPermission) {
      return this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(
        'can_access_billing_settings',
      );
    }

    let customer = model;
    let app = this.appService.app;

    if (app.isDeveloperWorkspace || app.isTestApp) {
      this.notificationsService.notifyError(
        'Developer and Test Workspaces cannot access billing settings',
      );
      return this.transitionTo('apps.app');
    }

    if (
      customer.get('hasNoActiveSubscription') &&
      !customer.get('hasEverHadSubscription') &&
      !(this.appService.app.hasValueBasedPricing || this.appService.app.onPricing5) &&
      !this._openingModal(transition)
    ) {
      return this._noSubscriptionRedirect(customer);
    }
    return this.loadData.perform(customer);
  }

  setupController(_controller, _model, transition) {
    super.setupController(...arguments);

    if (transition.to.queryParams.feature) {
      this.paywallService.paywall({ featureName: transition.to.queryParams.feature });
    }
  }

  @task *loadData(customer) {
    yield Promise.all([
      this.stripeLoaderService.load(),
      this.customerService.loadData({
        customer,
        fetchPrices: false,
      }),
      this.customerService.getStripeMigration(),
    ]);
  }

  _noSubscriptionRedirect(customer) {
    let regularPlan = customer.get('canModifyOrRemoveProducts');
    let app = this.appService.app;

    if (app.validEarlyStageApplicant) {
      return this._redirectForEarlyStageApplicant();
    } else if (regularPlan) {
      this.paywallService.redirectToPurchase();
    }
  }

  _redirectForEarlyStageApplicant() {
    this.transitionTo(
      this.paywallService.earlyStageApplicationRoute,
      this.paywallService.earlyStageApplicationRouteParameters,
    );
  }

  _openingModal(transition) {
    return (
      transition &&
      (transition.to.queryParams.addressModalIsOpen ||
        transition.to.queryParams.creditCardModalIsOpen)
    );
  }
}
