/* RESPONSIBLE TEAM: team-product-exploration */

import { inject as service } from '@ember/service';
import AccountRouteBase from '../../../account/base/account-route-base';
import type Router from '@ember/routing/router-service';

const COMPANY_SEGMENTS_ROUTE = 'apps.app.settings.personal.visible-to-you.company-segments';
const USER_SEGMENTS_ROUTE = 'apps.app.settings.personal.visible-to-you.user-segments';

export default class IndexRoute extends AccountRouteBase {
  @service declare router: Router;
  @service declare appService: $TSFixMe;

  beforeModel() {
    if (!this.appService.app.companies_enabled) {
      this.router.replaceWith(USER_SEGMENTS_ROUTE);
      return;
    }
    this.router.replaceWith(COMPANY_SEGMENTS_ROUTE);
  }
}
