/* RESPONSIBLE TEAM: team-ai-agent */

import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';

export default class ResolutionBotAnswersAnswerShowIndexRoute extends IntercomRoute {
  @service declare router: RouterService;

  redirect(model: $TSFixMe) {
    this.router.replaceWith('apps.app.fin-ai-agent.custom-answers.answer.show.index', model.id);
  }
}
