/* RESPONSIBLE TEAM: team-product-exploration */

import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import AccountRouteBase from '../../../account/base/account-route-base';
import type Router from '@ember/routing/router-service';

const USER_SEGMENTS_ROUTE = 'apps.app.settings.personal.visible-to-you.user-segments';

export default class CompanySegmentsRoute extends AccountRouteBase {
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare router: Router;

  beforeModel() {
    if (!this.appService.app.companies_enabled) {
      this.router.replaceWith(USER_SEGMENTS_ROUTE);
      return;
    }
  }

  analytics = {
    section: 'settings',
    place: 'personal.visible-to-you.company-segments',
  };

  get titleToken() {
    return this.intl.t('new-settings.submenu.personal.visible-to-you');
  }
}
