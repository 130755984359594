/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { inject as service } from '@ember/service';
import IntercomRoute from 'embercom/routes/base/intercom-route';

export default IntercomRoute.extend({
  store: service(),
  model(params) {
    return this.store.queryRecord('oauth/authorization-request', {
      client_id: params.clientId,
    });
  },
  afterModel(model, transition) {
    transition.trigger(
      false,
      'setDocumentTitle',
      `${model.get('appPackageName')} wants access to Intercom`,
    );
  },
  activate() {
    this.hidePrimaryNav();
  },
  deactivate() {
    this.showPrimaryNav();
  },
});
