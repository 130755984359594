/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-controller-access-in-routes */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';

const DEFAULT_ROUTE = 'apps.app.operator.task-bots.users';

export default IntercomRoute.extend({
  store: service(),
  intercomEventService: service(),
  navbarCollapsingService: service(),
  appService: service(),
  router: service(),
  app: readOnly('appService.app'),
  titleToken: 'Task Bots',
  warningMessage: 'Warning: You have unsaved changes. Do you want to leave the page?',

  model() {
    let findDescriptorPromise = async () => {
      return this.store.findAll('conversation-attributes/descriptor');
    };
    return Promise.all([
      findDescriptorPromise(),
      this.store.findRecord('operator-settings/task-bots', this.get('app.id')),
    ]).then((response) => {
      return response[1];
    });
  },

  beforeModel(transition) {
    let targetRoute = transition.to.name;
    if (
      targetRoute === 'apps.app.operator.task-bots' ||
      targetRoute === 'apps.app.operator.task-bots.index'
    ) {
      this.transitionTo(DEFAULT_ROUTE);
    }
  },

  redirect() {
    if (this.app.canSeeTaskBotsRedesign) {
      return this.router.transitionTo('apps.app.automation.basics');
    }
  },

  activate() {
    this._super(...arguments);
    window.onbeforeunload = () => {
      let controller = this.controller;
      if (controller.currentTaskHasChanged()) {
        return this.warningMessage;
      }
    };
    this.navbarCollapsingService.setActiveSubmenu('Operator');
    if (this.get('navbarCollapsingService.collapsed')) {
      this.navbarCollapsingService.toggleNavbarCollapsed();
    }
  },

  deactivate() {
    window.onbeforeunload = undefined;
  },

  actions: {
    didTransition() {
      let settings = this.get('controller.settings').serialize();
      let eventsService = this.intercomEventService;
      eventsService.trackAnalyticsEvent({
        action: 'visited',
        object: 'task_bots_settings',
        settings,
      });
      this._super();
    },

    willTransition(transition) {
      let controller = this.controller;
      if (controller.currentTaskHasChanged() && !window.confirm(this.warningMessage)) {
        transition.abort();
        return;
      } else if (transition.to.name === 'apps.app.operator.task-bots.index') {
        this.transitionTo(DEFAULT_ROUTE);
      }
    },
  },
});
