/* RESPONSIBLE TEAM: team-channels */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controller-access-in-routes */
import AccountRouteBase from 'embercom/routes/apps/app/account/base/account-route-base';

export default AccountRouteBase.extend({
  activate() {
    this._super(...arguments);
    window.onbeforeunload = function () {
      if (this.controller.hasUnsavedChanges()) {
        return 'Warning: You have unsaved changes.';
      }
    }.bind(this);
  },

  deactivate() {
    this._super(...arguments);
    window.onbeforeunload = undefined;
    if (this.controller.hasUnsavedChanges()) {
      this.controller.revertUnsavedChanges();
    }
  },

  willDestroy() {
    this._super(...arguments);
    window.onbeforeunload = undefined;
  },

  actions: {
    willTransition(transition) {
      if (this.controller.hasUnsavedChanges()) {
        if (!confirm('Warning: You have unsaved changes. Do you want to leave the page?')) {
          transition.abort();
        } else {
          this.controller.revertUnsavedChanges();
        }
      }
    },
  },
});
