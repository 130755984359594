/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
export default Route.extend({
  notificationsService: service('notificationsService'),
  redirect() {
    this.notificationsService.notifyError("We couldn't find the page you're looking for", -1);
    this.transitionTo('appstore');
  },
});
