/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
  store: service(),
  developerHub: service(),
  model() {
    let appStoreListingPath = 'appPackage.changeRequest.appPackageConfig.appStoreListing';
    this.developerHub.fillEmptyModel(
      appStoreListingPath,
      this.store.createFragment('developer-hub/app-store-listing'),
    );
    return this.store.query('appstore/category', { category_type: 'default' });
  },
  actions: {
    willTransition(transition) {
      if (this.get('developerHub.appPackage').hasDirtyAttributes) {
        this.get('developerHub.appPackage').rollbackAttributes();
      }
    },
  },
});
