/* RESPONSIBLE TEAM: team-standalone */
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { inject as service } from '@ember/service';

export default class FinStandaloneChannelsRoute extends Route {
  @service declare appService: any;
  @service declare finStandaloneService: any;
  @service declare router: RouterService;

  async beforeModel(transition: Transition) {
    await this.finStandaloneService.initialize();

    if (transition.to.name === 'apps.app.standalone.channels.index') {
      await this.router.transitionTo('apps.app.standalone.channels.overview');
    }
  }
}
