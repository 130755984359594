/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import ajax from 'embercom/lib/ajax';
import { action } from '@ember/object';

export default class TopicRoute extends IntercomRoute {
  @service notificationsService;
  @service appService;
  @service store;

  mergedTopic;

  async retrieveTopics(topic_ids) {
    return await ajax({
      url: '/ember/conversational_insights/suggested_conversation_topics/query',
      type: 'GET',
      contentType: 'application/json',
      data: {
        app_id: this.appService.app.id,
        topic_ids,
      },
    });
  }

  async model(params) {
    let topic_ids = params.topic_ids.split(',');
    let topics = await this.retrieveTopics(topic_ids);

    let suggestedTopicIds = topics.map((t) => t.id);
    let name = topics.map((t) => t.name).join(' and ');
    let keyphraseSet = new Set(topics.map((t) => t.keyphrases).flat());
    let keyphrases = [...keyphraseSet];

    this.mergedTopic = this.store.createRecord('conversational-insights/conversation-topic', {
      name,
      keyphrases,
    });
    return {
      mergedTopic: this.mergedTopic,
      suggestedTopicIds,
    };
  }

  @action
  willTransition() {
    this.mergedTopic.rollbackAttributes();
  }
}
