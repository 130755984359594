/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import BaseProductIndexRoute from 'embercom/routes/base/product-index-route';

export default BaseProductIndexRoute.extend({
  intercomEventService: service(),
  analytics: {
    place: 'index',
  },

  product: readOnly('app.articlesProduct'),
  defaultRoutePath: 'apps.app.articles.articles',

  activate() {
    this._super(...arguments);
    this.intercomEventService.trackEvent('visited_educate_product_section', {
      action: 'visited',
      object: 'educate_product_section',
      place: 'educate_product_section',
      owner: 'growth-sp',
    });
  },
});
