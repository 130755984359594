/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controller-access-in-routes */
import UserConversationsRoute from 'embercom/routes/base/user-conversations-route';

export default UserConversationsRoute.extend({
  beforeModel() {
    this._super(...arguments);
    this.controllerFor('apps.app.users.user').set('selectedOption', 'all-conversations');
    this.transitionTo('apps.app.users.user.all-conversations');
  },
});
