/* RESPONSIBLE TEAM: team-workflows */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class CustomBot extends Route {
  @service store;
  @service notificationsService;
  @service router;
  @service intl;

  workflowRoute = 'apps.app.automation.workflows.edit';

  model(params) {
    try {
      return this.store.findRecord('operator/custom-bot', params.id);
    } catch (err) {
      console.error(err);
      this.notificationsService.notifyError(
        this.intl.t('operator.custom-bot.overview.error.error-loading-that-bot'),
      );
      this.router.replaceWith('apps.app.automation.workflows-overview');
    }
  }

  afterModel(customBot) {
    if (customBot.type && customBot.rulesetId) {
      this.router.replaceWith(this.workflowRoute, customBot.rulesetId);
    } else {
      console.error("Custom Bot doesn't have associated ruleset");
      this.notificationsService.notifyError(
        this.intl.t('operator.custom-bot.overview.error.error-loading-that-bot'),
      );
      this.router.replaceWith('apps.app.automation.workflows-overview');
    }
  }
}
