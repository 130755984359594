/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Route from '@ember/routing/route';

export default Route.extend({
  beforeModel(transition) {
    let originalTarget = transition.targetName;
    let newTarget = originalTarget.replace(
      'apps.app.outbound.insights',
      'apps.app.reports.outbound-engagement',
    );
    return this.replaceWith(newTarget);
  },
});
