/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';
import type HelpcenterMainController from 'embercom/controllers/apps/app/settings/helpcenter/workspace-helpcenter';
import type Transition from '@ember/routing/transition';
import ajax from 'embercom/lib/ajax';
import type HelpcenterController from 'embercom/controllers/apps/app/settings/helpcenter/workspace-helpcenter';

// start of Q2C1FY23
const TLS_CUTOFF_DATE = Date.parse('2022-05-01');

export default class SettingsRoute extends IntercomRoute {
  @service declare helpCenterService: $TSFixMe;
  @service declare helpCenterSettingNavigationService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare permissionsService: $TSFixMe;
  @service declare intl: IntlService;

  owner: $TSFixMe;

  analytics = {
    place: 'settings',
  };

  get titleToken() {
    return this.intl.t('new-settings.helpcenter.settings.title');
  }

  async model() {
    let controller = this.controllerFor(
      'apps.app.settings.helpcenter.workspace-helpcenter',
    ) as HelpcenterController;
    let uploadHelper = controller.uploadHelper;
    let tlsSelectionHelper = controller.tlsSelectionHelper;
    let id = this.helpCenterService.site.id;
    let isManagedCustomDomainEnabled =
      this.appService.app.shouldBypassTlsCutoffDate ||
      Date.parse(this.appService.app.created_at) > TLS_CUTOFF_DATE;
    let customDomain = null;
    if (isManagedCustomDomainEnabled) {
      let customDomains = await ajax({
        type: 'GET',
        url: `/ember/custom_domains?app_id=${this.appService.app.id}&help_center_id=${id}`,
      });
      customDomain = customDomains.length > 0 ? customDomains[0] : null;
    }
    return { customDomain, isManagedCustomDomainEnabled, uploadHelper, tlsSelectionHelper };
  }

  async setupController(controller: $TSFixMe, model: $TSFixMe, transition: Transition) {
    super.setupController(controller, model, transition);
    controller.loadVisibleCollections.perform();
  }

  @action
  willTransition(transition: Transition) {
    let controller = this.controllerFor(
      'apps.app.settings.helpcenter.workspace-helpcenter',
    ) as HelpcenterMainController;
    if (controller.isSaveEnabled) {
      if (confirm(this.intl.t('new-settings.helpcenter.settings.unsaved-changes'))) {
        this.helpCenterService.site.rollbackAttributes();
      } else {
        transition.abort();
      }
    }
    this.helpCenterSettingNavigationService.reset();
  }

  trackEducateEvent(metadata: $TSFixMe) {
    metadata.owner = 'educate';
    this.intercomEventService.trackEvent('educate-event', metadata);
  }

  @action
  didTransition() {
    this.trackEducateEvent({ action: 'visited', object: 'settings', place: 'settings' });
  }
}
