/* RESPONSIBLE TEAM: team-messenger */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import type MessengerSettingsService from 'embercom/services/messenger-settings-service';
import type Transition from '@ember/routing/transition';
import { DEFAULT_SECTIONS_FOR_TABS, type Tab } from 'embercom/services/messenger-settings-service';

export default class NewMessengerGeneralRoute extends IntercomRoute {
  @service declare MessengerSettingsService: MessengerSettingsService;

  queryParams = {
    tab: {
      refreshModel: false,
      replace: true,
    },
    section: {
      refreshModel: false,
      replace: true,
    },
  };

  buildRouteInfoMetadata() {
    return {
      responsibleTeam: 'team-messenger',
    };
  }

  afterModel(_: any, transition: Transition) {
    let { tab, section } = transition.to.queryParams;
    tab ||= 'general';
    section ||= DEFAULT_SECTIONS_FOR_TABS[tab as Tab];
    this.MessengerSettingsService.navigate(tab as Tab, section, { changeUrl: false });
  }
}
