/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import type Store from '@ember-data/store';
import type Session from 'embercom/services/session';
import type TicketStateService from 'embercom/services/ticket-state-service';
import type Inbox2AssigneeSearch from 'embercom/services/inbox2-assignee-search';

export default class WorkflowsRoute extends Route {
  @service declare store: Store;
  @service intercomEventService: any;
  @service contentImportService: any;
  @service appService: any;
  @service session!: Session;
  @service notificationsService: any;
  @service declare ticketStateService: TicketStateService;
  @service declare inbox2AssigneeSearch: Inbox2AssigneeSearch;

  titleToken = 'Workflows';

  analytics = {
    place: 'custom-bots',
    section: 'operator',
  };

  get app() {
    return this.appService.app;
  }

  async beforeModel() {
    this.session.switchWorkspace(this.app.id);
    await this.session.workspace.fetchRequiredAttributes();
    await this.ticketStateService.setup();

    this.intercomEventService.trackEvent('visited-custom-bots', {
      onMatchingSystem: true,
    });
  }

  async model() {
    let [
      descriptors,
      customObjects,
      qualificationAttributes,
      messengerApps,
      customActions,
      customActionsTemplates,
      inboundTriggers,
    ] = await Promise.all([
      this.store.findAll('conversation-attributes/descriptor'),
      this.store.findAll('custom-objects/object-type'),
      this.store.findAll('people/qualification-attribute'),
      this.store.findAll('messenger-app'),
      this.store.peekAll('workflow-connector/insertable-action'),
      this.store.findAll('office-hours-schedule'),
      this.store.findAll('conversational-insights/conversation-topic'),
      this.store.findAll('inbox/ticket-type'),
      this.store.findAll('instagram/integration').catch(() => {
        this.notificationsService.notifyError('Error loading Instagram business accounts');
      }),
      this.store.query('whatsapp/integration', { no_external_updates: true }),
      this.store.findAll('workflow-connector/inbound-trigger'),
      this.store.findRecord('messenger-settings/all', this.appService.app.id),
    ]);

    return hash({
      descriptors,
      customObjects,
      qualificationAttributes,
      messengerApps,
      customActions,
      customActionsTemplates,
      inboundTriggers,
    });
  }

  async afterModel() {
    this.inbox2AssigneeSearch.loadAllAdminsAndTeams();
  }
}
