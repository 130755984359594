/* RESPONSIBLE TEAM: team-product-exploration */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type Router from '@ember/routing/router-service';
import {
  PERSONAL_BACKTRACK_REDIRECT_MAPPING,
  routeRedirector,
} from 'embercom/lib/settings/settings-redirect-map';

export default class IndexRoute extends Route {
  @service declare router: Router;
  @service declare appService: any;

  beforeModel(transition: any) {
    if (!this.appService.app.hasOptedInForProductIa) {
      routeRedirector(
        transition,
        this.router,
        PERSONAL_BACKTRACK_REDIRECT_MAPPING,
        'apps.app.account.general',
      );
    }
  }
}
