/* RESPONSIBLE TEAM: team-messenger */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import { hash } from 'ember-concurrency';
import SdkApp from 'embercom/models/sdk-app';
import type SdkAppService from 'embercom/services/sdk-app-service';
import type IntlService from 'ember-intl/services/intl';

export default class MessengerInstallRoute extends IntercomRoute {
  @service declare appService: any;
  @service declare store: any;
  @service declare sdkAppService: SdkAppService;
  @service declare intl: IntlService;

  queryParams = {
    tab: {
      refreshModel: false,
      replace: true,
    },
    section: {
      refreshModel: false,
      replace: true,
    },
  };

  get titleToken() {
    return this.intl.t(`new-settings.submenu.channels.messenger`);
  }

  buildRouteInfoMetadata() {
    return {
      responsibleTeam: 'team-messenger',
    };
  }

  async model() {
    return await hash({
      ios: SdkApp.findOrCreateForPlatform('iOS'),
      android: SdkApp.findOrCreateForPlatform('Android'),
    });
  }

  // This is a bit of a hack, please see PR description for more context
  // https://github.com/intercom/embercom/pull/84157
  setupController(controller: $TSFixMe, model: $TSFixMe, transition: $TSFixMe) {
    super.setupController(controller, model, transition);
    controller.set('iosApiSecret', model.ios.api_secret);
    controller.set('iosApiKey', model.ios.api_key);
    controller.set('iosGcmKey', model.ios.gcm_key);
    controller.set('androidApiKey', model.android.api_key);
    controller.set('androidGcmKey', model.android.gcm_key);
    controller.set('androidApiSecret', model.android.api_secret);
    controller.set('iosSdkApp', model.ios);
    controller.set('androidSdkApp', model.android);
  }
}
