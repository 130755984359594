/* RESPONSIBLE TEAM: team-data-interop */
import SettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';
import type Transition from '@ember/routing/transition';

export default class AuthenticationNewRoute extends SettingsRoute {
  @service declare store: $TSFixMe;
  @service declare intl: IntlService;
  @service declare intercomConfirmService: $TSFixMe;

  model() {
    return this.store.createRecord('custom-authentication/token');
  }

  get getCurrentModel(): any {
    return this.modelFor(this.routeName);
  }

  get currentModelIsDirty() {
    // we can't use .hasDirtyAttributes because that's always true for Ember Data models where .isNew is true
    return (
      !this.getCurrentModel.isDestroying &&
      Object.keys(this.getCurrentModel.changedAttributes()).length > 0
    );
  }

  @action
  async willTransition(transition: Transition) {
    let confirmOptions = {
      primaryButtonType: 'primary-destructive',
      title: this.intl.t('workflow-connector.builder.modal.title'),
      confirmButtonText: this.intl.t('workflow-connector.builder.modal.confirm-button'),
      cancelButtonText: this.intl.t('workflow-connector.builder.modal.cancel-button'),
      body: this.intl.t('workflow-connector.builder.modal.body'),
    };
    if (this.currentModelIsDirty) {
      transition.abort();
      let isConfirmed = await this.intercomConfirmService.confirm(confirmOptions);
      if (!isConfirmed) {
        return;
      }
      this.getCurrentModel.unloadRecord();
      transition.retry();
    } else {
      // unload so it doesn't appear in the index if you click the back button
      this.getCurrentModel.unloadRecord();
    }
  }
}
