/* RESPONSIBLE TEAM: team-proactive-support */
import LeadGenerationCustomBotsRoute from 'embercom/routes/apps/app/reports/lead-generation/custom-bots';

export default LeadGenerationCustomBotsRoute.extend({
  controllerName: 'apps.app.reports.lead-generation.custom-bots',
  templateName: 'apps.app.reports.lead-generation.custom-bots',

  beforeModel() {
    this.replaceWith('apps.app.reports.customer-support.custom-bots.sent');
  },
});
