/* RESPONSIBLE TEAM: team-workflows */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import { get } from 'embercom/lib/ajax';

export default class AppsAppOperatorRoute extends Route {
  @service store;
  @service appService;
  @service router;
  @service contentImportService;

  get app() {
    return this.appService.app;
  }

  buildRouteInfoMetadata() {
    return {
      responsibleTeam: 'team-workflows',
    };
  }

  async model() {
    this.contentImportService.fetchContentIngestionState();
    this.contentImportService.fetchAiContentLibrarySummary();
    this.contentImportService.fetchContentReviewSummary();
    await this.contentImportService.fetchFinConversationContentSettings();

    let counts = {};

    // We don't want to fail the page load in case this ever goes wrong
    try {
      let { custom_bots: customBots, answers } = await get(
        `/ember/operator_workflows/workflow_counts`,
        {
          app_id: this.app.id,
        },
      );
      counts = {
        customBots,
        answers,
      };
    } catch (e) {
      console.error(e);
    }

    return hash({
      lookAndFeel: this.store.findRecord('messenger-settings/look-and-feel', this.app.id),
      availability: this.store.findRecord('messenger-settings/availability', this.app.id),
      counts,
    });
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    controller.initializeConfigurationModel();
  }
}
