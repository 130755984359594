/* RESPONSIBLE TEAM: team-frontend-tech */
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type GeneralController from 'embercom/controllers/apps/app/settings/workspace/general';
import WorkspaceSettingsRoute from 'embercom/routes/apps/app/settings/base/workspace-settings-route';
import { get } from 'embercom/lib/ajax';
import { hash } from 'rsvp';

export default class GeneralRoute extends WorkspaceSettingsRoute {
  @service declare appService: any;
  @service declare store: any;
  @service declare intl: IntlService;

  queryParams = {
    highlight: {
      refreshModel: true,
    },
  };

  analytics = {
    section: 'new_settings',
    place: 'general',
  };

  get titleToken() {
    return this.intl.t(`new-settings.submenu.workspace.general`);
  }

  resetController(controller: GeneralController, isExiting: any) {
    if (isExiting) {
      controller.set('highlight', null);
    }
  }

  async model() {
    try {
      return hash({
        generalSettings: this.store.findRecord('general-app-settings', this.appService.app?.id),
        messengerSettings: this.store.findRecord('messenger-settings/all', this.appService.app?.id),
        companyAttribute:
          (
            await get(`/ember/apps/${this.appService.app?.id}.json?attribute_allowlist=attributes`)
          )?.attributes?.find((attr: any) => attr.identifier === 'companies') || {},
      });
    } catch (e) {
      return { generalSettings: {}, messengerSettings: {}, companyAttribute: {} };
    }
  }
}
