/* RESPONSIBLE TEAM: team-app-security */

import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Store from '@ember-data/store';
import { action } from '@ember/object';
import type Transition from '@ember/routing/transition';
import type AccountAccessController from 'embercom/controllers/apps/app/settings/personal/account-access';
import IntercomRoute from 'embercom/routes/base/intercom-route';

export default class AccountAccessRoute extends IntercomRoute {
  @service declare appService: $TSFixMe;
  @service declare store: Store;
  @service declare intl: IntlService;

  analytics = {
    section: 'settings',
    place: 'personal.account-access',
  };

  get titleToken() {
    return this.intl.t('new-settings.submenu.personal.account-access');
  }

  queryParams = {
    createFromMessengerConversation: { replace: true },
  };

  reactEnabled() {
    return this.appService.app.enableReactAccountAccessRoute;
  }

  // Do not prompt to save unsaved changes on this page
  activate() {}

  async model(_: $TSFixMe, transition: Transition) {
    let sessions = await this.store.findAll('session-device-detail');
    let consents = await this.store.findAll('admin-impersonation-consent');

    let latestActiveConsent = consents
      .filter((consent) => consent.get('isActive'))
      .get('lastObject');

    if (
      !latestActiveConsent &&
      transition.to.queryParams.createFromMessengerConversation === 'true'
    ) {
      let consent = await this.store.createRecord('admin-impersonation-consent').save();
      consents.toArray().pushObject(consent);
      return { latestActiveConsent: consent, consents };
    }

    return { latestActiveConsent, consents: consents.toArray(), sessions };
  }

  setupController(controller: AccountAccessController, model: $TSFixMe, _transition: Transition) {
    super.setupController(controller, model, _transition);
    let latestActiveConsent = model.latestActiveConsent;
    controller.set('impersonationConsents', model.consents);
    controller.set('sessions', model.sessions);
    controller.set('titleToken', this.titleToken);

    if (!latestActiveConsent) {
      return controller.set('model', this.store.createRecord('admin-impersonation-consent'));
    }

    controller.set('model', latestActiveConsent);
  }

  @action
  willTransition(_transition: Transition) {
    let controller = this.controller as AccountAccessController;
    if (!this.reactEnabled() && !controller.model.isSaving) {
      controller.revertUnsavedChanges();
    }
  }
}
