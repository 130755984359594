/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import EducateArticleListBase from 'embercom/routes/apps/app/articles/articles/list/educate-article-list-base';

export default EducateArticleListBase.extend({
  selectedContentStatus: 'missing',
  title: 'Missing articles',
});
