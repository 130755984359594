/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
import WorkflowsBaseRoute from 'embercom/routes/apps/app/settings/inbox-rules/workflows-base';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';

export default class WorkflowsEditRoute extends WorkflowsBaseRoute {
  @service intl;
  @service intercomConfirmService;
  @service store;

  queryParams = {
    mode: { refreshModel: true },
  };

  beforeModel(transition) {
    super.beforeModel(...arguments);

    this.store.findAll('inbox/ticket-type');

    if (this.appService.app.canUseFeature('disable-inbox-rules')) {
      this.transitionTo('apps.app.automation.workflows-overview');
    }
  }

  model(params) {
    return RSVP.hash({
      workflow: this.store.findRecord('workflow', params.id),
      ...this.contextModels(),
    });
  }

  setupController(controller, models) {
    controller.setProperties({
      workflow: models.workflow,
      actionEditorContext: {
        isRulePage: false,
        canRemoveAssignee: true,
        allowSlaOnDelete: true,
        isWorkflows: true,
        workflowConnectorActions: models.workflowConnectorActions,
        conversationSlas: models.conversationSlas,
        officeHoursSchedules: models.officeHoursSchedules,
        conversationCustomAttributes: models.conversationCustomAttributes,
      },
    });
  }

  activate() {
    window.onbeforeunload = () => {
      if (this.controller.workflow.hasChanges) {
        return this.intl.t('settings.inbox-rules.edit.warn-unsaved-changes');
      }
    };
  }

  deactivate() {
    window.onbeforeunload = undefined;
  }

  @action
  async willTransition(transition) {
    let workflow = this.controller.workflow;
    if (workflow.hasChanges) {
      transition.abort();

      // This seems to cover an underlying ember bug https://github.com/emberjs/ember.js/issues/5210
      if (window.history) {
        window.history.forward();
      }

      let isConfirmed = await this.intercomConfirmService.confirm({
        title: this.intl.t('settings.inbox-rules.edit.close-without-saving-title'),
        primaryButtonType: 'primary-destructive',
        confirmButtonText: this.intl.t('settings.inbox-rules.edit.close-without-saving-button'),
        cancelButtonText: this.intl.t('settings.inbox-rules.edit.keep-editing-button'),
        body: this.intl.t('settings.inbox-rules.edit.lose-changes'),
      });
      if (!isConfirmed) {
        return;
      }
      workflow.rollbackAttributes();
      transition.retry();
    }
  }

  resetController(controller) {
    controller.set('mode', null);
  }
}
