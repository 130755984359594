/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controller-access-in-routes */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import EventTracking from 'embercom/components/mixins/articles/event-tracking';
import ajax from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';

// start of Q2C1FY23
const TLS_CUTOFF_DATE = Date.parse('2022-05-01');

export default IntercomRoute.extend(EventTracking, {
  helpCenterService: service(),
  helpCenterSettingNavigationService: service(),
  titleToken: 'Help Center settings',
  analytics: {
    place: 'settings',
  },

  async setupController(controller, model) {
    this._super(controller, model);

    let currentImport = await controller.getApiImport();
    if (currentImport) {
      controller.set('hasActiveApiImport', true);
      controller.set('currentImport', currentImport);
    } else {
      controller.set('hasActiveApiImport', false);
      controller.set('currentImport', null);
    }

    controller.getSyncSettings.perform();
    controller.loadVisibleCollections.perform();
  },

  actions: {
    willTransition(transition) {
      if (this.controller.get('saveEnabled')) {
        if (confirm('Warning: You have unsaved changes. do you want to leave the page?')) {
          this.controller.send('cancel');
        } else {
          transition.abort();
        }
      }
      this.helpCenterSettingNavigationService.reset();
    },
    didTransition() {
      this._super();
      this.controller.resetMediaUploader();
      this.trackEducateEvent({ action: 'visited', object: 'settings', place: 'settings' });
    },
  },

  async model(params) {
    let id = params.id;
    let app = this.modelFor('apps.app');
    await this.helpCenterService.forceFetchSite(id);
    let isManagedCustomDomainEnabled =
      app.shouldBypassTlsCutoffDate || Date.parse(app.created_at) > TLS_CUTOFF_DATE;
    let customDomain = null;
    if (isManagedCustomDomainEnabled) {
      let customDomains = await ajax({
        type: 'GET',
        url: `/ember/custom_domains?app_id=${app.id}&help_center_id=${id}`,
      });
      customDomain = customDomains.length > 0 ? customDomains[0] : null;
    }
    return { customDomain, isManagedCustomDomainEnabled };
  },
});
