/* RESPONSIBLE TEAM: team-channels */
import ProductMessagesSettingsRoute from 'embercom/routes/apps/app/settings/base/product-messages-settings-route';
import { inject as service } from '@ember/service';

export default class EmailSpamSettings extends ProductMessagesSettingsRoute {
  @service intl;
  @service store;

  analytics = {
    section: 'settings',
    place: 'email_spam_settings',
    object: 'email_spam_settings',
  };
  get titleToken() {
    return this.intl.t('channels.email.settings.spam_settings.title');
  }

  get app() {
    return this.modelFor('apps.app');
  }

  model() {
    return this.store.findRecord('email-spam-settings', this.app.id);
  }
}
