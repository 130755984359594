/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import type Transition from '@ember/routing/transition';
import { inject as service } from '@ember/service';
import BillingSettingsRoute from 'embercom/routes/apps/app/settings/workspace/billing/settings';
import type IntlService from 'embercom/services/intl';

export default class BillingDetailsIndexRoute extends BillingSettingsRoute {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;

  get titleToken() {
    return this.intl.t('apps.app.billing.subscription');
  }

  queryParams = {
    selectedTab: {
      refreshModel: true,
    },
  };

  get app() {
    return this.appService.app;
  }

  async beforeModel(transition: Transition) {
    if (transition.to.queryParams.selectedTab === 'migration') {
      this.replaceWith(this.routeName, {
        queryParams: {
          selectedTab: 'current',
        },
      });
    }
    return;
  }
}
