/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';

export default class NewChartNewCustomReportRoute extends Route {
  @service store;
  @service appService;
  @service navbarCollapsingService;
  @service router;
  @service notificationsService;
  @service paywallService;
  @service intercomEventService;
  @service reportingMetrics;
  @service customReportsService;

  queryParams = {
    source: '',
    metricProperty: '',
    viewBy: '',
    metricId: '',
  };

  model(params) {
    let metricId = params.metricId || this.reportingMetrics.defaultCustomChartMetric.id;
    let chart = this.store.createRecord('reporting/custom/chart', {
      chartSeries: [{ metricId }],
    });
    chart.updateMetric(metricId, chart.chartSeries.firstObject);

    if (params.viewBy) {
      chart.updateViewBy(params.viewBy);
    }

    let settings =
      this.store.peekAll('reporting/settings')?.firstObject ||
      this.store.queryRecord('reporting/settings', {});
    let report =
      this.store.peekAll('reporting/custom/report').findBy('isNew', true) ||
      this.store.createRecord('reporting/custom/report');
    return hash({
      report,
      settings,
      chart,
    });
  }

  activate() {
    if (!this.appService.app.canSeeR2Beta) {
      this.navbarCollapsingService.setActiveSubmenu('Custom reports');
      this.navbarCollapsingService.toggleNavbarCollapsed();
    }
  }

  @action
  async willTransition(transition) {
    let { report, chart } = this.modelFor(this.routeName);
    let currentPage = 'apps.app.reports.custom-reports.report.new.chart.new';
    let isPaywallActive = this.paywallService.isPaywallActive({
      featureName: 'custom_reports',
    });
    let shouldCollapseNavbar = true;
    if (isPaywallActive) {
      chart.unloadRecord();
    } else if (transition.to.queryParams.cancelling && chart.isNew) {
      chart.unloadRecord();
    } else if (
      !transition.data.skipConfirmation &&
      !transition.to.queryParams.cancelling &&
      !transition.to.queryParams.cr2AddingToChart &&
      chart.isNew &&
      transition.to.name !== currentPage
    ) {
      transition.abort();
      let isNewReport = report.isNew;
      let confirmed = await this.customReportsService.confirmSave(isNewReport);
      if (confirmed === true) {
        transition.data.skipConfirmation = true;
        await report.save({
          adapterOptions: {
            canSeeR2Beta: this.appService.app.canSeeR2Beta,
          },
        });
        if (isNewReport) {
          // No need to await this, as they're navigating away
          this.customReportsService.saveDefaultReportAccess(report);
        }
        await report.saveChart(chart);
        this.notificationsService.notifyConfirmation('Your chart has been saved');
        this.intercomEventService.trackEvent('saved-custom-report');
        if (
          transition.urlMethod === null && // urlMethod is equal to null when the transition is triggered by a history pop state
          transition.to.name === 'apps.app.reports.custom-reports.report.new.index'
        ) {
          this.router.transitionTo('apps.app.reports.custom-reports.report.show', report.id);
        } else {
          transition.retry();
        }
      } else if (confirmed.canceled) {
        transition.data.skipConfirmation = true;
        chart.unloadRecord();
        transition.retry();
      } else if (confirmed.closed) {
        // Workaround for https://github.com/emberjs/ember.js/issues/5210#issuecomment-623967508
        this.router.location.setURL(this.router.currentURL);
        shouldCollapseNavbar = false;
      }
    }
    if (
      this.navbarCollapsingService.collapsed &&
      shouldCollapseNavbar &&
      !this.appService.app.canSeeR2Beta
    ) {
      this.navbarCollapsingService.toggleNavbarCollapsed();
    }
  }

  @action
  didTransition() {
    let params = this.paramsFor(this.routeName);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: 'chart_creator',
      from_url: params.source !== 'custom_report',
    });
  }
}
