/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';
import Ruleset from 'embercom/models/matching-system/ruleset';
import {
  objectTypes,
  matchBehaviors,
} from 'embercom/models/data/matching-system/matching-constants';

export default Route.extend({
  store: service(),
  appService: service(),
  app: readOnly('appService.app'),
  intercomEventService: service(),
  analytics: {
    section: 'tours',
    place: 'tour-editor',
  },

  tourEditRoute: 'apps.app.outbound.tour.edit',

  beforeModel(transition) {
    let admin_id = this.get('app.currentAdmin.id');
    let template_id = transition.to.queryParams.templateId;

    let params = {
      app_id: this.get('app.id'),
      admin_id,
      match_behavior: matchBehaviors.single,
      object_type: objectTypes.tour,
      object_data: {
        from_id: admin_id,
        template_id,
      },
    };
    Ruleset.createForType(this.store, params).then((ruleset) => {
      ruleset.set('isNewRuleset', true);

      this.replaceWith(this.tourEditRoute, ruleset, {
        queryParams: { mode: 'edit' },
      });

      let event = {
        action: 'viewed',
        object: 'new_tour',
        context: 'from_index_page',
        ruleset_id: ruleset.id,
      };

      if (params.object_data.template_id) {
        event.template_id = params.object_data.template_id;
      }

      this.intercomEventService.trackAnalyticsEvent(event);
    });
  },
});
