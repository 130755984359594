/* RESPONSIBLE TEAM: team-growth-opportunities */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/require-snake-case-analytics */
/* eslint-disable ember/no-classic-classes */
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
export default Route.extend({
  intercomEventService: service(),
  redirect(app) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'escape-hatch',
    });
    this.transitionTo('apps.app.index');
  },
});
