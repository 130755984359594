/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { hash } from 'rsvp';
export default Route.extend({
  store: service(),
  titleToken: 'Webhooks',

  model() {
    return hash({
      webhooks: this.store.findAll('developer-home/webhook'),
      topics: this.store.findAll('developer-hub/webhook-topic'),
    });
  },
});
