/* RESPONSIBLE TEAM: team-proactive-support */
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { post } from 'embercom/lib/ajax';

export default class NewVisualTemplateRoute extends Route {
  @service declare notificationsService: $TSFixMe;
  @service declare router: RouterService;
  @service declare intl: IntlService;

  async createTemplate(transition: any) {
    let templateId = transition.to.queryParams['templateId'];
    let app: any = this.modelFor('apps.app');

    try {
      let template = await post('/ember/email_templates', {
        template_id: templateId,
        app_id: app.id,
        type: 'blocks',
      });
      transition.abort();
      return this.router.replaceWith('apps.app.settings.channels.email.edit-template', template.id);
    } catch (error) {
      transition.abort();
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('outbound.settings.email-templates.new-template-error'),
      });
      return this.router.replaceWith('apps.app.settings.channels.email');
    }
  }

  async beforeModel(transition: any) {
    this.createTemplate(transition);
  }
}
