/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';

export default class AppsAppBillingCancelIndexRoute extends Route {
  setupController(controller: $TSFixMe, questions: $TSFixMe, transition: Transition) {
    super.setupController(controller, questions, transition);
    controller.questionQueue = questions.filter(
      ({ isFollowUp }: { isFollowUp: $TSFixMe }) => !isFollowUp,
    );
    controller.currentQuestion = controller.questionQueue.shift();
    controller.currentSelection = controller.currentQuestion.cancellationReasons.firstObject.id;
    controller.responses = [];
    if (controller.currentQuestion.multiSelect) {
      controller.currentQuestion.cancellationReasons.forEach((reason: $TSFixMe) => reason.reset());
    }
  }
}
