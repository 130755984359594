/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable promise/prefer-await-to-then */
import { hash } from 'rsvp';
import { SIGNAL_CONFIGS } from 'embercom/models/reporting/signal';
import { MEETING_BOOKED } from 'embercom/lib/reporting/queries';

import { readOnly } from '@ember/object/computed';

import PerformanceReportBase from 'embercom/routes/apps/app/reports/base/performance-report';
import setupLineChartSignal from 'embercom/lib/inbox/reporting/setup-line-chart-signal';

export default PerformanceReportBase.extend({
  dashboardIdentifier: 'inbox-performance-sales',
  chartIdentifiers: [
    'time-to-close-opportunity',
    'time-to-close-opportunity-counter',
    'meetings-booked-counter',
    'meetings-booked',
  ],
  reportName: 'performance_sales_report',
  paywalled: readOnly('app.canUseRevenueReports'),
  analytics: {
    place: 'sales',
  },
  shouldRedirectFromParent: true,
  titleToken: 'Sales',

  beforeModel() {
    this._super(...arguments);

    if (this.shouldRedirectFromParent) {
      this.replaceWith('apps.app.reports.lead-generation.sales.opportunities');
    }
  },

  filters(options = {}) {
    let teammateFilter = options.teammateFilter || 'teammate_ids';
    let filters = {};
    if (this.teammateIds && !this.get('teamOrTeammate.isAppTeam')) {
      filters[teammateFilter] = this.teammateIds;
    }
    return filters;
  },

  fetchSignals() {
    let range = this.range;

    let signals = hash({
      //These signals represent reference totals and should not be filtered
      opportunityCreated: this._fetchComparisonSignal(
        SIGNAL_CONFIGS.OPPORTUNITY_CREATED,
        'opportunity',
        range,
        {},
      ),
      opportunityCreatedAmount: this._fetchComparisonSignal(
        SIGNAL_CONFIGS.OPPORTUNITY_CREATED_AMOUNT,
        'dollars',
        range,
        {},
      ),
      closedWonAmount: this._fetchComparisonSignal(
        SIGNAL_CONFIGS.CLOSED_WON_AMOUNT,
        'dollars',
        range,
        {},
      ),

      meetingBooked: this._fetchComparisonSignal(
        MEETING_BOOKED,
        'teammate_participant_id',
        range,
        this.filters({ teammateFilter: 'teammate_participant_ids' }),
      ),
      closedWonInfluencedAmount: this._fetchComparisonSignal(
        SIGNAL_CONFIGS.CLOSED_WON_INFLUENCED_AMOUNT,
        'dollars',
        range,
        this.filters(),
      ),
      opportunityInfluenced: this._fetchComparisonSignal(
        SIGNAL_CONFIGS.OPPORTUNITY_INFLUENCED,
        'opportunity',
        range,
        this.filters(),
      ),
      opportunityInfluencedAmount: this._fetchComparisonSignal(
        SIGNAL_CONFIGS.OPPORTUNITY_INFLUENCED_AMOUNT,
        'dollars',
        range,
        this.filters(),
      ),
      teammatePerformance: this._fetchTabularSignal(
        SIGNAL_CONFIGS.TEAMMATE_PERFORMANCE,
        range,
        this.filters(),
      ),
      dealsInfluenced: this._fetchTabularSignal(
        SIGNAL_CONFIGS.DEALS_INFLUENCED,
        range,
        this.filters(),
      ),
    });

    return signals.then((signals) => this._addLineChartSignals(signals));
  },

  _fetchComparisonSignal(...args) {
    return this.reportingService.fetchComparisonSignal(...args);
  },

  _fetchTabularSignal(...args) {
    return this.reportingService.fetchTabularSignal(...args);
  },

  _addLineChartSignals(signals) {
    return {
      ...signals,
      opportunitiesChart: setupLineChartSignal([
        signals.opportunityCreated,
        signals.opportunityInfluenced,
      ]),
      pipelineChart: setupLineChartSignal([
        signals.opportunityCreatedAmount,
        signals.opportunityInfluencedAmount,
      ]),
      closedWonChart: setupLineChartSignal([
        signals.closedWonAmount,
        signals.closedWonInfluencedAmount,
      ]),
    };
  },
});
