/* RESPONSIBLE TEAM: team-ai-agent */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Store from '@ember-data/store';
import type AiContentSegmentsService from 'embercom/services/ai-content-segments-service';
import type RouterService from '@ember/routing/router-service';
import { PAGE_SIZE } from 'embercom/models/data/outbound/constants';
import { getOwner } from '@ember/application';
import { ContentWrapperType } from 'embercom/models/content-service/content-wrapper';
import AiContentLibraryApi from 'embercom/lib/ai-content-library/list-api';
import { aiContentEntities, type Predicate } from 'embercom/lib/ai-content-library/constants';
import { objectNamesToKeys } from 'embercom/models/data/matching-system/matching-constants';
import type Transition from '@ember/routing/transition';
import { Status } from 'embercom/models/data/content-service/content-review-statuses';
import { type ContentSearchParams } from 'embercom/lib/content-service/search-api';

type ModelParams = {
  selectedHelpCenterIds?: string;
  searchTerm: string;
  source: string;
  selectedObjectTypes?: string;
  state: string | null;
  predicates?: Array<Predicate>;
};

type AdditionalSearchableData = {
  source_id?: string;
  help_center_ids?: string[];
};

export default class FinAIAgentContentRoute extends Route {
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare aiContentSegmentsService: AiContentSegmentsService;
  @service declare router: RouterService;
  @service declare store: Store;

  analytics = {
    section: 'ai-chatbot',
    place: 'content',
  };

  get titleToken(): string {
    return this.intl.t('ai-agent.sub-menu.content.title');
  }

  async model(params: ModelParams): Promise<AiContentLibraryApi> {
    let searchParams: ContentSearchParams = {
      object_types:
        params.selectedObjectTypes && params.selectedObjectTypes.length > 0
          ? params.selectedObjectTypes.split(',').map((type) => Number(type))
          : [...aiContentEntities()],
      content_wrapper_types: [ContentWrapperType.AI_CONTENT],
      app_id: this.appService.app.id as string,
      per_page: PAGE_SIZE,
      title: params.searchTerm,
      additional_searchable_data: this.buildAdditionalSearchableData(params),
      states: params.state ? [Number(params.state)] : undefined,
      predicates: params.predicates?.length ? params.predicates : undefined,
    };

    searchParams.content_review_statuses = [Status.Approved, Status.NotApplicable];

    let api = new AiContentLibraryApi(getOwner(this), searchParams);

    await Promise.all([api.loadPage(), this.aiContentSegmentsService.loadSegments()]);

    return api;
  }

  beforeModel() {
    if (this.appService.app.finContentPageDeprecated) {
      this.router.transitionTo('apps.app.knowledge-hub.all-content');
    }
  }

  setupController(controller: $TSFixMe, model: AiContentLibraryApi, transition: Transition): void {
    super.setupController(controller, model, transition);
    let queryParams = transition.to.queryParams;
    let contentType = queryParams.content;
    if (!contentType) {
      return;
    }

    if (!aiContentEntities().includes(Number(objectNamesToKeys[contentType]))) {
      controller.setProperties({
        selectedContentType: undefined,
        selectedContentId: undefined,
      });
      this.redirectToFinContent(transition);
    }
  }

  private redirectToFinContent(transition: Transition): void {
    this.router.transitionTo({
      queryParams: {
        ...transition.to.queryParams,
        selectedContentType: undefined,
        selectedContentId: undefined,
        content: undefined,
        id: undefined,
      },
    });
  }

  private buildAdditionalSearchableData(params: ModelParams): AdditionalSearchableData {
    let additionalSearchableData: AdditionalSearchableData = {};
    if (params.source) {
      additionalSearchableData.source_id = params.source;
    }
    if (params.selectedHelpCenterIds) {
      additionalSearchableData.help_center_ids = params.selectedHelpCenterIds
        ?.split(',')
        .map((id) => String(id));
    }
    return additionalSearchableData;
  }
}
