/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Range from 'embercom/models/reporting/range';
import { tracked } from '@glimmer/tracking';

export default class EditChartCustomReportRoute extends Route {
  @service store;
  @service appService;
  @service navbarCollapsingService;
  @service router;
  @service notificationsService;
  @service paywallService;
  @service intercomEventService;
  @service permissionsService;
  @service customReportsService;

  @tracked originalChartState;

  queryParams = {
    isStandalone: false,
  };

  async model({ chart_id }) {
    let queryParams = this.paramsFor(this.routeName);
    let routeFromName = queryParams.isStandalone
      ? 'apps.app.standalone.reports.report.show'
      : 'apps.app.reports.custom-reports.report.show';
    let { report, settings } = this.modelFor(routeFromName);
    let chart = await this.store.peekRecord('reporting/custom/chart', chart_id);

    if (this.appService.app.canSeeR2Beta && !chart) {
      this.router.transitionTo(routeFromName, report.id);
    }

    this.originalChartState = this.store.createRecord(
      'reporting/custom/chart',
      chart.deepCopyChartAttributes(),
    );

    let timezone = report.reportTimezone;
    let dateRange = new Range(null, null, timezone);
    return { report, chart, settings, dateRange };
  }

  activate() {
    if (!this.appService.app.canSeeR2Beta) {
      this.navbarCollapsingService.setActiveSubmenu('Custom reports');
      this.navbarCollapsingService.toggleNavbarCollapsed();
    }
  }
  get canChangeCustomReports() {
    return this.customReportsService.canChangeCustomReports;
  }
  @action
  async willTransition(transition) {
    let queryParams = this.paramsFor(this.routeName);
    let { chart } = this.modelFor(this.routeName);
    let currentPage = queryParams.isStandalone
      ? 'apps.app.standalone.reports.report.show.chart.edit'
      : 'apps.app.reports.custom-reports.report.show.chart.edit';
    let shouldCollapseNavbar = true;
    let isPaywallActive = this.paywallService.isPaywallActive({
      featureName: 'custom_reports',
    });
    if (isPaywallActive) {
      chart.rollbackAttributes();
    } else if (transition.to.queryParams.cancelling && chart.hasDirtyAttributes) {
      if (chart.isNew && this.appService.app.canSeeR2Beta) {
        let attributes = this.originalChartState.deepCopyChartAttributes();
        chart.setProperties(attributes);
        this.originalChartState.unloadRecord();
      } else {
        chart.rollbackAttributes();
        this.originalChartState.unloadRecord();
      }
    } else if (
      !transition.data.skipConfirmation &&
      !transition.to.queryParams.cancelling &&
      chart.hasDirtyAttributes &&
      !transition.to.queryParams.cr2AddingToChart &&
      transition.to.name !== currentPage &&
      this.canChangeCustomReports
    ) {
      transition.abort();
      let confirmed = await this.customReportsService.confirmSave(false);
      if (confirmed === true) {
        await chart.validateAndSave();
        transition.data.skipConfirmation = true;
        this.notificationsService.notifyConfirmation('Your chart has been saved');
        transition.retry();
      } else if (confirmed.canceled) {
        chart.rollbackAttributes();
        transition.data.skipConfirmation = true;
        transition.retry();
      } else if (confirmed.closed) {
        // Workaround for https://github.com/emberjs/ember.js/issues/5210#issuecomment-623967508
        this.router.location.setURL(this.router.currentURL);
        shouldCollapseNavbar = false;
      }
    }
    if (
      this.navbarCollapsingService.collapsed &&
      shouldCollapseNavbar &&
      !this.appService.app.canSeeR2Beta
    ) {
      this.navbarCollapsingService.toggleNavbarCollapsed();
    }
  }

  @action
  didTransition() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'custom_chart',
    });
  }
}
