/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import ManageTeammatesRoute from 'embercom/routes/apps/app/settings/base/manage-teammates-route';
import { hash } from 'rsvp';
import type Transition from '@ember/routing/transition';

export default class BulkTeammatesRemoveRoute extends ManageTeammatesRoute {
  @service declare appService: $TSFixMe;

  analytics = {
    section: 'settings',
    place: 'teammates-remove',
  };

  async beforeModel(transition: Transition) {
    await super.beforeModel(...arguments);
    if (!transition.data.teammates) {
      this.transitionTo('apps.app.settings.workspace.teammates');
    }
  }

  model(_params: $TSFixMe, transition: Transition) {
    return hash({
      app: this.appService.app,
      teammates: transition.data.teammates as $TSFixMe,
      reassignments: { teams: {} },
    });
  }
}
