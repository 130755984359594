/* RESPONSIBLE TEAM: team-customer-data-platform */
import BaseSettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

export default class extends BaseSettingsRoute {
  @service declare intercomEventService;
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;

  get titleToken() {
    return this.intl.t('new-settings.data.custom-objects.page-title');
  }

  model() {
    return this.modelFor('apps.app.settings.data.custom-objects');
  }
}
