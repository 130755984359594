/* RESPONSIBLE TEAM: team-help-desk-experience */
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { type Router } from '@ember/routing';

export default class SearchRoute extends Route {
  @service declare router: Router;

  redirect(): any {
    this.router.transitionTo('inbox.workspace.inbox.search');
  }
}
