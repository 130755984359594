/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';

export default class TopicRoute extends IntercomRoute {
  @service notificationsService;
  @service reportingService;
  @service store;

  async retrieveTopic(topicId) {
    try {
      return await this.store.findRecord('conversational-insights/conversation-topic', topicId);
    } catch (err) {
      this.notificationsService.notifyResponseError(err, {
        default: `No such topic id: ${topicId}`,
      });
      this.transitionTo('apps.app.reports.conversational-insights');
    }
  }

  async model(params) {
    let topic = await this.retrieveTopic(params.topic_id);

    return {
      topic,
    };
  }
}
