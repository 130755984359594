/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controller-access-in-routes */
/* eslint-disable ember/no-classic-classes */
import { readOnly } from '@ember/object/computed';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { stats } from 'embercom/models/data/stats-system/stats-constants';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';

export default Route.extend({
  store: service(),
  appService: service(),
  app: readOnly('appService.app'),
  intercomEventService: service(),
  outboundHomeService: service(),
  analytics: {
    section: 'tours',
    place: 'tour-editor',
  },

  model(params) {
    return this.store.findRecord('matching-system/ruleset', params.id, {
      adapterOptions: {
        stats: [stats.receipt, stats.completion, stats.goalSuccess, stats.tourStepFailure],
      },
    });
  },

  afterModel(model, transition) {
    this.outboundHomeService.setActiveList(objectTypes.tour);
    let referer = transition.to.queryParams.referer;
    if (referer === 'insert_tour_modal') {
      let tour_id = model.get('rulesetLinks.firstObject.object.id');
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'edit_url',
        place: 'insert_tour_modal',
        section: 'tours',
        tour_id,
      });
    }
  },

  redirect(model, _) {
    if (model.isMemberOfSeries) {
      this.transitionTo('apps.app.outbound.series.series', model.seriesRelation.series_id, {
        queryParams: { mode: 'view', nodeId: model.seriesRelation.node_id },
      });
    }
  },

  actions: {
    didTransition() {
      let eventObject = this.controller.get('editing') ? 'edit_tour' : 'show_tour';
      this.intercomEventService.trackAnalyticsEvent({
        action: 'viewed',
        object: eventObject,
        ruleset_id: this.controller.get('model.id'),
      });
    },
  },
});
